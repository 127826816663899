import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const ContactDataPage = ({ Trans, dispatch }) => {
  return (
    <>
      <div style={{ height: 585, width: '90%', margin: 'auto', marginTop: '60px' }}>
        <Typography sx={{ mb: '24px' }} variant='h6'>
          Контактные данные
        </Typography>
        <div
          style={{ display: 'flex', height: '70%', background: 'antiquewhite', padding: '50px' }}
        >
          <div style={{ flexGrow: 1 }}>
            <p>
              <b>
                Управление предпринимательства и индустриально-инновационного развития Павлодарской
                области
              </b>
            </p>

            <p>Адрес: 140000, г. Павлодар, ул. Астана, 61</p>
            <p>Телефон: +7 (7182) 32-33-40, +7 (7182) 32-33-58</p>
            <p>E-mail: kense.upiir@pavlodar.gov.kz</p>
            <p>
              Банковские реквизиты: ГУ «Управление предпринимательства и
              индустриально-инновационного развития Павлодарской области»
            </p>
            <p>БИН 210140016914</p>
            <p>ИИК KZ10070102KSN4501000</p>
            <p>ИИК KZ10070102KSN4501000</p>
            <p>БИК KKMFKZ2A</p>
            <p>ГУ Департамент Казначейства по Павлодарской области</p>
            <Link
              target='_'
              href='https://www.gov.kz/memleket/entities/pavlodar-uiir/about?lang=ru'
              underline='hover'
              color='inherit'
            >
              Сайт
            </Link>
          </div>
        </div>
        <div style={{ display: 'flex', height: '30%', background: 'bisque', padding: '50px' }}>
          <div style={{ flexGrow: 1 }}>
            <p>
              <b>
                Государственное учреждение "Управление цифровых технологий Павлодарской области"
              </b>
            </p>
            <p>Адрес: 140000, г. Павлодар, ул. Астана, 61</p>
            <p>Телефон: +7 (7182) 32-20-04</p>
            <p>E-mail: uct@pavlodar.gov.kz</p>
            <Link
              target='_'
              href='https://www.gov.kz/memleket/entities/pavlodar-digital/about?lang=ru'
              underline='hover'
              color='inherit'
            >
              Сайт
            </Link>
          </div>
        </div>
        <div
          style={{ display: 'flex', height: '30%', background: 'antiquewhite', padding: '50px' }}
        >
          <div style={{ flexGrow: 1 }}>
            <p>
              <b>Управление сельского хозяйства Павлодарской области</b>
            </p>

            <p>Адрес: 140000, г. Павлодар, ул. Астана, 61</p>
            <p>Телефон: + 7 (7182) 32-32-04</p>
            <p>E-mail: kense.dsh@pavlodar.gov.kz</p>
            <Link
              target='_'
              href='https://www.gov.kz/memleket/entities/pavlodar-depagri?lang=ru'
              underline='hover'
              color='inherit'
            >
              Сайт
            </Link>
          </div>
        </div>
        <div style={{ display: 'flex', height: '30%', background: 'bisque', padding: '50px' }}>
          <div style={{ flexGrow: 1 }}>
            <p>
              <b>Аппарат Акима Павлодарской области</b>
            </p>
            <p>Адрес: 140000, г. Павлодар, ул. академика Сатпаева, 49</p>
            <p>Телефон: +7 (7182) 66-39-96, 65-10-50, 654022, 663925, 663842</p>
            <p>E-mail: kense.ao@pavlodar.gov.kz</p>
            <Link
              target='_'
              href='https://www.gov.kz/memleket/entities/pavlodar/about?lang=ru'
              underline='hover'
              color='inherit'
            >
              Сайт
            </Link>
          </div>
        </div>
      </div>{' '}
    </>
  );
};

export default ContactDataPage;
