import React from 'react';

import s from './DefaultMainPage.module.css';

import QuestionBlock from '../QuestionBlock/QuestionBlock';
import NewsWrapper from './NewsWrapper/NewsWrapper';

const DefaultMainPage = ({ Trans, dispatch }) => {
  return (
    <>
      <div className={s.root}>
        <div className={s.DefaultMainPage}>
          <NewsWrapper Trans={Trans} />
          {/* <QuestionBlock Trans={Trans} /> */}

          <img
            src='https://biznes.15000pvl.kz/file/img_1.jpg'
            style={{
              width: '60%',
              margin: 'auto',
              display: 'block',
              textAlign: 'center',
              marginTop: '50px',
              boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 5%)',
              borderRadius: '40px',
            }}
          ></img>
        </div>
      </div>
    </>
  );
};

export default DefaultMainPage;
