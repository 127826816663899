import React from 'react';

// import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ForkRightIcon from '@mui/icons-material/ForkRight';

const TaxesDrawer = ({ Trans, dispatch, anchor, handleDrawerClose }) => {
  //   const navigate = useNavigate();

  const menu_item = [
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.TaxesDrawer.item1' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.TaxesDrawer.item2' /> },
  ];

  return (
    <>
      <Box sx={{ width: 450, marginTop: '80px' }} role='presentation'>
        <List>
          {menu_item.map((text, index) => (
            <ListItem key={text.item} disablePadding>
              {text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.TaxesDrawer.item2' ? (
                <ListItemButton
                // onClick={() => {handleDrawerClose(); navigate('/services/taxes/selection-of-tax-regime') }}
                >
                  <ListItemIcon>
                    <ForkRightIcon />
                  </ListItemIcon>
                  <Link
                    target='_'
                    href='https://egov.kz/cms/ru/articles/2Ftax_rate2014'
                    underline='hover'
                    color='inherit'
                  >
                    {text.item}
                  </Link>{' '}
                </ListItemButton>
              ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.TaxesDrawer.item1' ? (
                <ListItemButton
                // onClick={() => {handleDrawerClose(); navigate('/services/taxes/selection-of-tax-regime') }}
                >
                  <ListItemIcon>
                    <ForkRightIcon />
                  </ListItemIcon>
                  <Link
                    target='_'
                    href='https://cdb.kz/sistema/page/nalogovyy-kalendar-na-2018-god-dlya-yuridicheskikh-lits-i-individualnykh-predprinimateley/'
                    underline='hover'
                    color='inherit'
                  >
                    {text.item}
                  </Link>
                </ListItemButton>
              ) : (
                <ListItemButton>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default TaxesDrawer;
