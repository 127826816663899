import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { appealsAPI } from '../../../api/api';
import { infoData } from '../../../common/helpers/sliceToasts';
import Link from '@mui/material/Link';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';

const AppealViewPDF = ({ CloseModal, IDforPDFAppeal, IDforAppeal, modal, Trans }) => {
  const [pdfName, setpdfName] = React.useState('none');
  const [pdfSigexID, setpdfSigexID] = React.useState('none');
  const [pdfAnswerName, setpdfAnswerName] = React.useState('none');

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
    },
  });

  const styleModal = {
    position: 'absolute',
    top: 350,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const handleCloseModal = () => {
    CloseModal();
  };
  //
  React.useEffect(() => {
    const fd = new FormData();
    fd.append('id', IDforAppeal == 'none' ? IDforPDFAppeal : IDforAppeal);
    const appealsResponse = appealsAPI.fetchAppealPDF(fd);
    const appealsResponseDraft = appealsAPI.fetchgetPDFNameDraft(fd);

    IDforAppeal == 'none'
      ? appealsResponse.then((res) => {
          setpdfName(res[0].MainDataB64);
          setpdfSigexID(res[0].sigexId);
        })
      : appealsResponseDraft.then((res) => setpdfName(res[0].MainDataB64));

    modal == 'pdfAnswer'
      ? appealsResponse.then((res) => setpdfAnswerName(res[0].answer_pdf))
      : console.info();

    modal == 'pdfAnswer' ? appealsResponse.then((res) => console.log(res[0])) : console.info();
  }, [IDforPDFAppeal]);

  return (
    <>
      <Modal
        open={true}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <div className={s.close_auth_modal} onClick={handleCloseModal}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div>
              <Link
                target='_'
                href={`https://sigex.kz/show/?id=${pdfSigexID}#documentMenu`}
                underline='hover'
                color='inherit'
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item23' /> #
                {pdfSigexID}
              </Link>
            </div>
          </div>

          <Box sx={{ minWidth: 120 }}>
            {pdfName == 'none' ? null : (
              <iframe
                title='myBook'
                src={
                  modal == 'pdfAnswer'
                    ? 'https://biznes.15000pvl.kz/file/appeal/answer/' + pdfAnswerName
                    : IDforAppeal == 'none'
                    ? 'https://biznes.15000pvl.kz/file/appeal/' + pdfName
                    : IDforPDFAppeal == 'none'
                    ? 'https://biznes.15000pvl.kz/file/appeal_draft/' + pdfName
                    : infoData('Возникла ошибка, попробуйте обновить страницу')
                }
                height='500'
                width='800'
                frameBorder='0'
                scrolling='no'
                allowFullScreen={false}
              ></iframe>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AppealViewPDF;
