import React, { useState } from 'react';

import AdminChooseAppealsType from '../../components/AdminChooseAppealsType/AdminChooseAppealsType';

const AdminAppealPage = ({ Trans, dispatch, ws, status }) => {
  const [SendAppealsReload, setSendAppealsReload] = useState(false);

  React.useEffect(() => {}, [SendAppealsReload]);

  return (
    <>
      <AdminChooseAppealsType Trans={Trans} dispatch={dispatch} ws={ws} status={status} />
    </>
  );
};

export default AdminAppealPage;
