import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import s from '../../pages/AppealsPage/AppealsPage.module.css';
import ds from '../AnalitikaBusinessObjectsBlock/AnalitikaBusinessObjectsBlock.module.css';

import { ruRU } from '../../common/helpers/ruRU';
import { kzKZ } from '../../common/helpers/kzKZ';
import { enEN } from '../../common/helpers/enEN';
import { errorData } from '../../common/helpers/sliceToasts';
import { infoData } from '../../common/helpers/sliceToasts';
import { mainPage } from '../../api/api';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { CSVDownload } from 'react-csv';

import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarFilterButton,
  GridToolbarContainer,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import clsx from 'clsx';

const StatHistory = ({ Trans, dispatch }) => {
  const user = useSelector((state) => state.store.mainSlice.user);

  const [getAllAppeals, setGetAllAppeals] = React.useState([]);
  const [Appeals, setAppeals] = React.useState([]);
  const modal = useSelector((state) => state.store.mainSlice.PDFmodal);
  const [IDforAcceptAppeal, setIDforAcceptAppeal] = React.useState('none');
  const [OpenModal, setOpenModal] = useState(false);
  const [OpenModalAdd, setOpenModalAdd] = useState(false);
  const [ViewData, setViewData] = useState('');
  const [titlesArr, settitlesArr] = useState([]);
  const [numbersArr, setnumbersArr] = useState([]);
  const [csvData, setcsvData] = useState('');
  const [resultWrapper, setResultWrapper] = useState('none');

  const lang = localStorage.getItem('lang');
  const [userStatus, setUserStatus] = React.useState(false);
  const [SotrudnikStatus, setSotrudnikStatus] = React.useState(false);
  const [AdminStatus, setAdminStatus] = React.useState(false);
  const [textFieldValue1, settextFieldValue1] = useState('');
  const [textFieldValue2, settextFieldValue2] = useState('');
  const [textFieldValue3, settextFieldValue3] = useState('');
  const [textFieldValue4, settextFieldValue4] = useState('');
  const [textFieldValue5, settextFieldValue5] = useState('');
  const [textFieldValue6, settextFieldValue6] = useState('');
  const [textFieldValue7, settextFieldValue7] = useState('');
  const [textFieldValue8, settextFieldValue8] = useState('');
  const [textFieldValue9, settextFieldValue9] = useState('');
  const [textFieldValueLang, settextFieldValueLang] = useState('');

  const optionsData = [
    { label: 'Русский', data: 0 },
    { label: 'Қазақ тілі', data: 1 },
    { label: 'English', data: 2 },
  ];

  React.useEffect(() => {
    console.log(modal);
  }, [modal]);

  React.useEffect(() => {
    if (user) {
      const ServiceResponse = mainPage.userHistory({ userID: user?.id });
      ServiceResponse.then((res) => setGetAllAppeals(res)).catch((err) => console.log(err));
    }
  }, []);

  React.useEffect(() => {
    var newAppeals = getAllAppeals?.map((res) => {
      return createData(
        res.id,
        res.name,
        res.date,
        res.date,
        res.status == '3' ? 'Завершен' : 'Ошибка',
        res.date_from,
        res.date_to,
        'Скачать',
        'Открыть',
      );
    });

    setAppeals(newAppeals);
  }, [getAllAppeals]);

  const styleModal = {
    position: 'absolute',
    top: 390,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const styleModal2 = {
    position: 'absolute',
    top: 390,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
    display: 'flex',
    flexDirection: 'column',
  };

  function FormRow(data) {
    return (
      <React.Fragment>
        <Grid item xs={9}>
          <Paper sx={{ background: '#e1e1e1' }}>{data.data?.name}</Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ background: '#e1e1e1' }}>{data.data?.data}</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  const handleOpenResult = async (ids) => {
    setResultWrapper('block');

    console.log(ids);
    const dates = {
      date1: ids.date_from,
      date2: ids.date_to,
      userID: user.id,
    };

    const data = await mainPage.ObjectsByDate(dates);

    var result = data.reduce(function (r, a) {
      r[a.sub_name] = r[a.sub_name] || [];
      r[a.sub_name].push(a);
      return r;
    }, Object.create(null));

    var Data = [];
    var titlesArr = [];
    var numbersArr = [];

    Object.keys(result).forEach(function (key, index) {
      Data.push({ name: key, data: result[key].length });
      titlesArr.push(key);
      numbersArr.push(result[key].length);
    });

    console.log(titlesArr);
    console.log(numbersArr);

    setViewData(Data);
    settitlesArr(titlesArr);
    setnumbersArr(numbersArr);
  };

  const handleCSV = (ids) => {
    handleOpenResult(ids);

    try {
      var majorArr = [];
      var headerArr = [];

      headerArr.push('Тип');
      headerArr.push('Количество');

      majorArr.push(headerArr);

      ViewData.map((data) => {
        var minorArr = [];
        minorArr.push(data.name);
        minorArr.push(data.data);

        majorArr.push(minorArr);
      });

      setcsvData(majorArr);

      setTimeout(() => clearCSV(), 2000);
    } catch {
      console.log('err');
    }
  };

  const clearCSV = () => {
    setcsvData('');
  };

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
      refused: {
        main: '#FF0000',
        contrastText: '#fff',
      },
    },
  });

  const myTheme = createTheme({
    components: {
      //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
      MuiDataGrid: {
        styleOverrides: {
          row: {
            '&.Mui-selected': {
              backgroundColor: '#47af6f69;',
              color: '#28323E',
              '&:hover': {
                backgroundColor: '#47af6fa6',
              },
            },
          },
        },
      },
    },
  });

  const CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        <svg width='120' height='100' viewBox='0 0 184 152' aria-hidden focusable='false'>
          <g fill='none' fillRule='evenodd'>
            <g transform='translate(24 31.67)'>
              <ellipse
                className='ant-empty-img-5'
                cx='67.797'
                cy='106.89'
                rx='67.797'
                ry='12.668'
              />
              <path
                className='ant-empty-img-1'
                d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
              />
              <path
                className='ant-empty-img-2'
                d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
              />
              <path
                className='ant-empty-img-3'
                d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
              />
            </g>
            <path
              className='ant-empty-img-3'
              d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
            />
            <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
              <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
              <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          <Trans i18nKey='dopSection.item12' />
        </Box>
      </StyledGridOverlay>
    );
  };

  const GridColDef = [
    {
      field: 'id',
      headerName: '№',
      width: 100,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
    },
    {
      field: 'name',
      headerName: <Trans i18nKey='dopSection.item27' />,
      minWidth: 400,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
    },
    {
      field: 'date',
      headerName: <Trans i18nKey='dopSection.item28' />,
      minWidth: 200,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
    },
    {
      field: 'date2',
      headerName: <Trans i18nKey='dopSection.item29' />,
      minWidth: 200,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
    },
    {
      field: 'status',
      headerName: <Trans i18nKey='dopSection.item30' />,
      minWidth: 100,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
      },
    },
    {
      field: 'download',
      headerName: <Trans i18nKey='dopSection.item31' />,
      minWidth: 100,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          open: params.value == 'Скачать',
        });
      },
    },
    {
      field: 'open',
      headerName: <Trans i18nKey='dopSection.item32' />,
      minWidth: 100,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          open: params.value == 'Открыть',
        });
      },
    },
  ];

  const createData = (id, name, date, date2, status, date_from, date_to, download, open) => {
    return {
      id,
      name,
      date,
      date2,
      status,
      date_from,
      date_to,
      download,
      open,
    };
  };

  React.useEffect(() => {
    user
      ? user.status_user == '0'
        ? setUserStatus(user.status_user)
        : user.status_user == '1'
        ? setSotrudnikStatus(user.status_user)
        : user.status_user == '2'
        ? setAdminStatus(user.status_user)
        : errorData('Ошибка аутентификации')
      : console.info();
  }, [user]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
          }}
        >
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
        <div style={{ marginTop: '50px' }}></div>
        <GridToolbarFilterButton
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
            color: '#454545',
          }}
        />
      </GridToolbarContainer>
    );
  };
  //
  const SelectionHandler = (newSelection) => {
    newSelection.field == 'download'
      ? handleCSV(newSelection.row)
      : handleOpenModal(newSelection.row);
  };
  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const handleOpenModal = (ids) => {
    setIDforAcceptAppeal(ids);
    handleOpenResult(ids);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };

  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  return (
    <>
      <div style={{ height: 585, width: '90%', margin: 'auto', marginTop: '60px' }}>
        {csvData && <CSVDownload data={csvData} target='_blank' />}
        <Typography sx={{ mb: '24px' }} variant='h6'>
          <Trans i18nKey='dopSection.item27' />
        </Typography>

        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: 585,
                width: '100%',
                '& .super-app.negative4': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff0000',
                  fontWeight: '600',
                },
                '& .super-app.negative': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#1a3e72',
                  fontWeight: '600',
                },
                '& .super-app.positive': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app.positive2': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app.positive3': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app-theme--header': {
                  backgroundColor: '#e0e0e0',
                  paddingLeft: '10px',
                },
                '& .super-app-theme--pdf': {
                  backgroundColor: '#e0e0e0',
                  paddingLeft: '10px',
                },
                '& .super-app-theme--cell': {
                  backgroundColor: 'rgba(224, 183, 60, 0.55)',
                  color: '#1a3e72',
                  fontWeight: '600',
                },

                '& .super-app.type': {
                  backgroundColor: '#cdccca8c',
                },
                '& .super-app.about': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '& .super-app.title': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '& .super-app.numberAndDate': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'pre-line',
                  lineHeight: 'normal',
                  textAlign: 'center',
                },
                '.MuiDataGrid-columnHeaderTitleContainer': {
                  backgroundColor: '#e0e0e0',
                },
                '& .super-app-theme--open': {
                  backgroundColor: '#ff8c00',
                  paddingLeft: '10px',
                },
                '& .super-app.open': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff7f00',
                  fontWeight: '600',
                  color: '#ff7f00',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
              }}
            >
              <ThemeProvider theme={myTheme}>
                <DataGrid
                  getRowHeight={() => 70}
                  pagination
                  disableExport
                  disableReorder
                  disableColumnMenu
                  rowsPerPageOptions={[5]}
                  pageSize={8}
                  localeText={
                    lang == 'ru' ? ruRU : lang == 'kz' ? kzKZ : lang == 'en' ? enEN : null
                  }
                  rows={Appeals}
                  columns={GridColDef}
                  onCellClick={(newSelection) => {
                    SelectionHandler(newSelection);
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterLogicOperator: GridLinkOperator.Or,
                      },
                    },
                  }}
                  components={{
                    // Toolbar: QuickSearchToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Toolbar: CustomToolbar,
                    Pagination: CustomPagination,
                  }}
                  componentsProps={{
                    panel: {
                      placement: 'top',
                    },
                  }}
                />
              </ThemeProvider>
            </Box>
          </div>
        </div>
      </div>

      <Modal
        open={OpenModal}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          {/* <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title='Введенные данные будут утеряны'
          > */}
          <div onClick={handleCloseModal}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          {/* </Tooltip> */}
          <div className={s.title_auth_modal}>
            <div>{IDforAcceptAppeal.name}</div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Trans i18nKey='dopSection.item28' />
            <Typography sx={{ mb: '24px' }} variant='h6'>
              {IDforAcceptAppeal.date}
            </Typography>
            <Trans i18nKey='dopSection.item29' />
            <Typography sx={{ mb: '24px' }} variant='h6'>
              {IDforAcceptAppeal.date}
            </Typography>

            <div
              id='divDownload'
              style={{ padding: '20px', margin: 'auto', overflow: 'scroll', height: '150px' }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  {/* xs={12} - 100% width,  xs={12} - 50% width*/}
                  <Grid item xs={12}>
                    <Grid container item xs={12} spacing={2}>
                      {ViewData ? ViewData.map((data) => <FormRow data={data} />) : console.info()}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Box>

          <ThemeProvider theme={theme}>
            <Button
              color='neutral'
              sx={{ mt: '24px', height: '48px', width: '100%' }}
              variant='outlined'
              onClick={handleCloseModal}
            >
              <Trans i18nKey='dopSection.item11' />
            </Button>
          </ThemeProvider>
        </Box>
      </Modal>
    </>
  );
};

export default StatHistory;
