import React from 'react';

import s from './CalculationForUrbanBusiness.module.css';

import CalculationForUrbanBusinessBlock from '../../components/CalculationForUrbanBusinessBlock/CalculationForUrbanBusinessBlock';

const CalculationForUrbanBusiness = ({ Trans, dispatch }) => {

  return (
    <>

    <div className={s.Wrapper}>
        <div className={s.title}>
            <p>Сервисы - Расчет бизнес плана - Онлайн-расчет бизнес-плана для городских видов бизнеса</p>
        </div>
        <div className={s.maintitle}>
            <p>Онлайн-расчет бизнес-плана для городских видов бизнеса</p>
        </div>

        <CalculationForUrbanBusinessBlock />
        
    </div>


    </>
  );
};


export default CalculationForUrbanBusiness;
