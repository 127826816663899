import { createSlice } from '@reduxjs/toolkit';

const ServicesSlice = createSlice({
  name: 'Services',
  initialState: {
    ExampleCounts: '',
  },
  reducers: {
    setExampleCounts(state, action) {
      state.ExampleCounts = action.payload;
    },
  },
  extraReducers: {},
});
export const { setExampleCounts } = ServicesSlice.actions;

export default ServicesSlice.reducer;
