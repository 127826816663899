import React from 'react';

import s from './AnnouncedPurchases.module.css';

import AnnouncedPurchasesBlock from '../../components/AnnouncedPurchasesBlock/AnnouncedPurchasesBlock';

const AnnouncedPurchases = ({ Trans, dispatch }) => {






  return (
    <>

    <div className={s.Wrapper}>
        <div className={s.title}>
            <p>Сервисы - Закупки - Объявленные закупки</p>
        </div>
        <div className={s.maintitle}>
            <p>Объявленные закупки</p>
        </div>

        <AnnouncedPurchasesBlock />
    </div>


    </>
  );
};


export default AnnouncedPurchases;
