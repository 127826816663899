import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ServiceOptionsStates } from '../../../redux/slices/mainSlice';

import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import KreditsDrawer from './KreditsDrawer/KreditsDrawer';
import TaxesDrawer from './TaxesDrawer/TaxesDrawer';
import PurchasesDrawer from './PurchasesDrawer/PurchasesDrawer';
import LeasingDrawer from './LeasingDrawer/LeasingDrawer';
import BusinessPropertyDrawer from './BusinessPropertyDrawer/BusinessPropertyDrawer';
import BusinessPlanCalculationDrawer from './BusinessPlanCalculationDrawer/BusinessPlanCalculationDrawer';
import MarketAnalysisDrawer from './MarketAnalysisDrawer/MarketAnalysisDrawer';

const ServiceDrawer = ({ Trans, dispatch, width, handleDrawerClose }) => {
  const theme = useTheme();
  const divDrawerBlock = React.useRef(null);
  const [state, setState] = React.useState({ left: false });
  const tabs = useSelector((state) => state.store.mainSlice.ServiceOptionsState);
  const navigate = useNavigate();

  var warrObjects = [];

  const handleDrawerClose2 = () => {
    setState({ ...state, ['left']: false });
  };

  const toggleDrawer = (anchor, open, cat) => {
    setState({ ...state, [anchor]: open });
    makeMenu(cat);
  };

  const handleClick = (item) => {
    toggleDrawer('left', true, item);
  };

  const makeMenu = (cat) => {
    for (let i = 0; i < 3; i++) {
      let user = makeCat(cat);
      warrObjects[i] = user;
    }
    dispatch(ServiceOptionsStates(warrObjects));
  };

  const makeCat = (cat) => {
    return {
      cat: cat,
      side: 'left',
    };
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'left',
  }));

  const list = (anchor) =>
    anchor === 'item 1' ? (
      <KreditsDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : anchor === 'item 9' ? (
      <TaxesDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : anchor === 'item 6' ? (
      <PurchasesDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : anchor === 'item 5' ? (
      <LeasingDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : anchor === 'item 4' ? (
      <BusinessPropertyDrawer
        dispatch={dispatch}
        Trans={Trans}
        handleDrawerClose={handleDrawerClose}
      />
    ) : anchor === 'item 3' ? (
      <BusinessPlanCalculationDrawer
        dispatch={dispatch}
        Trans={Trans}
        handleDrawerClose={handleDrawerClose}
      />
    ) : anchor === 'item 2' ? (
      <MarketAnalysisDrawer
        dispatch={dispatch}
        Trans={Trans}
        handleDrawerClose={handleDrawerClose}
      />
    ) : (
      console.log(1111)
    );

  const menu_item = [
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item1' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item2' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item3' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item4' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item5' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item6' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item7' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item8' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item9' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.item10' /> },
  ];

  return (
    <>
      <div ref={divDrawerBlock}>
        <Box sx={{ width: 450, marginTop: '50px' }} role='presentation'>
          <List>
            {menu_item.map((text, index) => (
              <ListItem key={index} disablePadding>
                {text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item1' ? (
                  <ListItemButton onClick={() => handleClick('item 1')}>
                    <ListItemIcon>
                      <Filter5Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item9' ? (
                  <ListItemButton onClick={() => handleClick('item 9')}>
                    <ListItemIcon>
                      <Filter2Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item6' ? (
                  <ListItemButton onClick={() => handleClick('item 6')}>
                    <ListItemIcon>
                      <Filter2Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item5' ? (
                  <ListItemButton onClick={() => handleClick('item 5')}>
                    <ListItemIcon>
                      <Filter2Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item4' ? (
                  <ListItemButton onClick={() => handleClick('item 4')}>
                    <ListItemIcon>
                      <Filter1Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item3' ? (
                  <ListItemButton onClick={() => handleClick('item 3')}>
                    <ListItemIcon>
                      <Filter1Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item2' ? (
                  <ListItemButton onClick={() => handleClick('item 2')}>
                    <ListItemIcon>
                      <Filter3Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item7' ? (
                  <ListItemButton>
                    <ListItemIcon>
                      <ForkRightIcon />
                    </ListItemIcon>
                    <Link
                      target='_'
                      href='https://egov.kz/cms/ru/services/pass262_mne'
                      underline='hover'
                      color='inherit'
                    >
                      {text.item}
                    </Link>
                  </ListItemButton>
                ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.item8' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/services/support-measures');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item.props.i18nKey == 'DefaultSideBar.ServiceDrawer.item10' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/services/contact-details');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : (
                  <ListItemButton>
                    <ListItemIcon>
                      <CloseIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
          {/* <Divider /> */}
          {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text.item} disablePadding>
            <ListItemButton>
                <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text.item} />
            </ListItemButton>
            </ListItem>
        ))}
        </List> */}
        </Box>
      </div>
      {tabs.map((anchor, index) => (
        <React.Fragment key={index}>
          <div>
            <Drawer
              BackdropProps={{ sx: { backgroundColor: 'rgb(0 0 0 / 15%) ' } }}
              PaperProps={{ sx: { left: width + 450, boxShadow: 'none' } }}
              anchor={anchor.side}
              open={state[anchor.side]}
              // onClose={toggleDrawer(anchor.side, false)}
              onClose={console.log(4444444444444)}
              variant={'temporary'}
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose2}>
                  {divDrawerBlock.current === null
                    ? null
                    : console.log(divDrawerBlock.current.getBoundingClientRect())}
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </DrawerHeader>

              {list(anchor.cat)}
            </Drawer>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default ServiceDrawer;
