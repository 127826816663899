import * as React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import { useForm } from 'react-hook-form';

import s from './header.module.css';
import LoadingDots from '../LoadingDots/LoadingDots';
import { mainPage } from '../../api/api';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '25%',
  left: '81%',
  transform: 'translate(-81%, -25%)',
  maxWidth: 464,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingTop: '33px',
  padding: '33px 48px',
  boxSizing: 'border-box',
};
const ModalActivateRecovery = ({
  openAcivateRecovery,
  handleCloseAcivateRecovery,
  activateRecoveryIin,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [isErrorPass, setIsErrorPass] = React.useState(false);
  const [isLoadingActivate, setIsLoadingActivate] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleActivateRecovery = (data) => {
    setIsErrorPass(false);

    if (data.password === data.password_agree) {
      setIsLoadingActivate(true);

      const fd = new FormData();
      fd.append('IIN', activateRecoveryIin);
      fd.append('password', data.password);
      const activate = mainPage.activateAccount(fd);
      activate.then((res) => {
        if (res) {
          setIsLoadingActivate(false);
          setIsSuccess(true);
          setTimeout(() => {
            reset();
            handleCloseAcivateRecovery();
            navigate('/');
          }, 2000);
        }
      });
    } else {
      console.log('no');
      setIsErrorPass(true);
    }
  };

  return (
    <Modal
      open={openAcivateRecovery}
      onClose={handleCloseAcivateRecovery}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className={s.close_auth_modal} onClick={handleCloseAcivateRecovery}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132"
              stroke="#292929"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={s.title_auth_modal}>
          <div className={s.title_auth_modal}>Активация/восстановление пароля</div>
        </div>
        <form className={s.form_registration} onSubmit={handleSubmit(handleActivateRecovery)}>
          <div className={s.labelNinputReg} style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="">ИИН</label>
            <TextField value={activateRecoveryIin} {...register('IIN')} />
          </div>
          <div className={s.activeRecovery}>
            <div>Придумайте новый пароль.</div>
            <div>Пароль должен содержать:</div>
            <ul>
              <li> минимум 8, максимум 12 символов,</li>
              <li>минимум 1 цифру</li>
              <li>минимум 1 букву латинского алфавита в верхнем регистре,</li>
              <li>минимум 1 букву латинского алфавита в нижнем регистре,</li>
              <li>минимум 1 специальный символ (@ # $ % &)</li>
            </ul>
          </div>
          <div
            className={s.labelNinputReg}
            style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
            <label htmlFor="">Пароль</label>
            <TextField
              id="outlined-required"
              type="password"
              {...register('password', {
                required: 'Введите пароль',
                pattern: {
                  value:
                    /^(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>]){1,})(?!.*(.)\1{2})([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>]{8,12})/,
                  message: 'Не соблюдены минимальные требования к паролю',
                },
                maxLength: {
                  value: 12,
                  message: 'Максимум 12 символов',
                },
                minLength: {
                  value: 8,
                  message: 'Минимум 8 символов',
                },
              })}
            />
            <div style={{ position: 'static', color: 'red' }}>
              {errors?.password && <span>{errors?.password?.message}</span>}
            </div>
          </div>

          <div className={s.labelNinputReg} style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="">Подтверждение пароля</label>
            <TextField
              id="outlined-required"
              type="password"
              onChange={() => isErrorPass(false)}
              {...register('password_agree', {
                required: 'Повторите пароль',
              })}
            />
          </div>
          <div style={{ color: 'red' }}>
            {errors?.password_agree && <span>{errors?.password_agree?.message}</span>}

            {isErrorPass && <span>Пароли не совпадают</span>}
          </div>
          {isLoadingActivate && (
            <div className={s.status_auth} style={{ marginTop: '20px' }}>
              <div>Активация/восстановление</div>
              <LoadingDots />
            </div>
          )}
          {isSuccess && (
            <div className={s.status_auth} style={{ marginTop: '20px' }}>
              <div>
                Активация/восстановление прошла успешно, сейчас произойдет перенаправление на
                главную страницу
              </div>
            </div>
          )}
          <button className={s.btn_login} style={{ marginTop: '40px' }}>
            Подтвердить
          </button>
          <button
            className={s.btn_cancel_modal_reg}
            style={{ marginTop: '24px' }}
            onClick={handleCloseAcivateRecovery}>
            Отмена
          </button>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalActivateRecovery;
