import React from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import ForkRightIcon from '@mui/icons-material/ForkRight';

const KreditsDrawer = ({ Trans, dispatch, anchor, handleDrawerClose }) => {
  const navigate = useNavigate();

  const menu_item = [
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.KreditsDrawer.item1' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.KreditsDrawer.item2' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.KreditsDrawer.item3' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.KreditsDrawer.item4' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.KreditsDrawer.item5' /> },
  ];

  return (
    <>
      <Box sx={{ width: 450, marginTop: '80px' }} role='presentation'>
        <List>
          {menu_item.map((text, index) => (
            <ListItem key={text.item} disablePadding>
              {text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.KreditsDrawer.item2' ? (
                <ListItemButton
                  onClick={() => {
                    handleDrawerClose();
                    navigate('/services/credits-and-fin-help/credit-limit');
                  }}
                >
                  <ListItemIcon>
                    <TurnRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.KreditsDrawer.item4' ? (
                <ListItemButton
                  onClick={() => {
                    handleDrawerClose();
                    navigate('/services/credits-and-fin-help/fin-help-programms');
                  }}
                >
                  <ListItemIcon>
                    <TurnRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.KreditsDrawer.item5' ? (
                <ListItemButton
                  onClick={() => {
                    handleDrawerClose();
                    navigate('/services/credits-and-fin-help/investors-search');
                  }}
                >
                  <ListItemIcon>
                    <TurnRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.KreditsDrawer.item1' ? (
                <ListItemButton
                  onClick={() => {
                    handleDrawerClose();
                    navigate('/services/credits-and-fin-help/banks');
                  }}
                >
                  <ListItemIcon>
                    <TurnRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.KreditsDrawer.item3' ? (
                <ListItemButton
                //   onClick={() => {
                //     handleDrawerClose();
                // navigate('/services/credits-and-fin-help/credit-online');
                //   }}
                >
                  <ListItemIcon>
                    <ForkRightIcon />
                  </ListItemIcon>
                  <Link
                    target='_'
                    href='https://yandex.kz/maps/190/pavlodar/category/bank'
                    underline='hover'
                    color='inherit'
                  >
                    {text.item}
                  </Link>
                </ListItemButton>
              ) : (
                <ListItemButton>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default KreditsDrawer;
