import React from 'react';

import s from '../AnalitikaBusinessObjects/AnalitikaBusinessObjects.module.css';

import AnalitikaBusinessObjectsQuadratsBlock from '../../components/AnalitikaBusinessObjectsQuadratsBlock/AnalitikaBusinessObjectsQuadratsBlock';

const AnalitikaBusinessObjectsQuadrats = ({ Trans, dispatch }) => {
  return (
    <>
      <div className={s.Wrapper2}>
        <div className={s.title2}>
          <p>
            Аналитика и формирование отчетности - Сводная отчетная информация по площади помещений
          </p>
        </div>
        <div className={s.maintitle2}>
          <p>Сводная отчетная информация по площади помещений</p>
        </div>

        <AnalitikaBusinessObjectsQuadratsBlock />
      </div>
    </>
  );
};

export default AnalitikaBusinessObjectsQuadrats;
