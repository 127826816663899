import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EncryptStorage } from 'encrypt-storage';

import NotificationsEntrepruneur from './NotificationsEntrepruneur';
import NotificationsAdmin from './NotificationsAdmin';
import {
  notificationsAll,
  notificationsAllEntrep,
} from '../../redux/asyncThunks/notificationsAsyncThunk';

import s from './notifications.module.css';

const Notifications = ({ ws, Trans }) => {
  const dispatch = useDispatch();
  const encryptStorage = new EncryptStorage('secret-key-value', {
    prefix: 'crypt',
  });

  const user = useSelector((state) => state.store.mainSlice.user);
  const isNewAppeals = useSelector((state) => state.store.notificationsSlice.isNewAppeals);
  const allNotifications = useSelector((state) => state.store.notificationsSlice.item);
  const onRead = useSelector((state) => state.store.notificationsSlice.countNoRead);

  React.useEffect(() => {
    allNotifications?.length && encryptStorage.setItem('notificationsCount', allNotifications);
  }, [allNotifications]);

  React.useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    if (user?.status_user === '0') {
      dispatch(notificationsAllEntrep(fd));
    }
    if (user?.status_user === '2' || user?.status_user === '1') {
      dispatch(notificationsAll(fd));
    }
  }, []);

  React.useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    if (isNewAppeals) {
      if (user?.status_user === '2' || user?.status_user === '1') {
        dispatch(notificationsAll(fd));
      }
    }
  }, [isNewAppeals]);

  if (user?.status_user === '2' || user?.status_user === '1') {
    return (
      <div>
        {onRead !== 0 && (
          <div className={s.counNoRead}>
            <Trans i18nKey='Notifications.item17' />: {onRead}
          </div>
        )}

        <NotificationsAdmin
          getNotifications={allNotifications}
          status_user={user?.status_user}
          ws={ws}
          Trans={Trans}
        />
      </div>
    );
  } else {
    return (
      <>
        {onRead !== 0 && <div className={s.counNoRead}>{onRead}</div>}
        <NotificationsEntrepruneur
          getNotifications={allNotifications}
          status_user={user?.status_user}
          ws={ws}
          Trans={Trans}
        />
      </>
    );
  }
};

export default Notifications;
