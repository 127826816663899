import React from 'react';
import uniqid from 'uniqid';
import { useSelector } from 'react-redux';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingImg from './LoadingImg';
import Pagination from '../../components/Pagination/Pagination';
import { galleryApi } from '../../api/api';

import 'photoswipe/dist/photoswipe.css';
import s from './Gallery.module.css';
import DnDC from './DnDC.js';

import { confirm } from 'react-confirm-box';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 512,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  boxSizing: 'border-box',
  padding: '48px 48px 32px 48px',
};

const MyGallery = () => {
  const userStatus = useSelector((state) => state.store.mainSlice?.user?.status_user);

  const [droppedFiles, setDroppedFiles] = React.useState([]);
  const [item, setItem] = React.useState([]);
  const [countItems, setCountItems] = React.useState(0);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [countPages, setCountPages] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDroppedFiles([]);
  };

  React.useEffect(() => {
    setIsLoading(true);
    const res = galleryApi.fetchGallery(currentPage);
    res
      .then((res) => {
        setItem(res[1]);
        setCountItems(res[0].count);
      })
      .catch((err) => console.log(err));
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [currentPage]);

  React.useEffect(() => {
    countItems > 0 && setCountPages(Math.ceil(countItems / 12));
  }, [countItems]);

  const customRender = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className={s.wrapModalConfirm}>
          <div className={s.modalConfirm}>
            <div className={s.modalConfirmMessage}> {message} </div>
            <div className={s.modalConfirmButton}>
              <button className={s.btnEditNews} onClick={onConfirm}>
                Да, удалить
              </button>
              <button className={s.btnReset} onClick={onCancel}>
                Нет, отмена
              </button>
            </div>
          </div>
        </div>
      );
    },
  };

  const deleteImg = async (option, id) => {
    const result = await confirm('Вы действительно хотите удалить файл?', option);
    const fd = new FormData();
    fd.append('id', id);
    if (result) {
      const res = galleryApi.deletePhoto(fd);
      res.then((res) => {
        if (res) {
          setIsLoading(true);
          const res = galleryApi.fetchGallery(currentPage);
          res
            .then((res) => {
              setItem(res[1]);
              setCountItems(res[0].count);
            })
            .catch((err) => console.log(err));
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      });
    }
  };

  return (
    <div className={s.galleryWrapper}>
      {userStatus === '2' && (
        <button className={s.btnLoadPhotos} onClick={handleOpen}>
          Загрузить фото
        </button>
      )}

      <Gallery style={{ width: '100%' }}>
        <div className={s.gallery}>
          {!isLoading &&
            item.map((item, i) => {
              return (
                <div key={i} className={s.wrapImg}>
                  <Item
                    original={`https://biznes.15000pvl.kz/file/photo_gallery/${item.img}`}
                    width="1024"
                    height="768">
                    {({ ref, open }) => (
                      <>
                        {userStatus === '2' && (
                          <svg
                            onClick={() => deleteImg(customRender, item.id)}
                            className={s.BtnDelImg}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 5H21M9 10V15M13 10V15M3 5H19L17.42 19.22C17.3658 19.7094 17.1331 20.1616 16.7663 20.49C16.3994 20.8184 15.9244 21 15.432 21H6.568C6.07564 21 5.60056 20.8184 5.23375 20.49C4.86693 20.1616 4.63416 19.7094 4.58 19.22L3 5ZM6.345 2.147C6.50675 1.80397 6.76271 1.514 7.083 1.31091C7.4033 1.10782 7.77474 0.999996 8.154 1H13.846C14.2254 0.999806 14.5971 1.10755 14.9176 1.31064C15.2381 1.51374 15.4942 1.80381 15.656 2.147L17 5H5L6.345 2.147Z"
                              stroke="#292929"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <img
                          className={s.galleryPicture}
                          ref={ref}
                          onClick={open}
                          src={`https://biznes.15000pvl.kz/file/photo_gallery/${item.img}`}
                          alt={''}
                        />
                      </>
                    )}
                  </Item>
                </div>
              );
            })}
          {isLoading && Array.from(Array(item.length), (_) => <LoadingImg key={uniqid()} />)}
        </div>
      </Gallery>

      {item.length === 12 && currentPage === 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Pagination
            onChange={(number) => setCurrentPage(number)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            countPages={countPages}
          />
        </div>
      ) : (
        currentPage > 1 && (
          <div className={s.galleryPagination}>
            <Pagination
              onChange={(number) => setCurrentPage(number)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              countPages={countPages}
            />
          </div>
        )
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className={s.close_auth_modal} onClick={handleClose}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132"
                stroke="#292929"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={s.title_file_modal}>
            <div className={s.title_file_modal}>Добавление фото</div>
          </div>
          <DndProvider backend={HTML5Backend}>
            <DnDC
              handleClose={handleClose}
              droppedFiles={droppedFiles}
              setDroppedFiles={setDroppedFiles}
              setCountItems={setCountItems}
              setItem={setItem}
            />
          </DndProvider>
        </Box>
      </Modal>
    </div>
  );
};

export default MyGallery;
