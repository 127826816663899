import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { statistics } from '../../api/api';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

const Statistics = ({ Trans, dispatch }) => {
  const [getAllAppeals, setGetAllAppeals] = React.useState([]);
  const [Appeals, setAppeals] = React.useState([]);
  const modal = useSelector((state) => state.store.mainSlice.PDFmodal);
  const lang = localStorage.getItem('lang');

  React.useEffect(() => {
    console.log(modal);
  }, [modal]);

  React.useEffect(() => {
    const ServiceResponse = statistics.countMetrica();
    ServiceResponse.then((res) => setGetAllAppeals(res)).catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    const newAppeals = getAllAppeals?.map((res) => {
      var visitsFromOther = 0;
      return createData(
        res.countAppeal,
        res.countAppealCompleted,
        res.countReg,

        res.getMetrica[0]?.metrics,

        res.getMetricaByAge[0]?.metrics
          ? '- ' +
              res.getMetricaByAge[0]?.metrics +
              (res.getMetricaByAge[0]?.metrics == 2 ||
              res.getMetricaByAge[0]?.metrics == 3 ||
              res.getMetricaByAge[0]?.metrics == 4
                ? lang == 'ru'
                  ? ' человека'
                  : lang == 'kz'
                  ? ' aдам'
                  : lang == 'en'
                  ? ' people'
                  : null
                : lang == 'ru'
                ? ' человек'
                : lang == 'kz'
                ? ' aдам'
                : lang == 'en'
                ? ' people'
                : null)
          : null,
        res.getMetricaByAge[0]?.parameters[0] == '18‑24 года'
          ? lang == 'ru'
            ? ' 18‑24 года'
            : lang == 'kz'
            ? ' 18-24 жыл'
            : lang == 'en'
            ? ' 18-24 years old'
            : ' 18‑24 года'
          : res.getMetricaByAge[0]?.parameters[0] == '25‑34 года'
          ? lang == 'ru'
            ? ' 25‑34 года'
            : lang == 'kz'
            ? ' 25‑34 жыл'
            : lang == 'en'
            ? ' 25‑34 years old'
            : ' 25‑34 года'
          : ' 25‑34 года',

        res.getMetricaByAge[1]?.metrics
          ? '- ' +
              res.getMetricaByAge[1]?.metrics +
              (res.getMetricaByAge[1]?.metrics == 2 ||
              res.getMetricaByAge[1]?.metrics == 3 ||
              res.getMetricaByAge[1]?.metrics == 4
                ? lang == 'ru'
                  ? ' человека'
                  : lang == 'kz'
                  ? ' aдам'
                  : lang == 'en'
                  ? ' people'
                  : null
                : lang == 'ru'
                ? ' человек'
                : lang == 'kz'
                ? ' aдам'
                : lang == 'en'
                ? ' people'
                : null)
          : null,
        res.getMetricaByAge[1]?.parameters[0] == '18‑24 года'
          ? lang == 'ru'
            ? ' 18‑24 года'
            : lang == 'kz'
            ? ' 18-24 жыл'
            : lang == 'en'
            ? ' 18-24 years old'
            : ' 18‑24 года'
          : res.getMetricaByAge[1]?.parameters[0] == '25‑34 года'
          ? lang == 'ru'
            ? ' 25‑34 года'
            : lang == 'kz'
            ? ' 25‑34 жыл'
            : lang == 'en'
            ? ' 25‑34 years old'
            : ' 25‑34 года'
          : ' 25‑34 года',
        // __________________________________________________
        // количество
        res.getMetricaByAgeAndGender[0]?.metrics
          ? '- ' +
              res.getMetricaByAgeAndGender[0]?.metrics +
              (res.getMetricaByAgeAndGender[0]?.metrics == 2 ||
              res.getMetricaByAgeAndGender[0]?.metrics == 3 ||
              res.getMetricaByAgeAndGender[0]?.metrics == 4
                ? lang == 'ru'
                  ? ' человека'
                  : lang == 'kz'
                  ? ' aдам'
                  : lang == 'en'
                  ? ' people'
                  : null
                : lang == 'ru'
                ? ' человек'
                : lang == 'kz'
                ? ' aдам'
                : lang == 'en'
                ? ' people'
                : null)
          : null,
        // возраст
        res.getMetricaByAgeAndGender[0]?.parameters[0]
          ? res.getMetricaByAgeAndGender[0]?.parameters[0] == '18‑24 года'
            ? lang == 'ru'
              ? ' - 18‑24 года'
              : lang == 'kz'
              ? ' - 18-24 жыл'
              : lang == 'en'
              ? ' - 18-24 years old'
              : ' - 18‑24 года'
            : res.getMetricaByAgeAndGender[0]?.parameters[0] == '25‑34 года'
            ? lang == 'ru'
              ? ' - 25‑34 года'
              : lang == 'kz'
              ? ' - 25‑34 жыл'
              : lang == 'en'
              ? ' - 25‑34 years old'
              : ' - 25‑34 года'
            : ' - 25‑34 года'
          : null,
        // пол
        res.getMetricaByAgeAndGender[0]?.parameters[1],
        // __________________________________________________
        // количество
        res.getMetricaByAgeAndGender[1]?.metrics
          ? '- ' +
              res.getMetricaByAgeAndGender[1]?.metrics +
              (res.getMetricaByAgeAndGender[1]?.metrics == 2 ||
              res.getMetricaByAgeAndGender[1]?.metrics == 3 ||
              res.getMetricaByAgeAndGender[1]?.metrics == 4
                ? lang == 'ru'
                  ? ' человека'
                  : lang == 'kz'
                  ? ' aдам'
                  : lang == 'en'
                  ? ' people'
                  : null
                : lang == 'ru'
                ? ' человек'
                : lang == 'kz'
                ? ' aдам'
                : lang == 'en'
                ? ' people'
                : null)
          : null,
        // возраст
        res.getMetricaByAgeAndGender[1]?.parameters[0]
          ? res.getMetricaByAgeAndGender[1]?.parameters[0] == '18‑24 года'
            ? lang == 'ru'
              ? ' - 18‑24 года'
              : lang == 'kz'
              ? ' - 18-24 жыл'
              : lang == 'en'
              ? ' - 18-24 years old'
              : ' - 18‑24 года'
            : res.getMetricaByAgeAndGender[1]?.parameters[0] == '25‑34 года'
            ? lang == 'ru'
              ? ' - 25‑34 года'
              : lang == 'kz'
              ? ' - 25‑34 жыл'
              : lang == 'en'
              ? ' - 25‑34 years old'
              : ' - 25‑34 года'
            : ' - 25‑34 года'
          : null,
        // пол

        res.getMetricaByAgeAndGender[1]?.parameters[1],
        // __________________________________________________
        // количество
        res.getMetricaByAgeAndGender[2]?.metrics
          ? '- ' +
              res.getMetricaByAgeAndGender[2]?.metrics +
              (res.getMetricaByAgeAndGender[2]?.metrics == 2 ||
              res.getMetricaByAgeAndGender[2]?.metrics == 3 ||
              res.getMetricaByAgeAndGender[2]?.metrics == 4
                ? lang == 'ru'
                  ? ' человека'
                  : lang == 'kz'
                  ? ' aдам'
                  : lang == 'en'
                  ? ' people'
                  : null
                : lang == 'ru'
                ? ' человек'
                : lang == 'kz'
                ? ' aдам'
                : lang == 'en'
                ? ' people'
                : null)
          : null,
        // возраст
        res.getMetricaByAgeAndGender[2]?.parameters[0]
          ? ' - ' + res.getMetricaByAgeAndGender[2]?.parameters[0]
          : null,
        // пол
        res.getMetricaByAgeAndGender[2]?.parameters[1],
        // __________________________________________________

        res.visitsFromOther
          ? res.visitsFromOther.map((res) => {
              console.log(res.metrics[0]);
              visitsFromOther += res.metrics[0];
              console.log(visitsFromOther);
              return visitsFromOther;
            })
          : 0,
      );
    });
    setAppeals(newAppeals);
    console.log(newAppeals);
  }, [getAllAppeals]);

  const createData = (
    countAppeal,
    countAppealCompleted,
    countReg,
    getMetrica,

    getMetricaByAge_metrics_25_34,
    getMetricaByAge_parameters_25_34,

    getMetricaByAge_metrics_18_24,
    getMetricaByAge_parameters_18_24,

    getMetricaByAgeAndGender_metrics_25_34_man,
    getMetricaByAgeAndGender_param_age_25_34_man,
    getMetricaByAgeAndGender_param_title_25_34_man,

    getMetricaByAgeAndGender_metrics_18_24_man,
    getMetricaByAgeAndGender_param_age_18_24_man,
    getMetricaByAgeAndGender_param_title_18_24_man,

    getMetricaByAgeAndGender_metrics_25_34_woman,
    getMetricaByAgeAndGender_param_age_25_34_woman,
    getMetricaByAgeAndGender_param_title_25_34_woman,

    visitsFromOther,
  ) => {
    return {
      countAppeal,
      countAppealCompleted,
      countReg,
      getMetrica,

      getMetricaByAge_metrics_25_34,
      getMetricaByAge_parameters_25_34,

      getMetricaByAge_metrics_18_24,
      getMetricaByAge_parameters_18_24,

      getMetricaByAgeAndGender_metrics_25_34_man,
      getMetricaByAgeAndGender_param_age_25_34_man,
      getMetricaByAgeAndGender_param_title_25_34_man,

      getMetricaByAgeAndGender_metrics_18_24_man,
      getMetricaByAgeAndGender_param_age_18_24_man,
      getMetricaByAgeAndGender_param_title_18_24_man,

      getMetricaByAgeAndGender_metrics_25_34_woman,
      getMetricaByAgeAndGender_param_age_25_34_woman,
      getMetricaByAgeAndGender_param_title_25_34_woman,

      visitsFromOther,
    };
  };
  return (
    <>
      <div style={{ height: 585, width: '90%', margin: 'auto', marginTop: '60px' }}>
        {/* <Typography sx={{ mb: '24px' }} variant='h6'>
          Статистика
        </Typography> */}
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <Box>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                <Trans i18nKey='Statistics.item1' /> {Appeals[0]?.countReg}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                <Trans i18nKey='Statistics.item2' /> {Appeals[0]?.countAppeal}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                <Trans i18nKey='Statistics.item3' /> {Appeals[0]?.countAppealCompleted}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                <Trans i18nKey='Statistics.item4' /> {Appeals[0]?.getMetrica}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                <Trans i18nKey='Statistics.item5' />
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                {Appeals[0]?.getMetricaByAge_parameters_18_24}
                {Appeals[0]?.getMetricaByAge_metrics_18_24}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                {Appeals[0]?.getMetricaByAge_parameters_25_34}
                {Appeals[0]?.getMetricaByAge_metrics_25_34}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                <Trans i18nKey='Statistics.item6' />
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                {Appeals[0]?.getMetricaByAgeAndGender_param_title_18_24_man == 'мужской'
                  ? lang == 'ru'
                    ? 'мужской'
                    : lang == 'kz'
                    ? 'еркек'
                    : lang == 'en'
                    ? 'male'
                    : Appeals[0]?.getMetricaByAgeAndGender_param_title_18_24_man
                  : Appeals[0]?.getMetricaByAgeAndGender_param_title_18_24_man == 'женский'
                  ? lang == 'ru'
                    ? 'женский'
                    : lang == 'kz'
                    ? 'әйел'
                    : lang == 'en'
                    ? 'female'
                    : Appeals[0]?.getMetricaByAgeAndGender_param_title_18_24_man
                  : Appeals[0]?.getMetricaByAgeAndGender_param_title_18_24_man}

                {Appeals[0]?.getMetricaByAgeAndGender_param_age_18_24_man}
                {Appeals[0]?.getMetricaByAgeAndGender_metrics_18_24_man}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                {Appeals[0]?.getMetricaByAgeAndGender_param_title_25_34_man == 'мужской'
                  ? lang == 'ru'
                    ? 'мужской'
                    : lang == 'kz'
                    ? 'еркек'
                    : lang == 'en'
                    ? 'male'
                    : Appeals[0]?.getMetricaByAgeAndGender_param_title_25_34_man
                  : Appeals[0]?.getMetricaByAgeAndGender_param_title_25_34_man == 'женский'
                  ? lang == 'ru'
                    ? 'женский'
                    : lang == 'kz'
                    ? 'әйел'
                    : lang == 'en'
                    ? 'female'
                    : Appeals[0]?.getMetricaByAgeAndGender_param_title_25_34_man
                  : Appeals[0]?.getMetricaByAgeAndGender_param_title_25_34_man}
                {Appeals[0]?.getMetricaByAgeAndGender_param_age_25_34_man}
                {Appeals[0]?.getMetricaByAgeAndGender_metrics_25_34_man}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                {Appeals[0]?.getMetricaByAgeAndGender_param_title_25_34_woman}
                {Appeals[0]?.getMetricaByAgeAndGender_param_age_25_34_woman}
                {Appeals[0]?.getMetricaByAgeAndGender_metrics_25_34_woman}
              </Typography>
              <Typography sx={{ mb: '24px' }} variant='h6'>
                <Trans i18nKey='Statistics.item7' />{' '}
                {Appeals[0]?.visitsFromOther[Appeals[0].visitsFromOther.length - 1]}
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
