import React from 'react';

import s from './ChoseTypeAdminNews.module.css';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { newsApi } from '../../../api/api';

import Published from './Published';

const ChoseTypeAdminNews = ({ dispatch }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const ChooseAppealsTypeWidth = React.useRef(null);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    const obj = {
      page: 'All',
      status: '',
    };
    const res = newsApi.fetchNews(obj);
    res.then((res) => {
      setItems(res[1]);
    });
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <div className={s.Wrapper} ref={ChooseAppealsTypeWidth}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            width: '100%',
            position: 'relative',
            minHeight: 200,
          }}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Published items={items} setItems={setItems} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </div>
    </>
  );
};

export default ChoseTypeAdminNews;
