import React from 'react';

import s from '../AnalitikaBusinessObjects/AnalitikaBusinessObjects.module.css';

import AnalitikaBusinessObjectsPassBlock from '../../components/AnalitikaBusinessObjectsPassBlock/AnalitikaBusinessObjectsPassBlock';

const AnalitikaBusinessObjectsPass = ({ Trans, dispatch }) => {
  return (
    <>
      <div className={s.Wrapper2}>
        <div className={s.title2}>
          <p>Аналитика и формирование отчетности - Сводная отчетная информация по проходимости</p>
        </div>
        <div className={s.maintitle2}>
          <p>Сводная отчетная информация по проходимости</p>
        </div>

        <AnalitikaBusinessObjectsPassBlock />
      </div>
    </>
  );
};

export default AnalitikaBusinessObjectsPass;
