import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import s from '../AnalitikaBusinessObjectsBlock/AnalitikaBusinessObjectsBlock.module.css';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { mainPage } from '../../api/api';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CSVDownload } from 'react-csv';
import Chart from '../../common/helpers/charts/horizontal_bar';
import Autocomplete from '@mui/material/Autocomplete';
import { useBusinessMap } from '../../pages/BusinessMap/useBusinessMap';
import ds from '../../pages/BusinessMap/style.module.css';
const AnalitikaBusinessObjectsCommunicationBlock = ({ Trans, dispatch }) => {
  const [resultWrapper, setResultWrapper] = useState('none');
  const [initiationStart, setinitiationStart] = useState(false);
  const [date1, setdate1] = useState('');
  const [date2, setdate2] = useState('');
  const [ViewData, setViewData] = useState('');
  const [csvData, setcsvData] = useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');

  const { items, filterCategories, fetchData } = useBusinessMap(inputValue, searchValue);

  const [titlesArr, settitlesArr] = useState([]);
  const [numbersArr, setnumbersArr] = useState([]);

  const user = useSelector((state) => state.store.mainSlice.user);

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
    },
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#D9D9D9',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '148px',
  }));

  const handleClick = async () => {
    setResultWrapper('block');

    const dates = {
      name: 'Сводная отчетная информация по подбору помещения для бизнеса',
      type: inputValue,
      userID: user.id,
    };

    // const data = await mainPage.historyInsert(dates);

    // console.log(data);
  };

  function FormRow(data) {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Paper sx={{ background: '#e1e1e1' }}>{data.data?.name}</Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ background: '#e1e1e1' }}>{data.data?.adress}</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper sx={{ background: '#e1e1e1' }}>Директор: {data.data?.boss}</Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ background: '#e1e1e1' }}>Категория: {data.data?.KRP_ru}</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  const handleTXT = () => {
    var text = '';
    items.map(
      (data) => (text = text + data.name + '----' + data.adress + '----' + data.boss + '\r\n'),
    );

    console.log(text);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'Отчет');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleDOCX = () => {
    var text = '';
    items.map(
      (data) => (text = text + data.name + '----' + data.adress + '----' + data.boss + '\r\n'),
    );

    console.log(text);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'Отчет.doc');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handlePDF = () => {
    try {
      const input = document.getElementById('divDownload');
      html2canvas(input).then((canvas) => {
        const ImageData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(ImageData, 'JPEG', 0, 0);
        pdf.save('Отчет.pdf');
      });
    } catch {
      console.log('err');
    }
  };

  const handleCSV = () => {
    try {
      var majorArr = [];
      var headerArr = [];

      headerArr.push('Наименование');
      headerArr.push('Адрес');
      headerArr.push('Директор');

      majorArr.push(headerArr);

      items.map((data) => {
        var minorArr = [];
        minorArr.push(data.name);
        minorArr.push(data.adress);
        minorArr.push(data.boss);

        majorArr.push(minorArr);
      });

      setcsvData(majorArr);
    } catch {
      console.log('err');
    }
  };

  return (
    <>
      <div className={s.questionBlock}>
        <div className={s.questionBlockWrapper}>
          <div className={s.questionInputWrapper}>
            {initiationStart ? (
              <>
                <div className={ds.filtersWrapper} style={{ margin: '40px' }}>
                  <Autocomplete
                    disablePortal
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setResultWrapper('none');
                      setInputValue(newInputValue);
                    }}
                    id='combo-box-demo'
                    options={filterCategories}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label='Вид обьекта' />}
                  />
                </div>

                <div className={s.fioWrapper}>
                  <div className={s.fio}>
                    {/* <TextField
                      id='outlined-basic'
                      type='date'
                      label='Период (с)'
                      variant='outlined'
                      onChange={(e) => {
                        setdate1(e.target.value);
                      }}
                    /> */}
                  </div>
                  <div className={s.fio}>
                    {/* <TextField
                      id='outlined-basic'
                      type='date'
                      label='Период (по)'
                      variant='outlined'
                      onChange={(e) => {
                        setdate2(e.target.value);
                      }}
                    /> */}
                  </div>
                </div>
              </>
            ) : null}

            <div className={s.sendbuttonWrapper}>
              <div className={s.sendbutton}>
                <ThemeProvider theme={theme}>
                  <div style={{ display: `${!resultWrapper}` }}>
                    {initiationStart ? (
                      <Button
                        color='neutral'
                        sx={{ height: '100%', width: '100%' }}
                        variant='contained'
                        // onClick={() => {
                        //   handleClick();
                        // }}
                      >
                        Ok
                      </Button>
                    ) : (
                      <Button
                        color='neutral'
                        sx={{ height: '100%', width: '100%' }}
                        variant='contained'
                        onClick={() => {
                          setinitiationStart(true);
                        }}
                      >
                        Новый отчет
                      </Button>
                    )}
                  </div>
                  <div style={{ display: resultWrapper !== 'none' ? 'flex' : 'none' }}>
                    <Button
                      color='neutral'
                      sx={{ height: '100%', width: '100%' }}
                      variant='contained'
                      onClick={() => {
                        handleTXT();
                      }}
                    >
                      TXT
                    </Button>
                    <Button
                      color='neutral'
                      sx={{ height: '100%', width: '100%' }}
                      variant='contained'
                      onClick={() => {
                        handleDOCX();
                      }}
                    >
                      DOCX
                    </Button>
                    <Button
                      color='neutral'
                      sx={{ height: '100%', width: '100%' }}
                      variant='contained'
                      onClick={() => {
                        handlePDF();
                      }}
                    >
                      PDF
                    </Button>

                    <Button
                      color='neutral'
                      sx={{ height: '100%', width: '100%' }}
                      variant='contained'
                      onClick={() => {
                        handleCSV();
                      }}
                    >
                      XLS
                    </Button>

                    {csvData && <CSVDownload data={csvData} target='_blank' />}
                  </div>
                </ThemeProvider>
              </div>
            </div>

            <div className={s.resultWrapper} style={{ display: `${resultWrapper}` }}>
              <div className={s.resultTitle}>Результат</div>
              <div id='divDownload' style={{ width: '210mm', padding: '20px', margin: 'auto' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    {/* xs={12} - 100% width,  xs={12} - 50% width*/}
                    <Grid item xs={12}>
                      <Grid container item xs={12} spacing={2}>
                        {console.log(items)}
                        {items ? items.map((data) => <FormRow data={data} />) : <p>Нет данных</p>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <div>{ViewData && <Chart titlesArr={titlesArr} numbersArr={numbersArr} />}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalitikaBusinessObjectsCommunicationBlock;
