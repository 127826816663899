import React from 'react';

import s from '../AnalitikaBusinessObjects/AnalitikaBusinessObjects.module.css';

import AnalitikaBusinessObjectsCommunicationBlock from '../../components/AnalitikaBusinessObjectsCommunicationBlock/AnalitikaBusinessObjectsCommunicationBlock';

const AnalitikaBusinessObjectsCommunication = ({ Trans, dispatch }) => {
  return (
    <>
      <div className={s.Wrapper2}>
        <div className={s.title2}>
          <p>Аналитика и формирование отчетности - Сводная отчетная информация по коммуникациям</p>
        </div>
        <div className={s.maintitle2}>
          <p>Сводная отчетная информация по коммуникациям</p>
        </div>

        <AnalitikaBusinessObjectsCommunicationBlock />
      </div>
    </>
  );
};

export default AnalitikaBusinessObjectsCommunication;
