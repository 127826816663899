import { createSlice } from '@reduxjs/toolkit';
import { loginEcp, loginInnPass, logOut } from '../asyncThunks/mainAsyncThunk';

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    language: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru',
    width: 430,
    MyAppealsWidth: 1000,
    mainPageOptionsState: [{ cat: 'der', side: 'left' }],
    ServiceOptionsState: [{ cat: 'der', side: 'left' }],
    user: null,
    isLoading: false,
    isLoadingAuth: false,
    error: null,
    modalAuth: false,
    PDFmodal: 'none',
    ncaLayerErr: null,
    ncaLayerStatus: null,
  },
  reducers: {
    editLanguage(state, action) {
      state.language = action.payload;
    },

    divBlockWidth(state, action) {
      state.width = action.payload;
    },

    MyAppealsWidth(state, action) {
      state.MyAppealsWidth = action.payload;
    },

    mainPageOptionsStates(state, action) {
      state.mainPageOptionsState = action.payload;
    },

    ServiceOptionsStates(state, action) {
      state.ServiceOptionsState = action.payload;
    },

    setNcaLayerStatus(state, action) {
      state.ncaLayerStatus = action.payload;
    },

    setNcaLayerErr(state, action) {
      state.ncaLayerErr = action.payload;
    },

    setModalAuth(state, action) {
      state.modalAuth = action.payload;
    },

    errorAuthNull(state, action) {
      state.error = null;
    },
    isLogined(state, action) {
      state.user = action.payload;
    },
    loginInnPassR(state, action) {
      state.user = action.payload;
    },
    setPDFModal(state, action) {
      state.PDFmodal = action.payload;
    },
  },
  extraReducers: {
    [loginEcp.pending]: (state) => {
      state.isLoading = true;
      state.user = null;
    },
    [loginEcp.fulfilled]: (state, action) => {
      if (action.payload?.message === 'Пользователь авторизован') {
        state.error = action.payload.message;
        state.ncaLayerStatus = null;
      } else if (action.payload?.message === 'Аккаунт заблокирован!') {
        state.error = action.payload.message;
      } else {
        state.user = action.payload;
      }
      state.isLoadingAuth = false;
    },
    [loginInnPass.pending]: (state) => {
      state.isLoadingAuth = true;
      state.user = null;
    },
    [loginInnPass.fulfilled]: (state, action) => {
      if (action.payload?.message === 'Не верный пароль!') {
        state.error = action.payload.message;
      } else if (action.payload?.message?.slice(0, 16) === 'Не верный пароль') {
        state.error = action.payload?.message;
      } else if (action.payload?.message === 'Аккаунт заблокирован!') {
        state.error = action.payload.message;
      } else if (action.payload?.message === 'Пользователь авторизован') {
        state.error = action.payload.message;
      } else {
        state.error = null;
        state.user = action.payload;
      }
      state.isLoadingAuth = false;
    },
    [logOut.pending]: (state) => {
      state.user = null;
    },
    [logOut.fulfilled]: (state) => {
      state.user = null;
    },
  },
});
export const {
  editLanguage,
  divBlockWidth,
  mainPageOptionsStates,
  ServiceOptionsStates,
  errorAuthNull,
  setNcaLayerStatus,
  setNcaLayerErr,
  setModalAuth,
  isLogined,
  MyAppealsWidth,
  loginInnPassR,
  setPDFModal,
} = mainSlice.actions;

export default mainSlice.reducer;
