import React from 'react';

import s from './ProcurementDocumentConstructor.module.css';

import ProcurementDocumentConstructorBlock from '../../components/ProcurementDocumentConstructorBlock/ProcurementDocumentConstructorBlock';

const ProcurementDocumentConstructor = ({ Trans, dispatch }) => {

  return (
    <>

    <div className={s.Wrapper}>
        <div className={s.title}>
            <p>Сервисы - Закупки - Конструктор документов для участия в закупках</p>
        </div>
        <div className={s.maintitle}>
            <p>Конструктор документов для участия в закупках</p>
        </div>

        <ProcurementDocumentConstructorBlock />
        
    </div>


    </>
  );
};


export default ProcurementDocumentConstructor;
