import { configureStore, combineReducers } from '@reduxjs/toolkit';
import mainSlice from './slices/mainSlice';
import notificationsSlice from './slices/notificationsSlice';
import appealsSlice from './slices/appealsSlice';
import ServicesSlice from './slices/ServicesSlice';
const store = combineReducers({
  mainSlice,
  notificationsSlice,
  appealsSlice,
  ServicesSlice,
});

export default configureStore({
  reducer: { store },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
