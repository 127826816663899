import { createSlice } from '@reduxjs/toolkit';

const appealsSlice = createSlice({
  name: 'appeals',
  initialState: {
    TypeAppealCatArrSlice: [],
    TypeAppealSubCatArrSlice: [],
    SelectedItemDispatch: null,
    SelectedSotrDispatch: null,
    ReloadAppeals: null,
  },
  reducers: {
    setTypeAppealCatArrSlice(state, action) {
      state.TypeAppealCatArrSlice = action.payload;
    },
    setTypeAppealSubCatArrSlice(state, action) {
      state.TypeAppealSubCatArrSlice = action.payload;
    },
    setSelectedItemDispatch(state, action) {
      state.SelectedItemDispatch = action.payload;
    },
    setSelectedSotrDispatch(state, action) {
      state.SelectedSotrDispatch = action.payload;
    },
    ReloadAppeals(state, action) {
      state.ReloadAppeals = action.payload;
    },
  },
  extraReducers: {},
});
export const {
  setTypeAppealCatArrSlice,
  setTypeAppealSubCatArrSlice,
  setSelectedItemDispatch,
  setSelectedSotrDispatch,
  ReloadAppeals,
} = appealsSlice.actions;

export default appealsSlice.reducer;
