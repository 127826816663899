import React from 'react';

import s from '../AnalitikaBusinessObjects/AnalitikaBusinessObjects.module.css';

import AnalitikaBusinessObjectsSelectBlock from '../../components/AnalitikaBusinessObjectsSelectBlock/AnalitikaBusinessObjectsSelectBlock';

const AnalitikaBusinessObjectsSelect = ({ Trans, dispatch }) => {
  return (
    <>
      <div className={s.Wrapper2}>
        <div className={s.title2}>
          <p>
            Аналитика и формирование отчетности - Сводная отчетная информация по подбору помещения
            для бизнеса
          </p>
        </div>
        <div className={s.maintitle2}>
          <p>Сводная отчетная информация по подбору помещения для бизнеса</p>
        </div>

        <AnalitikaBusinessObjectsSelectBlock />
      </div>
    </>
  );
};

export default AnalitikaBusinessObjectsSelect;
