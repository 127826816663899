import React from 'react';

import s from './AnalitikaBusinessObjects.module.css';

import AnalitikaBusinessObjectsBlock from '../../components/AnalitikaBusinessObjectsBlock/AnalitikaBusinessObjectsBlock';

const AnalitikaBusinessObjects = ({ Trans, dispatch }) => {
	return (
		<>
			<div className={s.Wrapper2}>
				<div className={s.title2}>
					<p>Аналитика и формирование отчетности - Сводная отчетная информация по объектам бизнеса</p>
				</div>
				<div className={s.maintitle2}>
					<p>Сводная отчетная информация по объектам бизнеса</p>
				</div>

				<AnalitikaBusinessObjectsBlock />
			</div>
		</>
	);
};

export default AnalitikaBusinessObjects;
