import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { appealsAPI } from '../../../api/api';
import { styleModal, myTheme, theme, CustomNoRowsOverlay } from '../../../theme';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';
import { ruRU } from '../../../common/helpers/ruRU';
import { kzKZ } from '../../../common/helpers/kzKZ';
import { enEN } from '../../../common/helpers/enEN';
import {
  appealsValueNotSelected,
  errorData,
  successData,
} from '../../../common/helpers/sliceToasts';

import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarFilterButton,
  GridToolbarContainer,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Popover from '@mui/material/Popover';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Button } from '@mui/material';

import clsx from 'clsx';

import { setPDFModal } from '../../../redux/slices/mainSlice';

import AppealViewForAdminPDFAttachment from '../AppealViewForAdminPDFAttachment/AppealViewForAdminPDFAttachment';
import AppealViewForAdminPDF from '../AppealViewForAdminPDF/AppealViewForAdminPDF';

const ReturnWithoutPerformer = ({ Trans, dispatch }) => {
  const width = useSelector((state) => state.store.mainSlice.MyAppealsWidth);

  const [getAllAppeals, setGetAllAppeals] = React.useState([]);
  const [Appeals, setAppeals] = React.useState([]);
  const user = useSelector((state) => state.store.mainSlice.user);
  const modal = useSelector((state) => state.store.mainSlice.PDFmodal);
  const [IDforPDFAppeal, setIDforPDFAppeal] = React.useState('none');
  const [IDforAcceptAppeal, setIDforAcceptAppeal] = React.useState('none');
  const [AttachmentForPDFAppeal, setAttachmentForPDFAppeal] = React.useState('none');
  // const [TEXTforPDFAppeal, setTEXTforPDFAppeal] = React.useState('none');
  // const [DATEforPDFAppeal, setDATEforPDFAppeal] = React.useState('none');
  const [SIGEXIDforPDFAppealAttachment, setSIGEXIDforPDFAppealAttachment] = React.useState('none');
  const [SIGEXIDforPDFAppeal, setSIGEXIDforPDFAppeal] = React.useState('none');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [SelectedItem, setSelectedItem] = React.useState(null);
  const [OpenModal, setOpenModal] = useState(false);
  const [Sotrudniki, setSotrudniki] = useState(null);
  const [SendAppealsReload, setSendAppealsReload] = useState(false);
  const [selectedSotr, setSelectedSotr] = useState('');
  const open = Boolean(anchorEl);
  const lang = localStorage.getItem('lang');

  let gridButtonWidth = (width - 135) / 6;

  React.useEffect(() => {
    console.log(modal);
  }, [modal]);

  React.useEffect(() => {
    const fd = new FormData();
    fd.append('status', 4);
    const appealsResponse = appealsAPI.getAppealStatus(fd);
    appealsResponse.then((res) => setGetAllAppeals(res)).catch((err) => console.log(err));

    const Sotrudniki = appealsAPI.fetchVseSotrudniki();
    Sotrudniki.then((res) => setSotrudniki(res));
  }, []);

  React.useEffect(() => {
    const newAppeals = getAllAppeals?.map((res) => {
      return createData(
        res.attachment_id,
        res.sigexId,
        res.id,
        res[0]?.last_name +
          ' ' +
          res[0]?.first_name[0] +
          '. ' +
          (res[0]?.middle_name[0] ? res[0]?.middle_name[0] : '__') +
          '.' +
          '_' +
          res[0]?.IIN +
          '_' +
          res[0]?.IIN[0] +
          res[0]?.IIN[1] +
          '.' +
          res[0]?.IIN[2] +
          res[0]?.IIN[3] +
          '.' +
          res[0]?.IIN[4] +
          res[0]?.IIN[5],
        res.title,
        res.text,
        res.id,
        res.dateCreate,
        res.attachment,
        res.id + '_' + res.dateCreate,
        res.status === '0'
          ? 'Не распределено'
          : res.status === '1'
          ? 'Зарегистрированные'
          : res.status === '2'
          ? 'На исполнении'
          : res.status === '3'
          ? 'Исполненные'
          : 'Возврат без исполнения',
        res.attachment ? 'pdf' : res.attachment,
        res[1]?.last_name +
          ' ' +
          res[1]?.first_name[0] +
          '. ' +
          (res[0]?.middle_name[0] ? res[0]?.middle_name[0] : '__') +
          '.',
      );
    });
    setAppeals(newAppeals);
  }, [getAllAppeals]);

  const GridColDef = [
    {
      field: 'title',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item1' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          title: params.value !== '',
        });
      },
    },
    {
      field: 'numberAndDate',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item2' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          numberAndDate: params.value !== '',
        });
      },
    },
    {
      field: 'userAbout',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item3' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          about: params.value !== '',
        });
      },
    },
    {
      field: 'status',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item4' />,
      width: gridButtonWidth + gridButtonWidth / 2 - 18,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      valueGetter: (params) => {
        if (params.value == 'Не распределено') {
          if (lang == 'ru') {
            return 'Не распределено';
          }
          if (lang == 'en') {
            return 'Not distributed';
          }
          if (lang == 'kz') {
            return 'Таратылмаған';
          }
        } else if (params.value == 'На исполнении') {
          if (lang == 'ru') {
            return 'На исполнении';
          }
          if (lang == 'en') {
            return 'On execution';
          }
          if (lang == 'kz') {
            return 'Орындауда';
          }
        } else if (params.value == 'Зарегистрированные') {
          if (lang == 'ru') {
            return 'Зарегистрирован';
          }
          if (lang == 'en') {
            return 'Registered';
          }
          if (lang == 'kz') {
            return 'Тіркелген';
          }
        } else if (params.value == 'Исполненные') {
          if (lang == 'ru') {
            return 'Исполнен';
          }
          if (lang == 'en') {
            return 'Executed';
          }
          if (lang == 'kz') {
            return 'Орындалды';
          }
        } else if (params.value == 'Возврат без исполнения') {
          if (lang == 'ru') {
            return 'Возврат без исполнения';
          }
          if (lang == 'en') {
            return 'Refund without execution';
          }
          if (lang == 'kz') {
            return 'Орындаусыз қайтару';
          }
        }
      },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.row.status == 'Не распределено',
          positive: params.row.status == 'На исполнении',
          positive2: params.row.status == 'Зарегистрированные',
          positive3: params.row.status == 'Исполненные',
          negative4: params.row.status == 'Возврат без исполнения',
        });
      },
    },
    {
      field: 'pdf',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item5' />,
      width: gridButtonWidth / 2,
      headerClassName: 'super-app-theme--pdf',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <DownloadRoundedIcon color={params.value == 'pdf' ? 'warning' : '#757575'} />;
      },
    },
    {
      field: 'ispolnitel',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item19' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          title: params.value !== '',
        });
      },
    },
  ];

  const createData = (
    attachment_id,
    sigexId,
    id,
    userAboutMod,
    title,
    text,
    number,
    dateCreate,
    attachment,
    numberAndDateMod,
    status,
    pdf,
    ispolnitelMod,
  ) => {
    const userAbout1 = userAboutMod.replace(/__./gi, '');
    const userAbout = userAbout1.replace(/_/gi, '\n');
    const numberAndDate = numberAndDateMod.replace(/_/gi, '\n');
    const ispolnitel = ispolnitelMod.replace(/__./gi, '');

    return {
      attachment_id,
      sigexId,
      id,
      userAbout,
      title,
      text,
      number,
      dateCreate,
      attachment,
      numberAndDate,
      status,
      pdf,
      ispolnitel,
    };
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
          }}
        >
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
        <div style={{ marginTop: '50px' }}></div>
        <GridToolbarFilterButton
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
            color: '#454545',
          }}
        />
      </GridToolbarContainer>
    );
  };
  //
  const SelectionHandler = (newSelection) => {
    console.log(newSelection.row.attachment);
    setIDforPDFAppeal(newSelection.row.id);
    // setTEXTforPDFAppeal(newSelection.row.text);
    // setDATEforPDFAppeal(newSelection.row.dateCreate);
    setSIGEXIDforPDFAppealAttachment(newSelection.row.attachment_id);
    setSIGEXIDforPDFAppeal(newSelection.row.sigexId);
    setAttachmentForPDFAppeal(newSelection.row.attachment);
    newSelection.field !== '__check__'
      ? newSelection.field == 'pdf'
        ? newSelection.row.attachment
          ? dispatch(setPDFModal('appealView'))
          : dispatch(setPDFModal('none'))
        : pdfData(newSelection.row.id)
      : dispatch(setPDFModal('none'));
  };

  const pdfData = (ids) => {
    dispatch(setPDFModal('pdf'));
    console.log(ids);
    setIDforAcceptAppeal(ids);
  };

  const CloseModal = () => {
    dispatch(setPDFModal('none'));
  };

  const GetDataAfterChange = () => {
    GetData();
    successData('Статус обращения успешно изменен!');
    CloseModal();
  };

  const GetData = () => {
    const fd = new FormData();
    fd.append('id', user.id);
    const appealsResponse = appealsAPI.fetchAllAppeals(fd);
    appealsResponse.then((res) => setGetAllAppeals(res)).catch((err) => console.log(err));

    const Sotrudniki = appealsAPI.fetchTypeAppeal();
    Sotrudniki.then((res) => setSotrudniki(res));
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const handleOpenModal = () => {
    CloseModal();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePopoverOpen = () => {
    setAnchorEl(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSelectedSotr(event.id);
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => Appeals.find((row) => row.id === id));
    setSelectedItem(selectedRowsData.map((id) => id.id));
  };

  const handleSelectedItemForward = () => {
    selectedSotr == '' ? appealsValueNotSelected() : handleTransfer(selectedSotr);
  };

  const handleTransfer = (selectedValue) => {
    const fd = new FormData();
    fd.append('user', selectedValue);
    fd.append('id', SelectedItem);
    const appealsResponse = appealsAPI.fetchTransfer(fd);
    appealsResponse.then((res) => successData('Обращение успешно назначено!'));
    handleCloseModal();
  };

  const handleStatus = (onChangeStatus) => {
    const fd = new FormData();
    var arr2 = [];
    SelectedItem.map((ids) => {
      let obj = {
        id: ids,
        status: onChangeStatus,
        user: user.id,
      };
      arr2.push(obj);
    });
    arr2 = JSON.stringify(arr2);
    console.log(arr2);
    fd.append('array', arr2);
    const appealsResponse = appealsAPI.fetchchangeStatus(fd);
    appealsResponse.then((res) => GetDataAfterChange());
  };

  return (
    <>
      <div style={{ height: 585, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: 585,
                width: '100%',
                '& .super-app.negative4': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff0000',
                  fontWeight: '600',
                },
                '& .super-app.negative': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#1a3e72',
                  fontWeight: '600',
                },
                '& .super-app.positive': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app.positive2': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app.positive3': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app-theme--header': {
                  backgroundColor: '#e0e0e0',
                  paddingLeft: '10px',
                },
                '& .super-app-theme--pdf': {
                  backgroundColor: '#e0e0e0',
                  paddingLeft: '10px',
                },
                '& .super-app.NullPdf': {
                  backgroundColor: '#e0e0e0',
                  color: '#07bc0c',
                  fontWeight: '600',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.pdf': {
                  backgroundColor: '#07bc0c',
                  color: '#07bc0c',
                  fontWeight: '600',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.about': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '& .super-app.title': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '& .super-app.numberAndDate': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'pre-line',
                  lineHeight: 'normal',
                  textAlign: 'center',
                },
                '.MuiDataGrid-columnHeaderTitleContainer': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              {/* <ThemeProvider theme={theme}>
                <Button
                  color='neutral'
                  sx={{ mb: '30px', height: '48px', width: '240px' }}
                  variant='contained'
                  disabled={SelectedItem ? (SelectedItem?.length == '0' ? true : false) : true}
                  onClick={() => {
                    handleStatus(2);
                  }}
                >
                  Принять в работу
                </Button>
                <Button
                  color='neutral'
                  sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                  variant='outlined'
                  disabled={SelectedItem?.length == '1' ? false : true}
                  onClick={() => {
                    handleOpenModal();
                  }}
                >
                  Передать исполнителю
                </Button>
                <Button
                  color='refused'
                  sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                  variant='outlined'
                  disabled={SelectedItem ? (SelectedItem?.length == '0' ? true : false) : true}
                  onClick={() => {
                    handleStatus(4);
                  }}
                >
                  Отказать
                </Button>
              </ThemeProvider> */}
              <ThemeProvider theme={myTheme}>
                <DataGrid
                  disableSelectionOnClick
                  getRowHeight={() => 'auto'}
                  //   checkboxSelection={true}
                  //   onSelectionModelChange={(newSelection) => {
                  //     onRowsSelectionHandler(newSelection);
                  //   }}
                  // selectionModel={select}
                  localeText={
                    lang == 'ru' ? ruRU : lang == 'kz' ? kzKZ : lang == 'en' ? enEN : null
                  }
                  pagination
                  disableExport
                  disableReorder
                  disableColumnMenu
                  rowsPerPageOptions={[5]}
                  pageSize={8}
                  rows={Appeals}
                  columns={GridColDef}
                  onCellClick={(newSelection) => {
                    SelectionHandler(newSelection);
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterLogicOperator: GridLinkOperator.Or,
                      },
                    },
                  }}
                  components={{
                    // Toolbar: QuickSearchToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Toolbar: CustomToolbar,
                    Pagination: CustomPagination,
                  }}
                  componentsProps={{
                    panel: {
                      placement: 'top',
                    },
                    cell: {
                      onMouseEnter: handlePopoverOpen,
                      onMouseLeave: handlePopoverClose,
                    },
                  }}
                />
              </ThemeProvider>
              <Popover
                sx={{
                  pointerEvents: 'none',
                  zIndex: '-1',
                }}
                open={false}
              ></Popover>
            </Box>
          </div>
        </div>
      </div>

      <Modal
        open={OpenModal}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title='Введенные данные будут утеряны'
          >
            <div onClick={handleCloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>Подача обращения</div>
          </div>

          <Box sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Выберите Сотрудника</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                // value={selectedValue}
                label='Выберите Сотрудника'
                // onChange={handleChange}
                sx={{
                  // width: 100,
                  // height: 40,
                  // marginRight: 15,
                  // border: "1px solid darkgrey",
                  // color: "#fff",
                  '& .MuiSvgIcon-root': {
                    width: '55px',
                    height: '55px',
                    background: 'black',
                    fill: 'darkorange',
                    borderBottomRightRadius: '5px',
                    borderTopRightRadius: '5px',
                  },
                }}
                // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                IconComponent={() => <KeyboardArrowDownRoundedIcon />}
              >
                {Sotrudniki?.map((item, index) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    onClick={() => {
                      handleChange(item);
                    }}
                    // disabled={item.disabled}
                  >
                    {item.first_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ThemeProvider theme={theme}>
            <Button
              color='neutral'
              sx={{
                mt: '64px',
                height: '48px',
                width: '100%',
                boxShadow: 'none',
                paddingTop: '15px',
              }}
              variant='contained'
              onClick={handleSelectedItemForward}
            >
              Передать
            </Button>
            <Button
              color='neutral'
              sx={{ mt: '24px', height: '48px', width: '100%' }}
              variant='outlined'
              // onClick={handleCloseModal}
            >
              Отмена
            </Button>
          </ThemeProvider>
        </Box>
      </Modal>
      {modal == 'appealView' ? (
        <AppealViewForAdminPDFAttachment
          CloseModal={CloseModal}
          IDforPDFAppeal={IDforPDFAppeal}
          IDforAppeal='none'
          SIGEXIDforPDFAppeal={SIGEXIDforPDFAppealAttachment}
          AttachmentForPDFAppeal={AttachmentForPDFAppeal}
          Trans={Trans}
        />
      ) : modal == 'pdf' ? (
        <AppealViewForAdminPDF
          CloseModal={CloseModal}
          IDforPDFAppeal={IDforPDFAppeal}
          IDforAppeal='none'
          AttachmentForPDFAppeal={AttachmentForPDFAppeal}
          SelectedItem={SelectedItem}
          handleOpenModal={handleOpenModal}
          IDforAcceptAppeal={IDforAcceptAppeal}
          GetDataAfterChange={GetDataAfterChange}
          functionFor='ReturnWithoutPerformer'
          SIGEXIDforPDFAppeal={SIGEXIDforPDFAppeal}
          dispatch={dispatch}
          Trans={Trans}
        />
      ) : null}
    </>
  );
};
export default ReturnWithoutPerformer;
