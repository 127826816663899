import React from 'react';

import s from './OnlineCredit.module.css';

import OnlineCreditBlock from '../../components/OnlineCreditBlock/OnlineCreditBlock';

const OnlineCredit = ({ Trans, dispatch }) => {






  return (
    <>

    <div className={s.Wrapper}>
        <div className={s.title}>
            <p>Сервисы - Кредиты и финансовая поддержка - Онлайн-заявка на кредит</p>
        </div>
        <div className={s.maintitle}>
            <p>Онлайн-заявка на кредит</p>
        </div>

        <OnlineCreditBlock />
    </div>


    </>
  );
};


export default OnlineCredit;
