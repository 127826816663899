import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';
import PDFEdit from './PDFEdit';
const AppealPDFEdit = ({
  ws,
  appealNumber,
  appealDate,
  CloseModal,
  IDforPDFAppeal,
  TEXTforPDFAppeal,
  DATEforPDFAppeal,
  setOpenModal,
  setSendAppealsReload,
  SendAppealsReload,
  textFieldValue,
  selectedValue,
  CatselectedValue,
  DopCatselectedValue,
  title,
  fileID,
  dataB64,
  dispatch,
  setModalStep3,
  DeleteID,
  IDforAppeal,
  selectedLangForSend,
  Trans,
}) => {
  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
    },
  });

  const styleModal = {
    position: 'absolute',
    top: 320,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const handleCloseModal = () => {
    CloseModal();
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <div className={s.close_auth_modal} onClick={handleCloseModal}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='SendAppeals.item11' />
            </div>
          </div>

          <Box sx={{ minWidth: 120 }}>
            <PDFEdit
              Trans={Trans}
              appealDate={appealDate}
              CatselectedValue={CatselectedValue}
              DopCatselectedValue={DopCatselectedValue}
              appealNumber={appealNumber}
              selectedValue={selectedValue}
              fileID={fileID}
              dataB64={dataB64}
              ws={ws}
              handleCloseModal={handleCloseModal}
              IDforPDFAppeal={IDforPDFAppeal}
              TEXTforPDFAppeal={TEXTforPDFAppeal}
              DATEforPDFAppeal={DATEforPDFAppeal}
              title={title}
              dispatch={dispatch}
              setOpenModal={setOpenModal}
              setSendAppealsReload={setSendAppealsReload}
              SendAppealsReload={SendAppealsReload}
              setModalStep3={setModalStep3}
              DeleteID={DeleteID}
              IDforAppeal={IDforAppeal}
              selectedLangForSend={selectedLangForSend}
            />

            {/* <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={handleCloseModal}
              >
                Сохранить в черновик
              </Button>
            </ThemeProvider> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AppealPDFEdit;
