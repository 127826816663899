import React from 'react';
import s from './style.module.css';
import Button from '@mui/material/Button';
import { useBusinessMap } from './useBusinessMap';
import BusinessMapTable from '../../components/BusinessMapTable/BusinessMapTable';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import BusinessMap from '../../components/BusinessMap/BusinessMap';
import { useSelector } from 'react-redux';
import BusinessMapAdmin from '../../components/BusinessMapAdmin/BusinessMapAdmin';

const BusinessMapPage = () => {
  // FOR FILTERS
  const [inputValue, setInputValue] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');

  const { items, filterCategories, fetchData } = useBusinessMap(inputValue, searchValue);
  const [isMap, setIsMap] = React.useState(false);

  const [userStatus, setUserStatus] = React.useState(false);
  const user = useSelector((state) => state.store.mainSlice.user);

  const clearFilter = () => {
    setSearchValue('');
    setInputValue('');
  };

  if (user?.status_user && !userStatus) {
    setUserStatus(user.status_user);
  }

  return (
    <div className={s.wrapper}>
      <h2 style={{ marginBottom: '20px', paddingLeft: '0' }}>Карта бизнеса</h2>

      <div className={s.filtersWrapper}>
        <Autocomplete
          disablePortal
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id='combo-box-demo'
          options={filterCategories}
          sx={{ width: '100%' }}
          renderInput={(params) => <TextField {...params} label='Вид обьекта' />}
        />
        <TextField
          label='Поиск'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button
          onClick={clearFilter}
          color='neutral'
          sx={{ height: '55px', width: '280px', marginTop: '0px' }}
          variant='contained'
        >
          Сбросить фильтры
        </Button>
      </div>
      <div className={s.switchMap}>
        <span onClick={() => setIsMap(false)} className={!isMap ? s.active : ''}>
          Таблица
        </span>
        <span onClick={() => setIsMap(true)} className={isMap ? s.active : ''}>
          Смотреть на карте
        </span>
      </div>

      {
        <>
          {isMap ? (
            <BusinessMap items={items} />
          ) : userStatus == '0' ? (
            <BusinessMapTable items={items} />
          ) : userStatus == '2' ? (
            <BusinessMapAdmin
              filterCategories={filterCategories}
              items={items}
              fetchData={fetchData}
              user_id={user?.id}
            />
          ) : userStatus == '1' ? (
            <BusinessMapAdmin
              filterCategories={filterCategories}
              items={items}
              fetchData={fetchData}
              user_id={user?.id}
            />
          ) : null}
        </>
      }
    </div>
  );
};

export default BusinessMapPage;
