import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import s from './header.module.css';

import Button from '@mui/material/Button';

import { logOut } from '../../redux/asyncThunks/mainAsyncThunk';
import { setModalAuth, setNcaLayerErr, setNcaLayerStatus } from '../../redux/slices/mainSlice';

const ModalAfterAuth = ({
  dispatch,
  setUserLogin,
  handleCloseModalAuth,
  countNotifLS,
  setCountNotifLS,
  Trans,
  toggleDrawerUslugiFirst,
  toggleDrawerServFirst,
}) => {
  const user = useSelector((state) => state.store.mainSlice.user);
  const navigate = useNavigate();

  const logout = () => {
    const fd = new FormData();
    fd.append('id', user.id);
    dispatch(logOut(fd));
    localStorage.removeItem('crypt:a');
    setUserLogin(false);
    dispatch(setNcaLayerErr(null));
    dispatch(setNcaLayerStatus(null));
    dispatch(setModalAuth(false));
    navigate('/');
  };

  return (
    <>
      <div className={s.modal_login}>
        <div
          className={s.modalLoginOption}
          onClick={() => {
            navigate('/personal-area');
            handleCloseModalAuth();
          }}
        >
          <Button variant='text'>
            <Trans i18nKey='header.login.modalAfterAuth.item1' />
          </Button>
        </div>

        <div
          className={s.modalLoginOption}
          onClick={() => {
            navigate('/appeals');
            handleCloseModalAuth();
          }}
        >
          <Button variant='text'>
            <Trans i18nKey='header.login.modalAfterAuth.item7' />
          </Button>
        </div>

        <div className={s.modalLoginOption}>
          <Button
            variant='text'
            onClick={() => {
              navigate('/notifications');
              handleCloseModalAuth();
              setCountNotifLS(0);
            }}
          >
            <Trans i18nKey='header.login.modalAfterAuth.item3' />
          </Button>
          <span>{countNotifLS > 0 && countNotifLS}</span>
        </div>

        <div className={s.modalLoginOption}>
          <Button
            variant='text'
            onClick={() => {
              toggleDrawerServFirst();
              handleCloseModalAuth();
            }}
          >
            <Trans i18nKey='header.login.modalAfterAuth.item4' />
          </Button>
        </div>

        <div className={s.modalLoginOption}>
          <Button
            onClick={() => {
              toggleDrawerUslugiFirst();
              handleCloseModalAuth();
            }}
            variant='text'
          >
            <Trans i18nKey='header.login.modalAfterAuth.item5' />
          </Button>
        </div>

        <button className={s.btn_login} onClick={logout}>
          <Trans i18nKey='header.login.modalAfterAuth.item6' />
        </button>
      </div>
    </>
  );
};

export default ModalAfterAuth;
