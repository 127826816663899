import * as React from 'react';
import { useForm } from 'react-hook-form';
import { EncryptStorage } from 'encrypt-storage';

import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridLinkOperator,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import clsx from 'clsx';
import { ruRU } from '../../common/helpers/ruRU';
import { kzKZ } from '../../common/helpers/kzKZ';
import { enEN } from '../../common/helpers/enEN';
import s from './notifications.module.css';
import { notificationsApi } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { successData } from '../../common/helpers/sliceToasts';
import { notificationsAll } from '../../redux/asyncThunks/notificationsAsyncThunk';
import { Link, useNavigate } from 'react-router-dom';

const NotificationsAdmin = ({ ws, Trans }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const encryptStorage = new EncryptStorage('secret-key-value', {
    prefix: 'crypt',
  });
  const getNotifications = useSelector((state) => state.store.notificationsSlice.item);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = React.useState([]);
  const [oneNotificaion, setOneNotificaion] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openModalAnswer, setOpenModalAnswer] = React.useState(false);
  const [isLoadingModal, setIsLoadingModalAnswer] = React.useState(false);

  const userId = useSelector((state) => state.store.mainSlice.user.id);
  const lang = localStorage.getItem('lang');

  const inputAnswerFile = React.useRef();
  const [fileNameAnswer, setFileNameAnswer] = React.useState(false);

  let idNotification = [];

  const languageView = {
    ru: ['Не принято', 'Не прочитано', 'Прочитано'],
    kz: ['Қабылданбады', 'Оқылмаған', 'Оқылды'],
    en: ['Not accepted', 'Not read', 'Read'],
  };

  const handleOpen = () => {
    setIsLoadingModalAnswer(true);
    const fd = new FormData();
    fd.append('id', userId);
    const obj = {
      id: idNotification.id,
      id_appeal: idNotification.row.id_appeal,
    };
    setOpen(true);

    const response = notificationsApi.onReadNotifications(obj);
    response.then(async (res) => {
      if (res[0] !== null) {
        setOneNotificaion(...res);
        setIsLoadingModalAnswer(false);
        // res[0].recipient === userId && res[0].status !== '1' && dispatch(notificationsAll(fd));
        dispatch(notificationsAll(fd));
      }
    });
  };

  const handleClose = () => setOpen(false);

  const handleAnswer = () => {
    setOpen(false);
    setOpenModalAnswer(true);
  };

  const handleAnswerClose = () => setOpenModalAnswer(false);

  function createData(
    id,
    id_appeal,
    recipient,
    sender,
    title,
    text,
    user,
    dateCreate,
    status,
    status_appeal,
  ) {
    return {
      id,
      id_appeal,
      recipient,
      sender,
      title,
      text,
      user,
      dateCreate,
      status,
      status_appeal,
    };
  }

  React.useEffect(() => {
    const newNotif = getNotifications
      ?.map((res) => {
        return createData(
          res.id,
          res.id_appeal,
          res.recipient == 'All'
            ? (localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
                (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
                (localStorage.getItem('lang') === 'en' && languageView.en[0])
            : res.status == 0
            ? (localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[1])
            : (localStorage.getItem('lang') === 'ru' && languageView.ru[2]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[2]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[2]),

          res.sender,
          res.title,
          res.text,
          res[0]?.last_name + ' ' + res[0]?.first_name + ' ' + res[0]?.middle_name,
          res.dateCreate,
          res.status,
          res.status_appeal,
        );
      })
      .sort((a, b) => b.id - a.id);
    setNotifications(newNotif);
    encryptStorage.setItem('notificationsCount', getNotifications);
  }, [getNotifications]);

  React.useEffect(() => {
    setDefNotificationWrapperWidth(notification_wrapperWidth.current.getBoundingClientRect().width);
  }, []);

  const [DefNotificationWrapperWidth, setDefNotificationWrapperWidth] = React.useState(1000);
  const notification_wrapperWidth = React.useRef(null);

  const SelectionHandler = (newSelection) => {
    idNotification = newSelection;
    handleOpen();
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
          }}
        >
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
        <div style={{ marginTop: '50px' }}></div>
        <GridToolbarFilterButton
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
            color: '#454545',
          }}
        />
      </GridToolbarContainer>
    );
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const GridColDef = [
    {
      field: 'title',
      headerName: <Trans i18nKey='Notifications.item12' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
          for_1_4_ne_prochitano:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[1])),
        });
      },
    },
    {
      field: 'id_appeal',
      headerName: <Trans i18nKey='Notifications.item13' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
          for_1_4_ne_prochitano:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[1])),
        });
      },
    },

    {
      field: 'user',
      headerName: <Trans i18nKey='Notifications.item14' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
          for_1_4_ne_prochitano:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[1])),
        });
      },
    },
    {
      field: 'dateCreate',
      headerName: <Trans i18nKey='Notifications.item15' />,
      type: 'date',
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
          for_1_4_ne_prochitano:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[1])),
        });
      },
    },
    {
      field: 'recipient',
      headerName: <Trans i18nKey='Notifications.item16' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          ne_prochitano:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[1])),
          prochitano:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[2]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[2]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[2])),
          ne_prosmotreno:
            params.row.recipient ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
        });
      },
    },
  ];

  const style = {
    position: 'absolute',
    top: '25%',
    left: '80%',
    transform: 'translate(-80%, -25%)',
    maxWidth: 424,
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '33px 48px 24px 48px',
    borderRadius: '5px',
  };

  const onAnswerNotification = (data) => {
    const fd = new FormData();
    fd.append('text', data.text);

    inputAnswerFile.current.files.length
      ? fd.append(
          'attachment',
          inputAnswerFile.current.files ? inputAnswerFile.current.files[0] : null,
        )
      : fd.append('attachment', 'no_files');
    fd.append('id_appeal', oneNotificaion.id_appeal);
    fd.append('to', oneNotificaion.sender);
    fd.append('sender', userId);
    fd.append('title', oneNotificaion.title);
    inputAnswerFile.current = [];

    const response = notificationsApi.onAnswerNotifications(fd);
    response.then((res) => {
      reset();
      handleAnswerClose();
      userId > 0 && ws.send([`isAnswerNotifiaction`, oneNotificaion.sender]);
      successData('Ответ на уведомление отправлен!');
    });
  };
  const handleChangeFile = () => {
    setFileNameAnswer(inputAnswerFile.current.files[0].name);
  };

  return (
    <div className={s.notification_wrapper} ref={notification_wrapperWidth}>
      <>
        <div style={{ height: 600, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <Box
                sx={{
                  height: 600,
                  width: '100%',
                  '& .super-app.for_1_4_ne_prochitano': {
                    backgroundColor: '#d1cec77d',
                    fontWeight: '600',
                    cursor: 'pointer',
                  },
                  '& .super-app.for_1_4_ne_prosmotreno': {
                    backgroundColor: '#f7e1a87d',
                    fontWeight: '600',
                    cursor: 'pointer',
                  },
                  '& .super-app.ne_prochitano': {
                    backgroundColor: '#d1cec77d',
                    color: '#28323e',
                    fontWeight: '600',
                  },
                  '& .super-app.prochitano': {
                    color: '#47AF6F',
                    fontWeight: '600',
                  },
                  '& .super-app.ne_prosmotreno': {
                    backgroundColor: '#f7e1a87d',
                    color: '#ff7f00',
                    fontWeight: '600',
                  },
                  '& .super-app-theme--header': {
                    paddingLeft: '10px',
                    backgroundColor: '#e0e0e0',
                  },
                }}
              >
                {notifications?.length > 0 && (
                  <DataGrid
                    rows={notifications}
                    columns={GridColDef}
                    localeText={
                      lang == 'ru' ? ruRU : lang == 'kz' ? kzKZ : lang == 'en' ? enEN : null
                    }
                    pagination
                    onCellClick={(newSelection) => {
                      SelectionHandler(newSelection);
                    }}
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterLogicOperator: GridLinkOperator.Or,
                        },
                      },
                      sorting: {
                        sortModel: [{ field: 'dateCreate', sort: 'desc' }],
                      },
                    }}
                    components={{
                      // Toolbar: QuickSearchToolbar,
                      Toolbar: CustomToolbar,
                      Pagination: CustomPagination,
                    }}
                  />
                )}
              </Box>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <div className={s.close_auth_modal} onClick={handleClose}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            {!isLoadingModal ? (
              <div className={s.title_auth_modal}>
                <div className={s.title_auth_modal}>
                  <Trans i18nKey='Notifications.item4' />: {oneNotificaion?.id_appeal}
                </div>
                <div className={s.content_view_modal}>{oneNotificaion?.text}</div>
                {oneNotificaion?.recipient === 'All' || oneNotificaion?.status > 1 ? null : (
                  <div>
                    {oneNotificaion?.attachment && <div>Прикрепленный файл:</div>}
                    <div className={s.linkModal}>
                      <a
                        href={`https://biznes.15000pvl.kz/file/notification/${oneNotificaion?.attachment}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {oneNotificaion?.attachment}
                      </a>
                    </div>
                  </div>
                )}
                {oneNotificaion?.recipient === 'All' || oneNotificaion?.status > 1 ? (
                  <div>
                    <button className={s.btn_main} onClick={() => navigate('/admin-appeals')}>
                      <Trans i18nKey='Notifications.item19' />
                    </button>
                    <button className={s.btn_main_2} onClick={handleClose}>
                      <Trans i18nKey='Notifications.item8' />
                    </button>
                  </div>
                ) : (
                  <div>
                    <Link className={s.btn_go_to_appeals} to='/appeals'>
                      <Trans i18nKey='Notifications.item19' />
                    </Link>
                    {oneNotificaion.status_appeal === '0' ? (
                      <>
                        <button className={s.btn_main} onClick={handleAnswer}>
                          <Trans i18nKey='Notifications.item7' />
                        </button>
                        <button className={s.btn_main_2} onClick={handleClose}>
                          <Trans i18nKey='Notifications.item8' />
                        </button>
                      </>
                    ) : (
                      <button className={s.btn_main} onClick={handleClose}>
                        <Trans i18nKey='Notifications.item6' />
                      </button>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className={s.content_view_modal}>
                <Trans i18nKey='Notifications.item9' />
                ...
              </div>
            )}
          </Box>
        </Modal>
        <Modal
          open={openModalAnswer}
          onClose={handleAnswerClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <div className={s.close_auth_modal} onClick={handleAnswerClose}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <div className={s.title_auth_modal}>
              <div className={s.title_auth_modal}>
                <Trans i18nKey='Notifications.item4' />: {oneNotificaion?.id_appeal}
              </div>
              <form onSubmit={handleSubmit(onAnswerNotification)} className={s.formNotif}>
                <div style={{ padding: '0px 0px 15px 0px' }}>
                  <TextareaAutosize
                    className={s.textAreaFormAnswer}
                    minRows={12}
                    placeholder='Введите сообщение'
                    {...register('text', {
                      required: 'Введите сообщение',
                    })}
                  />
                </div>
                <div className={s.fileInput}>
                  <input
                    name='file'
                    id='file'
                    type='file'
                    style={{ display: 'none' }}
                    ref={inputAnswerFile}
                    onChange={handleChangeFile}
                  />
                  <label htmlFor='file'>
                    <Trans i18nKey='Notifications.item11' /> <AttachFileIcon />
                  </label>
                </div>
                <div className={s.nameFiles}>{fileNameAnswer}</div>
                <div>
                  <button className={s.btn_main}>
                    <Trans i18nKey='Notifications.item7' />
                  </button>
                </div>
                <button className={s.btn_main_2} onClick={handleAnswerClose}>
                  <Trans i18nKey='Notifications.item8' />
                </button>
              </form>
            </div>
          </Box>
        </Modal>
      </>
    </div>
  );
};

export default NotificationsAdmin;
