import React from 'react';
import { useState } from 'react';

import s from './AdminChooseAppealsType.module.css';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';

import AdminAppeals from '../AdminAppeals/AdminAppeals';
import AllAppeals from '../AdminAppeals/AllAppeals/AllAppeals';
import MyNotFinishAppeals from '../MyNotFinishAppeals/MyNotFinishAppeals';
import AdminNotFinishAppealsAnswers from '../AdminAppeals/AdminNotFinishAppealsAnswers';
import { MyAppealsWidth } from '../../redux/slices/mainSlice';
const AdminChooseAppealsType = ({ Trans, dispatch, ws, status }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const AdminChooseAppealsTypeWidth = React.useRef(null);
  const [reload, setReload] = useState(false);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  React.useEffect(() => {
    dispatch(MyAppealsWidth(AdminChooseAppealsTypeWidth.current.getBoundingClientRect().width));
  }, []);

  return (
    <>
      <div className={s.Wrapper} ref={AdminChooseAppealsTypeWidth}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            width: '100%',
            position: 'relative',
            minHeight: 200,
          }}
        >
          <AppBar
            sx={{
              bgcolor: '#ffffff',
              boxShadow: 'none',
              width: '50%',
              color: '#ff7f00',
              marginLeft: '24px',
            }}
            position='static'
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='inherit'
              variant='fullWidth'
              aria-label='action tabs example'
              TabIndicatorProps={{
                style: { background: '#ff7f00', color: '#ff7f00' },
              }}
            >
              <Tab label={<Trans i18nKey='AdminChooseAppealsType.item1' />} {...a11yProps(0)} />
              <Tab label={<Trans i18nKey='AdminChooseAppealsType.item2' />} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AdminAppeals Trans={Trans} dispatch={dispatch} ws={ws} status={status} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AdminNotFinishAppealsAnswers
                Trans={Trans}
                ws={ws}
                dispatch={dispatch}
                status={status}
              />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </div>
    </>
  );
};

export default AdminChooseAppealsType;
