import React from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import ForkRightIcon from '@mui/icons-material/ForkRight';
const BusinessPlanCalculationDrawer = ({ Trans, dispatch, anchor, handleDrawerClose }) => {
  const navigate = useNavigate();

  const menu_item = [
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.BusinessPlanCalculationDrawer.item1' /> },
  ];

  return (
    <>
      <Box sx={{ width: 450, marginTop: '80px' }} role='presentation'>
        <List>
          {menu_item.map((text, index) => (
            <ListItem key={index} disablePadding>
              {text.item.props.i18nKey ==
              'DefaultSideBar.ServiceDrawer.BusinessPlanCalculationDrawer.item1' ? (
                <ListItemButton
                  onClick={() => {
                    handleDrawerClose();
                    navigate('/services/business-plan-calculation/calculation-for-urban-business');
                  }}
                >
                  <ListItemIcon>
                    <TurnRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              ) : (
                <ListItemButton>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default BusinessPlanCalculationDrawer;
