import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { errorData } from '../../common/helpers/sliceToasts';

import MainPage from '../../pages/main/MainPage';
import AppealsPage from '../../pages/AppealsPage/AppealsPage';
import AdminAppealPage from '../../pages/AdminAppealPage/AdminAppealPage';
import CreditLimitCalc from '../../pages/CreditLimitCalc/CreditLimitCalc';
// import FinHelpProgramms from '../../pages/FinHelpProgramms/FinHelpProgramms';
import SpisokProgrammFinHelp from '../../pages/SpisokProgrammFinHelp/SpisokProgrammFinHelp';
// import InvestorsSearch from '../../pages/InvestorsSearch/InvestorsSearch';
import SpisokInvestorov from '../../pages/SpisokInvestorov/SpisokInvestorov';
import OnlineCredit from '../../pages/OnlineCredit/OnlineCredit';
import TaxRegimeSelection from '../../pages/TaxRegimeSelection/TaxRegimeSelection';
import AnnouncedPurchases from '../../pages/AnnouncedPurchases/AnnouncedPurchases';
import ProcurementDocumentConstructor from '../../pages/ProcurementDocumentConstructor/ProcurementDocumentConstructor';
import LeasingOrganizations from '../../pages/LeasingOrganizations/LeasingOrganizations';
import AnalitikaBusinessObjects from '../../pages/AnalitikaBusinessObjects/AnalitikaBusinessObjects';
import AnalitikaBusinessObjectsQuadrats from '../../pages/AnalitikaBusinessObjectsQuadrats/AnalitikaBusinessObjectsQuadrats';
import AnalitikaBusinessObjectsRadius from '../../pages/AnalitikaBusinessObjectsRadius/AnalitikaBusinessObjectsRadius';
import AnalitikaBusinessObjectsSelect from '../../pages/AnalitikaBusinessObjectsSelect/AnalitikaBusinessObjectsSelect';
import AnalitikaBusinessObjectsCommunication from '../../pages/AnalitikaBusinessObjectsCommunication/AnalitikaBusinessObjectsCommunication';
import AnalitikaBusinessObjectsRentab from '../../pages/AnalitikaBusinessObjectsRentab/AnalitikaBusinessObjectsRentab';
import AnalitikaBusinessObjectsPass from '../../pages/AnalitikaBusinessObjectsPass/AnalitikaBusinessObjectsPass';
import SupportMeasures from '../../pages/SupportMeasures/SupportMeasures';
// import PremisesSearch from '../../pages/PremisesSearch/PremisesSearch';
import CalculationForUrbanBusiness from '../../pages/CalculationForUrbanBusiness/CalculationForUrbanBusiness';
import SpisokQuadrats from '../../pages/SpisokQuadrats/SpisokQuadrats';
import CreditLimit from '../../pages/CreditLimit/CreditLimit';
import OnlineBankCredit from '../../pages/OnlineBankCredit/OnlineBankCredit';
import StatHistory from '../../components/StatHistory/StatHistory';
// import FranchiseSelection from '../../pages/FranchiseSelection/FranchiseSelection';
import SpisokFranch from '../../pages/SpisokFranch/SpisokFranch';
import PersonalArea from '../../pages/PersonalArea/PersonalArea';
import ChoseTypeNotifications from '../../pages/Notifications/ChoseTypeNotifications';
import MyGallery from '../../pages/Gallery/MyGallery';
import { News } from '../../pages/News/News';
import BusinessMapPage from '../../pages/BusinessMap/BusinessMapPage';
import Post from '../../pages/News/Post/Post';

import SpisokGosUslug from '../../pages/SpisokGosUslug/SpisokGosUslug';
import Statistics from '../../pages/Statistics/Statistics';
import DocumentsPage from '../../pages/DocumentsPage/DocumentsPage';
import ContactDataPage from '../../pages/ContactDataPage/ContactDataPage';

const MainPageRoutes = ({ dispatch, i18n, Trans, ws }) => {
  const user = useSelector((state) => state.store.mainSlice.user);
  const [userStatus, setUserStatus] = useState(false);
  const [SotrudnikStatus, setSotrudnikStatus] = useState(false);
  const [AdminStatus, setAdminStatus] = useState(false);

  useEffect(() => {
    user
      ? user.status_user == '0'
        ? setUserStatus(user.status_user)
        : user.status_user == '1'
        ? setSotrudnikStatus(user.status_user)
        : user.status_user == '2'
        ? setAdminStatus(user.status_user)
        : errorData('Ошибка аутентификации')
      : console.info();
  }, [user]);

  return (
    <Routes>
      {/*Главная*/}
      <Route
        path='/'
        element={<MainPage i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/analitika/business-objects'
        element={<AnalitikaBusinessObjects i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/analitika/business-objects-square'
        element={
          <AnalitikaBusinessObjectsQuadrats i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
        }
      />

      <Route
        path='/analitika/business-objects-radius'
        element={
          <AnalitikaBusinessObjectsRadius i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
        }
      />

      <Route
        path='/analitika/business-objects-selection'
        element={
          <AnalitikaBusinessObjectsSelect i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
        }
      />

      <Route
        path='/analitika/business-objects-passability'
        element={
          <AnalitikaBusinessObjectsPass i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
        }
      />

      <Route
        path='/analitika/business-objects-communications'
        element={
          <AnalitikaBusinessObjectsCommunication
            i18n={i18n}
            Trans={Trans}
            dispatch={dispatch}
            ws={ws}
          />
        }
      />

      <Route
        path='/analitika/business-objects-profitability'
        element={
          <AnalitikaBusinessObjectsRentab i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
        }
      />

      <Route
        path='/analitika/history'
        element={<StatHistory i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/uslugi/spisok-gos-uslug'
        element={<SpisokGosUslug i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/services'
        element={<SpisokGosUslug i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/services/credits-and-fin-help/credit-online'
        element={<OnlineCredit i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/services/taxes/selection-of-tax-regime'
        element={<TaxRegimeSelection i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/property-for-business/search-for-public-and-quasipublic-premises'
        element={<SpisokQuadrats i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/purchases/procurement-document-constructor'
        element={<ProcurementDocumentConstructor i18n={i18n} Trans={Trans} dispatch={dispatch} />}
      />
      <Route
        path='/services/leasing/search-for-leasing-organizations'
        element={<LeasingOrganizations i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/business-plan-calculation/calculation-for-urban-business'
        element={<CalculationForUrbanBusiness i18n={i18n} Trans={Trans} dispatch={dispatch} />}
      />
      <Route
        path='/services/credits-and-fin-help/credit-limit-calc'
        element={<CreditLimitCalc i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/credits-and-fin-help/fin-help-programms'
        element={<SpisokProgrammFinHelp i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/credits-and-fin-help/investors-search'
        element={<SpisokInvestorov i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/credits-and-fin-help/banks'
        element={<OnlineBankCredit i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/credits-and-fin-help/credit-limit'
        element={<CreditLimit i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/purchases/announced-purchases'
        element={<AnnouncedPurchases i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/market-analysis/franchise-selection'
        element={<SpisokFranch i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/businessMap'
        element={<BusinessMapPage i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/statistics'
        element={<Statistics i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/documents'
        element={<DocumentsPage i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />

      <Route
        path='/services/contact-details'
        element={<ContactDataPage i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/services/support-measures'
        element={<SupportMeasures i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      {/*Уведомления*/}

      {userStatus && (
        <>
          <Route
            path='/appeals'
            element={<AppealsPage i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
          />
          <Route
            path='/notifications'
            element={
              <ChoseTypeNotifications i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
            }
          />
          {/*Профиль пользователя*/}
          <Route
            path='/personal-area'
            element={<PersonalArea i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
          />
        </>
      )}

      {AdminStatus && (
        <>
          <Route
            path='/admin-appeals'
            element={
              <AdminAppealPage
                i18n={i18n}
                Trans={Trans}
                dispatch={dispatch}
                ws={ws}
                status='Admin'
              />
            }
          />
          <Route
            path='/notifications'
            element={
              <ChoseTypeNotifications i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
            }
          />
          <Route
            path='/personal-area'
            element={<PersonalArea i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
          />
        </>
      )}

      {SotrudnikStatus && (
        <>
          <Route
            path='/admin-appeals'
            element={
              <AdminAppealPage
                i18n={i18n}
                Trans={Trans}
                dispatch={dispatch}
                ws={ws}
                status='Sotrudnik'
              />
            }
          />
          <Route
            path='/notifications'
            element={
              <ChoseTypeNotifications i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />
            }
          />
          <Route
            path='/personal-area'
            element={<PersonalArea i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
          />
        </>
      )}

      {/*Галлерея*/}
      <Route
        path='/gallery'
        element={<MyGallery i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      {/*Новости*/}

      <Route
        path='/news'
        element={<News i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='/news/post/:id'
        element={<Post i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
      <Route
        path='*'
        element={<MainPage i18n={i18n} Trans={Trans} dispatch={dispatch} ws={ws} />}
      />
    </Routes>
  );
};

export default MainPageRoutes;
