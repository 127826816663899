import React from "react";
import ContentLoader from "react-content-loader";
import s from "./Gallery.module.css";

const MyComponent = () => {
  return (
    <ContentLoader
      className={s.galleryPicture}
      speed={2}
      width={"100%"}
      height={200}
      backgroundColor="#f3f3f3"
      foregroundColor="#d6d6d6"
    >
      <rect x="-5" y="0" rx="0" ry="0" width="520" height="390" />
    </ContentLoader>
  );
};

export default MyComponent;
