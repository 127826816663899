import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { appealsAPI } from '../../api/api';
import { appealsValueNotSelected, errorData, successData } from '../../common/helpers/sliceToasts';

import { DataGrid, GridToolbarQuickFilter, GridLinkOperator } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import TextField from '@mui/material/TextField';

import clsx from 'clsx';

import s from '../../../src/pages/AppealsPage/AppealsPage.module.css';

import PDFCreateForAnswerForDraft from './AppealAnswerPDF/PDFCreateForAnswerForDraft';

const AdminNotFinishAppealsAnswers = ({ Trans, dispatch, ws }) => {
  const width = useSelector((state) => state.store.mainSlice.MyAppealsWidth);
  const [modal, setModal] = useState('none');
  const [IDforAppeal, setIDforAppeal] = useState('none');
  const [myDraftAppeal, setMyDraftAppeal] = React.useState([]);
  const [getMyDraftAppeals, setGetMyDraftAppeals] = React.useState([]);
  const [myDraftAppeals, setMyDraftAppeals] = React.useState([]);
  const user = useSelector((state) => state.store.mainSlice.user);
  const [SelectedItem, setSelectedItem] = React.useState(null);
  const [OpenModalSend, setOpenModalSend] = useState(false);
  const [OpenModalEdit, setOpenModalEdit] = useState(false);
  const [SendAppealsReload, setSendAppealsReload] = useState(false);
  const [DataForAnswerAppeal, setDataForAnswerAppeal] = React.useState(null);
  const [OpenModalEnd, setOpenModalEnd] = useState(false);
  const [OpenModalEnd2, setOpenModalEnd2] = useState(false);
  const [OpenModalEnd3, setOpenModalEnd3] = useState(false);
  const [completion, setcompletion] = useState(null);
  const [selectedCompl, setSelectedCompl] = useState('');
  const [textFieldValue, settextFieldValue] = useState('');
  const lang = localStorage.getItem('lang');
  const [selectedLang, setSelectedLang] = useState('');

  let gridButtonWidth = (width - 75) / 5;
  let DataGridWidth = width - gridButtonWidth;

  React.useEffect(() => {
    GetDataDraft();
  }, [modal]);

  React.useEffect(() => {
    console.log(getMyDraftAppeals);
    const newAppeals = getMyDraftAppeals?.map((res) => {
      return createData(
        res.answerType,
        res.answer_date,
        res.answer_pdf,
        res.answer_text,
        res.id,
        res.id_appeal,
        res.user_id,
        res[1]?.MainDataB64,
        res[1]?.attachment,
        res[1]?.attachment_id,
        res[1]?.category,
        res[1]?.character,
        res[1]?.consideration_period,
        res[1]?.dateCreate,
        res[1]?.form,
        res[1]?.language,
        res[1]?.reg_number,
        res[1]?.response_time,
        res[1]?.sigexId,
        res[1]?.status,
        res[1]?.status_read,
        res[1]?.subcategory,
        res[1]?.text,
        res[1]?.title,
        res[1]?.type_applicant,
        res[1]?.user_accepted,

        res[1]?.id + '_' + res[1]?.dateCreate,
        res[0]?.last_name +
          ' ' +
          res[0]?.first_name[0] +
          '. ' +
          (res[0]?.middle_name[0] ? res[0]?.middle_name[0] : '__') +
          '.' +
          '_' +
          res[0]?.IIN +
          '_' +
          res[0]?.IIN[0] +
          res[0]?.IIN[1] +
          '.' +
          res[0]?.IIN[2] +
          res[0]?.IIN[3] +
          '.' +
          res[0]?.IIN[4] +
          res[0]?.IIN[5],
        res[1]?.attachment ? 'pdf' : res[1]?.attachment,
        res[0]?.last_name + ' ' + res[0]?.first_name[0] + '. ' + res[0]?.middle_name[0] + '.',
        'Редактирование',
        'Удалить',
        'Черновик',
      );
    });
    setMyDraftAppeals(newAppeals);
  }, [getMyDraftAppeals]);

  React.useEffect(() => {
    const fds = new FormData();
    fds.append('typeofaction', 'completion');
    fds.append('lang', selectedLang);
    const completion = appealsAPI.action(fds);
    completion.then((res) => setcompletion(res));
  }, [selectedLang]);

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
      refused: {
        main: '#FF0000',
        contrastText: '#fff',
      },
    },
  });

  const styleModal = {
    position: 'absolute',
    top: 350,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const styleModal2 = {
    position: 'absolute',
    top: 320,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const GridColDef = [
    {
      field: 'title',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item1' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'numberDate',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item2' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          numberDate: params.value !== '',
        });
      },
    },
    {
      field: 'userAbout',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item3' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          about: params.value !== '',
        });
      },
    },
    {
      field: 'chernovik',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item4' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      valueGetter: (params) => {
        if (params.value == 'Черновик') {
          if (lang == 'ru') {
            return 'Черновик';
          }
          if (lang == 'en') {
            return 'Draft';
          }
          if (lang == 'kz') {
            return 'Жоба';
          }
        }
      },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          delete: params.value == 'Удалить',
        });
      },
    },
    {
      field: 'pdf',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item5' />,
      type: 'date',
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,

      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          delete: params.value == 'Удалить',
        });
      },
    },
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          mb: '10px',
          ml: '10px',
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput
              .split(',')
              .map((value) => value.trim())
              .filter((value) => value !== '')
          }
        />
      </Box>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width='120' height='100' viewBox='0 0 184 152' aria-hidden focusable='false'>
          <g fill='none' fillRule='evenodd'>
            <g transform='translate(24 31.67)'>
              <ellipse
                className='ant-empty-img-5'
                cx='67.797'
                cy='106.89'
                rx='67.797'
                ry='12.668'
              />
              <path
                className='ant-empty-img-1'
                d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
              />
              <path
                className='ant-empty-img-2'
                d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
              />
              <path
                className='ant-empty-img-3'
                d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
              />
            </g>
            <path
              className='ant-empty-img-3'
              d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
            />
            <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
              <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
              <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Вы еще не создали ни одного черновика</Box>
      </StyledGridOverlay>
    );
  }

  const GetDataDraft = () => {
    const fd = new FormData();
    fd.append('user_id', user.id);
    const appealsResponse = appealsAPI.getAnswerDraft(fd);
    appealsResponse.then((res) => setGetMyDraftAppeals(res)).catch((err) => console.log(err));
  };

  const SelectionHandler = (newSelection) => {
    newSelection.field == 'deleteAppeal'
      ? setModal('Удалить')
      : newSelection.field == 'editAppeal'
      ? setModal('Редактировать')
      : newSelection.field == 'title'
      ? setModal('pdf')
      : newSelection.field == 'dateCreate'
      ? setModal('pdf')
      : setModal('none');

    setIDforAppeal(newSelection.row.id);
    setMyDraftAppeal(newSelection.row);
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => myDraftAppeals.find((row) => row.id === id));
    setSelectedItem(selectedRowsData.map((id) => id));
    setDataForAnswerAppeal(selectedRowsData);
  };

  const CloseModal = () => {
    setOpenModalSend(false);
    setOpenModalEdit(false);
    setOpenModalEnd(false);
    setOpenModalEnd2(false);
    setOpenModalEnd3(false);
    setSelectedLang('');
  };

  const SendDraftModal = () => {
    setOpenModalSend(true);
  };

  const EditDraftModal = (value) => {
    setOpenModalEdit(value);
  };

  const handleChange = (event) => {
    setSelectedCompl(event.name_meaning);
  };

  const handleChangeLang = (event) => {
    setSelectedLang(event.target.value);
  };

  const DeleteDraft = () => {
    const fd = new FormData();
    var arr2 = [];
    SelectedItem.map((ids) => {
      let obj = {
        id: ids.id,
      };
      arr2.push(obj);
    });
    arr2 = JSON.stringify(arr2);
    fd.append('array', arr2);

    const appealsResponse = appealsAPI.deleteAnswerDraft(fd);
    appealsResponse
      .then((res) =>
        successData(
          <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item15' />,
        ),
      )
      .then(GetDataDraft);
  };

  const handleSelectedItemForward = () => {
    selectedCompl == '' ? appealsValueNotSelected() : handleSelectedItemForward1();
  };

  const handleSelectedItemForward1 = () => {
    setOpenModalEnd(false);
    setOpenModalEnd2(true);
  };

  const handleSelectedItemForward2 = () => {
    textFieldValue == ''
      ? errorData(
          <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item14' />,
        )
      : handleSelectedItemForward3();
  };

  const handleSelectedItemForward3 = () => {
    setOpenModalEnd2(false);
    setOpenModalEnd3(true);
  };

  function createData(
    answerType,
    answer_date,
    answer_pdf,
    answer_text,
    id,
    id_appeal,
    user_id,
    MainDataB64,
    attachment,
    attachment_id,
    category,
    character,
    consideration_period,
    dateCreate,
    form,
    language,
    reg_number,
    e_time,
    sigexId,
    status,
    status_read,
    subcategory,
    text,
    title,
    type_applicant,
    user_accepted,
    numberAndDateMod,
    userAboutMod,
    pdf,
    fio,
    edit,
    deletedraft,
    chernovik,
  ) {
    const userAbout1 = userAboutMod.replace(/__./gi, '');
    const userAbout = userAbout1.replace(/_/gi, '\n');
    const numberDate = numberAndDateMod.replace(/_/gi, '\n');

    return {
      answerType,
      answer_date,
      answer_pdf,
      answer_text,
      id,
      id_appeal,
      user_id,
      MainDataB64,
      attachment,
      attachment_id,
      category,
      character,
      consideration_period,
      dateCreate,
      form,
      language,
      reg_number,
      e_time,
      sigexId,
      status,
      status_read,
      subcategory,
      text,
      title,
      type_applicant,
      user_accepted,
      numberDate,
      userAbout,
      pdf,
      fio,
      edit,
      deletedraft,
      chernovik,
    };
  }

  return (
    <>
      <div style={{ height: 600, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: 600,
                width: '100%',
                '& .super-app.delete': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff7f00',
                  fontWeight: '600',
                  color: '#ff7f00',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.positive': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app-theme--header': {
                  paddingLeft: '10px',
                  backgroundColor: '#e0e0e0',
                },
                '& .super-app.about': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },

                '& .super-app.numberDate': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '& .Mui-selected': {
                  backgroundColor: '#ffce9e !important',
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#ffce9e !important',
                    fontWeight: 600,
                  },
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <Button
                  color='neutral'
                  sx={{ mb: '30px', height: '48px', width: '240px' }}
                  variant='contained'
                  disabled={SelectedItem ? (SelectedItem?.length == '0' ? true : false) : true}
                  onClick={() => {
                    SendDraftModal();
                  }}
                >
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item1' />
                </Button>
                <Button
                  color='neutral'
                  sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                  variant='outlined'
                  disabled={SelectedItem ? (SelectedItem?.length != '1' ? true : false) : true}
                  onClick={() => {
                    EditDraftModal(true);
                  }}
                >
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item2' />
                </Button>

                <Button
                  color='refused'
                  sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                  variant='outlined'
                  disabled={SelectedItem ? (SelectedItem?.length == '0' ? true : false) : true}
                  onClick={() => {
                    DeleteDraft();
                  }}
                >
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item3' />
                </Button>
              </ThemeProvider>

              <DataGrid
                getRowHeight={() => 'auto'}
                checkboxSelection={true}
                onSelectionModelChange={(newSelection) => {
                  onRowsSelectionHandler(newSelection);
                }}
                rows={myDraftAppeals}
                columns={GridColDef}
                onCellClick={(newSelection) => {
                  SelectionHandler(newSelection);
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterLogicOperator: GridLinkOperator.Or,
                    },
                  },
                }}
                components={{
                  Toolbar: QuickSearchToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
      <Modal
        open={OpenModalSend}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item7' />
            </div>
          </div>

          <Box sx={{ minWidth: 120 }}>
            <PDFCreateForAnswerForDraft
              selectedValue={SelectedItem ? SelectedItem[0]?.answerType : null}
              DeleteDraft={DeleteDraft}
              GetDataAfterChange={GetDataDraft}
              handleCloseModal={CloseModal}
              DataForAnswerAppeal={DataForAnswerAppeal}
              TEXTforPDFAppeal={SelectedItem ? SelectedItem[0]?.answer_text : null}
              dispatch={dispatch}
              setSendAppealsReload={setSendAppealsReload}
              SendAppealsReload={SendAppealsReload}
            />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={OpenModalEdit}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal2}>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item8' />
            </div>
          </div>

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel id='demo-simple-select-label'>
                {SelectedItem ? SelectedItem[0]?.answerType : null}
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                disabled
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item1' />}
              ></Select>
            </FormControl>
            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{
                  mt: '24px',
                  height: '48px',
                  width: '100%',
                  boxShadow: 'none',
                  paddingTop: '15px',
                }}
                variant='contained'
                onClick={() => {
                  setOpenModalEnd(true);
                }}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item9' />
              </Button>
              <Button
                color='neutral'
                sx={{
                  mt: '24px',
                  height: '48px',
                  width: '100%',
                  boxShadow: 'none',
                  paddingTop: '15px',
                }}
                variant='contained'
                // onClick={handleNoChangeSelect}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item10' />
              </Button>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={() => {
                  EditDraftModal(false);
                }}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item11' />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={OpenModalEnd}
        onClose={CloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={CloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />
            </div>
          </div>

          <Box sx={{ display: selectedLang == '' ? 'block' : 'none' }}>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedLang}
                  label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />}
                  onChange={(event) => {
                    handleChangeLang(event);
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '55px',
                      height: '55px',
                      background: 'black',
                      fill: 'darkorange',
                      borderBottomRightRadius: '5px',
                      borderTopRightRadius: '5px',
                    },
                  }}
                  IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                >
                  <MenuItem value='ru'>Русский</MenuItem>
                  <MenuItem value='kz'>Қазақ</MenuItem>
                  <MenuItem value='en'>English</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={CloseModal}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
              </Button>
            </ThemeProvider>
          </Box>

          <Box sx={{ display: selectedLang == '' ? 'none' : 'block' }}>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item17' />
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item17' />}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '55px',
                      height: '55px',
                      background: 'black',
                      fill: 'darkorange',
                      borderBottomRightRadius: '5px',
                      borderTopRightRadius: '5px',
                    },
                  }}
                  // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                  IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                >
                  {completion?.map((item, index) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      onClick={() => {
                        handleChange(item);
                      }}
                      // disabled={item.disabled}
                    >
                      {item.name_meaning}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{
                  mt: '64px',
                  height: '48px',
                  width: '100%',
                  boxShadow: 'none',
                  paddingTop: '15px',
                }}
                variant='contained'
                onClick={handleSelectedItemForward}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item10' />
              </Button>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={CloseModal}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={OpenModalEnd2}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={CloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <TextField
              required
              id='outlined-required'
              label='Ответ'
              // placeholder={
              //   <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />
              // }
              sx={{ mt: '40px', width: '100%' }}
              multiline
              rows={8}
              onChange={(e) => {
                settextFieldValue(e.target.value);
              }}
              value={textFieldValue}
            />
          </Box>

          <ThemeProvider theme={theme}>
            <Button
              color='neutral'
              sx={{
                mt: '64px',
                height: '48px',
                width: '100%',
                boxShadow: 'none',
                paddingTop: '15px',
              }}
              variant='contained'
              onClick={handleSelectedItemForward2}
            >
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item10' />
            </Button>
            <Button
              color='neutral'
              sx={{ mt: '24px', height: '48px', width: '100%' }}
              variant='outlined'
              onClick={CloseModal}
            >
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
            </Button>
          </ThemeProvider>
        </Box>
      </Modal>

      <Modal
        open={OpenModalEnd3}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={CloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <PDFCreateForAnswerForDraft
              selectedValue={selectedCompl}
              DeleteDraft={DeleteDraft}
              GetDataAfterChange={GetDataDraft}
              handleCloseModal={CloseModal}
              DataForAnswerAppeal={DataForAnswerAppeal}
              TEXTforPDFAppeal={textFieldValue}
              dispatch={dispatch}
              ws={ws}
              selectedLang={selectedLang}
              Trans={Trans}
              setSendAppealsReload={setSendAppealsReload}
              SendAppealsReload={SendAppealsReload}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AdminNotFinishAppealsAnswers;
