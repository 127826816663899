import React from 'react';
import { useSelector } from 'react-redux';

import { appealsAPI } from '../../../api/api';
import { styleModal, theme } from '../../../theme';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';

const AppealDelete = ({ Trans, dispatch, ws, CloseModal, IDforDeleteAppeal }) => {
  const user = useSelector((state) => state.store.mainSlice.user);

  const handleDeleteAppeal = () => {
    const fd = new FormData();
    fd.append('IIN', user.IIN);
    fd.append('id', IDforDeleteAppeal);

    const ItemDelete = appealsAPI.DeleteAppels(fd);
    ItemDelete.then((res) => console.log(res));

    CloseModal();
  };
  return (
    <>
      <Modal
        open={true}
        onClose={CloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <div className={s.close_auth_modal} onClick={CloseModal}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='MyNotFinishAppeals.item3' />
            </div>
          </div>

          <Box sx={{ minWidth: 120 }}>
            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{ mt: '64px', height: '48px', width: '100%' }}
                variant='contained'
                onClick={handleDeleteAppeal}
              >
                <Trans i18nKey='MyNotFinishAppeals.item4' />
              </Button>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={CloseModal}
              >
                <Trans i18nKey='MyNotFinishAppeals.item5' />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AppealDelete;
