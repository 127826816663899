import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ServiceOptionsStates } from '../../../redux/slices/mainSlice';

import Box from '@mui/material/Box';
import TurnRightIcon from '@mui/icons-material/TurnRight';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const AnalitikaDrawer = ({ Trans, dispatch, width, handleDrawerClose }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const divDrawerBlock = React.useRef(null);
  const [state, setState] = React.useState({ left: false });
  const tabs = useSelector((state) => state.store.mainSlice.ServiceOptionsState);

  var warrObjects = [];

  const handleDrawerClose2 = () => {
    setState({ ...state, ['left']: false });
  };

  const toggleDrawer = (anchor, open, cat) => {
    setState({ ...state, [anchor]: open });
    makeMenu(cat);
  };

  const handleClick = (item) => {
    toggleDrawer('left', true, item);
  };

  const makeMenu = (cat) => {
    for (let i = 0; i < 3; i++) {
      let user = makeCat(cat);
      warrObjects[i] = user;
    }
    dispatch(ServiceOptionsStates(warrObjects));
  };

  const makeCat = (cat) => {
    return {
      cat: cat,
      side: 'left',
    };
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'left',
  }));

  // const list = (anchor) =>
  // 	anchor == 'item 1'
  // 		? 'asd'
  // 		: anchor == 'item 9'
  // 		? 'asd'
  // 		: anchor == 'item 6'
  // 		? 'asd'
  // 		: anchor == 'item 5'
  // 		? 'asd'
  // 		: anchor == 'item 4'
  // 		? 'asd'
  // 		: anchor == 'item 3'
  // 		? 'asd'
  // 		: anchor == 'item 2'
  // 		? 'asd'
  // 		: console.log(1111);

  const menu_item = [
    { item: 'Сводная отчетная информация по объектам бизнеса' },
    { item: 'Сводная отчетная информация по площади помещений' },
    { item: 'Сводная отчетная информация по охвату радиуса помещений' },
    { item: 'Сводная отчетная информация по подбору помещений для бизнеса' },
    { item: 'Сводная отчетная информация по проходимости' },
    { item: 'Сводная отчетная информация по рентабельности места' },
    { item: 'Сводная отчетная информация по коммуникациям' },
    { item: 'История' },
  ];

  return (
    <>
      <div ref={divDrawerBlock}>
        <Box sx={{ width: 450, marginTop: '50px' }} role='presentation'>
          <List>
            {menu_item.map((text, index) => (
              <ListItem key={index} disablePadding>
                {text.item == 'Сводная отчетная информация по объектам бизнеса' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/business-objects');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item == 'История' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/history');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item == 'Сводная отчетная информация по площади помещений' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/business-objects-square');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item == 'Сводная отчетная информация по охвату радиуса помещений' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/business-objects-radius');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item == 'Сводная отчетная информация по подбору помещений для бизнеса' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/business-objects-selection');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item == 'Сводная отчетная информация по проходимости' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/business-objects-passability');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item == 'Сводная отчетная информация по рентабельности места' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/business-objects-profitability');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : text.item == 'Сводная отчетная информация по коммуникациям' ? (
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      navigate('/analitika/business-objects-communications');
                    }}
                  >
                    <ListItemIcon>
                      <TurnRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                ) : (
                  <ListItemButton>
                    <ListItemIcon>
                      <CloseIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.item} />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
          {/* <Divider /> */}
          {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text.item} disablePadding>
            <ListItemButton>
                <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text.item} />
            </ListItemButton>
            </ListItem>
        ))}
        </List> */}
        </Box>
      </div>
      {tabs.map((anchor, index) => (
        <React.Fragment key={index}>
          <div>
            <Drawer
              BackdropProps={{ sx: { backgroundColor: 'rgb(0 0 0 / 15%) ' } }}
              PaperProps={{ sx: { left: width + 450, boxShadow: 'none' } }}
              anchor={anchor.side}
              open={state[anchor.side]}
              // onClose={toggleDrawer(anchor.side, false)}
              onClose={console.log(4444444444444)}
              variant={'temporary'}
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose2}>
                  {divDrawerBlock.current === null
                    ? null
                    : console.log(divDrawerBlock.current.getBoundingClientRect())}
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </DrawerHeader>

              {/* {list(anchor.cat)} */}
            </Drawer>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default AnalitikaDrawer;
