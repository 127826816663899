import React from 'react';

import s from '../AnalitikaBusinessObjects/AnalitikaBusinessObjects.module.css';

import AnalitikaBusinessObjectsRentabBlock from '../../components/AnalitikaBusinessObjectsRentabBlock/AnalitikaBusinessObjectsRentabBlock';

const AnalitikaBusinessObjectsRentab = ({ Trans, dispatch }) => {
  return (
    <>
      <div className={s.Wrapper2}>
        <div className={s.title2}>
          <p>
            Аналитика и формирование отчетности - Сводная отчетная информация по рентабельности
            места
          </p>
        </div>
        <div className={s.maintitle2}>
          <p>Сводная отчетная информация по рентабельности места</p>
        </div>

        <AnalitikaBusinessObjectsRentabBlock />
      </div>
    </>
  );
};

export default AnalitikaBusinessObjectsRentab;
