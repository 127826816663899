import React from 'react';

import s from './LeasingOrganizations.module.css';

import LeasingOrganizationsBlock from '../../components/LeasingOrganizationsBlock/LeasingOrganizationsBlock';

const LeasingOrganizations = ({ Trans, dispatch }) => {

  return (
    <>

    <div className={s.Wrapper}>
        <div className={s.title}>
            <p>Сервисы - Льготный лизинг - Поиск лизинговых организаций</p>
        </div>
        <div className={s.maintitle}>
            <p>Поиск лизинговых организаций</p>
        </div>

        <LeasingOrganizationsBlock />
    </div>

    </>
  );
};


export default LeasingOrganizations;
