import React from 'react';
import s from './styles.module.css';
import { DataGrid } from '@mui/x-data-grid';
import CreateObjectModal from './modals/CreateObjectModal';
import { mapApi } from '../../api/api';
import UpdateObjectModal from './modals/UpdateObjectModal';

const columns = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: '0', headerName: 'Вид объекта', width: 130 },
  { field: 'name', headerName: 'Наименование', width: 130 },
  { field: 'adress', headerName: 'Адрес', width: 130 },
  { field: 'KRP_ru', headerName: 'Вид деятельности', width: 130 },
  { field: 'area', headerName: 'Площадь (кв.м)', width: 130 },
  { field: 'dateCreate', headerName: 'Дата создания', width: 130 },
  { field: 'author', headerName: 'Автор', width: 130 },
];

const BusinessMapAdmin = ({ items, user_id, fetchData, filterCategories }) => {
  const [openAddNews, setOpenAddNews] = React.useState(false);
  const [openUpdateNews, setOpenUpdateNews] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleOpenAddNews = () => setOpenAddNews(true);
  const handleCloseAddNews = () => setOpenAddNews(false);
  const handleOpenUpdate = () => setOpenUpdateNews(true);
  const handleCloseUpdate = () => setOpenUpdateNews(false);

  const deleteItems = () => {
    const fd = new FormData();
    var arr2 = [];
    arr2.push(selectedItems);
    const arr22 = JSON.stringify(selectedItems);
    fd.append('array', arr22);

    mapApi.deletePoint(fd).then(() => fetchData());
  };

  return (
    <div className={s.businessMapAdmin}>
      <CreateObjectModal
        filterCategories={filterCategories}
        user_id={user_id}
        fetchData={fetchData}
        handleCloseAddNews={handleCloseAddNews}
        openAddNews={openAddNews}
      />
      <UpdateObjectModal
        filterCategories={filterCategories}
        fetchData={fetchData}
        points={selectedItems}
        handleCloseAddNews={handleCloseUpdate}
        openAddNews={openUpdateNews}
      />

      <button className={s.btnAddNews} onClick={handleOpenAddNews}>
        Добавить
      </button>
      <button className={s.btnAddNews} onClick={handleOpenUpdate}>
        Редактировать
      </button>
      <button className={s.btnAddNews} onClick={deleteItems}>
        Удалить
      </button>

      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={items}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = items.filter((row) => selectedIDs.has(row.id.toString()));
            setSelectedItems([...selectedRowData]);
          }}
        />
      </div>
    </div>
  );
};

export default BusinessMapAdmin;
