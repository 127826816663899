import React from 'react';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { personalAreaAPI } from '../../api/api';

import s from './PersonalArea.module.css';
import objToDf from '../../common/helpers/objToFd';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import { successData } from '../../common/helpers/sliceToasts';

const PersonalArea = ({ Trans }) => {
  const { register, handleSubmit } = useForm();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const user = useSelector((state) => state.store.mainSlice.user);

  const [personalInfo, setPersonalInfo] = React.useState([]);

  React.useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    const res = personalAreaAPI.fetchPersonalInfo(fd);
    res.then((res) => setPersonalInfo(res)).catch((err) => console.log(err));
  }, [user.id]);

  const handleSubmitEdit = (data) => {
    setIsLoaded(true);
    const fd = new FormData();
    fd.append('id', user.id);
    objToDf(fd, data);
    const res = personalAreaAPI.sendEditPersonalInfo(fd);
    res
      .then(() => {
        setIsLoaded(false);
        successData(`Данные успешно обновлены`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {personalInfo.length > 0 && (
        <div className={s.personal_area_wrapper}>
          <div className={s.personal_area_title}>
            <Trans i18nKey="PersonalArea.item1" />
          </div>
          <form onSubmit={handleSubmit(handleSubmitEdit)}>
            <div className={s.personal_area_info}>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item2" />:
                </div>
                <div className={s.info_subtitle_sub}>{personalInfo[0].IIN}</div>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item3" />:
                </div>
                <input
                  type="text"
                  defaultValue={personalInfo[0].last_name}
                  {...register('last_name')}
                />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item4" />:
                </div>
                <input
                  type="text"
                  defaultValue={personalInfo[0].first_name}
                  {...register('first_name')}
                />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item5" />:
                </div>
                <input
                  type="text"
                  defaultValue={personalInfo[0].middle_name}
                  {...register('middle_name')}
                />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item6" />:
                </div>
                <input type="email" defaultValue={personalInfo[0].email} {...register('email')} />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item7" />:
                </div>
                <input
                  type="text"
                  defaultValue={personalInfo[0].country}
                  {...register('country')}
                />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item8" />:
                </div>
                <input type="text" defaultValue={personalInfo[0].area} {...register('area')} />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item9" />:
                </div>
                <input type="text" defaultValue={personalInfo[0].city} {...register('city')} />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <div className={s.info_subtitle}>
                  <Trans i18nKey="PersonalArea.item10" />:
                </div>
                <InputMask
                  mask="+7(999)999-99-99"
                  style={{ width: '100%' }}
                  placeholder="+7(___)___-__-__"
                  defaultValue={personalInfo[0].number}
                  className={s.input_iin_one}
                  {...register('number')}
                />
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                    fill="#292929"
                  />
                  <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
                </svg>
              </div>
            </div>
            <div className={s.personal_area_name_org}>
              <div className={s.info_subtitle}>
                <Trans i18nKey="PersonalArea.item11" />:
              </div>
              <input
                type="text"
                defaultValue={personalInfo[0].organization}
                {...register('organization')}
              />
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.00648 18.5866C1.08619 18.5962 1.16677 18.5962 1.24648 18.5866L5.24648 17.5866C5.42393 17.5444 5.58646 17.4545 5.71648 17.3266L18.0065 4.99657C18.379 4.62185 18.5881 4.11494 18.5881 3.58657C18.5881 3.0582 18.379 2.5513 18.0065 2.17657L16.4265 0.586573C16.2407 0.40062 16.0202 0.253102 15.7774 0.152454C15.5346 0.0518053 15.2743 0 15.0115 0C14.7487 0 14.4884 0.0518053 14.2456 0.152454C14.0028 0.253102 13.7822 0.40062 13.5965 0.586573L1.30648 12.8766C1.17727 13.0072 1.08423 13.1692 1.03648 13.3466L0.0364831 17.3466C0.000540935 17.476 -0.00897211 17.6113 0.00851131 17.7444C0.0259947 17.8776 0.0701166 18.0058 0.138245 18.1215C0.206373 18.2373 0.297111 18.3381 0.405048 18.418C0.512984 18.4979 0.635907 18.5552 0.766483 18.5866C0.846193 18.5962 0.926773 18.5962 1.00648 18.5866ZM15.0065 1.99657L16.5965 3.58657L15.0065 5.17657L13.4265 3.58657L15.0065 1.99657ZM2.91648 14.0966L12.0065 4.99657L13.5965 6.58657L4.49648 15.6866L2.38648 16.2066L2.91648 14.0966Z"
                  fill="#292929"
                />
                <line x1="1" y1="18" x2="19" y2="18" stroke="#292929" strokeWidth="2" />
              </svg>
            </div>
            {isLoaded && (
              <div className={s.status_auth} style={{ position: 'relative' }}>
                <LoadingDots />
              </div>
            )}
            <button className={s.btn_edit} style={{ width: 287 }}>
              Сохранить изменения
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default PersonalArea;
