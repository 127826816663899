import { createSlice } from '@reduxjs/toolkit';
import { notificationsAll, notificationsAllEntrep } from '../asyncThunks/notificationsAsyncThunk';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    isNewAppeals: 0,
    isLoading: false,
    item: [],
    countNoRead: false,
  },
  reducers: {
    newAppeals(state, action) {
      state.isNewAppeals = state.isNewAppeals + Number(action.payload);
    },
  },
  extraReducers: {
    [notificationsAll.pending]: (state) => {
      state.isLoading = true;
      state.item = null;
      state.countNoRead = null;
    },
    [notificationsAll.fulfilled]: (state, action) => {
      state.item = action.payload;
      state.countNoRead = action.payload.filter((notif) => notif.status === '0').length;
      state.isLoading = false;
    },
    [notificationsAllEntrep.pending]: (state) => {
      state.isLoading = true;
      state.item = null;
      state.countNoRead = null;
    },
    [notificationsAllEntrep.fulfilled]: (state, action) => {
      state.item = action.payload;
      state.countNoRead = action.payload.filter((notif) => notif.status === '0').length;
      state.isLoading = false;
    },
  },
});
export const { newAppeals } = notificationsSlice.actions;

export default notificationsSlice.reducer;
