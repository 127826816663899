import { useEffect, useState } from 'react';

import { personalAreaAPI } from '../../../api/api';
import { useSelector } from 'react-redux';
import { errorData, successData, infoData } from '../../../common/helpers/sliceToasts';
import { appealsAPI } from '../../../api/api';

import pdfMake from 'pdfmake';
import vfs from '../../../components/MyAppeals/AppealPDF/fonts/vfs_fonts';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { NCALayerClient } from 'ncalayer-js-client';

import './../../../pages/AppealsPage/AppealsPage.module.css';

const PDFCreateForAnswer = ({
  // setOpenModal,
  setSendAppealsReload,
  SendAppealsReload,
  selectedValue,
  handleCloseModal,
  ws,
  TEXTforPDFAppeal,
  DataForAnswerAppeal,
  setModalStep3,
  dispatch,
  handleStatus,
  GetDataAfterChange,
  IspFio,
  selectedLang,
  Trans,
}) => {
  const appealDate = DataForAnswerAppeal.dateCreate;
  const appealNumber = DataForAnswerAppeal.id;
  const fio = DataForAnswerAppeal.fio;
  const dateAnswer = new Date().toISOString().slice(0, 10);

  const [docDefinition, setDocDefinition] = useState({});
  const [personalInfo, setPersonalInfo] = useState([]);
  const [iframSrc, setIframeUrl] = useState('');
  const [modalWidth, setmodalWidth] = useState('0');
  const [AppealDataB64, setAppealDataB64] = useState(null);
  const [AppealDataB64Title, setAppealDataB64Title] = useState('-');
  const [ButtonsAccept, setButtonsAccept] = useState(true);
  const [error, seterror] = useState(null);

  const user = useSelector((state) => state.store.mainSlice.user);

  let ncaLayer = new NCALayerClient();
  const axios = require('axios').default;

  let predefinedEmails = [], // Сюда можно добавить сервисные адреса электронной почты
    awaitingSignature = false,
    signed = false,
    sigexId = null,
    connecting = true,
    description = 'Подписано в информационной системе Business Ertis',
    sigexURL = 'https://sigex.kz';

  useEffect(() => {
    setTableBodyData();
  }, [personalInfo]);

  useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    const res = personalAreaAPI.fetchPersonalInfo(fd);
    res.then((res) => setPersonalInfo(res)).catch((err) => console.log(err));
  }, []);

  const setTableBodyData = () => {
    const template = { ...docDefinitionDefault };
    setDocDefinition(template);
  };
  const create = () => {
    setmodalWidth('520');
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      setAppealDataB64(data);
      console.log(data);
    });
    setAppealDataB64Title('BusinessErtisAppeal_Answer_' + appealNumber + '.pdf');
    console.log('BusinessErtisAppeal_Answer_' + appealNumber);
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setIframeUrl(dataUrl);

      // pdfDocGenerator.download();
      // handleCloseModal();
      console.log(personalInfo);
      console.log(personalInfo[0].city);
    });
  };

  const handleItemSend = () => {
    selectStorageAndContinue();
    setButtonsAccept(false);
  };

  async function selectStorageAndContinue() {
    let storageTypeIn = '';

    try {
      await ncaLayer.connect();
      connecting = false;
    } catch (err) {
      console.log('Ошибка: Включите программу NCALayer' + err);
      errorData('Ошибка: Включите программу NCALayer' + err);
    }
    awaitingSignature = true;

    try {
      const storageTypes = await ncaLayer.getActiveTokens();

      if (storageTypes.length > 1) {
        console.log(
          'Обнаружено несколько разных типов защищенных хранилищ подключенных к компьютеру',
        );
        errorData(
          'Обнаружено несколько разных типов защищенных хранилищ подключенных к компьютеру',
        );
        return;
      }

      if (storageTypes.length == 0) {
        storageTypeIn = 'PKCS12';
      } else {
        storageTypeIn = storageTypes[0];
      }
      console.log(storageTypeIn);
    } catch (err) {
      console.log('NCALayer вернул неожиданную ошибку', err);
      errorData('NCALayer вернул неожиданную ошибку', err);
      return;
    }

    signAndSend(storageTypeIn);
  }

  async function signAndSend(storageType) {
    let signature;
    try {
      console.log(AppealDataB64);
      signature = await ncaLayer.createCMSSignatureFromBase64(storageType, AppealDataB64);
    } catch (err) {
      seterror({
        message: 'NCALayer вернул неожиданную ошибку',
        description: err,
      });
      errorData('NCALayer вернул неожиданную ошибку', err);
      return;
    }

    try {
      // const emailNotifications = {
      //   to: predefinedEmails,
      // }
      let response = await axios.post(`${sigexURL}/api`, {
        title: AppealDataB64Title,
        description: description,
        signature,
        // emailNotifications,
      });
      if (response.data.message) {
        this.error = {
          message: 'Сервер не принял подпись',
          description: response.data.message,
        };
        errorData('Сервер не принял подпись', response.data.message);
        return;
      }

      sigexId = response.data.documentId;

      const dataToSend = Uint8Array.from(atob(AppealDataB64), (c) => c.charCodeAt(0)).buffer;
      response = await axios.post(`${sigexURL}/api/${sigexId}/data`, dataToSend, {
        headers: { 'Content-Type': 'application/octet-stream' },
      });

      if (response.data.message) {
        seterror({
          message: 'Сервер не принял подпись (проблема с проверкой подписанных данных)',
          description: response.data.message,
        });
        errorData(
          'Сервер не принял подпись (проблема с проверкой подписанных данных)',
          response.data.message,
        );
        return;
      }

      awaitingSignature = false;
      signed = true;

      // const titleParts = title.split('.');
      // if (titleParts.length === 1) {
      //   settitle(`${title}-SigexId${sigexId}`);
      // } else {
      //   const extension = titleParts.pop();
      //   settitle(`${titleParts.join('.')}-SigexId${sigexId}.${extension}`);
      // }
    } catch (err) {
      seterror({
        message: 'Сервер вернул неожиданную ошибку',
        description: err,
      });
      errorData('Сервер вернул неожиданную ошибку', err);
    }
    successData('Файл успешно подписан');

    GeneralSendAppeal(sigexId, AppealDataB64Title, AppealDataB64);
  }

  const GeneralSendAppeal = (sigexId, AppealDataB64Title, AppealDataB64) => {
    const fd = new FormData();
    fd.append('user_id', user.id);
    fd.append('answer_text', TEXTforPDFAppeal);
    // fd.append('title', selectedValue);
    // fd.append('CatTitle', CatselectedValue);
    // fd.append('DopCatTitle', DopCatselectedValue);
    // fd.append('metod', 'SendAppeals');
    // fd.append('attachment', title);
    // fd.append('attachmentID', fileID);
    // dataB64 === null ? console.log(1) : fd.append('dataB64', `data:application/pdf;base64,${dataB64}`);
    fd.append('id_appeal', appealNumber);
    fd.append('date', dateAnswer);
    fd.append('answer_pdf_name', AppealDataB64Title);
    fd.append('answer_pdf', `data:application/pdf;base64,${AppealDataB64}`);
    fd.append('answer_pdf_sigexid', sigexId);
    fd.append('lang', selectedLang);

    const ItemSend = appealsAPI.completed(fd);
    ItemSend.then((res) =>
      res.message == 'Такой файл уже есть!'
        ? errorData('Такой файл уже есть!')
        : handleSuccesSend(res.message),
    );
    handleCloseModal();
    setSendAppealsReload(!SendAppealsReload);
    // handleStatus(3);
    setTimeout(GetDataAfterChange, 1000);
  };

  const SendAppealDraft = () => {
    const fd = new FormData();
    fd.append('user_id', user.id);
    fd.append('answer_text', TEXTforPDFAppeal);
    fd.append('answerType', selectedValue);
    // fd.append('CatTitle', CatselectedValue);
    // fd.append('DopCatTitle', DopCatselectedValue);
    // fd.append('metod', 'SendAppeals');
    // fd.append('attachment', title);
    // fd.append('attachmentID', fileID);
    // dataB64 === null ? console.log(1) : fd.append('dataB64', `data:application/pdf;base64,${dataB64}`);
    fd.append('id_appeal', appealNumber);
    fd.append('date', dateAnswer);
    fd.append('answer_pdf_name', AppealDataB64Title);
    fd.append('answer_pdf', `data:application/pdf;base64,${AppealDataB64}`);
    fd.append('lang', selectedLang);

    const ItemSend = appealsAPI.completedDraft(fd);
    ItemSend.then((res) =>
      res.message == 'Такой файл уже есть!'
        ? errorData('Такой файл уже есть!')
        : infoData(res.message),
    );

    // ItemSend.then((res) =>
    // 	res.message == 'Такой файл уже есть!' ? null : ws.send('SendAppeals')
    // );

    handleCloseModal();
    // setOpenModal(false);
    setModalStep3(false);
    setSendAppealsReload(!SendAppealsReload);
  };

  const handleSuccesSend = (message) => {
    infoData(message);
    let strAp = '';
    strAp = 'ActionAppeals,' + appealNumber;
    setTimeout(() => ws.send(strAp), 1000);
  };

  pdfMake.vfs = vfs;
  pdfMake.fonts = {
    NimbusSans: {
      normal: 'NimbusSanL-Reg.otf',
      bold: 'NimbusSanL-Bol.otf',
      italics: 'NimbusSanL-RegIta.otf',
      bolditalics: 'NimbusSanL-BolIta.otf',
    },
  };

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
    },
  });

  const docDefinitionDefault = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 60, 40, 60],
    content: [
      {
        text: 'Общие сведения ответа',
        style: 'header',
        alignment: 'left',
      },
      {
        style: 'table1',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Тип исполнителя', 'Характер ответа', 'Номер обращения'],
            ['Физическое лицо', 'Индвидуальное', appealNumber],
          ],
        },
      },

      {
        text: 'Ответ',
        style: 'header2',
        alignment: 'left',
      },
      {
        style: 'table1',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Вид ответа ', 'Форма ответа', 'Язык ответа'],
            [
              selectedValue,
              'Электронная',
              selectedLang == 'ru'
                ? 'Русский'
                : selectedLang == 'kz'
                ? 'Қазақ'
                : selectedLang == 'en'
                ? 'English'
                : '-',
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Категория', 'Подкатегория', 'Государственный орган'],
            ['CatselectedValue', 'DopCatselectedValue', 'GovStrType'],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Регистрационный номер', 'Срок рассмотрения', 'Срок предоставление ответа'],
            ['7339026', 'Неизвестно', 'Неизвестно'],
          ],
        },
      },
      {
        style: 'table3',
        alignment: 'center',
        table: {
          widths: ['*', '68%'],
          heights: [100, 100],
          body: [['Описание', TEXTforPDFAppeal ? TEXTforPDFAppeal : 'j']],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['*', '68%'],
          body: [['Вложенные документы', 'Нет']],
        },
      },
      {
        text: 'Исполнитель',
        style: 'header2',
        alignment: 'left',
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Отдел', 'Наименование организации', 'Дата регистрации обращения'],
            ['Отдел земельных отношений', 'Министерство юстиций', appealDate],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['ФИО', 'Должность', 'Дата регистрации ответа'],
            [IspFio, 'Неизвестно', dateAnswer],
          ],
        },
      },
    ],
    defaultStyle: {
      font: 'NimbusSans',
    },
    styles: {
      withMargin: {
        margin: [20, 20, 20, 20],
      },
      alignCenter: {
        alignment: 'center',
      },
      header: {
        fontSize: 18,
        bold: true,
      },
      header2: {
        marginTop: 20,
        fontSize: 18,
        bold: true,
      },
      header3: {
        marginTop: 160,
        fontSize: 18,
        bold: true,
      },
      textBody: {
        fontSize: 12,
      },
      subheader: {
        fontSize: 15,
        bold: true,
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 8,
      },
      table1: {
        marginTop: 20,
      },
      table2: {
        marginTop: 10,
      },
      table3: {
        marginTop: 5,
      },
    },
  };
  return (
    <div className='PDFcreate'>
      <iframe
        title='myBook'
        src={iframSrc}
        height='310'
        width='800'
        frameBorder='0'
        scrolling='yes'
        allowFullScreen={false}
        // ref={refIframe}
      ></iframe>
      <ThemeProvider theme={theme}>
        <Button
          color='neutral'
          sx={{ height: '48px', width: '100%' }}
          variant='outlined'
          onClick={create}
        >
          <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item12' />
        </Button>
        <Button
          color='neutral'
          disabled={ButtonsAccept ? (AppealDataB64Title == '-' ? true : false) : true}
          sx={{
            mt: '24px',
            height: '48px',
            width: '100%',
            boxShadow: 'none',
            paddingTop: '15px',
          }}
          variant='contained'
          onClick={handleItemSend}
        >
          <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item13' />
        </Button>
        <Button
          color='neutral'
          disabled={AppealDataB64Title == '-' ? true : false}
          sx={{ mt: '24px', height: '48px', width: '100%' }}
          variant='outlined'
          onClick={SendAppealDraft}
        >
          <Trans i18nKey='SendAppeals.item14' />
        </Button>
      </ThemeProvider>
    </div>
  );
};
export default PDFCreateForAnswer;
