import React from 'react';
import { useSelector } from 'react-redux';

import NotificationSendedAll from './NotificationSendedAll';
import { notificationsApi } from '../../api/api';

const NotificationSended = ({ ws, Trans }) => {
  const user = useSelector((state) => state.store.mainSlice.user);
  const [sendedItems, setSendedItems] = React.useState([]);

  React.useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    const response = notificationsApi.sendedNotifications(fd);
    response.then((res) => setSendedItems(res));
  }, []);

  return (
    <NotificationSendedAll
      getNotifications={sendedItems}
      status_user={user?.status_user}
      ws={ws}
      Trans={Trans}
    />
  );
};

export default NotificationSended;
