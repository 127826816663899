import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

const style = {
  border: '0.8px dashed #28323E',
  height: '136px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  background: '#E4E5E6',
  margin: '0 auto',
  borderRadius: 5,
  cursor: 'pointer',
};

const styleText = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '16px',
  marginBottom: 14,
  letterSpacing: '0.02em',
  color: '#28323E',
  width: '50%',
  margin: '0 auto',
};

const styleFile = {
  cursor: 'pointer',
  fontWeight: 600,
  textDecoration: 'underline',
  marginBottom: 14,
};

export const TargetBox = (props) => {
  const { onDrop } = props;
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item) {
        return true;
      },
      hover(item) {},
      collect: (monitor) => {
        const item = monitor.getItem();

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props],
  );
  return (
    <div ref={drop} style={style} onClick={(e) => props.getfiles(e)}>
      {/*{isActive ? "Release to drop" : "Перетащите сюда изображение"}*/}
      <div>
        <div style={styleText}>
          <props.Trans i18nKey="News.newsAdmin.item11" />
        </div>

        <div style={(styleText, styleFile)}>
          <props.Trans i18nKey="News.newsAdmin.item12" />
        </div>
        <div style={styleText}>
          <props.Trans i18nKey="News.newsAdmin.item13" />
        </div>
        <input
          ref={props.inputFiles}
          type={'file'}
          onChange={props.FindFile}
          name="filesInput"
          multiple
          accept="image/jpeg,image/png,image/jpg,video/avi,video/mp4,video/mkv"
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};
