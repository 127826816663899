import React from 'react';
import { useState } from 'react';

import s from './ChoseTypeNotifiactions.module.css';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';

import { MyAppealsWidth } from '../../redux/slices/mainSlice';
import Notifications from './Notifications';
import NotificationSended from './NotificationSended';

const ChoseTypeNotifications = ({ dispatch, ws, Trans }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const ChooseAppealsTypeWidth = React.useRef(null);
  const [reload, setReload] = useState(false);
  // const onRead = useSelector((state) => state.store.notificationsSlice.countNoRead);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  React.useEffect(() => {
    dispatch(MyAppealsWidth(ChooseAppealsTypeWidth.current.getBoundingClientRect().width));
  }, []);

  return (
    <>
      <div className={s.Wrapper} ref={ChooseAppealsTypeWidth}>
        <div className={s.notification_title}>
          <Trans i18nKey="Notifications.item1" />
        </div>
        <Box
          sx={{
            bgcolor: 'background.paper',
            width: '100%',
            position: 'relative',
            minHeight: 200,
          }}>
          <AppBar
            sx={{
              bgcolor: '#ffffff',
              boxShadow: 'none',
              width: '50%',
              color: '#ff7f00',
            }}
            position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="action tabs example"
              TabIndicatorProps={{
                style: { background: '#ff7f00', color: '#ff7f00' },
              }}>
              <Tab label={<Trans i18nKey="Notifications.item2" />} {...a11yProps(0)} />
              <Tab label={<Trans i18nKey="Notifications.item3" />} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Notifications ws={ws} Trans={Trans} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <NotificationSended ws={ws} Trans={Trans} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </div>
    </>
  );
};

export default ChoseTypeNotifications;
