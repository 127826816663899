import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { mainPage } from '../../api/api';

import s from '../AppealsPage/AppealsPage.module.css';

import { DataGrid, GridLinkOperator, ruRU } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';

import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';

import clsx from 'clsx';
const DocumentsPage = ({ Trans, dispatch }) => {
 const [getAllAppeals, setGetAllAppeals] = React.useState([]);
 const [Appeals, setAppeals] = React.useState([]);
 const modal = useSelector((state) => state.store.mainSlice.PDFmodal);
 const [IDforAcceptAppeal, setIDforAcceptAppeal] = React.useState([]);

 const [OpenModal, setOpenModal] = useState(false);
 const [TopMargin, setTopMargin] = useState(390);

 //  React.useEffect(() => {
 //   console.log(modal);
 //  }, [modal]);

 const documentsData = [
  {
   id: '1',
   name: 'Разработка аванпроекта',
   type: 'Научные исследования',
   documents: {
    doc1: {
     id: '1',
     name: 'Научный отчет, включающий проект технического задания',
     link: ['/documents/Проект технического задания.pdf'],
    },
   },
  },
  {
   id: '2',
   name: 'Разработка технического задания',
   type: 'Разработка, согласование, утверждение ТЗ',
   documents: {
    doc1: {
     id: '2',
     name: 'Техническое задание',
     link: [
      '/documents/Техническое задание.pdf',
      '/documents/СТПО 1.pdf',
      '/documents/СТПО 2.pdf',
      '/documents/СТПО 3.pdf',
      '/documents/СТПО 4.pdf',
      '/documents/СТПО 6.pdf',
      '/documents/СТПО 7.pdf',
      '/documents/СТПО 8.pdf',
      '/documents/СТПО 9.pdf',
      '/documents/СТПО 10.pdf',
      '/documents/СТПО 11.pdf',
     ],
    },
   },
  },
  {
   id: '3',
   name: 'Проектирование',
   type: 'Эскизное проектирование',
   documents: {
    doc1: {
     id: '3',
     name: '1.1 Конструкторские документы (описание эскизного проекта ИВС - принятых технических решений',
     link: [
      '/documents/Конструкторские документы 1.pdf',
      '/documents/Конструкторские документы 2.pdf',
      '/documents/Конструкторские документы 3.pdf',
      '/documents/Конструкторские документы 4.pdf',
     ],
    },
    doc2: { id: '4', name: '1.2 Откорректированное ТЗ (при необходимости)', link: [] },
    doc3: {
     id: '5',
     name:
      '2.1 Конструкторские документы (описание технического проекта как доработанного эскизного проекта ИВС - приянтых технических решений), ТЗ на программирование',
     link: [
      '/documents/Конструкторские документы 1.pdf',
      '/documents/Конструкторские документы 2.pdf',
      '/documents/Конструкторские документы 3.pdf',
      '/documents/Конструкторские документы 4.pdf',
      '/documents/Конструкторские документы 5.pdf',
      '/documents/Конструкторские документы 6.pdf',
     ],
    },
    doc4: { id: '6', name: '2.2 Откорректированное ТЗ (при необходимости)', link: [] },
    doc5: {
     id: '7',
     name:
      '3.1 Конструкторские документы (описание принятых технических решений ИВС, текст программы на языке программирования)',
     link: [
      '/documents/Конструкторские документы 1.pdf',
      '/documents/Конструкторские документы 2.pdf',
      '/documents/Конструкторские документы 3.pdf',
      '/documents/Конструкторские документы 4.pdf',
      '/documents/Конструкторские документы 5.pdf',
      '/documents/Конструкторские документы 6.pdf',
     ],
    },
    doc6: {
     id: '8',
     name: '3.2 Эксплуатационные документы',
     link: ['/documents/Руководство пользователя для сайта «Business_Ertis».pdf'],
    },
    doc7: { id: '9', name: '3.3 Откорректированное ТЗ (при необходимости)', link: [] },
    doc8: { id: '10', name: '4.1 Откорректированные конструкторские документы', link: [] },
    doc9: { id: '11', name: '4.2 Откорректированные эксплуатационные документы', link: [] },
    doc10: { id: '12', name: '4.3 Протоколы испытаний', link: ['/documents/Протокол испытаний.pdf'] },
    doc11: { id: '13', name: '4.4 Нормативные документы по стандартизации', link: [] },
    doc12: { id: '11', name: '4.5 Акты сдачи и приемки', link: [] },
   },
  },
  {
   id: '4',
   name: 'Внедрение',
   type: 'Адаптация на конкретные условия',
   documents: {
    doc1: { id: '11', name: 'Откорректированные конструкторские документы', link: [] },
   },
  },
  {
   id: '5',
   name: 'Сопровождение',
   type: 'Анализ проблем и разработка предложений по изменениям',
   documents: {
    doc1: { id: '11', name: 'ТЗ на внесение изменений', link: [] },
    doc2: { id: '11', name: 'Рабочие проекты изменений', link: [] },
    doc3: { id: '11', name: 'Откорректироованные конструкторские документы', link: [] },
    doc4: { id: '11', name: 'Откорректироованные эксплуатационные документы', link: [] },
    doc5: { id: '11', name: 'Протокол проверки изменений', link: [] },
    doc6: { id: '21', name: 'Протоколы испытаний', link: ['/documents/Протокол испытаний.pdf'] },
    doc7: { id: '31', name: 'Откорректированные нормативные документы по стандартизации', link: [] },
    doc8: { id: '41', name: 'Акты сдачи и приемки', link: [] },
   },
  },
  {
   id: '6',
   name: 'Снятие с эксплуатации',
   type: 'Утилизация',
   documents: {
    doc1: { id: '11', name: 'Протоколы об архивировании программ утилизации аппаратных средств', link: [] },
   },
  },
 ];

 console.log(documentsData);

 React.useEffect(() => {
  const ServiceResponse = mainPage.getService();
  ServiceResponse.then((res) => setGetAllAppeals(res)).catch((err) => console.log(err));
 }, []);

 React.useEffect(() => {
  const newAppeals = getAllAppeals?.map((res) => {
   return createData(
    res.id,
    res.name,
    res.type,
    res.service_provider,
    res.service_recipients,
    res.way_service,
    res.price,
    res.term_service,
    res.additionally,
    res.url_service
   );
  });
  setAppeals(newAppeals);
 }, [getAllAppeals]);

 const styleModal = {
  position: 'absolute',
  top: TopMargin,
  right: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  padding: '33px 48px',
  p: 4,
  outline: 0,
 };

 const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
   fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
   fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
   fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
   fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
   fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
   fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
 }));

 const theme = createTheme({
  palette: {
   neutral: {
    main: '#FF7F00',
    contrastText: '#fff',
   },
   refused: {
    main: '#FF0000',
    contrastText: '#fff',
   },
  },
 });

 const myTheme = createTheme({
  components: {
   MuiDataGrid: {
    styleOverrides: {
     row: {
      '&.Mui-selected': {
       backgroundColor: '#47af6f69;',
       color: '#28323E',
       '&:hover': {
        backgroundColor: '#47af6fa6',
       },
      },
     },
    },
   },
  },
 });

 const modalTheme = createTheme({
  components: {
   MuiModal: {
    styleOverrides: {
     row: {
      '&.root': {
       backgroundColor: '#47af6f69;',
       color: '#28323E',
      },
     },
    },
   },
  },
 });

 const CustomNoRowsOverlay = () => {
  return (
   <StyledGridOverlay>
    <svg width='120' height='100' viewBox='0 0 184 152' aria-hidden focusable='false'>
     <g fill='none' fillRule='evenodd'>
      <g transform='translate(24 31.67)'>
       <ellipse className='ant-empty-img-5' cx='67.797' cy='106.89' rx='67.797' ry='12.668' />
       <path
        className='ant-empty-img-1'
        d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
       />
       <path
        className='ant-empty-img-2'
        d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
       />
       <path
        className='ant-empty-img-3'
        d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
       />
      </g>
      <path
       className='ant-empty-img-3'
       d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
      />
      <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
       <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
       <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
      </g>
     </g>
    </svg>
    <Box sx={{ mt: 1 }}>Документов еще нет</Box>
   </StyledGridOverlay>
  );
 };

 const GridColDef = [
  {
   field: 'id',
   headerName: '№',
   width: 100,
   headerClassName: 'super-app-theme--header',
   cellClassName: 'super-app-theme--cell',
   headerAlign: 'center',
   align: 'center',
   hideable: false,
  },
  {
   field: 'name',
   headerName: 'Стадия жизненного цикла ИВС',
   minWidth: 400,
   flex: 1,
   headerClassName: 'super-app-theme--header',
   headerAlign: 'center',
   align: 'center',
   hideable: false,
  },
  // {
  //  field: 'type',
  //  headerName: 'Этап',
  //  minWidth: 300,
  //  headerClassName: 'super-app-theme--header',
  //  headerAlign: 'center',
  //  align: 'center',
  //  hideable: false,
  //  cellClassName: (params) => {
  //   if (params.value == null) {
  //    return '';
  //   }

  //   return clsx('super-app', {
  //    type: params.value !== 0,
  //   });
  //  },
  // },
 ];

 const createData = (
  id,
  name,
  type,
  service_provider,
  service_recipients,
  way_service,
  price,
  term_service,
  additionally,
  url_service
 ) => {
  return {
   id,
   name,
   type,
   service_provider,
   service_recipients,
   way_service,
   price,
   term_service,
   additionally,
   url_service,
  };
 };

 const CustomToolbar = () => {
  return null;
 };
 //
 const SelectionHandler = (newSelection) => {
  handleOpenModal(newSelection.row);
 };

 const CustomPagination = () => {
  return null;
 };
 const handleOpenModal = (ids) => {
  ids.name == 'Проектирование'
   ? setTopMargin(1000)
   : ids.name == 'Сопровождение'
   ? setTopMargin(1000)
   : setTopMargin(390);
  var mass = [];
  Object.entries(ids.documents).forEach((i) => mass.push({ name: i[1].name, link: i[1].link }));
  setIDforAcceptAppeal(mass);
  setOpenModal(true);
 };

 const handleCloseModal = () => {
  setOpenModal(false);
 };

 return (
  <>
   <div style={{ height: 585, width: '90%', margin: 'auto', marginTop: '60px' }}>
    <Typography sx={{ mb: '24px' }} variant='h6'>
     Документы
    </Typography>
    <div style={{ display: 'flex', height: '100%' }}>
     <div style={{ flexGrow: 1 }}>
      {' '}
      <Box
       sx={{
        height: 585,
        width: '100%',
        '& .super-app.negative4': {
         // backgroundColor: 'rgba(157, 255, 118, 0.49)',
         color: '#ff0000',
         fontWeight: '600',
        },
        '& .super-app.negative': {
         // backgroundColor: 'rgba(157, 255, 118, 0.49)',
         color: '#1a3e72',
         fontWeight: '600',
        },
        '& .super-app.positive': {
         // backgroundColor: 'rgba(157, 255, 118, 0.49)',
         color: '#47AF6F',
         fontWeight: '600',
        },
        '& .super-app.positive2': {
         // backgroundColor: 'rgba(157, 255, 118, 0.49)',
         color: '#47AF6F',
         fontWeight: '600',
        },
        '& .super-app.positive3': {
         // backgroundColor: 'rgba(157, 255, 118, 0.49)',
         color: '#47AF6F',
         fontWeight: '600',
        },
        '& .super-app-theme--header': {
         backgroundColor: '#e0e0e0',
         paddingLeft: '10px',
        },
        '& .super-app-theme--pdf': {
         backgroundColor: '#e0e0e0',
         paddingLeft: '10px',
        },
        '& .super-app-theme--cell': {
         backgroundColor: 'rgba(224, 183, 60, 0.55)',
         color: '#1a3e72',
         fontWeight: '600',
        },

        '& .super-app.type': {
         backgroundColor: '#cdccca8c',
        },
        '& .super-app.about': {
         whiteSpace: 'pre-line !important',
         textAlign: 'center',
        },
        '& .super-app.title': {
         whiteSpace: 'pre-line !important',
         textAlign: 'center',
        },
        '& .super-app.numberAndDate': {
         whiteSpace: 'pre-line !important',
         textAlign: 'center',
        },
        '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
         whiteSpace: 'pre-line',
         lineHeight: 'normal',
         textAlign: 'center',
        },
        '.MuiDataGrid-columnHeaderTitleContainer': {
         backgroundColor: '#e0e0e0',
        },
       }}
      >
       <ThemeProvider theme={myTheme}>
        <DataGrid
         getRowHeight={() => 30}
         localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
         disableExport
         disableReorder
         disableColumnMenu
         rowsPerPageOptions={[5]}
         pageSize={13}
         rows={documentsData}
         columns={GridColDef}
         onCellClick={(newSelection) => {
          SelectionHandler(newSelection);
         }}
         initialState={{
          filter: {
           filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
           },
          },
         }}
         components={{
          // Toolbar: QuickSearchToolbar,
          //   NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
         }}
         componentsProps={{
          panel: {
           placement: 'top',
          },
         }}
        />
       </ThemeProvider>
      </Box>
     </div>
    </div>
   </div>{' '}
   <ThemeProvider theme={modalTheme}>
    <Modal
     open={OpenModal}
     // onClose={handleCloseModal}
     aria-labelledby='modal-modal-title'
     aria-describedby='modal-modal-description'
     sx={{ overflow: 'scroll' }}
    >
     <Box sx={styleModal}>
      <Tooltip className={s.close_auth_modal} placement='top' title='Введенные данные будут утеряны'>
       <div onClick={handleCloseModal}>
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path
          d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
          stroke='#292929'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
         />
        </svg>
       </div>
      </Tooltip>
      <div className={s.title_auth_modal}>
       <div>Комплектность документов</div>
      </div>

      <Box sx={{ width: '100%' }}>
       {IDforAcceptAppeal?.map((i) => {
        return (
         <div
          style={{
           display: 'inline-flex',
           border: '1px solid #a89595',
           borderRadius: '10px',
           padding: '10px',
           marginBottom: '10px',
          }}
         >
          <Typography sx={{ mb: '24px' }} variant='h6'>
           {i.name}
          </Typography>
          <div style={{ marginBottom: '24px' }}>
           {i.link.length !== 0
            ? i.link.map((j) => {
               return (
                <Tooltip placement='top' title={j.replace('/documents/', '')}>
                 <Button sx={{ alignItems: 'normal' }} variant='text' startIcon={<PictureAsPdf />}>
                  <Link target='_' href={j}>
                   PDF
                  </Link>
                 </Button>
                </Tooltip>
               );
              })
            : null}
          </div>
         </div>
        );
       })}
      </Box>

      <ThemeProvider theme={theme}>
       <Button
        color='neutral'
        sx={{ mt: '24px', height: '48px', width: '100%' }}
        variant='outlined'
        onClick={handleCloseModal}
       >
        Закрыть
       </Button>
      </ThemeProvider>
     </Box>
    </Modal>
   </ThemeProvider>
  </>
 );
};

export default DocumentsPage;
