import React from 'react';
import { useState } from 'react';

import s from './CalculationForUrbanBusinessBlock.module.css';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useForm } from 'react-hook-form';
import { mapApi } from '../../api/api';

const CalculationForUrbanBusinessBlock = ({ Trans, dispatch }) => {
  const [resultWrapper, setResultWrapper] = useState('none');

  const programms = [
    { label: 'Упрощенный режим (УСН)', year: 1 },
    { label: 'Общеустановленный режим (ОУР)', year: 2 },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#D9D9D9',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '148px',
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [result, setResult] = useState(null);

  const calculate = ({ obor_sred, summa_obor, prohod, credit_time, credit }) => {
    let New_prohod = prohod * 31;
    let New_credit = credit || 0;
    let New_credit_time = credit_time || 0;
    let New_obor_sred = obor_sred;
    let New_summa_obor = summa_obor;

    const invest = Number(New_obor_sred) + Number(New_summa_obor);
    const viruchka = Number(New_obor_sred);
    const npv =
      viruchka / (1 + 0.08) +
      (viruchka * 2) / (1 + 0.08) ** 2 +
      (viruchka * 3) / (1 + 0.08) ** 3 +
      (viruchka * 4) / (1 + 0.08) ** 4 +
      (viruchka * 5) / (1 + 0.08) ** 5 -
      invest;
    const random_number = Math.random() * (9 - 4) + 4;
    const index_prib = Number(New_summa_obor) / npv;
    const prib = Number(New_obor_sred) * index_prib;
    const pb = Number(New_summa_obor) / (viruchka / 12);
    const dpb = pb + random_number;

    let up = viruchka * 5;
    let bottom = invest * 5;

    let vnd = Math.pow(up / bottom, 5);

    setResult({
      viruchka: parseInt(viruchka / 12) + ' тнг.',
      index_prib: index_prib,
      pb: parseInt(pb) + ' месяцев',
      prib: parseInt(prib / 12) + ' тнг.',
      npv: parseInt(npv / 4) + ' тнг.',
      dpb: parseInt(dpb) + ' месяцев',
      vnd: parseInt(vnd),
    });
  };

  return (
    <>
      <div className={s.questionBlock}>
        <div className={s.questionBlockWrapper}>
          <div className={s.questionInputWrapper}>
            <div className={s.email_programmWrapper}>
              <div className={s.programm}>
                <div className={s.selectTitle}>СИСТЕМА НАЛОГОВОЙ ОТЧЕТНОСТИ</div>
                <Autocomplete
                  disablePortal
                  id='combo-box-demo'
                  options={programms}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} label='Выбор бизнеса' />}
                />
              </div>
            </div>
            <form onSubmit={handleSubmit(calculate)}>
              <h2>РАСХОДЫ</h2>
              <div className={s.inputsWrapper}>
                <div className={s.fio}>
                  <TextField
                    id='outlined-basic'
                    label='Сумма оборудования'
                    variant='outlined'
                    {...register('summa_obor')}
                  />
                </div>
                <div className={s.fio}>
                  <TextField
                    id='outlined-basic'
                    label='оборотные средства'
                    variant='outlined'
                    {...register('obor_sred')}
                  />
                </div>
                <div className={s.fio}>
                  <TextField
                    id='outlined-basic'
                    label='Проходимость'
                    variant='outlined'
                    {...register('prohod')}
                  />
                </div>
              </div>

              <h2>ЕЖЕМЕСЯЧНЫЕ РАСХОДЫ</h2>
              <div className={s.inputsWrapper2}>
                <div className={s.fio}>
                  <TextField
                    id='outlined-basic'
                    label='Ежемесячный платеж, тенге'
                    variant='outlined'
                    {...register('credit')}
                  />
                </div>
                <div className={s.fio}>
                  <TextField
                    id='outlined-basic'
                    label='Срок, месяц'
                    variant='outlined'
                    {...register('credit_time')}
                  />
                </div>
              </div>

              <div className={s.sendbuttonWrapper}>
                <div className={s.sendbutton}>
                  <Button
                    type={'submit'}
                    color='neutral'
                    sx={{ height: '100%', width: '100%' }}
                    variant='contained'
                  >
                    Рассчитать
                  </Button>
                </div>
              </div>
            </form>

            <div className={s.results}>
              {result && (
                <>
                  <div>Среднемесячная выручка: {result.viruchka}</div>
                  <div>Индекс прибыльности PL: {result.index_prib}</div>
                  <div>PB срок окупаемости: {result.pb}</div>
                  <div>Прибыль в месяц: {result.prib}</div>
                  <div>NPV - величина чистого дисконтированного дохода: {result.npv}</div>
                  <div>DPB дисконтированный период окупаемости: {result.dpb}</div>
                  <div>IRR Внутренняя норма доходности : {result.vnd}</div>
                </>
              )}
            </div>
            <div className={s.resultWrapper} style={{ display: `${resultWrapper}` }}>
              <div className={s.resultTitle}>Результаты</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculationForUrbanBusinessBlock;
