import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationsApi } from '../../api/api';

export const notificationsAll = createAsyncThunk(
  'notifications/all',
  async function (fd, rejectWithVlaue) {
    try {
      const data = await notificationsApi.fetchAllNotifications(fd);
      return data;
    } catch (e) {
      return rejectWithVlaue(e);
    }
  },
);

export const notificationsAllEntrep = createAsyncThunk(
  'notifications/entrepruneur',
  async function (fd, rejectWithVlaue) {
    try {
      const data = await notificationsApi.getNotificationsEntrepruneur(fd);
      return data;
    } catch (e) {
      return rejectWithVlaue(e);
    }
  },
);
