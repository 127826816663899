import * as React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridLinkOperator,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';

import clsx from 'clsx';
import { ruRU } from '../../common/helpers/ruRU';
import { kzKZ } from '../../common/helpers/kzKZ';
import { enEN } from '../../common/helpers/enEN';
import s from './notifications.module.css';

const NotificationSendedAll = ({ getNotifications, Trans }) => {
  const [notifications, setNotifications] = React.useState([]);
  const lang = localStorage.getItem('lang');

  const languageView = {
    ru: ['Не просмотрено', 'Просмотрено'],
    kz: ['Қаралмаған', 'Қаралды'],
    en: ['Not viewed', 'Viewed'],
  };

  function createData(id, id_appeal, recipient, sender, title, text, user, dateCreate, status) {
    return { id, id_appeal, recipient, sender, title, text, user, dateCreate, status };
  }

  React.useEffect(() => {
    const newNotif = getNotifications
      ?.map((res) => {
        return createData(
          res.id,
          res.id_appeal,
          res.recipient,
          res.sender,
          res.title,
          res.text,
          res[0]?.last_name + ' ' + res[0]?.first_name + ' ' + res[0]?.middle_name,
          res.dateCreate,
          res.status === '0'
            ? (localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
                (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
                (localStorage.getItem('lang') === 'en' && languageView.en[0])
            : (localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
                (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
                (localStorage.getItem('lang') === 'en' && languageView.en[1]),
        );
      })
      .sort((a, b) => b.id - a.id);
    setNotifications(newNotif);
  }, [getNotifications]);

  React.useEffect(() => {
    setDefNotificationWrapperWidth(notification_wrapperWidth.current.getBoundingClientRect().width);
  }, []);

  const [DefNotificationWrapperWidth, setDefNotificationWrapperWidth] = React.useState(1000);
  const notification_wrapperWidth = React.useRef(null);

  styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
          }}
        >
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
        <div style={{ marginTop: '50px' }}></div>
        <GridToolbarFilterButton
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
            color: '#454545',
          }}
        />
      </GridToolbarContainer>
    );
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const GridColDef = [
    {
      field: 'title',
      headerName: <Trans i18nKey='Notifications.item12' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.status ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
        });
      },
    },
    {
      field: 'id_appeal',
      headerName: <Trans i18nKey='Notifications.item13' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.status ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
        });
      },
    },

    {
      field: 'user',
      headerName: <Trans i18nKey='Notifications.item14' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.status ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
        });
      },
    },
    {
      field: 'dateCreate',
      headerName: <Trans i18nKey='Notifications.item15' />,
      type: 'date',
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno:
            params.row.status ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
        });
      },
    },
    {
      field: 'status',
      headerName: <Trans i18nKey='Notifications.item16' />,
      width: (DefNotificationWrapperWidth - 19) / 5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          prosmotreno:
            params.row.status ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[1]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[1]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[1])),
          ne_prosmotreno:
            params.row.status ===
            ((localStorage.getItem('lang') === 'ru' && languageView.ru[0]) ||
              (localStorage.getItem('lang') === 'kz' && languageView.kz[0]) ||
              (localStorage.getItem('lang') === 'en' && languageView.en[0])),
        });
      },
    },
  ];

  return (
    <div className={s.notification_wrapper} ref={notification_wrapperWidth}>
      <>
        <div style={{ height: 600, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <Box
                sx={{
                  height: 600,
                  width: '100%',
                  '& .super-app.for_1_4_ne_prosmotreno': {
                    backgroundColor: '#f7e1a87d',
                    fontWeight: '600',
                    cursor: 'pointer',
                  },
                  '& .super-app.prosmotreno': {
                    color: '#47AF6F',
                    fontWeight: '600',
                  },
                  '& .super-app.ne_prosmotreno': {
                    backgroundColor: '#f7e1a87d',
                    color: '#ff7f00',
                    fontWeight: '600',
                  },
                  '& .super-app-theme--header': {
                    paddingLeft: '10px',
                    backgroundColor: '#e0e0e0',
                  },
                }}
              >
                {notifications?.length > 0 && (
                  <DataGrid
                    rows={notifications}
                    columns={GridColDef}
                    localeText={
                      lang == 'ru' ? ruRU : lang == 'kz' ? kzKZ : lang == 'en' ? enEN : null
                    }
                    pagination
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterLogicOperator: GridLinkOperator.Or,
                        },
                      },
                    }}
                    components={{
                      // Toolbar: QuickSearchToolbar,
                      Toolbar: CustomToolbar,
                      Pagination: CustomPagination,
                    }}
                  />
                )}
              </Box>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default NotificationSendedAll;
