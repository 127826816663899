import React from 'react';
import { useState } from 'react';

import s from './ProcurementDocumentConstructorBlock.module.css'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const ProcurementDocumentConstructorBlock = ({ Trans, dispatch }) => {

    const [resultWrapper, setResultWrapper] = useState('none');

    const programms = [
        { label: 'Выбор закупок 1', year: 1 },
        { label: 'Выбор закупок 2', year: 2 },
      ];

      const theme = createTheme({
        palette: {
          neutral: {
            main: '#FF7F00',
            contrastText: '#fff',
          },
        },
      });

      const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#D9D9D9',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '148px'
      }));

      const handleClick = () => {
        setResultWrapper('block')
      }

  return (
    <>

        <div className={s.questionBlock}>
        <div className={s.questionBlockWrapper}>

        <div className={s.questionInputWrapper}>

            <div className={s.email_programmWrapper}>

                <div className={s.programm}>
                <div className={s.selectTitle}>
                Вид закупок
                </div>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={programms}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Выбор типа закупок" />}
                />
                </div>
            </div>

            <div className={s.inputsWrapper}>
                <div className={s.fio}>
                <TextField id="outlined-basic" label="Поле 1" variant="outlined" />
                </div>
                <div className={s.fio}>
                <TextField id="outlined-basic" label="Поле 2" variant="outlined" />
                </div>
                <div className={s.fio}>
                <TextField id="outlined-basic" label="Поле 3" variant="outlined" />
                </div>
            </div>

            <div className={s.inputsWrapper2}>
                <div className={s.fio}>
                <TextField id="outlined-basic" label="Поле 4" variant="outlined" />
                </div>
                <div className={s.fio}>
                <TextField id="outlined-basic" label="Поле 5" variant="outlined" />
                </div>
                <div className={s.fio}>
                <TextField id="outlined-basic" label="Поле 6" variant="outlined" />
                </div>
            </div>

            <div className={s.sendbuttonWrapper}>

                <div className={s.sendbutton}>
                <ThemeProvider theme={theme}>
                <Button color="neutral" sx={{ height: '100%', width: '100%' }} variant="contained" onClick={() => {handleClick()}}>Сформировать</Button>
                </ThemeProvider>
                </div>

            </div>

            <div className={s.resultWrapper} style={{display: `${resultWrapper}`}}>
            
            <div className={s.resultTitle}>
            Результаты поиска
            </div>

            <div className={s.resultTitle}>
            <ThemeProvider theme={theme}>
            <Button color="neutral" variant="text">Скачать пакет документов</Button>
            </ThemeProvider>
            </div>

            <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {/* xs={12} - 100% width,  xs={12} - 50% width*/}
                <Grid item xs={12}>
                <Item>Результат</Item>
                </Grid>
            </Grid>
            </Box>

            </div>

        </div>
        

        </div>
        </div>


    </>
  );
};


export default ProcurementDocumentConstructorBlock;
