import * as React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';

import s from './header.module.css';

import { useForm } from 'react-hook-form';
import { mainPage } from '../../api/api';
import LoadingDots from '../LoadingDots/LoadingDots';

const style = {
  position: 'absolute',
  top: '25%',
  left: '81%',
  transform: 'translate(-81%, -25%)',
  maxWidth: 424,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingTop: '33px',
  padding: '33px 48px',
  boxSizing: 'border-box',
};

const styleModalSuccess = {
  position: 'absolute',
  top: '25%',
  left: '81%',
  transform: 'translate(-81%, -25%)',
  maxWidth: 424,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingTop: '33px',
  padding: '33px 48px',
  boxSizing: 'border-box',
};

const ModalResetPassword = ({ openResetPassword, handleCloseResetPassword }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [isLoadingReset, setIsLoadingReset] = React.useState(false);

  const handleSendIIN = (data) => {
    setErrorEmail(false);
    setIsLoadingReset(true);
    const fd = new FormData();
    fd.append('IIN', data.IIN);
    const reset = mainPage.resetPassword(fd);
    reset.then((res) => {
      if (res[0] === true) {
        handleOpenSuccessModal();
        handleCloseResetPassword();
        setEmail(res[1]);
        setIsLoadingReset(false);
      } else {
        setIsLoadingReset(false);

        setErrorEmail('ИИН не найден');
        setInterval(() => {
          setErrorEmail(false);
        }, 2000);
      }
      reset();
    });
  };

  //START------------------------------Модальное окно успешной регистрации
  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  //END------------------------------Модальное окно успешной регистрации
  return (
    <>
      <Modal
        open={openResetPassword}
        onClose={handleCloseResetPassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className={s.close_auth_modal} onClick={handleCloseResetPassword}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132"
                stroke="#292929"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div className={s.title_auth_modal}>Восстановление пароля</div>
          </div>
          <form onSubmit={handleSubmit(handleSendIIN)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '64px',
                marginTop: '75px',
              }}>
              <TextField
                id="outlined-basic"
                label="Введите ИИН"
                variant="outlined"
                {...register('IIN', {
                  required: 'Введите ИИН',
                  pattern: {
                    value: /^\d+$/,
                    message: 'Вводить можно только цифры',
                  },
                  maxLength: {
                    value: 12,
                    message: 'Максимум 12 цифр',
                  },
                  minLength: {
                    value: 12,
                    message: 'Минимум 12 цифр',
                  },
                })}
              />
            </div>
            <div className={s.validate_form_reg} style={{ top: '184px' }}>
              {errors?.IIN && <span>{errors?.IIN?.message}</span>}
            </div>
            {errorEmail && (
              <div className={s.validate_form_reg} style={{ top: '200px' }}>
                <span>{errorEmail}</span>
              </div>
            )}
            {isLoadingReset && (
              <div className={s.status_auth} style={{ marginBottom: '20px' }}>
                <div>Отправление почты</div>
                <LoadingDots />
              </div>
            )}
            <button className={s.btn_login}>Далее</button>
            <button
              className={s.btn_cancel_modal_reg}
              style={{ marginTop: '24px' }}
              onClick={handleCloseResetPassword}>
              Отмена
            </button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styleModalSuccess}>
          <div className={s.close_auth_modal} onClick={handleCloseSuccessModal}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132"
                stroke="#292929"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div className={s.title_auth_modal}>Регистрация завершена</div>
          </div>
          <div className={s.content_success_modal}>
            Ссылка для активации учетной записи была направлена на указанный электронный адрес{' '}
            {email?.slice(0, 1)}****
            {email?.slice(-14)}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className={s.btn_login}
              style={{ width: 200 }}
              onClick={handleCloseSuccessModal}>
              Хорошо
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalResetPassword;
