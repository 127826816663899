import React, { useCallback, useState } from 'react';

import { TargetBox } from './TargetBox.js';

export const Container = ({
  setDroppedFiles,
  droppedFiles,
  setIsLoadingSuccess,
  setLoadSubErr,
  setLoadErr,
  Trans,
}) => {
  const inputFiles = React.useRef();

  const handleFileDrop = useCallback(
    (item) => {
      setDroppedFiles([]);
      setLoadErr(false);
      setLoadSubErr(false);
      setIsLoadingSuccess(false);
      if (item) {
        const files = item.files;
        setDroppedFiles(files);
      }
    },
    [setDroppedFiles],
  );

  const getfiles = () => {
    setIsLoadingSuccess([]);
    setLoadErr(false);
    setLoadSubErr(false);
    inputFiles.current.click();
  };

  const FindFile = async (e) => {
    setDroppedFiles(inputFiles.current.files);
  };

  return (
    <>
      <TargetBox
        onDrop={handleFileDrop}
        inputFiles={inputFiles}
        getfiles={getfiles}
        FindFile={FindFile}
        Trans={Trans}
      />
    </>
  );
};
