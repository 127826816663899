import instance from './instance';
import objToDf from '../common/helpers/objToFd';

export const mainPage = {
  async loginEcp(fd) {
    try {
      const { data } = await instance.post('/Api/profill/loginDigitalSignature.php', fd);
      return data[0];
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async loginIinPass(fd) {
    try {
      const { data } = await instance.post('/Api/profill/login.php', fd);
      return data[0];
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async logOut(fd) {
    try {
      const { data } = await instance.post('/Api/profill/logout.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async userRegistation(fd) {
    try {
      const { data } = await instance.post('/Api/profill/reg.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async activateAccount(fd) {
    try {
      const { data } = await instance.post('/Api/profill/activateAccountPassword.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async resetPassword(fd) {
    try {
      const { data } = await instance.post('/Api/profill/resetPassword.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchNotifications(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/pullAllAppeal.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async getService(fd) {
    try {
      const { data } = await instance.post('/Api/service/service.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async addGosUslugi(fd) {
    try {
      const { data } = await instance.post('/Api/service/addGosUslugi.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async fin_help_programs_add(fd) {
    try {
      const { data } = await instance.post('/Api/service/fin_help_programs_add.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async fin_help_programs(fd) {
    try {
      const { data } = await instance.post('/Api/service/fin_help_programs.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async investors_add(fd) {
    try {
      const { data } = await instance.post('/Api/service/investors_add.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async investors(fd) {
    try {
      const { data } = await instance.post('/Api/service/investors.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async franchise_add(fd) {
    try {
      const { data } = await instance.post('/Api/service/franchise_add.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async franchise(fd) {
    try {
      const { data } = await instance.post('/Api/service/franchise.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async premises_add(fd) {
    try {
      const { data } = await instance.post('/Api/service/premises_add.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async premises(fd) {
    try {
      const { data } = await instance.post('/Api/service/premises.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async support_measures_add(fd) {
    try {
      const { data } = await instance.post('/Api/service/support_measures_add.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async support_measures(fd) {
    try {
      const { data } = await instance.post('/Api/service/support_measures.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async bankCredits_add(fd) {
    try {
      const { data } = await instance.post('/Api/service/bankCredits_add.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async bankCredits(fd) {
    try {
      const { data } = await instance.post('/Api/service/bankCredits.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async credit_limits_add(fd) {
    try {
      const { data } = await instance.post('/Api/service/credit_limits_add.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async credit_limits(fd) {
    try {
      const { data } = await instance.post('/Api/service/credit_limits.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async userHistory(fd) {
    try {
      const { data } = await instance.post('/Api/main/userHistory.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async ObjectsByDate(payload) {
    const fd = new FormData();

    try {
      const { data } = await instance.post('/Api/map/ObjectsByDate.php', objToDf(fd, payload));
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async historyInsert(payload) {
    const fd = new FormData();

    try {
      const { data } = await instance.post('/Api/map/historyInsert.php', objToDf(fd, payload));
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};

//
//
//
//
//
//
//
//
//
//
//
export const statistics = {
  async countMetrica(fd) {
    try {
      const { data } = await instance.post('/Api/main/metrica/countMetrica.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};

//
//
//
//
//
//
//
//
//
//
//

export const appealsAPI = {
  async deleteAnswerDraft(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/completed/deleteAnswerDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async getAnswerDraft(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/completed/getAnswerDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async completedDraft(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/completed/completedDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async completed(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/completed/completed.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async action(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/action.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async getAppealStatusBy1(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/getAppealStatusBy1.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async getAppealStatus(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/getAppealStatus.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchTransfer(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/reassign.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchVseSotrudniki(fd) {
    try {
      const { data } = await instance.post('/Api/profill/getUsers1.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchchangeStatus(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/changeStatus.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchTypeAppeal(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/putTypeAppeal.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchTypeAppealCat(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/putTypeAppealCat.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async fetchTypeAppealSubCat(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/putTypeAppealSubCat.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async editAppealDraft(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/editAppealDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchgetPDFNameDraft(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/getPDFNameDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchSendAppealLastIdDraft(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/LastIdDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async fetchSendAppealDraft(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/createAppealDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchAppealPDF(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/getPDF.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchAppealNumber(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/LastId.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async SendAppeals(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/createAppeal.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async DeleteAppels(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/deleteAppealDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async SendChernovikAppeals(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/createAppealDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchMyAppeals(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/appeal.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchMyDraftAppeals(fd) {
    try {
      const { data } = await instance.post('/Api/appeal/appealDraft.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchLastApeals() {
    try {
      const { data } = await instance.post('/Api/appeal/getLastAppeal.php');
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async fetchAllAppeals() {
    try {
      const { data } = await instance.post('/Api/appeal/pullAllAppeal.php');
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};

export const personalAreaAPI = {
  async fetchPersonalInfo(fd) {
    try {
      const { data } = await instance.post('/Api/profill/getInfoUser.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async sendEditPersonalInfo(fd) {
    try {
      const { data } = await instance.post('/Api/profill/editProfill.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};

export const galleryApi = {
  async fetchGallery(page) {
    try {
      const { data } = await instance.post(`/Api/main/photoGallery.php?page=${page}`);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async sendFiles(fd) {
    try {
      const { data } = await instance.post('/Api/main/addPhotoGallery.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async replaceFiles(fd) {
    try {
      const { data } = await instance.post('/Api/main/replacement.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async deletePhoto(fd) {
    try {
      const { data } = await instance.post('Api/main/deletePhoto.php', fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};

export const mapApi = {
  async getMapItems() {
    try {
      const { data } = await instance.get('/Api/map/map.php');
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async getCategories() {
    try {
      const { data } = await instance.get('/Api/map/catName.php');
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async addPoint(payload) {
    const fd = new FormData();

    try {
      const { data } = await instance.post('/Api/map/addPoint.php', objToDf(fd, payload));
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async updatePoint(payload) {
    const fd = new FormData();

    try {
      const { data } = await instance.post('/Api/map/editPoint.php', objToDf(fd, payload));
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async deletePoint(payload) {
    try {
      const { data } = await instance.post('/Api/map/deletePoint.php', payload);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};

export const newsApi = {
  async fetchNews(obj) {
    try {
      const { data } = await instance.get(
        `/Api/main/news.php?page=${obj.page}&status=${obj.status}&count=${obj.countNews}`,
      );
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async addPost(fd) {
    try {
      const { data } = await instance.post(`/Api/main/addNews.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async delPost(fd) {
    try {
      const { data } = await instance.post(`/Api/main/deleteNews.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async changeStatus(fd) {
    try {
      const { data } = await instance.post(`/Api/main/changeStatusNews.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async editNews(fd) {
    try {
      const { data } = await instance.post(`/Api/main/editNews.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async getPost(id) {
    try {
      const { data } = await instance.get(`/Api/main/news/oneNews.php?id=${id}`);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};

export const notificationsApi = {
  async fetchAllNotifications(fd) {
    try {
      const { data } = await instance.post(`/Api/notification/notification.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
  async onReadNotifications(obj) {
    try {
      const { data } = await instance.get(
        `/Api/notification/viewed.php?id=${obj.id}&id_appeal=${obj.id_appeal}`,
      );
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async onAnswerNotifications(fd) {
    try {
      const { data } = await instance.post(`/Api/notification/sendNotification.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async getNotificationsEntrepruneur(fd) {
    try {
      const { data } = await instance.post(`/Api/notification/getNotification0.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async sendedNotifications(fd) {
    try {
      const { data } = await instance.post(`/Api/notification/sender.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async apealAbout(fd) {
    try {
      const { data } = await instance.post(`/Api/appeal/viewAppeal.php`, fd);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },
};
