import React, {useEffect, useMemo} from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import s from "../styles.module.css";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {mapApi} from "../../../api/api";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const styleAddNews = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 832,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  boxSizing: 'border-box',
  padding: '36px 32px 32px 32px',
};

const UpdateObjectModal = ({openAddNews, handleCloseAddNews, points, fetchData, filterCategories}) => {
  const [point, setPoint] = React.useState(false)
  const [type, setType] = React.useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: useMemo(() =>  ({
      typeObject: point?.['0'],
      name: '312',
      adress: point?.adress,
      KRP_ru: point?.KRP_ru,
      area: point?.area
    }), [points])
  });

  const onAddPost = async (data) => {
    data.location = ''
    data.id_category_sub = type
    data.id = point.id
    await mapApi.updatePoint(data)
    fetchData()
    handleCloseAddNews()
  }

  useEffect(() => {
    if(points){
      setPoint(points.at(-1))
    }

  }, [points])

  useEffect(() => {
    reset({
      typeObject: point?.['0'],
      name: point?.name,
      adress: point?.adress,
      KRP_ru: point?.KRP_ru,
      area: point?.area,
      x: point?.location?.split(', ')[0],
      y: point?.location?.split(', ')[1]
    });

    if(point){
      setType(point?.id_category_sub)
    }
  }, [point])


  if(!point){
    return <></>
  }
  return (
    <Modal
      open={openAddNews}
      onClose={handleCloseAddNews}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styleAddNews}>
        <div className={s.close_add_news_modal} onClick={handleCloseAddNews}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132"
              stroke="#292929"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={s.title_add_news_modal}>
          <div className={s.title_add_news_modal}>Редактировать</div>
          <form onSubmit={handleSubmit(onAddPost)}>
            <div className={s.wrapperFormAddNews} style={{marginBottom: '20px'}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Вид обьекта</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  name={'id_category_sub'}
                  label="Вид обьекта"
                  onChange={handleChange}
                >
                  {filterCategories.map((el) =><MenuItem key={el.id} value={el.id}>{el.label}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor="">Наименование</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('name', {
                    required: 'Введите Наименование',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor="">Адрес</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('adress', {
                    required: 'Введите Адрес',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor="">Вид деятельности</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('KRP_ru', {
                    required: 'Введите Вид деятельности',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor="">Площадь</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('area', {
                    required: 'Введите Площадь',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor="">широта</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('y', {
                    required: 'Введите широту',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor="">долгота</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('x', {
                    required: 'Введите долготу',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.groupBtnAddNews}>
              <button className={s.btnEditNews} style={{ width: 176 }}>
                Сохранить
              </button>
              <button className={s.btnReset} style={{ width: 176 }}>
                Отмена
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
}

export default UpdateObjectModal;