import React, { useState } from 'react';

import DefaultSideBar from '../../components/DefaultSideBar/DefaultSideBar'
import DefaultMainPage from '../../components/DefaultMainPage/DefaultMainPage'

import IconButton from '@mui/material/IconButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import s from './mainPage.module.css'

const MainPage = ({ Trans, dispatch }) => {

  const [showSideBar, setShowSideBar] = useState(false)







  return (
    <>

 
    
    <DefaultMainPage dispatch={dispatch} Trans={Trans}/>


    {/* <div>
      <Trans i18nKey="mainPage.title" />
    </div> */}

    </>
  );
};


export default MainPage;
