import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import s from '../styles.module.css';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { mapApi } from '../../../api/api';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const styleAddNews = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 832,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  boxSizing: 'border-box',
  padding: '36px 32px 32px 32px',
};

const CreateObjectModal = ({
  openAddNews,
  handleCloseAddNews,
  fetchData,
  user_id,
  filterCategories,
}) => {
  const [type, setType] = React.useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onAddPost = async (data) => {
    data.location = '';
    data.author = user_id;
    data.typeObject = type;
    await mapApi.addPoint(data);
    fetchData();
    handleCloseAddNews();
  };

  return (
    <Modal
      open={openAddNews}
      onClose={handleCloseAddNews}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styleAddNews}>
        <div className={s.close_add_news_modal} onClick={handleCloseAddNews}>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
              stroke='#292929'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <div className={s.title_add_news_modal}>
          <div className={s.title_add_news_modal}>Добавить</div>
          <form onSubmit={handleSubmit(onAddPost)}>
            <div className={s.wrapperFormAddNews} style={{ marginBottom: '20px' }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Вид обьекта</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={type}
                  label='Вид обьекта'
                  onChange={handleChange}
                >
                  {filterCategories.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''>Наименование</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('name', {
                    required: 'Введите Наименование',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''>Адрес</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('address', {
                    required: 'Введите Адрес',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''>ФИО Директора</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('boss', {
                    required: 'Введите ФИО Директора',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''>Вид деятельности</label>
                <TextField
                  style={{ width: '100%' }}
                  {...register('typeActivity', {
                    required: 'Введите Вид деятельности',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''>Проходимость</label>
                <TextField
                  type='number'
                  style={{ width: '100%' }}
                  {...register('prohodimost', {
                    required: 'Введите Проходимость',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''>Площадь</label>
                <TextField
                  type='number'
                  style={{ width: '100%' }}
                  {...register('area', {
                    required: 'Введите Площадь',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''></label>
                <TextField
                  label='Широта: 52.XXXXXX'
                  style={{ width: '100%' }}
                  {...register('y', {
                    required: 'Введите широту',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.wrapperFormAddNews}>
              <div className={s.labelinputAddNews}>
                <label htmlFor=''></label>
                <TextField
                  label='Долгота: 76.XXXXXX'
                  style={{ width: '100%' }}
                  {...register('x', {
                    required: 'Введите долготу',
                  })}
                />
                <div className={s.validate_form_add_news}>
                  {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                </div>
              </div>
            </div>
            <div className={s.groupBtnAddNews}>
              <button className={s.btnEditNews} style={{ width: 176 }}>
                Сохранить
              </button>
              <button className={s.btnReset} style={{ width: 176 }}>
                Отмена
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateObjectModal;
