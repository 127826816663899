import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const BusinessMapTable = ({ items }) => {
  return (
    <TableContainer component={Paper} style={{ width: '100%' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>Вид объекта </TableCell>
            <TableCell>Наименование </TableCell>
            <TableCell>Адрес</TableCell>
            <TableCell>Вид деятельности</TableCell>
            <TableCell>Площадь (кв.м)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map((item) => (
              <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>718201{item?.id}</TableCell>
                <TableCell>{item?.['0'] || 'Не указан'}</TableCell>
                <TableCell>{item?.name}</TableCell>
                <TableCell>{item?.adress}</TableCell>
                <TableCell>{item?.KRP_ru || 'Не указан'}</TableCell>
                <TableCell>{item?.area}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BusinessMapTable;
