import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { appealsAPI } from '../../../api/api';
import { styleModal, myTheme, theme, CustomNoRowsOverlay } from '../../../theme';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';
import { ruRU } from '../../../common/helpers/ruRU';
import { kzKZ } from '../../../common/helpers/kzKZ';
import { enEN } from '../../../common/helpers/enEN';
import {
  appealsValueNotSelected,
  errorData,
  successData,
} from '../../../common/helpers/sliceToasts';

import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarFilterButton,
  GridToolbarContainer,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Popover from '@mui/material/Popover';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

import clsx from 'clsx';

import { setPDFModal } from '../../../redux/slices/mainSlice';

import AppealViewForAdminPDFAttachment from '../AppealViewForAdminPDFAttachment/AppealViewForAdminPDFAttachment';
import AppealViewForAdminPDF from '../AppealViewForAdminPDF/AppealViewForAdminPDF';
import AppealAnswerPDF from '../AppealAnswerPDF/AppealAnswerPDF';
const OnExecution = ({ Trans, dispatch, status, ws }) => {
  const width = useSelector((state) => state.store.mainSlice.MyAppealsWidth);

  const [getAllAppeals, setGetAllAppeals] = React.useState([]);
  const [Appeals, setAppeals] = React.useState([]);
  const user = useSelector((state) => state.store.mainSlice.user);
  const modal = useSelector((state) => state.store.mainSlice.PDFmodal);
  const [IDforPDFAppeal, setIDforPDFAppeal] = React.useState('none');
  const [IDforAcceptAppeal, setIDforAcceptAppeal] = React.useState('none');
  const [AttachmentForPDFAppeal, setAttachmentForPDFAppeal] = React.useState('none');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [DataForAnswerAppeal, setDataForAnswerAppeal] = React.useState(null);
  const [SIGEXIDforPDFAppealAttachment, setSIGEXIDforPDFAppealAttachment] = React.useState('none');
  const [SIGEXIDforPDFAppeal, setSIGEXIDforPDFAppeal] = React.useState('none');
  const [SelectedItem, setSelectedItem] = React.useState(null);
  const [SelectedItemsAcceptables, setSelectedItemsAcceptables] = React.useState(null);
  const [OpenModalEnd, setOpenModalEnd] = useState(false);
  const [OpenModalEnd2, setOpenModalEnd2] = useState(false);
  const [OpenModalEnd3, setOpenModalEnd3] = useState(false);
  const [OpenModalRefuse, setOpenModalRefuse] = useState(false);
  const [OpenModalTransfer, setOpenModalTransfer] = useState(false);
  const [Status3Items, setStatus3Items] = useState(null);
  const [SendAppealsReload, setSendAppealsReload] = useState(false);
  const [selectedSotr, setSelectedSotr] = useState('');
  const [selectedCompl, setSelectedCompl] = useState('');
  const [selectedRefuse, setSelectedRefuse] = useState('');
  const [selectedLang, setSelectedLang] = useState('');
  const [refusal, setrefusal] = useState(null);
  const [completion, setcompletion] = useState(null);
  const [textFieldValue, settextFieldValue] = useState('');
  const open = Boolean(anchorEl);
  const SelectedItemDispatch = useSelector(
    (state) => state.store.appealsSlice.SelectedItemDispatch,
  );
  let gridButtonWidth = (width - 155) / 6;
  const lang = localStorage.getItem('lang');

  React.useEffect(() => {
    console.log(modal);
  }, [modal]);

  React.useEffect(() => {
    status == 'Admin' ? GetData() : GetDataForSotrudnik();

    const Status3Items = appealsAPI.fetchVseSotrudniki();
    Status3Items.then((res) => setStatus3Items(res));

    // const fs = new FormData();
    // fs.append('typeofaction', 'refusal');
    // const refusal = appealsAPI.action(fs);
    // refusal.then((res) => setrefusal(res));

    // const fds = new FormData();
    // fds.append('typeofaction', 'completion');
    // const completion = appealsAPI.action(fds);
    // completion.then((res) => setcompletion(res));
  }, []);

  React.useEffect(() => {
    const fs = new FormData();
    fs.append('typeofaction', 'refusal');
    fs.append('lang', selectedLang);
    const refusal = appealsAPI.action(fs);
    refusal.then((res) => setrefusal(res));

    const fds = new FormData();
    fds.append('typeofaction', 'completion');
    fds.append('lang', selectedLang);
    const completion = appealsAPI.action(fds);
    completion.then((res) => setcompletion(res));
  }, [selectedLang]);

  React.useEffect(() => {
    const newAppeals = getAllAppeals?.map((res) => {
      return createData(
        res.attachment_id,
        res.sigexId,
        res[0]?.last_name +
          ' ' +
          res[0]?.first_name[0] +
          '. ' +
          (res[0]?.middle_name[0] ? res[0]?.middle_name[0] : '__') +
          '.',
        res.id,
        res[0]?.last_name +
          ' ' +
          res[0]?.first_name[0] +
          '. ' +
          (res[0]?.middle_name[0] ? res[0]?.middle_name[0] : '__') +
          '.' +
          '_' +
          res[0]?.IIN +
          '_' +
          res[0]?.IIN[0] +
          res[0]?.IIN[1] +
          '.' +
          res[0]?.IIN[2] +
          res[0]?.IIN[3] +
          '.' +
          res[0]?.IIN[4] +
          res[0]?.IIN[5],
        res.title,
        res.text,
        res.id,
        res.dateCreate,
        res.attachment,
        res.id + '_' + res.dateCreate,
        res.status === '0'
          ? 'Не распределено'
          : res.status === '1'
          ? 'Зарегистрированные'
          : res.status === '2'
          ? 'На исполнении'
          : res.status === '3'
          ? 'Исполненные'
          : 'Возврат без исполнения',
        res.attachment ? 'pdf' : res.attachment,
        res[1]?.last_name +
          ' ' +
          res[1]?.first_name[0] +
          '. ' +
          (res[0]?.middle_name[0] ? res[0]?.middle_name[0] : '__') +
          '.',
      );
    });
    setAppeals(newAppeals);
  }, [getAllAppeals]);

  const GridColDef = [
    {
      field: 'title',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item1' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          title: params.value !== '',
        });
      },
    },
    {
      field: 'numberAndDate',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item2' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          numberAndDate: params.value !== '',
        });
      },
    },
    {
      field: 'userAbout',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item3' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          about: params.value !== '',
        });
      },
    },
    {
      field: 'status',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item4' />,
      width: gridButtonWidth + gridButtonWidth / 2 - 18,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      valueGetter: (params) => {
        if (params.value == 'Не распределено') {
          if (lang == 'ru') {
            return 'Не распределено';
          }
          if (lang == 'en') {
            return 'Not distributed';
          }
          if (lang == 'kz') {
            return 'Таратылмаған';
          }
        } else if (params.value == 'На исполнении') {
          if (lang == 'ru') {
            return 'На исполнении';
          }
          if (lang == 'en') {
            return 'On execution';
          }
          if (lang == 'kz') {
            return 'Орындауда';
          }
        } else if (params.value == 'Зарегистрированные') {
          if (lang == 'ru') {
            return 'Зарегистрирован';
          }
          if (lang == 'en') {
            return 'Registered';
          }
          if (lang == 'kz') {
            return 'Тіркелген';
          }
        } else if (params.value == 'Исполненные') {
          if (lang == 'ru') {
            return 'Исполнен';
          }
          if (lang == 'en') {
            return 'Executed';
          }
          if (lang == 'kz') {
            return 'Орындалды';
          }
        } else if (params.value == 'Возврат без исполнения') {
          if (lang == 'ru') {
            return 'Возврат без исполнения';
          }
          if (lang == 'en') {
            return 'Refund without execution';
          }
          if (lang == 'kz') {
            return 'Орындаусыз қайтару';
          }
        }
      },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.row.status == 'Не распределено',
          positive: params.row.status == 'На исполнении',
          positive2: params.row.status == 'Зарегистрированные',
          positive3: params.row.status == 'Исполненные',
          negative4: params.row.status == 'Возврат без исполнения',
        });
      },
    },
    {
      field: 'pdf',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item5' />,
      width: gridButtonWidth / 2,
      headerClassName: 'super-app-theme--pdf',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <DownloadRoundedIcon color={params.value == 'pdf' ? 'warning' : '#757575'} />;
      },
    },
    {
      field: 'ispolnitel',
      headerName: <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item19' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          title: params.value !== '',
        });
      },
    },
  ];

  const createData = (
    attachment_id,
    sigexId,
    fio,
    id,
    userAboutMod,
    title,
    text,
    number,
    dateCreate,
    attachment,
    numberAndDateMod,
    status,
    pdf,
    ispolnitelMod,
  ) => {
    const userAbout1 = userAboutMod.replace(/__./gi, '');
    const userAbout = userAbout1.replace(/_/gi, '\n');
    const numberAndDate = numberAndDateMod.replace(/_/gi, '\n');
    const ispolnitel = ispolnitelMod.replace(/__./gi, '');

    return {
      attachment_id,
      sigexId,
      fio,
      id,
      userAbout,
      title,
      text,
      number,
      dateCreate,
      attachment,
      numberAndDate,
      status,
      pdf,
      ispolnitel,
    };
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
          }}
        >
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
        <div style={{ marginTop: '50px' }}></div>
        <GridToolbarFilterButton
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
            color: '#454545',
          }}
        />
      </GridToolbarContainer>
    );
  };
  //
  const SelectionHandler = (newSelection) => {
    console.log(newSelection.row.attachment);
    setIDforPDFAppeal(newSelection.row.id);
    setAttachmentForPDFAppeal(newSelection.row.attachment);
    setDataForAnswerAppeal(newSelection.row);
    setSIGEXIDforPDFAppealAttachment(newSelection.row.attachment_id);
    setSIGEXIDforPDFAppeal(newSelection.row.sigexId);
    newSelection.field !== '__check__'
      ? newSelection.field == 'pdf'
        ? newSelection.row.attachment
          ? dispatch(setPDFModal('appealView'))
          : dispatch(setPDFModal('none'))
        : pdfData(newSelection.row.id)
      : dispatch(setPDFModal('none'));

    let Acceptables = 0;
    setSelectedItem(newSelection.row.id);
    newSelection.row.status == 'Не распределено' ? console.info() : (Acceptables += 1);
    setSelectedItemsAcceptables(Acceptables);
  };

  const pdfData = (ids) => {
    dispatch(setPDFModal('pdf'));
    console.log(ids);
    setIDforAcceptAppeal(ids);
  };

  const CloseModal = () => {
    dispatch(setPDFModal('none'));
    setSelectedItem(null);
    setSelectedItemsAcceptables(null);
  };

  const GetDataAfterChange = () => {
    status == 'Admin' ? GetData() : GetDataForSotrudnik();
    successData(<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item6' />);
    CloseModal();
  };

  const GetData = () => {
    const fd = new FormData();
    fd.append('status', 2);
    const appealsResponse = appealsAPI.getAppealStatus(fd);
    appealsResponse.then((res) => setGetAllAppeals(res)).catch((err) => console.log(err));

    const Status3Items = appealsAPI.fetchVseSotrudniki();
    Status3Items.then((res) => setStatus3Items(res));

    // const fs = new FormData();
    // fs.append('typeofaction', 'refusal');
    // const refusal = appealsAPI.action(fs);
    // refusal.then((res) => setrefusal(res));

    // const fds = new FormData();
    // fds.append('typeofaction', 'completion');
    // const completion = appealsAPI.action(fds);
    // completion.then((res) => setcompletion(res));
  };

  const GetDataForSotrudnik = () => {
    const fd = new FormData();
    fd.append('status', 2);
    fd.append('id', user.id);
    const appealsResponse = appealsAPI.getAppealStatusBy1(fd);
    appealsResponse.then((res) => setGetAllAppeals(res)).catch((err) => console.log(err));

    const Status3Items = appealsAPI.fetchVseSotrudniki();
    Status3Items.then((res) => setStatus3Items(res));

    const fs = new FormData();
    fs.append('typeofaction', 'refusal');
    const refusal = appealsAPI.action(fs);
    refusal.then((res) => setrefusal(res));

    const fds = new FormData();
    fds.append('typeofaction', 'completion');
    const completion = appealsAPI.action(fds);
    completion.then((res) => setcompletion(res));
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const handleOpenModal = (das) => {
    // CloseModal();
    das == 'end'
      ? setOpenModalEnd(true)
      : das == 'refuse'
      ? setOpenModalRefuse(true)
      : das == 'transfer'
      ? setOpenModalTransfer(true)
      : CloseModal();
  };

  const handleCloseModal = () => {
    setOpenModalEnd(false);
    setOpenModalEnd2(false);
    setOpenModalRefuse(false);
    setOpenModalTransfer(false);
    setOpenModalEnd3(false);
    setSelectedLang('');
  };

  const handlePopoverOpen = () => {
    setAnchorEl(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeS = (event) => {
    setSelectedSotr(event.id);
  };

  const handleChange = (event) => {
    setSelectedCompl(event.name_meaning);
  };

  const handleChange2 = (event) => {
    setSelectedRefuse(event.name_meaning);
  };

  const handleChangeLang = (event) => {
    setSelectedLang(event.target.value);
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => Appeals.find((row) => row.id === id));
    let Acceptables = 0;
    setSelectedItem(selectedRowsData.map((id) => id.id));
    selectedRowsData.map((id) => {
      id.status == 'На исполнении' ? console.info() : (Acceptables += 1);
    });
    setSelectedItemsAcceptables(Acceptables);
  };

  const handleSelectedItemForward = () => {
    selectedCompl == '' ? appealsValueNotSelected() : handleSelectedItemForward3();
  };

  const handleSelectedItemForward1 = () => {
    selectedSotr == '' ? appealsValueNotSelected() : handleTransfer(selectedSotr);
  };

  const handleSelectedItemForward2 = () => {
    selectedRefuse == '' ? appealsValueNotSelected() : handleStatus(4);
  };

  const handleSelectedItemForward3 = () => {
    setOpenModalEnd(false);
    setOpenModalEnd2(true);
    // handleStatus(3)
  };

  const handleSelectedItemForward4 = () => {
    textFieldValue == ''
      ? errorData(<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item20' />)
      : handleSelectedItemForward5();
  };

  const handleSelectedItemForward5 = () => {
    setOpenModalEnd2(false);
    setOpenModalEnd3(true);
  };

  const handleTransfer = (selectedValue) => {
    // fd.append('user', selectedValue);
    // fd.append('id', SelectedItem);
    console.log(SelectedItem);
    console.log(SelectedItem.length);

    try {
      const fd = new FormData();
      var arr2 = [];
      SelectedItem.map((ids) => {
        let obj = {
          id: ids,
          status: 1,
          user: selectedValue,
        };
        arr2.push(obj);

        let strAp = '';
        strAp = 'ActionAppeals,' + ids;
        setTimeout(() => ws.send(strAp), 1000);
      });
      arr2 = JSON.stringify(arr2);
      fd.append('array', arr2);
      const appealsResponse = appealsAPI.fetchTransfer(fd);
      appealsResponse.then((res) =>
        successData(<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item7' />),
      );
      handleCloseModal();
      GetDataAfterChange();
    } catch (e) {
      const fd = new FormData();
      var arr2 = [];

      let obj = {
        id: SelectedItemDispatch,
        status: 1,
        user: selectedValue,
      };

      arr2.push(obj);

      let strAp = '';
      strAp = 'ActionAppeals,' + SelectedItemDispatch;
      setTimeout(() => ws.send(strAp), 1000);

      arr2 = JSON.stringify(arr2);
      fd.append('array', arr2);
      const appealsResponse = appealsAPI.fetchTransfer(fd);
      appealsResponse.then((res) =>
        successData(<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item7' />),
      );
      handleCloseModal();
      GetDataAfterChange();
    }
  };

  const handleStatus = (onChangeStatus) => {
    const fd = new FormData();
    var arr2 = [];
    onChangeStatus == 4
      ? SelectedItem
        ? SelectedItem.map((ids) => {
            let obj = {
              id: ids,
              status: onChangeStatus,
              user: user.id,
              refusal: selectedRefuse,
            };
            arr2.push(obj);

            let strAp = '';
            strAp = 'ActionAppeals,' + ids;
            setTimeout(() => ws.send(strAp), 1000);
            // ws.send(strAp);
          })
        : ChangeStatusFromModal()
      : SelectedItem.map((ids) => {
          let obj = {
            id: ids,
            status: onChangeStatus,
            user: user.id,
          };
          arr2.push(obj);

          let strAp = '';
          strAp = 'ActionAppeals,' + ids;
          setTimeout(() => ws.send(strAp), 1000);
        });
    arr2 = JSON.stringify(arr2);
    fd.append('array', arr2);
    const appealsResponse = appealsAPI.fetchchangeStatus(fd);
    appealsResponse.then((res) => GetDataAfterChange());
    handleCloseModal();
    setSelectedRefuse('');
  };

  const ChangeStatusFromModal = () => {
    const fd = new FormData();
    var arr2 = [];

    let obj = {
      id: SelectedItemDispatch,
      status: 4,
      user: user.id,
      refusal: selectedRefuse,
    };

    arr2.push(obj);

    let strAp = '';
    strAp = 'ActionAppeals,' + SelectedItemDispatch;
    setTimeout(() => ws.send(strAp), 1000);

    arr2 = JSON.stringify(arr2);
    fd.append('array', arr2);
    const appealsResponse = appealsAPI.fetchchangeStatus(fd);
    appealsResponse.then((res) => GetDataAfterChange());
    setSelectedRefuse('');
    setSelectedLang('');
  };

  return (
    <>
      <div style={{ height: 585, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: 585,
                width: '100%',
                '& .super-app.negative4': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff0000',
                  fontWeight: '600',
                },
                '& .super-app.negative': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#1a3e72',
                  fontWeight: '600',
                },
                '& .super-app.positive': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app.positive2': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app.positive3': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app-theme--header': {
                  backgroundColor: '#e0e0e0',
                  paddingLeft: '10px',
                },
                '& .super-app-theme--pdf': {
                  backgroundColor: '#e0e0e0',
                  paddingLeft: '10px',
                },
                '& .super-app.NullPdf': {
                  backgroundColor: '#e0e0e0',
                  color: '#07bc0c',
                  fontWeight: '600',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.pdf': {
                  backgroundColor: '#07bc0c',
                  color: '#07bc0c',
                  fontWeight: '600',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.about': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '& .super-app.title': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '& .super-app.numberAndDate': {
                  whiteSpace: 'pre-line !important',
                  textAlign: 'center',
                },
                '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'pre-line',
                  lineHeight: 'normal',
                  textAlign: 'center',
                },
                '.MuiDataGrid-columnHeaderTitleContainer': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <Button
                  color='neutral'
                  sx={{ mb: '30px', height: '48px', width: '240px' }}
                  variant='contained'
                  disabled={SelectedItem ? (SelectedItem?.length != '1' ? true : false) : true}
                  onClick={() => {
                    // (3);
                    handleOpenModal('end');
                  }}
                >
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item21' />
                </Button>
                {status == 'Admin' ? (
                  <Button
                    color='neutral'
                    sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                    variant='outlined'
                    disabled={
                      SelectedItem
                        ? SelectedItem?.length == '0'
                          ? true
                          : SelectedItemsAcceptables > 0
                          ? true
                          : false
                        : true
                    }
                    onClick={() => {
                      handleOpenModal('transfer');
                    }}
                  >
                    <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item9' />
                  </Button>
                ) : null}
                <Button
                  color='refused'
                  sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                  variant='outlined'
                  disabled={SelectedItem ? (SelectedItem?.length == '0' ? true : false) : true}
                  onClick={() => {
                    // handleStatus(4);
                    handleOpenModal('refuse');
                  }}
                >
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item10' />
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={myTheme}>
                <DataGrid
                  disableSelectionOnClick
                  getRowHeight={() => 'auto'}
                  checkboxSelection={true}
                  onSelectionModelChange={(newSelection) => {
                    onRowsSelectionHandler(newSelection);
                  }}
                  // selectionModel={select}
                  localeText={
                    lang == 'ru' ? ruRU : lang == 'kz' ? kzKZ : lang == 'en' ? enEN : null
                  }
                  pagination
                  disableExport
                  disableReorder
                  disableColumnMenu
                  rowsPerPageOptions={[5]}
                  pageSize={8}
                  rows={Appeals}
                  columns={GridColDef}
                  onCellClick={(newSelection) => {
                    SelectionHandler(newSelection);
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterLogicOperator: GridLinkOperator.Or,
                      },
                    },
                  }}
                  components={{
                    // Toolbar: QuickSearchToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Toolbar: CustomToolbar,
                    Pagination: CustomPagination,
                  }}
                  componentsProps={{
                    panel: {
                      placement: 'top',
                    },
                    cell: {
                      onMouseEnter: handlePopoverOpen,
                      onMouseLeave: handlePopoverClose,
                    },
                  }}
                />
              </ThemeProvider>
              <Popover
                sx={{
                  pointerEvents: 'none',
                  zIndex: '-1',
                }}
                open={false}
              ></Popover>
            </Box>
          </div>
        </div>
      </div>

      <Modal
        open={OpenModalTransfer}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={handleCloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item12' />
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item13' />
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item13' />}
                sx={{
                  '& .MuiSvgIcon-root': {
                    width: '55px',
                    height: '55px',
                    background: 'black',
                    fill: 'darkorange',
                    borderBottomRightRadius: '5px',
                    borderTopRightRadius: '5px',
                  },
                }}
                // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                IconComponent={() => <KeyboardArrowDownRoundedIcon />}
              >
                {Status3Items?.map((item, index) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    onClick={() => {
                      handleChangeS(item);
                    }}
                    // disabled={item.disabled}
                  >
                    {item.first_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ThemeProvider theme={theme}>
            <Button
              color='neutral'
              sx={{
                mt: '64px',
                height: '48px',
                width: '100%',
                boxShadow: 'none',
                paddingTop: '15px',
              }}
              variant='contained'
              onClick={handleSelectedItemForward1}
            >
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item14' />
            </Button>
            <Button
              color='neutral'
              sx={{ mt: '24px', height: '48px', width: '100%' }}
              variant='outlined'
              // onClick={handleCloseModal}
            >
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
            </Button>
          </ThemeProvider>
        </Box>
      </Modal>

      <Modal
        open={OpenModalEnd}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={handleCloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />
            </div>
          </div>

          <Box sx={{ display: selectedLang == '' ? 'block' : 'none' }}>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedLang}
                  label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />}
                  onChange={(event) => {
                    handleChangeLang(event);
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '55px',
                      height: '55px',
                      background: 'black',
                      fill: 'darkorange',
                      borderBottomRightRadius: '5px',
                      borderTopRightRadius: '5px',
                    },
                  }}
                  IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                >
                  <MenuItem value='ru'>Русский</MenuItem>
                  <MenuItem value='kz'>Қазақ</MenuItem>
                  <MenuItem value='en'>English</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={handleCloseModal}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
              </Button>
            </ThemeProvider>
          </Box>

          <Box sx={{ display: selectedLang == '' ? 'none' : 'block' }}>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item17' />
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item17' />}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '55px',
                      height: '55px',
                      background: 'black',
                      fill: 'darkorange',
                      borderBottomRightRadius: '5px',
                      borderTopRightRadius: '5px',
                    },
                  }}
                  // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                  IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                >
                  {completion?.map((item, index) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      onClick={() => {
                        handleChange(item);
                      }}
                      // disabled={item.disabled}
                    >
                      {item.name_meaning}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{
                  mt: '64px',
                  height: '48px',
                  width: '100%',
                  boxShadow: 'none',
                  paddingTop: '15px',
                }}
                variant='contained'
                onClick={handleSelectedItemForward}
              >
                <Trans i18nKey='header.modalregistration.item3' />
              </Button>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={handleCloseModal}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={OpenModalRefuse}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={handleCloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item16' />
            </div>
          </div>

          <Box sx={{ display: selectedLang == '' ? 'block' : 'none' }}>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedLang}
                  label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />}
                  onChange={(event) => {
                    handleChangeLang(event);
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '55px',
                      height: '55px',
                      background: 'black',
                      fill: 'darkorange',
                      borderBottomRightRadius: '5px',
                      borderTopRightRadius: '5px',
                    },
                  }}
                  IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                >
                  {/* фыв */}
                  <MenuItem value='ru'>Русский</MenuItem>
                  <MenuItem value='kz'>Қазақ</MenuItem>
                  <MenuItem value='en'>English</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={handleCloseModal}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
              </Button>
            </ThemeProvider>
          </Box>

          <Box sx={{ display: selectedLang == '' ? 'none' : 'block' }}>
            {console.log(selectedLang)}
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item17' />
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item17' />}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '55px',
                      height: '55px',
                      background: 'black',
                      fill: 'darkorange',
                      borderBottomRightRadius: '5px',
                      borderTopRightRadius: '5px',
                    },
                  }}
                  IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                >
                  {refusal?.map((item, index) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      onClick={() => {
                        handleChange2(item);
                      }}
                    >
                      {item.name_meaning}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{
                  mt: '64px',
                  height: '48px',
                  width: '100%',
                  boxShadow: 'none',
                  paddingTop: '15px',
                }}
                variant='contained'
                onClick={handleSelectedItemForward2}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item18' />
              </Button>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={handleCloseModal}
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={OpenModalEnd2}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={handleCloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <TextField
              required
              id='outlined-required'
              label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />}
              sx={{ mt: '40px', width: '100%' }}
              multiline
              rows={8}
              onChange={(e) => {
                settextFieldValue(e.target.value);
              }}
              value={textFieldValue}
            />
          </Box>

          <ThemeProvider theme={theme}>
            <Button
              color='neutral'
              sx={{
                mt: '64px',
                height: '48px',
                width: '100%',
                boxShadow: 'none',
                paddingTop: '15px',
              }}
              variant='contained'
              onClick={handleSelectedItemForward4}
            >
              <Trans i18nKey='header.modalregistration.item3' />
            </Button>
            <Button
              color='neutral'
              sx={{ mt: '24px', height: '48px', width: '100%' }}
              variant='outlined'
              onClick={handleCloseModal}
            >
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item15' />
            </Button>
          </ThemeProvider>
        </Box>
      </Modal>

      <Modal
        open={OpenModalEnd3}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <Tooltip
            className={s.close_auth_modal}
            placement='top'
            title={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item11' />}
          >
            <div onClick={handleCloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </Tooltip>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item22' />
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <AppealAnswerPDF
              CloseModal={handleCloseModal}
              handleStatus={handleStatus}
              GetDataAfterChange={GetDataAfterChange}
              selectedValue={selectedCompl}
              selectedLang={selectedLang}
              //   DopCatselectedValue={DopCatselectedValueForSend}
              //   appealNumber={appealNumber}
              //   title={title}
              //   fileID={fileID}
              //   dataB64={dataB64}
              ws={ws}
              //   DATEforPDFAppeal={'DATEforPDFAppeal'}
              //   setOpenModal={setOpenModal}
              setSendAppealsReload={setSendAppealsReload}
              SendAppealsReload={SendAppealsReload}
              //   appealDate={appealDate}
              dispatch={dispatch}
              //   setModalStep3={setModalStep3}
              Trans={Trans}
              DataForAnswerAppeal={DataForAnswerAppeal}
              TEXTforPDFAppeal={textFieldValue}
            />
          </Box>
        </Box>
      </Modal>

      {modal == 'appealView' ? (
        <AppealViewForAdminPDFAttachment
          CloseModal={CloseModal}
          IDforPDFAppeal={IDforPDFAppeal}
          IDforAppeal='none'
          SIGEXIDforPDFAppeal={SIGEXIDforPDFAppealAttachment}
          AttachmentForPDFAppeal={AttachmentForPDFAppeal}
          Trans={Trans}
        />
      ) : modal == 'pdf' ? (
        <AppealViewForAdminPDF
          CloseModal={CloseModal}
          IDforPDFAppeal={IDforPDFAppeal}
          IDforAppeal='none'
          AttachmentForPDFAppeal={AttachmentForPDFAppeal}
          SelectedItem={SelectedItem}
          handleOpenModal={handleOpenModal}
          IDforAcceptAppeal={IDforAcceptAppeal}
          GetDataAfterChange={GetDataAfterChange}
          functionFor='OnExecution'
          SIGEXIDforPDFAppeal={SIGEXIDforPDFAppeal}
          dispatch={dispatch}
          ws={ws}
          Trans={Trans}
        />
      ) : null}
    </>
  );
};
export default OnExecution;
