import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const BusinessMap = ({ items }) => {
  return (
    <YMaps>
      <div id="map-container">
        <Map height="700px" width="100%" defaultState={{ center: [52.29, 76.96], zoom: 12 }}>
          {items.map((item) => {
            const placeMark = {
              geometry: [item.location.split(', ')[0], item.location.split(', ')[1]],
              properties: {
                hintContent: `
                    <div>Название: ${item.name}</div>
                    <div>id: ${item.id}</div>
                    <div>Проходимость: ${item.prohodimost}</div>
                    <div>Категория: ${item?.['0']}</div>
                    <div>Адрес: ${item?.adress}</div>`,
              },
              options: {
                iconLayout: 'default#image',
              },
              modules: ['geoObject.addon.balloon', 'geoObject.addon.hint'],
            };
            return <Placemark key={item.id} {...placeMark} />;
          })}
        </Map>
      </div>
    </YMaps>
  );
};

export default BusinessMap;
