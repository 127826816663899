import React from 'react';
import { useNavigate } from 'react-router-dom';
import { newsApi } from '../../../api/api';
import { LoadingBlocks } from '../../../pages/News/LoadingBlocks';

import s from './NewsWrapper.module.css';

const NewsWrapper = ({ Trans, dispatch }) => {
  const navigate = useNavigate();
  const [news, setNews] = React.useState(false);

  React.useEffect(() => {
    const obj = {
      page: 1,
      status: 1,
      countNews: 9,
    };
    const response = newsApi.fetchNews(obj);
    response.then((res) => setNews(res));
  }, []);

  return (
    <>
      {news ? (
        <div className={s.newsWrapper}>
          {news[1][0] && (
            <div className={s.newsItem} onClick={() => navigate('/news/post/' + news[1][0]?.id)}>
              <div className={s.newsItemImg}>
                <img
                  src={`https://biznes.15000pvl.kz/file/news/${news[1][0]?.img?.trim()}`}
                  alt='Pic'
                />
              </div>
              <div className={s.newsItemTitle}>{news[1][0]?.title}</div>
              <div className={s.newsItemFooter}>
                <Trans i18nKey='News.newsAdmin.item27' />
                <svg
                  width='15'
                  height='10'
                  viewBox='0 0 15 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.4199 4.69832C14.4999 4.77833 14.5448 4.88685 14.5448 5C14.5448 5.11315 14.4999 5.22167 14.4199 5.30168L10.7997 8.92188C10.7196 9.00189 10.6111 9.04684 10.498 9.04684C10.3848 9.04684 10.2763 9.00189 10.1963 8.92188C10.1163 8.84187 10.0713 8.73335 10.0713 8.6202C10.0713 8.50704 10.1163 8.39852 10.1963 8.31851L13.0882 5.42658L0.844118 5.42718C0.78802 5.42718 0.732471 5.41613 0.680643 5.39467C0.628814 5.3732 0.581722 5.34173 0.542054 5.30206C0.502387 5.2624 0.470921 5.2153 0.449453 5.16348C0.427985 5.11165 0.416935 5.0561 0.416935 5C0.416935 4.9439 0.427985 4.88835 0.449453 4.83652C0.470921 4.7847 0.502387 4.7376 0.542054 4.69794C0.581722 4.65827 0.628814 4.6268 0.680643 4.60533C0.732471 4.58387 0.78802 4.57282 0.844118 4.57282L13.0882 4.57342L10.1963 1.68149C10.1163 1.60148 10.0713 1.49296 10.0713 1.3798C10.0713 1.26665 10.1163 1.15813 10.1963 1.07812C10.2763 0.998109 10.3848 0.95316 10.498 0.95316C10.6111 0.95316 10.7196 0.99811 10.7997 1.07812L14.4199 4.69832Z'
                    fill='#FF7F00'
                  />
                </svg>
              </div>
            </div>
          )}
          {news[1][1] && (
            <div className={s.newsItem} onClick={() => navigate('/news/post/' + news[1][1]?.id)}>
              <div className={s.newsItemImg}>
                <img
                  src={`https://biznes.15000pvl.kz/file/news/${news[1][1]?.img?.trim()}`}
                  alt='Pic'
                />
              </div>
              <div className={s.newsItemTitle}>{news[1][1]?.title}</div>
              <div className={s.newsItemFooter}>
                <Trans i18nKey='News.newsAdmin.item27' />
                <svg
                  width='15'
                  height='10'
                  viewBox='0 0 15 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.4199 4.69832C14.4999 4.77833 14.5448 4.88685 14.5448 5C14.5448 5.11315 14.4999 5.22167 14.4199 5.30168L10.7997 8.92188C10.7196 9.00189 10.6111 9.04684 10.498 9.04684C10.3848 9.04684 10.2763 9.00189 10.1963 8.92188C10.1163 8.84187 10.0713 8.73335 10.0713 8.6202C10.0713 8.50704 10.1163 8.39852 10.1963 8.31851L13.0882 5.42658L0.844118 5.42718C0.78802 5.42718 0.732471 5.41613 0.680643 5.39467C0.628814 5.3732 0.581722 5.34173 0.542054 5.30206C0.502387 5.2624 0.470921 5.2153 0.449453 5.16348C0.427985 5.11165 0.416935 5.0561 0.416935 5C0.416935 4.9439 0.427985 4.88835 0.449453 4.83652C0.470921 4.7847 0.502387 4.7376 0.542054 4.69794C0.581722 4.65827 0.628814 4.6268 0.680643 4.60533C0.732471 4.58387 0.78802 4.57282 0.844118 4.57282L13.0882 4.57342L10.1963 1.68149C10.1163 1.60148 10.0713 1.49296 10.0713 1.3798C10.0713 1.26665 10.1163 1.15813 10.1963 1.07812C10.2763 0.998109 10.3848 0.95316 10.498 0.95316C10.6111 0.95316 10.7196 0.99811 10.7997 1.07812L14.4199 4.69832Z'
                    fill='#FF7F00'
                  />
                </svg>
              </div>
            </div>
          )}
          {news[1][2] && (
            <div className={s.newsItem} onClick={() => navigate('/news/post/' + news[1][2]?.id)}>
              <div className={s.newsItemImg}>
                <img
                  src={`https://biznes.15000pvl.kz/file/news/${news[1][2]?.img?.trim()}`}
                  alt='Pic'
                />
              </div>
              <div className={s.newsItemTitle}>{news[1][2]?.title}</div>
              <div className={s.newsItemFooter}>
                <Trans i18nKey='News.newsAdmin.item27' />
                <svg
                  width='15'
                  height='10'
                  viewBox='0 0 15 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.4199 4.69832C14.4999 4.77833 14.5448 4.88685 14.5448 5C14.5448 5.11315 14.4999 5.22167 14.4199 5.30168L10.7997 8.92188C10.7196 9.00189 10.6111 9.04684 10.498 9.04684C10.3848 9.04684 10.2763 9.00189 10.1963 8.92188C10.1163 8.84187 10.0713 8.73335 10.0713 8.6202C10.0713 8.50704 10.1163 8.39852 10.1963 8.31851L13.0882 5.42658L0.844118 5.42718C0.78802 5.42718 0.732471 5.41613 0.680643 5.39467C0.628814 5.3732 0.581722 5.34173 0.542054 5.30206C0.502387 5.2624 0.470921 5.2153 0.449453 5.16348C0.427985 5.11165 0.416935 5.0561 0.416935 5C0.416935 4.9439 0.427985 4.88835 0.449453 4.83652C0.470921 4.7847 0.502387 4.7376 0.542054 4.69794C0.581722 4.65827 0.628814 4.6268 0.680643 4.60533C0.732471 4.58387 0.78802 4.57282 0.844118 4.57282L13.0882 4.57342L10.1963 1.68149C10.1163 1.60148 10.0713 1.49296 10.0713 1.3798C10.0713 1.26665 10.1163 1.15813 10.1963 1.07812C10.2763 0.998109 10.3848 0.95316 10.498 0.95316C10.6111 0.95316 10.7196 0.99811 10.7997 1.07812L14.4199 4.69832Z'
                    fill='#FF7F00'
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={s.newsWrapper}>
          <LoadingBlocks />
          <LoadingBlocks />
          <LoadingBlocks />
        </div>
      )}

      <div className={s.allNews} onClick={() => navigate('/news')}>
        <Trans i18nKey='News.newsAdmin.item26' />
      </div>
    </>
  );
};

export default NewsWrapper;
