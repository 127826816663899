import { createAsyncThunk } from '@reduxjs/toolkit';
import { mainPage } from '../../api/api';

export const loginEcp = createAsyncThunk('main/loginEcp', async function (fd, rejectWithVlaue) {
  try {
    const data = await mainPage.loginEcp(fd);
    return data;
  } catch (e) {
    return rejectWithVlaue(e);
  }
});

export const loginInnPass = createAsyncThunk(
  'main/loginInnPass',
  async function (fd, rejectWithVlaue) {
    try {
      const data = await mainPage.loginIinPass(fd);
      return data;
    } catch (e) {
      return rejectWithVlaue(e);
    }
  },
);

export const logOut = createAsyncThunk('main/logOut', async function (fd, rejectWithVlaue) {
  try {
    const data = await mainPage.logOut(fd);
    return data;
  } catch (e) {
    return rejectWithVlaue(e);
  }
});

export const userRegistation = createAsyncThunk(
  'main/userRegistation',
  async function (fd, rejectWithVlaue) {
    try {
      const data = await mainPage.userRegistation(fd);
      return data;
    } catch (e) {
      return rejectWithVlaue(e);
    }
  },
);
