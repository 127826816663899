import React, { useState } from 'react';
import { appealsAPI } from '../../../api/api';
import { useSelector } from 'react-redux';
import {
  appealsValueNotSelected,
  infoData,
  errorData,
  successData,
} from '../../../common/helpers/sliceToasts';
import {
  setTypeAppealCatArrSlice,
  setTypeAppealSubCatArrSlice,
} from '../../../redux/slices/appealsSlice';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';

import { NCALayerClient } from 'ncalayer-js-client';

import AppealPDFEdit from '../../MyAppeals/AppealPDF/AppealPDFEdit';

const AppealDraftEdit = ({
  CloseModal,
  IDforPDFAppeal,
  IDforAppeal,
  dispatch,
  myDraftAppeal,
  ws,
  Trans,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [CatselectedValue, setCatSelectedValue] = useState('');
  const [DopCatselectedValue, setDopCatSelectedValue] = useState('');
  const [selectedValueForSend, setSelectedValueForSend] = useState('');
  const [CatselectedValueForSend, setCatSelectedValueForSend] = useState('');
  const [DopCatselectedValueForSend, setDopCatSelectedValueForSend] = useState('');
  const [textFieldValue, settextFieldValue] = useState(myDraftAppeal.text);
  const [selectedLang, setSelectedLang] = useState('');
  const [selectedLangForSend, setSelectedLangForSend] = useState('');
  const [TypeAppeal, setTypeAppeal] = useState(null);

  const [TypeAppealCat, setTypeAppealCat] = useState(null);
  var TypeAppealCatArr = [];
  const TypeAppealCatArrSlice = useSelector(
    (state) => state.store.appealsSlice.TypeAppealCatArrSlice,
  );

  const [TypeAppealSubCat, setTypeAppealSubCat] = useState(null);
  var TypeAppealSubCatArr = [];
  const TypeAppealSubCatArrSlice = useSelector(
    (state) => state.store.appealsSlice.TypeAppealSubCatArrSlice,
  );
  const [OpenModal, setOpenModal] = useState(false);
  const [ModalStep1, setModalStep1] = useState(true);
  const [ModalStep2, setModalStep2] = useState(false);
  const [ModalStep3, setModalStep3] = useState(false);

  let ncaLayer = new NCALayerClient();
  const axios = require('axios').default;
  const [dataB64, setdataB64] = useState(null);
  const [error, seterror] = useState(null);
  const [storageType, setstorageType] = useState(null);
  const [title, settitle] = useState('Нет');
  const [appealNumber, setappealNumber] = useState('-');
  const [appealDate, setDate] = useState('-');
  const [fileID, setFileID] = useState('Нет');

  let predefinedEmails = [], // Сюда можно добавить сервисные адреса электронной почты
    awaitingSignature = false,
    signed = false,
    sigexId = null,
    connecting = true,
    description = 'Подписано в информационной системе Business Ertis',
    sigexURL = 'https://sigex.kz';

  React.useEffect(() => {
    const fs = new FormData();
    fs.append('lang', selectedLang);
    const TypeAppeal = appealsAPI.fetchTypeAppeal(fs);
    TypeAppeal.then((res) => setTypeAppeal(res));

    const TypeAppealCat = appealsAPI.fetchTypeAppealCat(fs);
    TypeAppealCat.then((res) => setTypeAppealCat(res));

    const TypeAppealSubCat = appealsAPI.fetchTypeAppealSubCat(fs);
    TypeAppealSubCat.then((res) => setTypeAppealSubCat(res));
  }, [selectedLang]);

  const handleChangeModal = () => {
    setModalStep1(false);
  };

  const handleNoChangeSelect = () => {
    setSelectedValue(myDraftAppeal.title);
    setCatSelectedValue(myDraftAppeal.category);
    setDopCatSelectedValue(myDraftAppeal.subcategory);
    setSelectedValueForSend(myDraftAppeal.title);
    setCatSelectedValueForSend(myDraftAppeal.category);
    setDopCatSelectedValueForSend(myDraftAppeal.subcategory);
    setSelectedLangForSend(myDraftAppeal.language);
    setSelectedLang(myDraftAppeal.language);
    setModalStep2(true);
  };

  const handleSelectedItemForward = () => {
    selectedValue == ''
      ? appealsValueNotSelected()
      : CatselectedValue == ''
      ? appealsValueNotSelected()
      : DopCatselectedValue == ''
      ? appealsValueNotSelected()
      : setModalStep2(true);
  };

  const UpdateStates = () => {
    setSelectedValue('');
    setCatSelectedValue('');
    setDopCatSelectedValue('');
    setSelectedValueForSend('');
    setCatSelectedValueForSend('');
  };

  const handleChangeLang = (event) => {
    setSelectedLang(event.target.value);
    setSelectedLangForSend(event.target.value);
  };

  const handleChange = (event) => {
    setSelectedValue(event.id);
    setSelectedValueForSend(event.appeal);
    TypeAppealCat?.map((item) => (item.appeal_id == event.id ? TypeAppealCatArr.push(item) : null));
    dispatch(setTypeAppealCatArrSlice(TypeAppealCatArr));
  };

  const handleCatChange = (event) => {
    setCatSelectedValue(event.id);
    setCatSelectedValueForSend(event.name);
    TypeAppealSubCat?.map((item) =>
      item.cat_id == event.id ? TypeAppealSubCatArr.push(item) : null,
    );
    dispatch(setTypeAppealSubCatArrSlice(TypeAppealSubCatArr));
  };

  const handleDopCatChange = (event) => {
    console.log(event);
    setDopCatSelectedValue(event.id);
    setDopCatSelectedValueForSend(event.name);
  };

  const handleCloseModal = () => {
    CloseModal();
  };

  const handleSelectedItemForward2 = () => {
    textFieldValue == ''
      ? errorData(<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item20' />)
      : handleSelectedItemForward3();
  };

  const handleSelectedItemForward3 = () => {
    setModalStep2(false);
    const fd = new FormData();
    fd.append('metod', 'CreateAppeals');
    const AppealNumber = appealsAPI.fetchAppealNumber(fd);
    AppealNumber.then((res) => setappealNumber(res[0].id)).then(setModalStep3(true));
    setDate(new Date().toISOString().slice(0, 10));
  };

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    const typeOfFile = name.substring(name.length - 3);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setdataB64(reader.result.replace('data:application/pdf;base64,', ''));
      settitle(name);
      console.log(name);
      selectStorageAndContinue(name, reader.result.replace('data:application/pdf;base64,', ''));
      typeOfFile == 'pdf'
        ? console.log('pdf')
        : errorData(<Trans i18nKey='MyNotFinishAppeals.item6' />);
    };
  };

  async function selectStorageAndContinue(title, dataB64) {
    let storageTypeIn = '';

    try {
      await ncaLayer.connect();
      connecting = false;
    } catch (err) {
      console.log('Ошибка: Включите программу NCALayer' + err);
      errorData(<Trans i18nKey='MyNotFinishAppeals.item7' /> + err);
    }
    awaitingSignature = true;

    try {
      const storageTypes = await ncaLayer.getActiveTokens();

      if (storageTypes.length > 1) {
        console.log(
          'Обнаружено несколько разных типов защищенных хранилищ подключенных к компьютеру',
        );
        errorData(<Trans i18nKey='MyNotFinishAppeals.item8' />);
        return;
      }

      if (storageTypes.length == 0) {
        setstorageType('PKCS12');
        storageTypeIn = 'PKCS12';
      } else {
        setstorageType(storageTypes[0]);
        storageTypeIn = storageTypes[0];
      }
      console.log(storageTypeIn);
    } catch (err) {
      console.log('NCALayer вернул неожиданную ошибку', err);
      errorData(<Trans i18nKey='MyNotFinishAppeals.item9' />, err);
      return;
    }

    signAndSend(storageTypeIn, title, dataB64);
  }

  async function signAndSend(storageType, title, dataB64) {
    let signature;
    try {
      console.log(dataB64);
      signature = await ncaLayer.createCMSSignatureFromBase64(storageType, dataB64);
    } catch (err) {
      seterror({
        message: 'NCALayer вернул неожиданную ошибку',
        description: err,
      });
      errorData(<Trans i18nKey='MyNotFinishAppeals.item10' />, err);
      return;
    }

    try {
      // const emailNotifications = {
      //   to: predefinedEmails,
      // }
      let response = await axios.post(`${sigexURL}/api`, {
        title: title,
        description: description,
        signature,
        // emailNotifications,
      });
      if (response.data.message) {
        this.error = {
          message: 'Сервер не принял подпись',
          description: response.data.message,
        };
        errorData(<Trans i18nKey='MyNotFinishAppeals.item11' />, response.data.message);
        return;
      }

      sigexId = response.data.documentId;

      const dataToSend = Uint8Array.from(atob(dataB64), (c) => c.charCodeAt(0)).buffer;
      response = await axios.post(`${sigexURL}/api/${sigexId}/data`, dataToSend, {
        headers: { 'Content-Type': 'application/octet-stream' },
      });

      if (response.data.message) {
        seterror({
          message: 'Сервер не принял подпись (проблема с проверкой подписанных данных)',
          description: response.data.message,
        });
        errorData(<Trans i18nKey='MyNotFinishAppeals.item12' />, response.data.message);
        return;
      }

      awaitingSignature = false;
      signed = true;

      setFileID(sigexId);
    } catch (err) {
      seterror({
        message: 'Сервер вернул неожиданную ошибку',
        description: err,
      });
      errorData(<Trans i18nKey='MyNotFinishAppeals.item13' />, err);
    }
    successData(<Trans i18nKey='MyNotFinishAppeals.item14' />);
  }

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
    },
  });

  const styleModal = {
    position: 'absolute',
    top: 290,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const Upload = createTheme({
    palette: {
      neutrals: {
        main: 'transparent',
        contrastText: '#28323E',
      },
    },
  });

  return (
    <>
      <Modal
        open={true}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <div className={s.close_auth_modal} onClick={handleCloseModal}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='MyNotFinishAppeals.item15' />
            </div>
          </div>

          {!ModalStep2 ? (
            ModalStep1 ? (
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                  <InputLabel id='demo-simple-select-label'>{myDraftAppeal.title}</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    disabled
                    label={<Trans i18nKey='MyNotFinishAppeals.item16' />}
                  ></Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                  <InputLabel id='demo-simple-select-label'>{myDraftAppeal.category}</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    disabled
                    label={<Trans i18nKey='MyNotFinishAppeals.item16' />}
                  ></Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                  <InputLabel id='demo-simple-select-label'>{myDraftAppeal.subcategory}</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    disabled
                    label={<Trans i18nKey='MyNotFinishAppeals.item16' />}
                  ></Select>
                </FormControl>

                <ThemeProvider theme={theme}>
                  <Button
                    color='neutral'
                    sx={{
                      mt: '24px',
                      height: '48px',
                      width: '100%',
                      boxShadow: 'none',
                      paddingTop: '15px',
                    }}
                    variant='contained'
                    onClick={handleChangeModal}
                  >
                    <Trans i18nKey='MyNotFinishAppeals.item17' />
                  </Button>
                  <Button
                    color='neutral'
                    sx={{
                      mt: '24px',
                      height: '48px',
                      width: '100%',
                      boxShadow: 'none',
                      paddingTop: '15px',
                    }}
                    variant='contained'
                    onClick={handleNoChangeSelect}
                  >
                    <Trans i18nKey='MyNotFinishAppeals.item18' />
                  </Button>
                  <Button
                    color='neutral'
                    sx={{ mt: '24px', height: '48px', width: '100%' }}
                    variant='outlined'
                    onClick={handleCloseModal}
                  >
                    <Trans i18nKey='MyNotFinishAppeals.item19' />
                  </Button>
                </ThemeProvider>
              </Box>
            ) : (
              <>
                <Box sx={{ display: selectedLang == '' ? 'block' : 'none' }}>
                  <Box sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={selectedLang}
                        label={
                          <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item24' />
                        }
                        onChange={(event) => {
                          handleChangeLang(event);
                        }}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            width: '55px',
                            height: '55px',
                            background: 'black',
                            fill: 'darkorange',
                            borderBottomRightRadius: '5px',
                            borderTopRightRadius: '5px',
                          },
                        }}
                        IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                      >
                        <MenuItem value='ru'>Русский</MenuItem>
                        <MenuItem value='kz'>Қазақ</MenuItem>
                        <MenuItem value='en'>English</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <ThemeProvider theme={theme}>
                    <Button
                      color='neutral'
                      sx={{ mt: '24px', height: '48px', width: '100%' }}
                      variant='outlined'
                      onClick={handleCloseModal}
                    >
                      <Trans i18nKey='SendAppeals.item8' />
                    </Button>
                  </ThemeProvider>
                </Box>

                <Box sx={{ display: selectedLang == '' ? 'none' : 'block' }}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        <Trans i18nKey='MyNotFinishAppeals.item16' />
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={selectedValue}
                        label={<Trans i18nKey='MyNotFinishAppeals.item16' />}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            width: '55px',
                            height: '55px',
                            background: 'black',
                            fill: 'darkorange',
                            borderBottomRightRadius: '5px',
                            borderTopRightRadius: '5px',
                          },
                        }}
                        // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                        IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                      >
                        {TypeAppeal?.map((item, index) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            onClick={() => {
                              handleChange(item);
                            }}
                            // disabled={item.disabled}
                          >
                            {item.appeal}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{
                        marginTop: '25px',
                        display: selectedValue == '' ? 'none' : 'inline-flex',
                        '& .MuiSvgIcon-root': {
                          width: '55px',
                          height: '55px',
                          background: 'black',
                          fill: 'darkorange',
                          borderBottomRightRadius: '5px',
                          borderTopRightRadius: '5px',
                        },
                      }}
                      fullWidth
                    >
                      <InputLabel id='cat-simple-select-label'>
                        <Trans i18nKey='MyNotFinishAppeals.item20' />
                      </InputLabel>
                      <Select
                        labelId='cat-simple-select-label'
                        id='cat-simple-select'
                        value={CatselectedValue}
                        label={<Trans i18nKey='MyNotFinishAppeals.item20' />}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            width: '55px',
                            height: '55px',
                            background: 'black',
                            fill: 'darkorange',
                            borderBottomRightRadius: '5px',
                            borderTopRightRadius: '5px',
                          },
                        }}
                        // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                        IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                      >
                        {TypeAppealCatArrSlice?.map((item) => {
                          return (
                            <MenuItem
                              value={item.id}
                              key={item.id}
                              onClick={() => {
                                handleCatChange(item);
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{
                        marginTop: '25px',
                        display: CatselectedValue == '' ? 'none' : 'inline-flex',
                        '& .MuiSvgIcon-root': {
                          width: '55px',
                          height: '55px',
                          background: 'black',
                          fill: 'darkorange',
                          borderBottomRightRadius: '5px',
                          borderTopRightRadius: '5px',
                        },
                      }}
                      fullWidth
                    >
                      <InputLabel id='dopcat-simple-select-label'>
                        <Trans i18nKey='MyNotFinishAppeals.item21' />
                      </InputLabel>
                      <Select
                        labelId='dopcat-simple-select-label'
                        id='dopcat-simple-select'
                        value={DopCatselectedValue}
                        label={<Trans i18nKey='MyNotFinishAppeals.item21' />}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            width: '55px',
                            height: '55px',
                            background: 'black',
                            fill: 'darkorange',
                            borderBottomRightRadius: '5px',
                            borderTopRightRadius: '5px',
                          },
                        }}
                        // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                        IconComponent={() => <KeyboardArrowDownRoundedIcon />}
                      >
                        {TypeAppealSubCatArrSlice?.map((item) => {
                          return (
                            <MenuItem
                              value={item.id}
                              key={item.id}
                              onClick={() => {
                                handleDopCatChange(item);
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <ThemeProvider theme={theme}>
                      <Button
                        color='neutral'
                        sx={{
                          mt: '64px',
                          height: '48px',
                          width: '100%',
                          boxShadow: 'none',
                          paddingTop: '15px',
                        }}
                        variant='contained'
                        onClick={handleSelectedItemForward}
                      >
                        <Trans i18nKey='MyNotFinishAppeals.item18' />
                      </Button>
                      <Button
                        color='neutral'
                        sx={{ mt: '24px', height: '48px', width: '100%' }}
                        variant='outlined'
                        onClick={handleCloseModal}
                      >
                        <Trans i18nKey='MyNotFinishAppeals.item5' />
                      </Button>
                    </ThemeProvider>
                  </Box>
                </Box>
              </>
            )
          ) : (
            <>
              <Box sx={{ width: '100%' }}>
                <FormControl fullWidth sx={{ width: '50%' }}>
                  <InputLabel id='demo-simple-select-label'>{selectedValueForSend}</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    disabled
                    label={<Trans i18nKey='MyNotFinishAppeals.item16' />}
                  >
                    <MenuItem value={'вид обращения 1'}>{selectedValue}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                required
                id='outlined-required'
                label={<Trans i18nKey='MyNotFinishAppeals.item23' />}
                // placeholder='Опишите свое обращение. Укажите здесь важные детали и всю дополнительную информацию для решения вашего вопроса.'
                sx={{ mt: '40px', width: '100%' }}
                multiline
                rows={8}
                onChange={(e) => {
                  settextFieldValue(e.target.value);
                }}
                value={textFieldValue}
              />

              <ThemeProvider theme={theme}>
                <Button
                  color='neutral'
                  sx={{ mt: '24px', height: '48px', width: '40%' }}
                  variant='contained'
                  onClick={handleSelectedItemForward2}
                >
                  <Trans i18nKey='MyNotFinishAppeals.item18' />
                </Button>
                {/* <Button
									color='neutral'
									sx={{ mt: "24px", height: "48px", width: "40%", ml: "15px" }}
									variant='outlined'
									onClick={handleItemSendChernovik}
								>
									Сохранить черновик
								</Button> */}
              </ThemeProvider>

              <ThemeProvider theme={Upload}>
                <Tooltip placement='top' title={<Trans i18nKey='MyNotFinishAppeals.item22' />}>
                  <Button
                    color='neutrals'
                    variant='contained'
                    component='label'
                    endIcon={<AttachFileIcon />}
                    disabled={title == 'Нет' ? false : true}
                    sx={{
                      mt: '24px',
                      height: '48px',
                      width: '40%',
                      display: 'inline-block',
                      textAlign: 'center',
                      paddingTop: '15px',
                      textTransform: 'none',
                      marginLeft: '15px',
                      boxShadow: 'none',
                    }}
                  >
                    {title == 'Нет' ? 'Добавить файл (.pdf)' : title}
                    <input
                      hidden
                      accept='application/pdf'
                      type='file'
                      onChange={handleFileUpload}
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </>
          )}

          {ModalStep3 ? (
            <Box sx={{ minWidth: 120 }}>
              <AppealPDFEdit
                CloseModal={handleCloseModal}
                IDforPDFAppeal={'IDforPDFAppeal'}
                TEXTforPDFAppeal={textFieldValue}
                selectedValue={selectedValueForSend}
                CatselectedValue={CatselectedValueForSend}
                DopCatselectedValue={DopCatselectedValueForSend}
                selectedLangForSend={selectedLangForSend}
                appealNumber={appealNumber}
                IDforAppeal={IDforAppeal}
                DeleteID={myDraftAppeal.id}
                title={title}
                fileID={fileID}
                dataB64={dataB64}
                ws={ws}
                DATEforPDFAppeal={'DATEforPDFAppeal'}
                setOpenModal={setOpenModal}
                Trans={Trans}
                // SendApp ealsReload={SendAppealsReload}
                appealDate={myDraftAppeal.dateCreate}
                dispatch={dispatch}
                setModalStep3={setModalStep3}
              />
            </Box>
          ) : null}
        </Box>
      </Modal>
    </>
  );
};

export default AppealDraftEdit;
