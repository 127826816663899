import React from 'react';
import { Container } from './DnD/Container';

const DnDC = ({ handleClose, droppedFiles, setDroppedFiles, setCountItems, setItem }) => {
  return (
    <div>
      <Container
        handleClose={handleClose}
        setDroppedFiles={setDroppedFiles}
        droppedFiles={droppedFiles}
        setItem={setItem}
        setCountItems={setCountItems}
      />
    </div>
  );
};

export default DnDC;
