import React from 'react';

import s from './TaxRegimeSelection.module.css';

import TaxRegimeSelectionBlock from '../../components/TaxRegimeSelectionBlock/TaxRegimeSelectionBlock';

const TaxRegimeSelection = ({ Trans, dispatch }) => {

  return (
    <>

    <div className={s.Wrapper}>
        <div className={s.title}>
            <p>Сервисы - Налоги - Подбор налогового режима</p>
        </div>
        <div className={s.maintitle}>
            <p>Подбор налогового режима</p>
        </div>

        <TaxRegimeSelectionBlock />
    </div>


    </>
  );
};


export default TaxRegimeSelection;
