import {useEffect, useState} from "react";
import { mapApi} from "../../api/api";

export const useBusinessMap = (filterValue, searchValue) => {
  const [items, setItems] = useState([])
  const [allItems, setAllItems] = useState([])
  const [categories, setCategories] = useState([])
  const [filterCategories, setFilterCategories] = useState([])

  const fetchData = () => {
    mapApi.getMapItems()
      .then((data) => {
        setItems(data)
        setAllItems(data)
      })

    mapApi.getCategories()
      .then((data) => {
        data = data.map(el => el = el.categories_sub).filter(Boolean)
        setCategories(data)
        setFilterCategories(data.map(el => ({...el, ['label']: el.sub_name})))
      })
  }

  useEffect(()=>{
    fetchData()
  }, [])

  useEffect(() => {

    setItems(allItems.filter(el => el.name.toLowerCase().includes(searchValue.toLowerCase())))

    if(filterValue.length > 0){
      const category = categories.find(el => el?.sub_name === filterValue)
      if(category?.id){
        setItems( prevState => prevState.filter(el => el.id_category_sub === category?.id))
      }
    }
  }, [filterValue, searchValue])

  return {
    items,
    categories,
    filterCategories,
    fetchData
  }
}