import React from 'react';
import { Container } from './DnD/Container';

const DnDC = ({
  droppedFiles,
  setDroppedFiles,
  setIsLoadingSuccess,
  setLoadErr,
  setLoadSubErr,
  Trans,
}) => {
  return (
    <div>
      <Container
        setDroppedFiles={setDroppedFiles}
        droppedFiles={droppedFiles}
        setIsLoadingSuccess={setIsLoadingSuccess}
        setLoadErr={setLoadErr}
        setLoadSubErr={setLoadSubErr}
        Trans={Trans}
      />
    </div>
  );
};

export default DnDC;
