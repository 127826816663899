import React from 'react';
import ReactPaginate from 'react-paginate';
import s from './Pagination.module.css';

const Pagination = ({ onChange, countPages, marginPagesDisplayed, pageRangeDisplayed }) => {
  return (
    <ReactPaginate
      className={s.root}
      pageClassName={s.page_item}
      pageLinkClassName={s.page_link}
      previousClassName={s.prev}
      previousLinkClassName={s.page_link}
      nextClassName={s.next}
      nextLinkClassName={s.page_link}
      activeClassName={s.active}
      breakClassName={s.page_item}
      breakLinkClassName={s.page_link}
      breakLabel="..."
      nextLabel={
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 13L7 7L1 1"
            stroke="#28323E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onPageChange={(e) => onChange(e.selected + 1)}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={countPages}
      previousLabel={
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 1L1 7L7 13"
            stroke="#28323E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      renderOnZeroPageCount={null}
    />
  );
};

export default Pagination;
