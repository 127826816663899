import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styleModal2 } from '../../../theme';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';
import PDFcreate from './PDFcreate';
const AppealPDF = ({
  ws,
  appealNumber,
  appealDate,
  CloseModal,
  IDforPDFAppeal,
  TEXTforPDFAppeal,
  DATEforPDFAppeal,
  setOpenModal,
  setSendAppealsReload,
  SendAppealsReload,
  textFieldValue,
  selectedValue,
  CatselectedValue,
  DopCatselectedValue,
  title,
  fileID,
  dataB64,
  dispatch,
  setModalStep3,
  Trans,
  selectedLangForSend,
}) => {
  const handleCloseModal = () => {
    CloseModal();
  };

  return (
    <>
      <Modal
        open={true}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal2}>
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='SendAppeals.item11' />
            </div>
          </div>

          <Box sx={{ minWidth: 120 }}>
            <PDFcreate
              appealDate={appealDate}
              CatselectedValue={CatselectedValue}
              DopCatselectedValue={DopCatselectedValue}
              appealNumber={appealNumber}
              selectedValue={selectedValue}
              fileID={fileID}
              dataB64={dataB64}
              ws={ws}
              handleCloseModal={handleCloseModal}
              IDforPDFAppeal={IDforPDFAppeal}
              TEXTforPDFAppeal={TEXTforPDFAppeal}
              DATEforPDFAppeal={DATEforPDFAppeal}
              title={title}
              Trans={Trans}
              dispatch={dispatch}
              setOpenModal={setOpenModal}
              setSendAppealsReload={setSendAppealsReload}
              SendAppealsReload={SendAppealsReload}
              setModalStep3={setModalStep3}
              selectedLangForSend={selectedLangForSend}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AppealPDF;
