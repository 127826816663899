import React from 'react';
import { useState } from 'react';

import s from './AnnouncedPurchasesBlock.module.css'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const AnnouncedPurchasesBlock = ({ Trans, dispatch }) => {

    const [resultWrapper, setResultWrapper] = useState('none');
    const [resultWrapperWidthState, setResultWrapperWidthState] = useState(600);

    const FirstTitleWidth = resultWrapperWidthState / 100 * 75 
    const SecondTitleWidth = (resultWrapperWidthState -100)/ 100 * 25 

    const resultWrapperWidth = React.useRef(null);

    const programms = [
        { label: 'Закупка 1', year: 1 },
        { label: 'Закупка 2', year: 2 },
      ];

      const GridRowsProp = [
        { id: 1, col1: 'Объявление 1', col2: '' },
        { id: 2, col1: 'Объявление 2', col2: '' },
        { id: 4, col1: 'Объявление 3', col2: '' },
      ];

    const GridColDef = [
        { field: 'col1', headerName: 'Объявления о закупках', width: FirstTitleWidth},
        { field: 'col2', headerName: 'Критерий 2', width: SecondTitleWidth},
        ];

      const theme = createTheme({
        palette: {
          neutral: {
            main: '#FF7F00',
            contrastText: '#fff',
          },
        },
      });

      const handleClick = () => {
        setResultWrapper('block')
      }

      React.useEffect(() => {
        setResultWrapperWidthState(resultWrapperWidth.current.getBoundingClientRect().width);
    }, [resultWrapper]);

  return (
    <>

        <div className={s.questionBlock}>
        <div className={s.questionBlockWrapper}>

        <div className={s.questionInputWrapper}>

            <div className={s.email_programmWrapper}>

                <div className={s.programm}>
                <div className={s.selectTitle}>
                Вид закупок
                </div>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={programms}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Выбор типа закупок" />}
                />
                </div>
            </div>

            <div className={s.sendbuttonWrapper}>

                <div className={s.sendbutton}>
                <ThemeProvider theme={theme}>
                <Button color="neutral" sx={{ height: '100%', width: '100%' }} variant="contained" onClick={() => {handleClick()}}>Найти</Button>
                </ThemeProvider>
                </div>

            </div>

            <div className={s.resultWrapper} style={{display: `${resultWrapper}`}} ref={resultWrapperWidth}>
            
            <div className={s.resultTitle}>
                Результаты поиска
            </div>

            <div style={{ height: 400, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid 
                    rows={GridRowsProp} 
                    columns={GridColDef}    
                    />
                </div>
            </div>
            </div>

            </div>

        </div>
        

        </div>
        </div>


    </>
  );
};


export default AnnouncedPurchasesBlock;
