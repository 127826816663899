function list(files) {
  const label = (file) =>
    `'${file.name}' of size '${file.size}' and type '${file.type}'`;
  return files.map((file) => <li key={file.name}>{label(file)}</li>);
}

export const FileList = ({ files }) => {
  if (files.length === 0) {
    return <div></div>;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const fileList = useMemo(() => list(files), [files]);
  return (
    <div style={{ marginTop: "20px" }}>
      Количество загружаемых файлов: {files.length}
    </div>
  );
};
