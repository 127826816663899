import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { appealsAPI } from '../../api/api';
import { CustomNoRowsOverlay } from '../../theme';
import { ruRU } from '../../common/helpers/ruRU';
import { kzKZ } from '../../common/helpers/kzKZ';
import { enEN } from '../../common/helpers/enEN';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import clsx from 'clsx';

import AppealDelete from './AppealDelete/AppealDelete';
import AppealViewPDF from '../MyAppeals/AppealViewPDF/AppealViewPDF';
import AppealDraftEdit from './AppealDraftEdit/AppealDraftEdit';

const MyNotFinishAppeals = ({ Trans, dispatch, ws }) => {
  const width = useSelector((state) => state.store.mainSlice.MyAppealsWidth);
  const [modal, setModal] = useState('none');
  const [IDforAppeal, setIDforAppeal] = useState('none');
  const [myDraftAppeal, setMyDraftAppeal] = React.useState([]);
  const [getMyDraftAppeals, setGetMyDraftAppeals] = React.useState([]);
  const [myDraftAppeals, setMyDraftAppeals] = React.useState([]);
  const user = useSelector((state) => state.store.mainSlice.user);
  const lang = localStorage.getItem('lang');

  let gridButtonWidth = (width - 2) / 4;
  let DataGridWidth = width - gridButtonWidth;

  React.useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    const appealsResponse = appealsAPI.fetchMyDraftAppeals(fd);
    appealsResponse.then((res) => setGetMyDraftAppeals(res)).catch((err) => console.log(err));
  }, [modal]);

  React.useEffect(() => {
    const newAppeals = getMyDraftAppeals.map((res) => {
      return createData(
        res.id,
        res.title,
        res.dateCreate,
        lang == 'ru'
          ? 'Редактирование'
          : lang == 'kz'
          ? 'Редакциялау'
          : lang == 'en'
          ? 'Editing'
          : 'Редактирование',
        lang == 'ru' ? 'Удалить' : lang == 'kz' ? 'Жою' : lang == 'en' ? 'Remove' : 'Удалить',
        res.category,
        res.subcategory,
        res.text,
        res.language,
      );
    });
    setMyDraftAppeals(newAppeals);
  }, [getMyDraftAppeals]);

  const GridColDef = [
    {
      field: 'title',
      headerName: <Trans i18nKey='MyNotFinishAppeals.item1' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dateCreate',
      headerName: <Trans i18nKey='MyNotFinishAppeals.item2' />,
      type: 'date',
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'editAppeal',
      headerName: '',
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          delete: params.value == 'Редактирование',
          delete1: params.value == 'Редакциялау',
          delete2: params.value == 'Editing',
        });
      },
    },
    {
      field: 'deleteAppeal',
      headerName: '',
      width: gridButtonWidth / 2,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,

      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          delete: params.value == 'Удалить',
          delete1: params.value == 'Жою',
          delete2: params.value == 'Remove',
        });
      },
    },
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          mb: '10px',
          ml: '10px',
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput
              .split(',')
              .map((value) => value.trim())
              .filter((value) => value !== '')
          }
        />
      </Box>
    );
  }

  const SelectionHandler = (newSelection) => {
    newSelection.field == 'deleteAppeal'
      ? setModal('Удалить')
      : newSelection.field == 'editAppeal'
      ? setModal('Редактировать')
      : newSelection.field == 'title'
      ? setModal('pdf')
      : newSelection.field == 'dateCreate'
      ? setModal('pdf')
      : setModal('none');

    setIDforAppeal(newSelection.row.id);
    setMyDraftAppeal(newSelection.row);
  };

  const CloseModal = () => {
    setModal('none');
  };

  function createData(
    id,
    title,
    dateCreate,
    editAppeal,
    deleteAppeal,
    category,
    subcategory,
    text,
    language,
  ) {
    return {
      id,
      title,
      dateCreate,
      editAppeal,
      deleteAppeal,
      category,
      subcategory,
      text,
      language,
    };
  }

  return (
    <>
      <div style={{ height: 600, width: DataGridWidth + gridButtonWidth / 2 }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: 600,
                width: '100%',
                '& .super-app.delete': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff7f00',
                  fontWeight: '600',
                  color: '#ff7f00',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.delete1': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff7f00',
                  fontWeight: '600',
                  color: '#ff7f00',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.delete2': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff7f00',
                  fontWeight: '600',
                  color: '#ff7f00',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
                '& .super-app.positive': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app-theme--header': {
                  paddingLeft: '10px',
                  backgroundColor: '#e0e0e0',
                },
                '& .Mui-selected': {
                  backgroundColor: '#ffce9e !important',
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#ffce9e !important',
                    fontWeight: 600,
                  },
                },
              }}
            >
              <DataGrid
                localeText={lang == 'ru' ? ruRU : lang == 'kz' ? kzKZ : lang == 'en' ? enEN : null}
                rows={myDraftAppeals}
                columns={GridColDef}
                onCellClick={(newSelection) => {
                  SelectionHandler(newSelection);
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterLogicOperator: GridLinkOperator.Or,
                    },
                  },
                }}
                components={{
                  Toolbar: QuickSearchToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
      {modal == 'Удалить' ? (
        <AppealDelete
          Trans={Trans}
          dispatch={dispatch}
          CloseModal={CloseModal}
          IDforDeleteAppeal={IDforAppeal}
        />
      ) : modal == 'pdf' ? (
        <AppealViewPDF
          Trans={Trans}
          CloseModal={CloseModal}
          IDforAppeal={IDforAppeal}
          IDforPDFAppeal='none'
          dispatch={dispatch}
        />
      ) : modal == 'Редактировать' ? (
        <AppealDraftEdit
          Trans={Trans}
          dispatch={dispatch}
          CloseModal={CloseModal}
          IDforAppeal={IDforAppeal}
          myDraftAppeal={myDraftAppeal}
          ws={ws}
        />
      ) : null}
    </>
  );
};

export default MyNotFinishAppeals;
