import React from 'react';

import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  ruRU,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import { Trans } from 'react-i18next';

import clsx from 'clsx';

import { confirm } from 'react-confirm-box';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import s from './Published.module.css';
import DnDC from './DnDC';
import { newsApi } from '../../../api/api';
import LoadingDots from '../../../components/LoadingDots/LoadingDots';

import { FileList } from './DnD/FileList';
import { successData } from '../../../common/helpers/sliceToasts';

import 'suneditor/dist/css/suneditor.min.css';
import suneditor from 'suneditor';

import { ru } from 'suneditor/src/lang';
import plugins from 'suneditor/src/plugins';

const StyleNoActiveBtn = {
  background: '#D8D8D8',
  color: 'white',
  cursor: 'auto',
  border: 'none',
};

const styleAddNews = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 832,
  height: '90vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  boxSizing: 'border-box',
  padding: '36px 32px 32px 32px',
};

export default function Published({ items, setItems }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const userId = useSelector((state) => state.store.mainSlice.user.status_user);
  const [openAddNews, setOpenAddNews] = React.useState(false);
  const [openEditNews, setOpenEditNews] = React.useState(false);
  const [select, setSelect] = React.useState('');
  const [droppedFiles, setDroppedFiles] = React.useState([]);
  const [isLoadingPost, setIsLoadingPost] = React.useState(false);
  const [loadErr, setLoadErr] = React.useState(false);
  const [loadSubErr, setLoadSubErr] = React.useState(false);
  const [isLoadingSuccess, setIsLoadingSuccess] = React.useState(false);
  const [allNews, setAllNews] = React.useState([]);

  const [inputSearch, setInputSearch] = React.useState('');

  const [titlePost, setTitlePost] = React.useState('');
  const [posts, setPosts] = React.useState([]);

  const handleOpenAddNews = () => setOpenAddNews(true);
  const handleCloseAddNews = (e) => {
    setOpenAddNews(false);
  };

  const handleOpenEditNews = () => setOpenEditNews(true);
  const handleCloseEditNews = (e) => {
    console.log(e);
    e.preventDefault();
    setOpenEditNews(false);
    const obj = {
      page: 'All',
      status: '',
    };
    const res = newsApi.fetchNews(obj);
    res.then((res) => {
      setItems(res[1]);
    });
    setPosts([]);
  };

  const [editorContent, setEditorContent] = React.useState(false);
  React.useEffect(() => {
    if (openAddNews) {
      setTimeout(() => {
        const editor = suneditor.create(document.getElementById('textArea'), {
          plugins: plugins,
          buttonList: [
            ['undo', 'redo'],
            ['fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['removeFormat'],
            '/', // Line break
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video'],
            ['paragraphStyle', 'blockquote'],
          ],
          lang: ru,
          defaultStyle: 'font-family: tahoma; font-size: 14px;font-weight:normal',
        });
        editor.onChange = function (contents, core) {
          setEditorContent(contents);
        };
      }, 0);
    }
    if (openEditNews) {
      setTimeout(() => {
        const editor = suneditor.create(document.getElementById('textAreaEdit'), {
          plugins: plugins,
          buttonList: [
            ['undo', 'redo'],
            ['fontSize', 'formatBlock'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['removeFormat'],
            '/', // Line break
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video'], // You must add the 'katex' library at
            ['fullScreen', 'showBlocks', 'codeView'],
            ['paragraphStyle', 'blockquote'],
          ],
          lang: ru,
          defaultStyle: 'font-family: tahoma; font-size: 14px;font-weight:normal',
          value: posts.length && posts[0].text,
        });
        editor.onChange = function (contents, core) {
          setEditorContent(contents);
        };
      }, 0);
    }
  }, [openAddNews, openEditNews]);

  React.useEffect(() => {
    if (items?.length > 0) {
      const newNotif = items
        ?.map((res) => {
          return createData(
            res.dateCreate,
            res.id,
            res.img,
            res.status === '0' ? 'Не опубликовано' : 'Опубликовано',
            res.text,
            res.title,
            res.user_id,
          );
        })
        .sort((a, b) => b.id - a.id);
      setAllNews(newNotif);
    }
  }, [items]);

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const onAddPost = (e) => {
    e.preventDefault();
    setIsLoadingPost(true);
    const fd = new FormData();
    fd.append('id', userId);
    fd.append('title', titlePost);
    fd.append('text', editorContent);
    for (let x = 0; x < droppedFiles.length; x++) {
      if (
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'png' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'jpeg' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'jpg' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'avi' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'mp4' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'mkv'
      ) {
        const fileSizeLt2 = String(droppedFiles[x].size / 1024 / 1024);
        if (fileSizeLt2.split('.')[0] >= 50) {
          setLoadErr('Не удалось загрузить файл!');
          setLoadSubErr('Размер файла не должен превышать 50 мб.');
          setIsLoadingPost(false);
          return;
        }
        fd.append('attachment[]', droppedFiles[x]);
      } else {
        setLoadErr('Не удалось загрузить файл!');
        setLoadSubErr(
          'Тип файла недоступен для загрузки. Выберите файл формата png, jpeg, avi, mp4, mkv.',
        );
        setIsLoadingPost(false);
      }
    }
    const response = newsApi.addPost(fd);
    response.then((res) => {
      setDroppedFiles([]);
      reset();
      setIsLoadingPost(false);
      setIsLoadingSuccess('Загрузка прошла успешно!');
      const obj = {
        page: 'All',
        status: '',
      };
      const resGet = newsApi.fetchNews(obj);
      resGet.then((res) => {
        setItems(res[1]);
      });
    });
  };

  function createData(dateCreate, id, img, status, text, title, user_id) {
    return { dateCreate, id, img, status, text, title, user_id };
  }

  const customRender = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className={s.wrapModalConfirm}>
          <div className={s.modalConfirm}>
            <div className={s.modalConfirmMessage}> {message} </div>
            <div className={s.modalConfirmButton}>
              <button className={s.btnAddNews} onClick={onConfirm}>
                <Trans i18nKey="News.newsAdmin.item24" />
              </button>
              <button className={s.btnReset} onClick={onCancel}>
                <Trans i18nKey="News.newsAdmin.item25" />
              </button>
            </div>
          </div>
        </div>
      );
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
          }}>
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
        <div style={{ marginTop: '50px' }}></div>
        <GridToolbarFilterButton
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
            color: '#454545',
          }}
        />
      </GridToolbarContainer>
    );
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const GridColDef = [
    {
      field: 'title',

      headerName: <Trans i18nKey="News.newsAdmin.item14" />,
      minWidth: 190,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno: params.row.status === 'Не просмотрено',
        });
      },
    },
    {
      field: 'text',
      headerName: <Trans i18nKey="News.newsAdmin.item15" />,
      minWidth: 190,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno: params.row.status === 'Не просмотрено',
        });
      },
    },

    {
      field: 'dateCreate',
      headerName: <Trans i18nKey="News.newsAdmin.item16" />,
      type: 'date',

      minWidth: 190,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          // for_1_4_columns_prosmotreno: params.row.status == 'Просмотрено',
          for_1_4_ne_prosmotreno: params.row.status === 'Не просмотрено',
        });
      },
    },
    {
      field: 'status',
      headerName: <Trans i18nKey="News.newsAdmin.item17" />,
      minWidth: 150,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          prosmotreno: params.value === 'Не опубликовано',
          ne_prosmotreno: params.value === 'Опубликовано',
        });
      },
    },
  ];

  const SelectionHandler = (newSelection) => {
    posts.length === 0 && setPosts((posts) => [...posts, newSelection.row]);
    filterPosts(newSelection);
  };

  const filterPosts = (newSelection) => {
    posts.map((item) => {
      if (item.id !== newSelection.row.id) {
        setPosts((posts) => [...posts, newSelection.row]);
      } else {
        setPosts(posts.filter((item) => item.id !== newSelection.row.id));
      }
    });
  };

  const deletePost = async (options) => {
    const result = await confirm(<Trans i18nKey="News.newsAdmin.item19" />, options);
    console.log(posts);
    posts.map((item) => {
      if (result) {
        const fd = new FormData();
        fd.append('id', item.id);
        const response = newsApi.delPost(fd);
      }
    });
    successData(<Trans i18nKey="News.newsAdmin.item20" />);
    const obj = {
      page: 'All',
      status: '',
    };
    const res = newsApi.fetchNews(obj);
    res.then((res) => {
      setItems(res[1]);
    });
    setPosts([]);
  };

  const publication = () => {
    const obj = {
      page: 'All',
      status: '',
    };

    posts?.map((item) => {
      const fd = new FormData();
      fd.append('id', item.id);
      fd.append('status', item.status === 'Не опубликовано' && 1);
      const response = newsApi.changeStatus(fd);
      response.then((res) => {
        const resS = newsApi.fetchNews(obj);
        resS.then((res) => {
          setItems(res[1]);
        });
      });
      successData(<Trans i18nKey="News.newsAdmin.item21" />);
    });
    setPosts([]);
  };

  const EditPost = (e) => {
    e.preventDefault();

    setIsLoadingPost(true);
    const fd = new FormData();
    fd.append('id', posts[0].id);
    fd.append('title', !titlePost.length ? document.getElementById('titleId').value : titlePost);
    fd.append('text', editorContent === false ? posts[0].text : editorContent);
    for (let x = 0; x < droppedFiles.length; x++) {
      if (
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'png' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'jpeg' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'jpg'
      ) {
        const fileSizeLt2 = String(droppedFiles[x].size / 1024 / 1024);
        if (fileSizeLt2.split('.')[0] >= 5) {
          setLoadErr(<Trans i18nKey="News.newsAdmin.item23" />);
          setLoadSubErr('Размер файла не должен превышать 5 мб.');
          setIsLoadingPost(false);
          return;
        }
        fd.append('attachment[]', droppedFiles[x]);
      } else {
        setLoadErr('Не удалось загрузить файл!');
        setLoadSubErr('Тип файла недоступен для загрузки. Выберите файл формата png, jpeg');
        setIsLoadingPost(false);
      }
    }
    // fd.append('attachment[]', droppedFiles?.files[0]?.name && droppedFiles?.files[0]?.name);

    const response = newsApi.editNews(fd);
    response.then((res) => {
      setDroppedFiles([]);
      reset();
      setIsLoadingPost(false);
      setIsLoadingSuccess(<Trans i18nKey="News.newsAdmin.item22" />);
      const obj = {
        page: 'All',
        status: '',
      };
      const resGet = newsApi.fetchNews(obj);
      resGet.then((res) => {
        setItems(res[1]);
      });
    });
  };

  return (
    <>
      {/* <div className={s.headerPublished}>
        <div>
          <Trans i18nKey='News.newsAdmin.item1' />
          <div className={s.filter}>
            <Box sx={{ width: 256, height: 40 }}>
              <FormControl fullWidth>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={select}
                  label=''
                  onChange={handleChange}
                >
                  <MenuItem value={10}>1</MenuItem>
                  <MenuItem value={20}>2</MenuItem>
                  <MenuItem value={30}>3</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className={s.search}>
          <input
            type='text'
            placeholder='Поиск'
            onChange={(e) => searchInput(e)}
            value={inputSearch}
          />
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M19.9399 18.5624L13.4474 12.0699C14.4549 10.7675 14.9999 9.17496 14.9999 7.49997C14.9999 5.49498 14.2174 3.61498 12.8024 2.19749C11.3874 0.779996 9.50246 0 7.49997 0C5.49748 0 3.61248 0.782496 2.19749 2.19749C0.779996 3.61248 0 5.49498 0 7.49997C0 9.50246 0.782496 11.3874 2.19749 12.8024C3.61248 14.2199 5.49498 14.9999 7.49997 14.9999C9.17496 14.9999 10.765 14.4549 12.0674 13.4499L18.5599 19.9399C18.579 19.959 18.6016 19.9741 18.6264 19.9844C18.6513 19.9947 18.678 20 18.7049 20C18.7318 20 18.7585 19.9947 18.7834 19.9844C18.8083 19.9741 18.8309 19.959 18.8499 19.9399L19.9399 18.8524C19.959 18.8334 19.9741 18.8108 19.9844 18.7859C19.9947 18.761 20 18.7343 20 18.7074C20 18.6805 19.9947 18.6538 19.9844 18.6289C19.9741 18.6041 19.959 18.5815 19.9399 18.5624ZM11.46 11.46C10.4 12.5174 8.99496 13.0999 7.49997 13.0999C6.00497 13.0999 4.59998 12.5174 3.53998 11.46C2.48249 10.4 1.89999 8.99496 1.89999 7.49997C1.89999 6.00497 2.48249 4.59748 3.53998 3.53998C4.59998 2.48249 6.00497 1.89999 7.49997 1.89999C8.99496 1.89999 10.4025 2.47999 11.46 3.53998C12.5174 4.59998 13.0999 6.00497 13.0999 7.49997C13.0999 8.99496 12.5174 10.4025 11.46 11.46Z'
              fill='#28323E'
            />
          </svg>
        </div>
        <div>
          <button className={s.btnReset} onClick={resetFilter}>
            <Trans i18nKey='News.newsAdmin.item2' />
          </button>
        </div>
      </div> */}
      <div className={s.interactions}>
        <div>
          {posts.length > 0 ? (
            <button className={s.btnAddNews} onClick={posts.length && publication}>
              <Trans i18nKey="News.newsAdmin.item3" />
            </button>
          ) : (
            <button className={s.btnAddNews} onClick={handleOpenAddNews}>
              <Trans i18nKey="News.newsAdmin.item4" />
            </button>
          )}
        </div>
        <div>
          <button
            className={s.btnEditNews}
            style={!posts.length || posts.length > 1 ? StyleNoActiveBtn : null}
            onClick={() => posts.length && handleOpenEditNews()}>
            <Trans i18nKey="News.newsAdmin.item5" />
          </button>
        </div>
        <div>
          <button
            className={s.btnDeleteNews}
            style={!posts.length ? StyleNoActiveBtn : null}
            onClick={() => posts.length && deletePost(customRender)}>
            <Trans i18nKey="News.newsAdmin.item6" />
          </button>
        </div>
      </div>
      <div className={s.table}>
        <Box
          sx={{
            height: 600,
            width: '100%',
            '& .super-app-theme--header': {
              paddingLeft: '10px',
              backgroundColor: '#e0e0e0',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer ': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeaderDraggableContainer ': {
              backgroundColor: '#e0e0e0',
            },
          }}>
          <DataGrid
            rows={allNews}
            columns={GridColDef}
            pstatusFieldSize={5}
            onCellClick={(newSelection) => {
              SelectionHandler(newSelection);
            }}
            rowsPerPstatusFieldOptions={[5]}
            checkboxSelection
            experimentalFeatures={{ newEditingApi: true }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            pagination
            components={{
              // Toolbar: QuickSearchToolbar,
              Toolbar: CustomToolbar,
              Pagination: CustomPagination,
            }}
          />
        </Box>
      </div>
      <Modal
        open={openAddNews}
        onClose={handleCloseAddNews}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styleAddNews}>
          <div className={s.close_add_news_modal} onClick={handleCloseAddNews}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132"
                stroke="#292929"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={s.title_add_news_modal}>
            <div className={s.title_add_news_modal}>
              <Trans i18nKey="News.newsAdmin.item4" />
            </div>
            <form>
              <div className={s.wrapperFormAddNews}>
                <div className={s.labelinputAddNews}>
                  <label htmlFor="">
                    <Trans i18nKey="News.newsAdmin.item7" />
                  </label>
                  <TextField
                    style={{ width: '100%' }}
                    onChange={(e) => setTitlePost(e.target.value)}
                  />
                  <div className={s.validate_form_add_news}></div>
                  <div className={s.dnd}>
                    <DndProvider backend={HTML5Backend}>
                      <DnDC
                        droppedFiles={droppedFiles}
                        setDroppedFiles={setDroppedFiles}
                        setIsLoadingSuccess={setIsLoadingSuccess}
                        setLoadSubErr={setLoadSubErr}
                        setLoadErr={setLoadErr}
                        Trans={Trans}
                      />
                    </DndProvider>
                    <FileList files={droppedFiles} />
                  </div>
                  <div className={s.validate_form_add_news}>
                    {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                  </div>
                </div>
              </div>
              <div className={s.labelinputAddNewsText}>
                <label htmlFor="">
                  <Trans i18nKey="News.newsAdmin.item8" />
                </label>
                {/* <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  style={{ width: '100%', height: '216px' }}
                  {...register('text', {
                    required: 'Введите текст',
                  })}
                /> */}
                <textarea id="textArea" style={{ width: '100%', height: 300 }}></textarea>
                <div className={s.validate_form_add_news}></div>
              </div>

              {isLoadingPost && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <LoadingDots />
                </div>
              )}
              {loadErr && <div className={s.errorLoad}>{loadErr}</div>}
              {loadSubErr && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: 10,
                  }}>
                  {loadSubErr}
                </div>
              )}
              {isLoadingSuccess && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <div>{isLoadingSuccess}</div>
                </div>
              )}
              <div className={s.groupBtnAddNews}>
                <button
                  className={s.btnSavePost}
                  onClick={(e) => onAddPost(e)}
                  style={{ width: 176 }}>
                  <Trans i18nKey="News.newsAdmin.item9" />
                </button>
                <button className={s.btnReset} onClick={handleCloseAddNews} style={{ width: 176 }}>
                  <Trans i18nKey="News.newsAdmin.item10" />
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEditNews}
        onClose={handleCloseEditNews}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styleAddNews}>
          <div className={s.close_add_news_modal} onClick={handleCloseEditNews}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132"
                stroke="#292929"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={s.title_add_news_modal}>
            <div className={s.title_add_news_modal}>
              <Trans i18nKey="News.newsAdmin.item4" />
            </div>
            <form>
              <div className={s.wrapperFormAddNews}>
                <div className={s.labelinputAddNews}>
                  <label htmlFor="">
                    <Trans i18nKey="News.newsAdmin.item7" />
                  </label>
                  <TextField
                    style={{ width: '100%' }}
                    onChange={(e) => setTitlePost(e.target.value)}
                    defaultValue={posts[0]?.title}
                    id="titleId"
                  />
                  <div className={s.validate_form_add_news}>
                    {errors?.title && <span>{errors?.title?.message || 'Error!'}</span>}
                  </div>
                </div>
              </div>
              <div className={s.dnd}>
                <DndProvider backend={HTML5Backend}>
                  <DnDC
                    droppedFiles={droppedFiles}
                    setDroppedFiles={setDroppedFiles}
                    setIsLoadingSuccess={setIsLoadingSuccess}
                    setLoadSubErr={setLoadSubErr}
                    setLoadErr={setLoadErr}
                    Trans={Trans}
                  />
                </DndProvider>
                <FileList files={droppedFiles} />
              </div>
              <div className={s.labelinputAddNewsText} style={{ marginTop: '20px' }}>
                <label htmlFor="">
                  {' '}
                  <Trans i18nKey="News.newsAdmin.item8" />
                </label>
                {/* <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  style={{ width: '100%', height: '216px' }}
                  {...register('text', {
                    required: 'Введите текст',
                  })}
                  defaultValue={posts[0]?.text}
                /> */}
                <textarea id="textAreaEdit" style={{ width: '100%', height: 300 }}></textarea>
                <div className={s.validate_form_add_news}>
                  {/* {errors?.text && <span>{errors?.text?.message || 'Error!'}</span>} */}
                </div>
              </div>

              {isLoadingPost && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <LoadingDots />
                </div>
              )}
              <div>
                <div style={{ margin: '10px 0px' }}>
                  <Trans i18nKey="News.newsAdmin.item18" />
                </div>
                <img
                  src={`https://biznes.15000pvl.kz/file/news/${posts[0]?.img.trim()}`}
                  alt="files"
                  width={150}
                />
              </div>
              {loadErr && <div className={s.errorLoad}>{loadErr}</div>}
              {loadSubErr && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: 10,
                  }}>
                  {loadSubErr}
                </div>
              )}
              {isLoadingSuccess && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <div>{isLoadingSuccess}</div>
                </div>
              )}
              <div className={s.groupBtnAddNews}>
                <button
                  className={s.btnSavePost}
                  onClick={(e) => EditPost(e)}
                  style={{ width: 176 }}>
                  <Trans i18nKey="News.newsAdmin.item9" />
                </button>
                <button
                  className={s.btnReset}
                  onClick={(e) => handleCloseEditNews(e)}
                  style={{ width: 176 }}>
                  <Trans i18nKey="News.newsAdmin.item10" />
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}
