import React from 'react';
import { useSelector } from 'react-redux';
import { appealsAPI } from '../../api/api';
import { styleModal, theme, CustomNoRowsOverlay } from '../../theme';
import { ruRU } from '../../common/helpers/ruRU';
import { kzKZ } from '../../common/helpers/kzKZ';
import { enEN } from '../../common/helpers/enEN';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarFilterButton,
  GridToolbarContainer,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import Modal from '@mui/material/Modal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';

import { setPDFModal } from '../../redux/slices/mainSlice';

import AppealPDF from './AppealPDF/AppealPDF';
import AppealViewPDF from './AppealViewPDF/AppealViewPDF';

import s from '../../pages/AppealsPage/AppealsPage.module.css';

const MyAppeals = ({ Trans, dispatch }) => {
  const width = useSelector((state) => state.store.mainSlice.MyAppealsWidth);
  const ReloadAppeals = useSelector((state) => state.store.appealsSlice.ReloadAppeals);
  const user = useSelector((state) => state.store.mainSlice.user);
  const modal = useSelector((state) => state.store.mainSlice.PDFmodal);

  const [getMyAppeals, setGetMyAppeals] = React.useState([]);
  const [Appeals, setAppeals] = React.useState([]);
  const [IDforPDFAppeal, setIDforPDFAppeal] = React.useState('none');
  const [TEXTforPDFAppeal, setTEXTforPDFAppeal] = React.useState('none');
  const [DATAforPDFAppeal, setDATAforPDFAppeal] = React.useState('none');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const lang = localStorage.getItem('lang');

  let gridButtonWidth = (width - 50) / 5;

  React.useEffect(() => {
    console.log(modal);
  }, [modal]);

  React.useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    const appealsResponse = appealsAPI.fetchMyAppeals(fd);
    appealsResponse.then((res) => setGetMyAppeals(res)).catch((err) => console.log(err));
  }, [ReloadAppeals]);

  // React.useEffect(() => {
  //   const fd = new FormData();
  //   fd.append('id', user.id);
  //   if (isNewAppeals) {
  //     if (user?.status_user === '2' || user?.status_user === '1') {
  //       dispatch(notificationsAll(fd));
  //     }
  //   }
  // }, [isNewAppeals]);

  React.useEffect(() => {
    const newAppeals = getMyAppeals?.map((res) => {
      return createData(
        res.id,
        res.title,
        res.refusal,
        res.text,
        res.id,
        res.dateCreate,
        res.status === '0'
          ? 'В очереди'
          : res.status === '1'
          ? 'Обращение зарегистрировано'
          : res.status === '2'
          ? 'Обращение на исполнении'
          : res.status === '3'
          ? 'Исполнено'
          : res.status === '4'
          ? 'Отказано'
          : 'Не опоределено',
        'PDF',
      );
    });
    setAppeals(newAppeals);
  }, [getMyAppeals]);

  const GridColDef = [
    {
      field: 'title',
      headerName: <Trans i18nKey='MyAppeals.item1' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
    },
    {
      field: 'number',
      headerName: <Trans i18nKey='MyAppeals.item2' />,
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
    },
    {
      field: 'dateCreate',
      headerName: <Trans i18nKey='MyAppeals.item3' />,
      type: 'date',
      width: gridButtonWidth,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
    },
    {
      field: 'status',
      headerName: <Trans i18nKey='MyAppeals.item4' />,
      width: gridButtonWidth + gridButtonWidth / 2 - 18,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      valueGetter: (params) => {
        if (params.value == 'В очереди') {
          if (lang == 'ru') {
            return 'В очереди';
          }
          if (lang == 'en') {
            return 'In the queue';
          }
          if (lang == 'kz') {
            return 'Кезекте';
          }
        } else if (params.value == 'Обращение на исполнении') {
          if (lang == 'ru') {
            return 'Обращение на исполнении';
          }
          if (lang == 'en') {
            return 'Appeal on execution';
          }
          if (lang == 'kz') {
            return 'Орындаудағы өтініш';
          }
        } else if (params.value == 'Обращение зарегистрировано') {
          if (lang == 'ru') {
            return 'Обращение зарегистрировано';
          }
          if (lang == 'en') {
            return 'The appeal is registered';
          }
          if (lang == 'kz') {
            return 'Өтініш тіркелді';
          }
        } else if (params.value == 'Исполнено') {
          if (lang == 'ru') {
            return 'Исполнено';
          }
          if (lang == 'en') {
            return 'Executed';
          }
          if (lang == 'kz') {
            return 'Орындалды';
          }
        } else if (params.value == 'Отказано') {
          if (lang == 'ru') {
            return 'Отказано';
          }
          if (lang == 'en') {
            return 'Refused';
          }
          if (lang == 'kz') {
            return 'Бас тартылды';
          }
        }
      },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.row.status == 'В очереди',
          positive1: params.row.status == 'Обращение зарегистрировано',
          positive2: params.row.status == 'Обращение на исполнении',
          positive3: params.row.status == 'Исполнено',
          refNeg1: params.row.status == 'Отказано',
          refNeg2: params.row.status == 'Не определено',
        });
      },
    },
    {
      field: 'pdf',
      headerName: 'PDF',
      type: 'date',
      width: gridButtonWidth / 2,
      headerClassName: 'super-app-theme--pdf',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,

      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          pdf: params.value == 'PDF',
        });
      },
    },
  ];

  const createData = (id, title, refusal, text, number, dateCreate, status, pdf) => {
    return { id, title, refusal, text, number, dateCreate, status, pdf };
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
          }}
        >
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
        <div style={{ marginTop: '50px' }}></div>
        <GridToolbarFilterButton
          sx={{
            p: 0.5,
            pb: 0,
            mb: '10px',
            ml: '10px',
            color: '#454545',
          }}
        />
      </GridToolbarContainer>
    );
  };

  const SelectionHandler = (newSelection) => {
    setIDforPDFAppeal(newSelection.row.id);
    setTEXTforPDFAppeal(newSelection.row.text);
    setDATAforPDFAppeal(newSelection.row);
    console.log(newSelection.row);
    newSelection.field == 'pdf'
      ? dispatch(setPDFModal('pdf'))
      : newSelection.field == 'status'
      ? newSelection.row.status == 'Исполнено'
        ? dispatch(setPDFModal('pdfAnswer'))
        : newSelection.row.status == 'Отказано'
        ? dispatch(setPDFModal('Refusal'))
        : dispatch(setPDFModal('none'))
      : dispatch(setPDFModal('none'));
  };

  const CloseModal = () => {
    dispatch(setPDFModal('none'));
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const handlePopoverOpen = () => {
    setAnchorEl(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div style={{ height: 585, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: 585,
                width: '100%',
                '& .super-app.negative': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#1a3e72',
                  fontWeight: '600',
                },
                '& .super-app.refNeg1': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff0052',
                  fontWeight: '600',
                  background: '#ef3f3f45',
                },
                '& .super-app.refNeg2': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff0000',
                  fontWeight: '600',
                },
                '& .super-app.positive1': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                },
                '& .super-app.positive2': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#b1ad2f',
                  fontWeight: '600',
                },
                '& .super-app.positive3': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#47AF6F',
                  fontWeight: '600',
                  background: '#3fff0029',
                },
                '& .super-app-theme--header': {
                  backgroundColor: '#e0e0e0',
                  paddingLeft: '10px',
                },
                '& .super-app-theme--pdf': {
                  backgroundColor: '#ff8c00',
                  paddingLeft: '10px',
                },
                '& .super-app.pdf': {
                  // backgroundColor: 'rgba(157, 255, 118, 0.49)',
                  color: '#ff7f00',
                  fontWeight: '600',
                  color: '#ff7f00',
                  outlineOffset: '-10px',
                  borderRadius: '100px',
                  outline: 'solid',
                  cursor: 'pointer',
                },
              }}
            >
              <DataGrid
                localeText={lang == 'ru' ? ruRU : lang == 'kz' ? kzKZ : lang == 'en' ? enEN : null}
                pagination
                disableExport
                disableReorder
                disableColumnMenu
                rowsPerPageOptions={[5]}
                pageSize={8}
                rows={Appeals}
                columns={GridColDef}
                onCellClick={(newSelection) => {
                  SelectionHandler(newSelection);
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterLogicOperator: GridLinkOperator.Or,
                    },
                  },
                }}
                components={{
                  // Toolbar: QuickSearchToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                  Pagination: CustomPagination,
                }}
                componentsProps={{
                  panel: {
                    placement: 'top',
                  },
                  cell: {
                    onMouseEnter: handlePopoverOpen,
                    onMouseLeave: handlePopoverClose,
                  },
                }}
              />
              <Popover
                sx={{
                  pointerEvents: 'none',
                  zIndex: '-1',
                }}
                open={false}
              ></Popover>
            </Box>
          </div>
        </div>
      </div>
      {/*  */}
      {modal == 'pdf' ? (
        <AppealViewPDF
          Trans={Trans}
          CloseModal={CloseModal}
          IDforPDFAppeal={IDforPDFAppeal}
          IDforAppeal='none'
        />
      ) : null}

      {modal == 'pdfAnswer' ? (
        <AppealViewPDF
          Trans={Trans}
          CloseModal={CloseModal}
          IDforPDFAppeal={IDforPDFAppeal}
          IDforAppeal='none'
          modal={'pdfAnswer'}
        />
      ) : null}

      {modal == 'Refusal' ? (
        <Modal
          open={true}
          // onClose={handleCloseModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={styleModal}>
            <div style={{ textAlign: 'end' }} onClick={CloseModal}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                  stroke='#292929'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <div className={s.title_auth_modal}>
              <div>
                <Trans i18nKey='MyAppeals.item5' />
              </div>
            </div>

            <Box sx={{ width: '100%' }}>{DATAforPDFAppeal?.refusal}</Box>

            <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={CloseModal}
              >
                <Trans i18nKey='MyAppeals.item6' />
              </Button>
            </ThemeProvider>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default MyAppeals;
