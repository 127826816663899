import React from 'react';
import { newsApi } from '../../../api/api';

import s from './post.module.css';

const Post = () => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const params = document.location.pathname.split('/');
    const id = params[params.length - 1];
    const response = newsApi.getPost(id);
    response.then((res) => setData(...res));
  }, []);

  return (
    <div className={s.rootPost}>
      <div className={s.headerPost}>
        <div className={s.headerDiv}>
          <div className={s.headerTitle}>{data?.title}</div>
          <div className={s.headerDateCreate}>Дата публикации : {data?.dateCreate}</div>
        </div>
        <div className={s.headerImg}>
          <img src={`	https://biznes.15000pvl.kz/file/news/${data?.img?.trim()}`} alt={data?.id} />
        </div>
      </div>
      <div className={s.bodyPost} dangerouslySetInnerHTML={{ __html: data?.text }}></div>
    </div>
  );
};

export default Post;
