import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainPageOptionsStates } from '../../redux/slices/mainSlice';
import { useNavigate } from 'react-router-dom';

import s from './DefaultSideBar.module.css';
import { errorData } from '../../common/helpers/sliceToasts';

import UslugiDrawer from './UslugiDrawer/UslugiDrawer';
import AnalitikaDrawer from './AnalitikaDrawer/AnalitikaDrawer';
import ServiceDrawer from './ServiceDrawer/ServiceDrawer';
import ERMDrawer from './ERMDrawer/ERMDrawer';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { styled, useTheme } from '@mui/material/styles';

import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SummarizeIcon from '@mui/icons-material/Summarize';

const DefaultSideBar = ({ Trans }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const width = useSelector((state) => state.store.mainSlice.width);
  const tabs = useSelector((state) => state.store.mainSlice.mainPageOptionsState);

  var warrObjects2 = [];

  const [state, setState] = React.useState({ left: false });

  const user = useSelector((state) => state.store.mainSlice.user);
  const [userStatus, setUserStatus] = React.useState(false);
  const [SotrudnikStatus, setSotrudnikStatus] = React.useState(false);
  const [AdminStatus, setAdminStatus] = React.useState(false);

  React.useEffect(() => {
    user
      ? user.status_user == '0'
        ? setUserStatus(user.status_user)
        : user.status_user == '1'
        ? setSotrudnikStatus(user.status_user)
        : user.status_user == '2'
        ? setAdminStatus(user.status_user)
        : errorData('Ошибка аутентификации')
      : console.info();
  }, [user]);

  const toggleDrawer = (anchor, open, cat) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    makeMenu(cat);
    console.log(123123);
  };

  const list = (anchor) =>
    anchor == 'Услуги' ? (
      <UslugiDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : anchor == 'Сервисы' ? (
      <ServiceDrawer
        dispatch={dispatch}
        Trans={Trans}
        width={width}
        handleDrawerClose={handleDrawerClose}
      />
    ) : anchor == 'Единое рабочее место' ? (
      <ERMDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : (
      <AnalitikaDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    );

  const makeMenu = (cat) => {
    for (let i = 0; i < 3; i++) {
      let user = makeCat(cat);
      warrObjects2[i] = user;
    }
    dispatch(mainPageOptionsStates(warrObjects2));
  };

  const makeCat = (cat) => {
    return {
      cat: cat,
      side: 'left',
    };
  };

  const handleDrawerClose = () => {
    setState({ ...state, ['left']: false });
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'left',
  }));

  return (
    <>
      <div style={{ width: `${width}px`, height: '950px', position: 'fixed' }} className={s.root}>
        <div className={s.title}>
          <Trans i18nKey='DefaultSideBar.item8' />
        </div>

        <div className={s.sideBarAllOptions}>
          <div className={s.sideBarOption}>
            <Button
              variant='text'
              startIcon={<LayersTwoToneIcon />}
              onClick={toggleDrawer('left', true, 'Услуги')}
            >
              <Trans i18nKey='DefaultSideBar.item1' />
            </Button>
          </div>
          <div className={s.sideBarOption}>
            <Button
              variant='text'
              startIcon={<MiscellaneousServicesIcon />}
              onClick={toggleDrawer('left', true, 'Сервисы')}
            >
              <Trans i18nKey='DefaultSideBar.item2' />
            </Button>
          </div>
          <div className={s.sideBarOption}>
            <Button
              variant='text'
              startIcon={<HomeRepairServiceIcon />}
              onClick={toggleDrawer('left', true, 'Единое рабочее место')}
            >
              <Trans i18nKey='DefaultSideBar.item3' />
            </Button>
          </div>

          <div className={s.sideBarOption}>
            <Button
              variant='text'
              startIcon={<FmdGoodIcon />}
              onClick={() => {
                navigate('/businessMap');
              }}
            >
              <Trans i18nKey='DefaultSideBar.item9' />
            </Button>
          </div>
          <div className={s.sideBarOption}>
            <Button
              variant='text'
              startIcon={<SignalCellularAltIcon />}
              onClick={() => {
                navigate('/statistics');
              }}
            >
              <Trans i18nKey='DefaultSideBar.item10' />
            </Button>
          </div>
          <div className={s.sideBarOption}>
            <Button
              variant='text'
              startIcon={<SummarizeIcon />}
              onClick={() => {
                navigate('/documents');
              }}
            >
              <Trans i18nKey='DefaultSideBar.item11' />
            </Button>
          </div>

          {AdminStatus && (
            <div className={s.sideBarOption}>
              <Button
                variant='text'
                startIcon={<QueryStatsIcon />}
                onClick={toggleDrawer('left', true, 'undef')}
              >
                <Trans i18nKey='DefaultSideBar.item4' />
              </Button>
            </div>
          )}

          {SotrudnikStatus && (
            <div className={s.sideBarOption}>
              <Button
                variant='text'
                startIcon={<QueryStatsIcon />}
                onClick={toggleDrawer('left', true, 'undef')}
              >
                <Trans i18nKey='DefaultSideBar.item4' />
              </Button>
            </div>
          )}

          {/* {AdminStatus && (
            <div className={s.sideBarOption}>
              <Button variant='text' startIcon={<SignalCellularAltIcon />}>
                <Link
                  target='_'
                  href='https://biznes.15000pvl.kz/innovation.php'
                  underline='hover'
                  color='inherit'
                >
                  <Trans i18nKey='DefaultSideBar.item4' />
                </Link>
              </Button>
            </div>
          )}

          {SotrudnikStatus && (
            <div className={s.sideBarOption}>
              <Button variant='text' startIcon={<SignalCellularAltIcon />}>
                <Link
                  target='_'
                  href='https://biznes.15000pvl.kz/innovation.php'
                  underline='hover'
                  color='inherit'
                >
                  <Trans i18nKey='DefaultSideBar.item4' />
                </Link>
              </Button>
            </div>
          )} */}
        </div>

        {tabs.map((anchor, index) => (
          <React.Fragment key={index}>
            <Drawer
              BackdropProps={{
                sx: { backgroundColor: 'rgb(0 0 0 / 25%) ', left: width },
              }}
              PaperProps={{ sx: { left: width, boxShadow: 'none' } }}
              anchor={anchor.side}
              open={state[anchor.side]}
              onClose={toggleDrawer(anchor.side, false)}
              variant={'temporary'}
            >
              <div
                style={{ width: width, height: 'inherit', left: 0, position: 'fixed' }}
                onClick={toggleDrawer(anchor.side, false)}
              ></div>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </DrawerHeader>

              {list(anchor.cat)}
            </Drawer>
          </React.Fragment>
        ))}

        <div className={s.dopinfo}>
          <Link
            target='_'
            href='https://www.gov.kz/memleket/entities/pavlodar-uiir/faq/1?lang=ru'
            underline='hover'
            color='inherit'
          >
            <Trans i18nKey='DefaultSideBar.item5' />
          </Link>
        </div>
        <div className={s.dopinfo}>
          <Link
            target='_'
            href='https://biznes.15000pvl.kz/documents/%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F%20%D0%B4%D0%BB%D1%8F%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20%C2%ABBusiness_Ertis%C2%BB.pdf'
            underline='hover'
            color='inherit'
          >
            <Trans i18nKey='dopSection.item22' />
          </Link>
        </div>
        <div className={s.dopinfo}>
          <Trans i18nKey='DefaultSideBar.item6' />
        </div>

        <div className={s.dopinfo}>
          <Trans i18nKey='DefaultSideBar.item7' />
        </div>
      </div>
    </>
  );
};

export default DefaultSideBar;
