import React from 'react';

// import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ForkRightIcon from '@mui/icons-material/ForkRight';

const LeasingDrawer = ({ Trans, dispatch, anchor, handleDrawerClose }) => {
  //   const navigate = useNavigate();

  const menu_item = [
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.LeasingDrawer.item1' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.LeasingDrawer.item2' /> },
  ];

  return (
    <>
      <Box sx={{ width: 450, marginTop: '80px' }} role='presentation'>
        <List>
          {menu_item.map((text, index) => (
            <ListItem key={index} disablePadding>
              {text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.LeasingDrawer.item2' ? (
                <ListItemButton
                // onClick={() => {handleDrawerClose(); navigate('/services/leasing/search-for-leasing-organizations') }}
                >
                  <ListItemIcon>
                    <ForkRightIcon />
                  </ListItemIcon>
                  <Link
                    target='_'
                    href='https://bankchart.kz/spravochniki/kompanii/11'
                    underline='hover'
                    color='inherit'
                  >
                    {text.item}
                  </Link>{' '}
                </ListItemButton>
              ) : text.item.props.i18nKey === 'DefaultSideBar.ServiceDrawer.LeasingDrawer.item1' ? (
                <ListItemButton
                // onClick={() => {handleDrawerClose(); navigate('/services/leasing/search-for-leasing-organizations') }}
                >
                  <ListItemIcon>
                    <ForkRightIcon />
                  </ListItemIcon>
                  <Link
                    target='_'
                    href='https://damu.kz/programmi/loans/damu-lizing/'
                    underline='hover'
                    color='inherit'
                  >
                    {text.item}
                  </Link>
                </ListItemButton>
              ) : (
                <ListItemButton>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default LeasingDrawer;
