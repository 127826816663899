import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { personalAreaAPI } from '../../../api/api';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';
import PDFCreateForAnswer from './PDFCreateForAnswer';
const AppealAnswerPDF = ({
  ws,
  appealNumber,
  appealDate,
  CloseModal,
  IDforPDFAppeal,
  TEXTforPDFAppeal,
  DATEforPDFAppeal,
  setOpenModal,
  setSendAppealsReload,
  SendAppealsReload,
  handleStatus,
  GetDataAfterChange,
  selectedValue,
  CatselectedValue,
  DataForAnswerAppeal,
  title,
  fileID,
  dataB64,
  dispatch,
  setModalStep3,
  selectedLang,
  Trans,
}) => {
  const user = useSelector((state) => state.store.mainSlice.user);
  const [IspFio, setIspFio] = useState('-');

  // useEffect(() => {
  //   let ispolnitelFIO = '';
  //   personalInfo
  //     ? (ispolnitelFIO =
  // personalInfo.last_name +
  // ' ' +
  // personalInfo.first_name +
  // '. ' +
  // personalInfo.middle_name +
  // '.')
  //     : console.log(123);

  //   console.log(ispolnitelFIO);
  //   setIspFio(ispolnitelFIO);
  // }, [personalInfo]);

  useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    const res = personalAreaAPI.fetchPersonalInfo(fd);
    res
      .then((res) =>
        setIspFio(res[0].last_name + ' ' + res[0].first_name + '. ' + res[0].middle_name + '.'),
      )
      .catch((err) => console.log(err));
  }, []);

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
    },
  });

  const styleModal = {
    position: 'absolute',
    top: 320,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const handleCloseModal = () => {
    CloseModal();
  };

  return (
    <>
      <Modal
        open={true}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          {/* <div className={s.close_auth_modal} onClick={handleCloseModal}>
						<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
								stroke='#292929'
								strokeWidth='3'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</div> */}
          <div className={s.title_auth_modal}>
            <div>
              <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AdminNotFinishAppealsAnswers.item7' />
            </div>
          </div>

          <Box sx={{ minWidth: 120 }}>
            <PDFCreateForAnswer
              // appealDate={appealDate}
              // CatselectedValue={CatselectedValue}
              // DopCatselectedValue={DopCatselectedValue}
              // appealNumber={appealNumber}
              selectedValue={selectedValue}
              handleStatus={handleStatus}
              GetDataAfterChange={GetDataAfterChange}
              ws={ws}
              handleCloseModal={CloseModal}
              DataForAnswerAppeal={DataForAnswerAppeal}
              TEXTforPDFAppeal={TEXTforPDFAppeal}
              // DATEforPDFAppeal={DATEforPDFAppeal}
              // title={title}
              dispatch={dispatch}
              selectedLang={selectedLang}
              setSendAppealsReload={setSendAppealsReload}
              SendAppealsReload={SendAppealsReload}
              IspFio={IspFio}
              Trans={Trans}
            />

            {/* <ThemeProvider theme={theme}>
              <Button
                color='neutral'
                sx={{ mt: '24px', height: '48px', width: '100%' }}
                variant='outlined'
                onClick={handleCloseModal}
              >
                Сохранить в черновик
              </Button>
            </ThemeProvider> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AppealAnswerPDF;
