import React, { useState } from 'react';

import ChooseAppealsType from '../../components/ChooseAppealsType/ChooseAppealsType';
import SendAppeals from '../../components/AppealsModalWindows/SendAppeals';

const AppealsPage = ({ Trans, dispatch, ws }) => {
  const [SendAppealsReload, setSendAppealsReload] = useState(false);

  React.useEffect(() => {}, [SendAppealsReload]);

  return (
    <>
      <ChooseAppealsType Trans={Trans} dispatch={dispatch} ws={ws} />

      <SendAppeals
        dispatch={dispatch}
        Trans={Trans}
        ws={ws}
        setSendAppealsReload={setSendAppealsReload}
        SendAppealsReload={SendAppealsReload}
      />
    </>
  );
};

export default AppealsPage;
