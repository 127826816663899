export const enEN = {
  noRowsLabel: 'No strings',
  noResultsOverlayLabel: 'No data found.',
  errorOverlayDefaultLabel: 'An error was detected.',
  // Density selector toolbar button text
  toolbarDensity: 'Row height',
  toolbarDensityLabel: 'Row height',
  toolbarDensityCompact: 'Compact',
  toolbarDensityStandard: 'Standard',
  toolbardensitycomorable: 'Comfortable',
  // Columns selector toolbar button text
  toolbarColumns: 'Columns',
  toolbarColumnsLabel: 'Select columns',
  // Filters toolbar button text
  toolbarFilters: 'Filters',
  toolbarFiltersLabel: 'Show filters',
  toolbarFiltersTooltipHide: 'Hide filters',
  toolbarFiltersTooltipShow: 'Show filters',
  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Search...',
  toolbarQuickFilterLabel: 'Search',
  toolbarQuickFilterDeleteIconLabel: 'Clear',
  // Export selector toolbar button text
  toolbarExport: 'Export',
  toolbarExportLabel: 'Export',
  toolbarExportCSV: 'Download in CSV format',
  toolbarExportPrint: 'Print',
  toolbarExportExcel: 'Download in Excel format',
  // Columns panel text
  columnsPanelTextFieldLabel: 'Find column',
  columnsPanelTextFieldPlaceholder: 'Column header',
  columnsPanelDragIconLabel: 'Change column order',
  columnsPanelShowAllButton: 'Show all',
  columnsPanelHideAllButton: 'Hide all',
  // Filter panel text
  filterPanelAddFilter: 'Add Filter',
  filterPanelDeleteIconLabel: 'Delete',
  // filterPanelLinkOperator: 'Logic operator',
  filterPanelOperators: 'Operators',
  // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'And',
  filterPanelOperatorOr: 'Or',
  filterPanelColumns: 'Columns',
  filterPanelInputLabel: 'Value',
  filterPanelInputPlaceholder: 'Filter value',
  // Filter operators text
  filterOperatorContains: 'contains',
  filterOperatorEquals: 'equal to',
  filterOperatorStartsWith: 'starts with',
  filterOperatorEndsWith: 'ends with',
  filterOperatorIs: 'equal to',
  filterOperatorNot: 'not equal',
  filterOperatorAfter: 'more than',
  filterOperatorOnOrAfter: 'greater than or equal to',
  filterOperatorBefore: 'less than',
  filterOperatorOnOrBefore: 'less than or equal to',
  filterOperatorIsEmpty: 'empty',
  filterOperatorIsNotEmpty: 'not empty',
  filterOperatorIsAnyOf: 'any of',
  // Filter values text
  filterValueAny: 'any',
  filterValueTrue: 'true',
  filterValueFalse: 'false',
  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Show columns',
  columnMenuFilter: 'Filter',
  columnMenuHideColumn: 'Hide',
  columnMenuUnsort: 'Cancel sorting',
  columnMenuSortAsc: 'Sort in ascending order',
  columnMenuSortDesc: 'Sort in descending order',
  columnHeaderFiltersLabel: 'Show filters',
  columnHeaderSortIconLabel: 'Sort',
  checkboxSelectionHeaderName: 'Select checkbox',
  // checkboxSelectionSelectAllRows: 'Select all rows',
  // checkboxSelectionUnselectAllRows: 'Unselect all rows',
  // checkboxSelectionSelectRow: 'Select row',
  // checkboxSelectionUnselectRow: 'Unselect row',
  // Boolean cell text
  booleanCellTrueLabel: 'true',
  booleanCellFalseLabel: 'false',
  // Actions cell more text
  actionsCellMore: 'more',
  // Column pinning text
  pinToLeft: 'Pin on the left',
  pinToRight: 'Pin Right',
  unpin: 'Unpin',
  // Tree Data
  treeDataGroupingHeaderName: 'Group',
  treeDataExpand: 'show child elements',
  treeDataCollapse: 'hide child elements',
  expandDetailPanel: 'Expand',
  collapseDetailPanel: 'Collapse', // Row reordering text
  footerRowSelected: (count) => {
    let pluralForm = 'rows selected';
    const lastDigit = count % 10;

    if (lastDigit > 1 && lastDigit < 5) {
      pluralForm = 'lines selected';
    } else if (lastDigit === 1) {
      pluralForm = 'row selected';
    }

    return `${count} ${pluralForm}`;
  },
};
