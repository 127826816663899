import React from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import ForkRightIcon from '@mui/icons-material/ForkRight';
const MarketAnalysisDrawer = ({ Trans, dispatch, anchor, handleDrawerClose }) => {
  const navigate = useNavigate();

  const menu_item = [
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.MarketAnalysisDrawer.item1' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.MarketAnalysisDrawer.item2' /> },
    { item: <Trans i18nKey='DefaultSideBar.ServiceDrawer.MarketAnalysisDrawer.item3' /> },
  ];

  return (
    <>
      <Box sx={{ width: 450, marginTop: '80px' }} role='presentation'>
        <List>
          {menu_item.map((text, index) => (
            <ListItem key={index} disablePadding>
              {text.item.props.i18nKey ==
              'DefaultSideBar.ServiceDrawer.MarketAnalysisDrawer.item3' ? (
                <ListItemButton
                  onClick={() => {
                    handleDrawerClose();
                    navigate('/services/market-analysis/franchise-selection');
                  }}
                >
                  <ListItemIcon>
                    <TurnRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              ) : text.item.props.i18nKey ===
                'DefaultSideBar.ServiceDrawer.MarketAnalysisDrawer.item2' ? (
                <ListItemButton
                //   onClick={() => {
                //     handleDrawerClose();
                // navigate('/services/credits-and-fin-help/credit-online');
                //   }}
                >
                  <ListItemIcon>
                    <ForkRightIcon />
                  </ListItemIcon>
                  <Link
                    target='_'
                    href='https://marketingcenter.kz/articles.html'
                    underline='hover'
                    color='inherit'
                  >
                    {text.item}
                  </Link>
                </ListItemButton>
              ) : text.item.props.i18nKey ===
                'DefaultSideBar.ServiceDrawer.MarketAnalysisDrawer.item1' ? (
                <ListItemButton
                //   onClick={() => {
                //     handleDrawerClose();
                // navigate('/services/credits-and-fin-help/credit-online');
                //   }}
                >
                  <ListItemIcon>
                    <ForkRightIcon />
                  </ListItemIcon>
                  <Link
                    target='_'
                    href='https://biznes.15000pvl.kz/innovation.php'
                    underline='hover'
                    color='inherit'
                  >
                    {text.item}
                  </Link>
                </ListItemButton>
              ) : (
                <ListItemButton>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary={text.item} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default MarketAnalysisDrawer;
