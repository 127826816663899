import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { EncryptStorage } from 'encrypt-storage';

import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import MainPageRoutes from './common/routes/MainPageRoutes';
import Header from '../src/components/Header/Header';
import DefaultSideBar from './components/DefaultSideBar/DefaultSideBar';
import { logOut } from './redux/asyncThunks/mainAsyncThunk';
import { isLogined } from './redux/slices/mainSlice';
import { newAppeals } from './redux/slices/notificationsSlice';
import { ReloadAppeals } from './redux/slices/appealsSlice';
import { newNotification } from './common/helpers/sliceToasts';
import { mainPage, notificationsApi } from './api/api';
import { notificationsAllEntrep } from './redux/asyncThunks/notificationsAsyncThunk';

function App() {
  const encryptStorage = new EncryptStorage('secret-key-value', {
    prefix: 'crypt',
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const category_type = pathname.split('/')[1];
  const width = useSelector((state) => state.store.mainSlice.width);

  const language = useSelector((state) => state.store.mainSlice.language);
  const isNewAppeals = useSelector((state) => state.store.notificationsSlice.isNewAppeals);

  const userRd = useSelector((state) => state.store.mainSlice.user);
  const [userLogin, setUserLogin] = useState(false);

  const ws = useRef(null);

  //Для отлеживание активности пользователя
  let timeout;
  const [wait, setWait] = useState(1800000);

  useEffect(() => {
    if (encryptStorage.getItem('a')) {
      setUserLogin(true);
      dispatch(isLogined(encryptStorage.getItem('a')));
    }
    wsConnect();
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ru');
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('lang')) {
      i18n.changeLanguage(localStorage.getItem('lang'));
    } else {
      i18n.changeLanguage(localStorage.getItem('ru'));
    }
  }, [i18n]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [localStorage.getItem('lang')]);

  //START------------------------------------------- websocket
  const wsConnect = () => {
    ws.current = new WebSocket('wss:/biznes.15000pvl.kz:2347');

    ws.current.onopen = () => {
      console.log('Socket is opened.');
      gettingData();
    };

    ws.current.onclose = (e) => {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        wsConnect();
      }, 1000);
    };

    ws.current.onerror = (err) => {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      ws.current.close();
    };
  };

  const gettingData = useCallback(() => {
    if (encryptStorage.getItem('a')) {
      ws.current.onmessage = async (e) => {
        const message = await JSON.parse(e.data);
        if (
          message[0].split(',')[0] === 'isAnswerNotifiaction' &&
          message[0].split(',')[1] === encryptStorage.getItem('a').id
        ) {
          const fd = new FormData();
          fd.append('id', encryptStorage.getItem('a').id);
          dispatch(notificationsAllEntrep(fd));
          const api = notificationsApi.getNotificationsEntrepruneur(fd);
          api.then((res) => {
            newNotification(
              <div onClick={() => navigate('/notifications')}>
                {` Уведомление от: ${res[res.length - 1][0].last_name}${' '}
                ${res[res.length - 1][0].first_name}${' '}
                ${res[res.length - 1][0].middle_name}, Вид обращения: ${res[res.length - 1].title}`}
              </div>,
            );
          });
          dispatch(newAppeals(isNewAppeals + 1));
        }
        if (
          message[0].split(',')[0] === 'isAnswerNotifiactionEntrepruneur' &&
          message[0].split(',')[1] === encryptStorage.getItem('a').id
        ) {
          const fd = new FormData();
          fd.append('id', encryptStorage.getItem('a').id);
          dispatch(notificationsAllEntrep(fd));
          dispatch(newAppeals(isNewAppeals + 1));
        }
        if (message === 'SendAppeals') {
          if (encryptStorage.getItem('a').status_user >= 0) {
            dispatch(newAppeals(isNewAppeals + 1));
          }
        }
        if (
          message[0].split(',')[0] === 'ActionAppeals' &&
          message[0].split(',')[2] === encryptStorage.getItem('a').id
        ) {
          dispatch(ReloadAppeals(isNewAppeals + 1));
          const fd = new FormData();
          fd.append('id', message[0].split(',')[1]);
          const api = notificationsApi.apealAbout(fd);
          api.then((res) => {
            res[0].status === '1' &&
              newNotification(
                <div onClick={() => navigate('/notifications')}>
                  Ваше обращение №{res[0].id} зарегестрированно
                </div>,
              );
            res[0].status === '2' &&
              newNotification(
                <div onClick={() => navigate('/notifications')}>
                  Ваше обращение №{res[0].id} находится на исполнении
                </div>,
              );
            res[0].status === '3' &&
              newNotification(
                <div onClick={() => navigate('/notifications')}>
                  Ваше обращение №{res[0].id} исполненно
                </div>,
              );
            res[0].status === '4' &&
              newNotification(
                <div onClick={() => navigate('/notifications')}>
                  Вашему обращению №{res[0].id} было отказанно
                </div>,
              );
          });
        }
        if (
          message[0].split(',')[0] === 'TransferAppeal' &&
          message[0].split(',')[1] === encryptStorage.getItem('a').id
        ) {
          newNotification(
            <div onClick={() => navigate('/admin-appeals')}>
              Администратор назначил Вам обращение
            </div>,
          );
        }
      };
    }
  }, []);
  //END------------------------------------------- websocket

  let routes;
  switch (category_type) {
    default:
      routes = <MainPageRoutes dispatch={dispatch} Trans={Trans} ws={ws.current} />;
  }

  //START ------------------------------------------- Для отлеживание активности пользователя
  const trackMove = () => {
    if (encryptStorage.getItem('a')) {
      clearTimeout(timeout);
      timeout = setTimeout(unLogin, wait);
    }
  };
  const unLogin = () => {
    if (userRd !== null) {
      const fd = new FormData();
      fd.append('id', userRd.id);
      const logout = mainPage.logOut(fd);
      logout.then((res) => {
        if (res === true) {
          localStorage.removeItem('crypt:a');
          setUserLogin(false);
          clearTimeout(timeout);
          window.location.reload();
        }
      });
    }
  };
  //END ------------------------------------------- Для отлеживание активности пользователя

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="App" onMouseMove={trackMove}>
        <Header
          dispatch={dispatch}
          Trans={Trans}
          setUserLogin={setUserLogin}
          userLogin={userLogin}
        />
        <div>
          <div
            style={{
              height: '150px',
              zIndex: -1,
              background: '#f0f0f0',
              top: 0,
              position: 'fixed',
              width: width,
            }}></div>
          <div className="content">
            {/* <MenuComponent category_type={category_type} /> */}
            <DefaultSideBar dispatch={dispatch} Trans={Trans} />
            <div style={{ display: 'flex', marginLeft: width }} className="router-wrapper">
              {routes}
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default App;
