import React, { useState } from 'react';

import s from './CreditLimitCalc.module.css';

import QuestionBlock from '../../components/QuestionBlock/QuestionBlock';
import CreditLimitCalcInputsBlock from '../../components/CreditLimitCalcInputsBlock/CreditLimitCalcInputsBlock';

const CreditLimitCalc = ({ Trans, dispatch }) => {






  return (
    <>

    <div className={s.Wrapper}>
        <div className={s.title}>
            <p>Сервисы - Кредиты и финансовая поддержка - Расчет кредитного лимита</p>
        </div>
        <div className={s.maintitle}>
            <p>Расчет кредитного лимита</p>
        </div>

        <CreditLimitCalcInputsBlock />
    </div>


    </>
  );
};


export default CreditLimitCalc;
