export const kzKZ = {
  noRowsLabel: 'жолдар жоқ',
  noResultsOverlayLabel: 'деректер табылған жоқ.',
  errorOverlayDefaultLabel: 'қате табылды.',
  // Density selector toolbar button text
  toolbarDensity: 'жолдың биіктігі',
  toolbarDensityLabel: 'жолдың биіктігі',
  toolbarDensityCompact: 'ықшам',
  toolbarDensityStandard: 'стандартты',
  toolbarDensityComfortable: 'жайлы',
  // Columns selector toolbar button text
  toolbarColumns: 'бағандар',
  toolbarColumnsLabel: 'бағандарды бөлектеңіз',
  // Filters toolbar button text
  toolbarFilters: 'Сүзгілер',
  toolbarFiltersLabel: 'сүзгілерді көрсету',
  toolbarFiltersTooltipHide: 'сүзгілерді жасыру',
  toolbarFiltersTooltipShow: 'сүзгілерді көрсету',
  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'іздеу...',
  toolbarQuickFilterLabel: 'іздеу',
  toolbarQuickFilterDeleteIconLabel: 'тазалау',
  // Export selector toolbar button text
  toolbarExport: 'экспорттау',
  toolbarExportLabel: 'экспорттау',
  toolbarExportCSV: 'CSV форматында жүктеу',
  toolbarExportPrint: 'Басып шығару',
  toolbarExportExcel: 'Excel форматында жүктеу',
  // Columns panel text
  columnsPanelTextFieldLabel: 'бағанды табу',
  columnsPanelTextFieldPlaceholder: 'баған тақырыбы',
  columnsPanelDragIconLabel: 'бағанның ретін өзгерту',
  columnsPanelShowAllButton: 'барлығын көрсету',
  columnsPanelHideAllButton: 'барлығын жасыру',
  // Filter panel text
  filterPanelAddFilter: 'сүзгіні қосу',
  filterPanelDeleteIconLabel: 'жою',
  // filterPanelLinkOperator: 'Logic operator',
  filterPanelOperators: 'операторлар',
  // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'және',
  filterPanelOperatorOr: 'немесе',
  filterPanelColumns: 'бағандар',
  filterPanelInputLabel: 'мәні',
  filterPanelInputPlaceholder: 'сүзгі мәні',
  // Filter operators text
  filterOperatorContains: 'құрамында',
  filterOperatorEquals: 'тең',
  filterOperatorStartsWith: 'басталады',
  filterOperatorEndsWith: 'аяқталады',
  filterOperatorIs: 'тең',
  filterOperatorNot: 'тең емес',
  filterOperatorAfter: 'артық',
  filterOperatorOnOrAfter: 'үлкен немесе тең',
  filterOperatorBefore: 'аз',
  filterOperatorOnOrBefore: 'аз немесе тең',
  filterOperatorIsEmpty: 'бос',
  filterOperatorIsNotEmpty: 'бос емес',
  filterOperatorIsAnyOf: 'кез келген',
  // Filter values text
  filterValueAny: 'кез келген',
  filterValueTrue: 'шындық',
  filterValueFalse: 'жалған',
  // Column menu text
  columnMenuLabel: 'мәзір',
  columnMenuShowColumns: 'бағандарды көрсету',
  columnMenuFilter: 'сүзгі',
  columnMenuHideColumn: 'жасыру',
  columnMenuUnsort: 'сұрыптауды болдырмау',
  columnMenuSortAsc: 'Өсу бойынша сұрыптау',
  columnMenuSortDesc: 'төмен қарай сұрыптау',
  columnHeaderFiltersLabel: 'сүзгілерді көрсету',
  columnHeaderSortIconLabel: 'сұрыптау',
  checkboxSelectionHeaderName: 'құсбелгіні таңдау',
  // checkboxSelectionSelectAllRows: 'Select all rows',
  // checkboxSelectionUnselectAllRows: 'Unselect all rows',
  // checkboxSelectionSelectRow: 'Select row',
  // checkboxSelectionUnselectRow: 'Unselect row',
  // Boolean cell text
  booleanCellTrueLabel: 'шындық',
  booleanCellFalseLabel: 'өтірік',
  // Actions cell more text
  actionsCellMore: 'тағы',
  // Column pinning text
  pinToLeft: 'сол жаққа бекіту',
  pinToRight: 'оң жаққа бекіту',
  unpin: 'ашу',
  // Tree Data
  treeDataGroupingHeaderName: 'топ',
  treeDataExpand: 'балаларды көрсету',
  treeDataCollapse: 'балалар элементтерін жасыру',
  expandDetailPanel: 'кеңейту',
  collapseDetailPanel: 'орау', // Row reordering text
  footerRowSelected: (count) => {
    let pluralForm = 'жолдар таңдалды';
    const lastDigit = count % 10;

    if (lastDigit > 1 && lastDigit < 5) {
      pluralForm = 'таңдалған жолдар';
    } else if (lastDigit === 1) {
      pluralForm = 'жол таңдалды';
    }

    return `${count} ${pluralForm}`;
  },
};
