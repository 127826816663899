import React from 'react';
import { useState } from 'react';

import s from '../AdminChooseAppealsType/AdminChooseAppealsType.module.css';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';

import AllAppeals from '../AdminAppeals/AllAppeals/AllAppeals';
import NotAcceptedAppeals from '../AdminAppeals/NotAcceptedAppeals/NotAcceptedAppeals';
import RegisteredAppeals from '../AdminAppeals/RegisteredAppeals/RegisteredAppeals';
import ReturnWithoutPerformer from '../AdminAppeals/ReturnWithoutPerformer/ReturnWithoutPerformer';
import Fulfilled from '../AdminAppeals/Fulfilled/Fulfilled';
import OnExecution from '../AdminAppeals/OnExecution/OnExecution';

import { MyAppealsWidth } from '../../redux/slices/mainSlice';
import { FamilyRestroom } from '@mui/icons-material';
const AdminAppeals = ({ Trans, dispatch, ws, status }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const AdminChooseAppealsTypeWidth = React.useRef(null);
  const [reload, setReload] = useState(false);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  var styles = {
    default_tab: {
      color: '#28323E',
      //   backgroundColor: '#ff7f00',
      fontWeight: 400,
      fontSize: '12px',
    },
    active_tab: {
      color: '#ff7f00',
    },
  };

  styles.tab = [];
  styles.tab[0] = styles.default_tab;
  styles.tab[1] = styles.default_tab;
  styles.tab[2] = styles.default_tab;
  styles.tab[3] = styles.default_tab;
  styles.tab[4] = styles.default_tab;
  styles.tab[5] = styles.default_tab;
  styles.tab[value] = Object.assign({}, styles.tab[value], styles.active_tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  React.useEffect(() => {
    dispatch(MyAppealsWidth(AdminChooseAppealsTypeWidth.current.getBoundingClientRect().width));
  }, []);

  return (
    <>
      <div ref={AdminChooseAppealsTypeWidth}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            width: '100%',
            position: 'relative',
            minHeight: 200,
          }}
        >
          <AppBar
            sx={{
              bgcolor: '#ffffff',
              boxShadow: 'none',
              width: '100%',
              color: '#ff7f00',
            }}
            position='static'
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='inherit'
              variant='fullWidth'
              aria-label='action tabs example'
              TabIndicatorProps={{
                style: { background: '#ff7f00', color: '#ff7f00' },
              }}
            >
              <Tab
                style={styles.tab[0]}
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.item1' />}
                {...a11yProps(0)}
              />
              <Tab
                style={styles.tab[1]}
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.item2' />}
                {...a11yProps(1)}
              />
              <Tab
                style={styles.tab[2]}
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.item3' />}
                {...a11yProps(0)}
              />
              <Tab
                style={styles.tab[3]}
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.item4' />}
                {...a11yProps(1)}
              />
              <Tab
                style={styles.tab[4]}
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.item5' />}
                {...a11yProps(0)}
              />
              <Tab
                style={styles.tab[5]}
                label={<Trans i18nKey='AdminChooseAppealsType.AdminAppeals.item6' />}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            // animateHeight={false}
            // ignoreNativeScroll={true}
            containerStyle={{ height: '700px' }}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AllAppeals dispatch={dispatch} Trans={Trans} ws={ws} status={status} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <NotAcceptedAppeals ws={ws} dispatch={dispatch} Trans={Trans} status={status} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <RegisteredAppeals dispatch={dispatch} Trans={Trans} ws={ws} status={status} />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <OnExecution ws={ws} dispatch={dispatch} Trans={Trans} status={status} />
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <Fulfilled dispatch={dispatch} Trans={Trans} ws={ws} status={status} />
            </TabPanel>
            <TabPanel value={value} index={5} dir={theme.direction}>
              <ReturnWithoutPerformer ws={ws} dispatch={dispatch} Trans={Trans} status={status} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </div>
    </>
  );
};

export default AdminAppeals;
