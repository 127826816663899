/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState } from 'react';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import { NCALayerClient } from 'ncalayer-js-client';
import { EncryptStorage } from 'encrypt-storage';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';

import s from './header.module.css';

import ru from './img/ru.png';
import kz from './img/kz.png';
import en from './img/en.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  divBlockWidth,
  editLanguage,
  errorAuthNull,
  setModalAuth,
  setNcaLayerErr,
  setNcaLayerStatus,
} from '../../redux/slices/mainSlice';
import { loginEcp, loginInnPass } from '../../redux/asyncThunks/mainAsyncThunk';

import LoadingDots from '../LoadingDots/LoadingDots';
import ModalRegistration from './ModalRegistration';
import ModalAfterAuth from './ModalAfterAuth';
import ModalAfterAuthForAdmin from './ModalAfterAuthForAdmin';
import { appealsAPI, mainPage, notificationsApi } from '../../api/api';
import ModalActivateRecovery from './ModalActivateRecovery';
import ModalResetPassword from './ModalResetPassword';
import { newNotification, successData } from '../../common/helpers/sliceToasts';
import { getCountNotifications } from '../../redux/slices/notificationsSlice';
import DefaultSideBarCrup from '../DefaultSideBar/DefaultSideBarCrup';

const styleModal = {
  position: 'absolute',
  top: '275px',
  right: '-148px',
  transform: 'translate(-50%, -50%)',
  width: 360,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  padding: '33px 48px',
  p: 4,
  outline: 0,
};

const styleModalLogin = {
  position: 'absolute',
  top: '225px',
  right: '-148px',
  transform: 'translate(-50%, -50%)',
  width: 360,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  padding: '33px 64px',
  p: 4,
  outline: 0,
};
const styleActiveArr = {
  position: 'absolute',
  top: '25%',
  left: '81%',
  transform: 'translate(-81%, -25%)',
  maxWidth: 424,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingTop: '33px',
  padding: '33px 48px',
};

export default function Header({ dispatch, userLogin, setUserLogin, Trans }) {
  let ncaLayer = new NCALayerClient();
  const encryptStorage = new EncryptStorage('secret-key-value', {
    prefix: 'crypt',
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const language = useSelector((state) => state.store.mainSlice.language);
  const user = useSelector((state) => state.store.mainSlice.user);
  const error = useSelector((state) => state.store.mainSlice.error);
  const isLoadingAuth = useSelector((state) => state.store.mainSlice.isLoadingAuth);
  const modalAuth = useSelector((state) => state.store.mainSlice.modalAuth);
  const ncaLayerErr = useSelector((state) => state.store.mainSlice.ncaLayerErr);
  const ncaLayerStatus = useSelector((state) => state.store.mainSlice.ncaLayerStatus);
  const isNewAppeals = useSelector((state) => state.store.notificationsSlice.isNewAppeals);

  const [modalRegistation, setModalRegistation] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userEcp, setUserEcp] = useState(false);
  const [activateRecoveryIin, setActivateRecoveryIin] = useState(false);
  const [openAcivateRecovery, setOpenAcivateRecovery] = useState(false);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const [openAcivateRecoveryErr, setOpenAcivateRecoveryErr] = useState(false);
  const [activateRecoveryErrText, setActivateRecoveryErrText] = useState('');
  const [iconEye, setIconEye] = useState(false);
  const [countNotifLS, setCountNotifLS] = React.useState(0);
  const [toggleDriwer1, settoggleDriwer1] = useState(false);
  const [toggleDriwer2, settoggleDriwer2] = useState(false);

  let undataB64, storageType;

  const url = window.location.href.split('/')[3];

  const menu_item = [
    { item: <Trans i18nKey='header.headerView.item1' />, url: '/' },
    { item: <Trans i18nKey='header.headerView.item2' />, url: '/news' },
    { item: <Trans i18nKey='header.headerView.item4' />, url: '/gallery' },
    { item: <Trans i18nKey='header.headerView.item3' />, url: '/services' },
  ];

  React.useEffect(() => {
    dispatch(divBlockWidth(divBlock.current.getBoundingClientRect().width));
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));
      if (params?.activateAccount) {
        const fd = new FormData();
        fd.append('code', params.activateAccount);
        const activate = mainPage.activateAccount(fd);
        activate.then((res) => {
          if (res[0].IIN) {
            setActivateRecoveryIin(res[0].IIN);
            handleOpenAcivateRecovery();
          } else {
            setActivateRecoveryErrText(res[0].message);
            handleOpenAcivateRecoveryErr();
          }
        });
      }
    }
  }, []);

  React.useEffect(() => {
    url === '' && setActiveMenu(0);
    url === 'news' && setActiveMenu(1);
    url === 'gallery' && setActiveMenu(2);
    url === 'services' && setActiveMenu(3);
  }, [activeMenu]);

  React.useEffect(() => {
    if (isNewAppeals >= 0) {
      if (user?.status_user >= 1) {
        const appealLast = appealsAPI.fetchLastApeals();
        appealLast
          .then((res) => {
            const person = res[0];
            if (person) {
              newNotification(
                <div
                  onClick={() => {
                    navigate('/notifications');
                    setCountNotifLS(0);
                  }}
                >
                  {`Уведомление от ${person[0].last_name} ${person[0].first_name}  ${person[0].middle_name}
                Вид обращения: ${res[0].title}`}
                </div>,
              );
            }
          })
          .catch((err) => console.log(err));
      }
      const countNotifLS = encryptStorage.getItem('notificationsCount')?.length;
      if (encryptStorage.getItem('notificationsCount')) {
        if (user?.status_user >= 1) {
          const fd = new FormData();
          fd.append('id', user?.id);
          const appeals = notificationsApi.fetchAllNotifications(fd);
          appeals
            .then((res) => {
              setCountNotifLS(res.length - Number(countNotifLS));
            })
            .catch((err) => console.log(err));
        }
        if (user?.status_user === '0') {
          const fd = new FormData();
          fd.append('id', user?.id);
          const appeals = notificationsApi.getNotificationsEntrepruneur(fd);
          appeals
            .then((res) => {
              setCountNotifLS(res.length - Number(countNotifLS));
            })
            .catch((err) => console.log(err));
        }
      }
    }
  }, [isNewAppeals]);

  React.useEffect(() => {
    userEcp && authUser();
  }, [userEcp]);

  React.useEffect(() => {
    user && encryptStorage.setItem('a', user);
    user && setUserLogin(true);

    if (encryptStorage.getItem('notificationsCount')) {
      const countNotifLS = encryptStorage.getItem('notificationsCount').length;
      if (user?.status_user >= 1) {
        const fd = new FormData();
        fd.append('id', user?.id);
        const appeals = notificationsApi.fetchAllNotifications(fd);
        appeals
          .then((res) => {
            setCountNotifLS(res.length - Number(countNotifLS));
          })
          .catch((err) => console.log(err));
      }
      if (user?.status_user === '0') {
        const fd = new FormData();
        fd.append('id', user?.id);
        const appeals = notificationsApi.getNotificationsEntrepruneur(fd);
        appeals
          .then((res) => {
            setCountNotifLS(res.length - Number(countNotifLS));
          })
          .catch((err) => console.log(err));
      }
    }
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLanguage = Boolean(anchorEl);
  const id = openLanguage ? 'simple-popover' : undefined;
  const divBlock = React.useRef(null);

  const changeLanguage = (languageChecked, event) => {
    if (language !== languageChecked) {
      dispatch(editLanguage(languageChecked));
      localStorage.setItem('lang', languageChecked);

      setAnchorEl(null);
      successData('Смена языка...');
    }
  };

  const handleOpenModalAuth = () => dispatch(setModalAuth(true));
  const handleCloseModalAuth = () => {
    setActiveMenu(false);
    setUserLogin(false);
    dispatch(setModalAuth(false));
    dispatch(errorAuthNull(null));
    dispatch(setNcaLayerStatus(null));
    dispatch(setNcaLayerErr(null));

    reset();
  };
  const handleOpenModalReg = () => {
    setModalRegistation(true);
    dispatch(setModalAuth(false));
    handleCloseAcivateRecoveryErr();
  };
  const handleCloseModalReg = () => {
    setModalRegistation(false);
    dispatch(setNcaLayerStatus(null));
    dispatch(setNcaLayerErr(null));
  };

  const startLoginEcp = async () => {
    //  ЭЦП

    dispatch(setNcaLayerErr(null));

    dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item1' />));

    fetch('https://sigex.kz/api/auth', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
      },

      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(function (data) {
        undataB64 = data.nonce;

        selectStorageAndContinue();
      })
      .catch(function (error) {
        // errorsBlog.innerHTML="Ошибка: Перезагрузите страницу" + error;
        dispatch(setNcaLayerStatus(null));
        dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item2' /> + ' ' + error));

        console.log('Request failed', error);
      });
  };

  const selectStorageAndContinue = async () => {
    //работает при нажатии на кнопку Подписать документ
    try {
      await ncaLayer.connect();
    } catch (err) {
      console.log(err);
      dispatch(setNcaLayerStatus(null));
      dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item3' /> + ' ' + err));
    }

    try {
      dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item4' />));

      const storageTypes = await ncaLayer.getActiveTokens();
      if (storageTypes.length > 1) {
        dispatch(setNcaLayerStatus(null));
        dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item5' />));

        return false;
      }

      if (storageTypes.length === 0) {
        storageType = 'PKCS12';
      } else {
        storageType = storageTypes[0];
      }
    } catch (err) {
      dispatch(setNcaLayerStatus(null));
      dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item3' />));

      console.log(err);
      return false;
    }
    signAndSend();
  };

  const signAndSend = async () => {
    // eslint-disable-next-line no-unused-vars
    let userId, email, fullname, dadname, serialnumber, nationality, signature;

    //открытие NCALAyer и подписка данных с sigex
    try {
      signature = await ncaLayer.createCAdESFromBase64(storageType, undataB64);
    } catch (err) {
      dispatch(setNcaLayerStatus(null));
      dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item6' />));

      console.log('NCALayer вернул неожиданную ошибку signAndSend', err);
      return; //Если произошла ошибка, то он выходит. Код дальше не обрабтывается
    }

    dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item7' />));

    //Отправка на API Sigex
    await fetch('https://sigex.kz/api/auth', {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        nonce: undataB64,
        signature: signature,
        external: true,
      }),
    })
      .then((response) => response.json())
      .then(function (data) {
        const res = data;
        let separatedArray = [];
        separatedArray = res.subject.split(',');

        userId = res.userId;
        email = res.email; //res.email.toLowerCase();
        fullname = separatedArray[0].replace('CN=', '').split('SURNAME=');

        dadname = separatedArray[4].replace('GIVENNAME=', '');
        serialnumber = res.userId.replace('IIN', '');
        nationality = separatedArray[3].replace('C=', '');
        let arr = {};
        arr['email'] = email;
        arr['fullname'] = fullname[1];
        arr['iin'] = serialnumber;
        arr['nationality'] = nationality;
        setUserEcp(arr);
      })
      .catch(function (error) {
        dispatch(setNcaLayerStatus(null));
        dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item8' /> + ' ' + error));

        console.log('Сервер не принял подпись (проблема с проверкой подписанных данных) ', error);
      });
  };

  const authUser = async () => {
    const fd = new FormData();
    fd.append('IIN', userEcp.iin);
    dispatch(loginEcp(fd));
    dispatch(setNcaLayerStatus(''));
  };

  const onLoginInnPass = (data) => {
    dispatch(errorAuthNull(null));
    const fd = new FormData();
    fd.append('IIN', data.IIN);
    fd.append('password', data.password);
    dispatch(loginInnPass(fd));
  };

  const handleOpenAcivateRecovery = () => {
    setOpenAcivateRecovery(true);
    dispatch(setModalAuth(false));
  };
  const handleCloseAcivateRecovery = () => setOpenAcivateRecovery(false);

  const handleOpenResetPassword = () => {
    setOpenResetPassword(true);
    handleCloseModalAuth();
  };
  const handleCloseResetPassword = () => {
    setOpenResetPassword(false);
  };

  const handleOpenAcivateRecoveryErr = () => {
    setOpenAcivateRecoveryErr(true);
  };

  const handleCloseAcivateRecoveryErr = () => {
    setOpenAcivateRecoveryErr(false);
    navigate('');
  };

  const toggleDrawerServFirst = () => {
    settoggleDriwer1(!toggleDriwer1);
  };

  const toggleDrawerUslugiFirst = () => {
    settoggleDriwer2(!toggleDriwer2);
  };
  const viewOnPassword = () => {
    setIconEye((iconEye) => !iconEye);
    document.querySelector('#input_password').setAttribute('type', 'password');
  };

  const viewOfPassword = () => {
    setIconEye((iconEye) => !iconEye);
    document.querySelector('#input_password').setAttribute('type', 'text');
  };
  return (
    <div className={s.root_header}>
      <DefaultSideBarCrup
        dispatch={dispatch}
        Trans={Trans}
        toggleDriwer1={toggleDriwer1}
        toggleDriwer2={toggleDriwer2}
      />
      <div className={s.header_left} ref={divBlock}>
        <div
          className={s.header_title}
          onClick={() => {
            navigate('/');
          }}
        >
          Business Ertis
        </div>
        <div className={s.wrap_language}>
          <div>
            <div
              className={s.language}
              aria-describedby={id}
              variant='contained'
              onClick={handleClick}
            >
              {language === 'ru' && (
                <div className={s.language}>
                  <div>Ru</div>
                  <img src={ru} alt='' style={{ marginLeft: 15 }} />
                </div>
              )}
              {language === 'kz' && (
                <div className={s.language}>
                  <div>Kz</div>
                  <img src={kz} alt='' />
                </div>
              )}
              {language === 'en' && (
                <div className={s.language}>
                  <div>En</div>
                  <img src={en} alt='' />
                </div>
              )}
            </div>
            <Popover
              id={id}
              open={openLanguage}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2 }} component={'div'}>
                <div className={s.language} onClick={() => changeLanguage('ru')}>
                  <div>Ru</div>
                  <img src={ru} alt='' style={{ marginLeft: 15 }} />
                </div>
                <div className={s.language} onClick={() => changeLanguage('kz')}>
                  <div>Kz</div>
                  <img src={kz} alt='' />
                </div>
                <div className={s.language} onClick={() => changeLanguage('en')}>
                  <div>En</div>
                  <img src={en} alt='' />
                </div>
              </Typography>
            </Popover>
          </div>
        </div>
      </div>
      <div className={s.header_right}>
        <div className={s.header_top_menu}>
          {menu_item.map((item, i) => (
            <div
              key={i}
              className={i === activeMenu ? s.active_menu_header : null}
              onClick={() => {
                setActiveMenu(i);
                navigate(item.url);
              }}
            >
              {item.item}
            </div>
          ))}
        </div>
        <div className={s.search_n_user}>
          {/* <div className={s.input_search}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23.9279 22.2749L16.1369 14.4839C17.3459 12.9209 17.9999 11.01 17.9999 8.99996C17.9999 6.59397 17.0609 4.33798 15.3629 2.63699C13.6649 0.935996 11.403 0 8.99996 0C6.59697 0 4.33498 0.938996 2.63699 2.63699C0.935996 4.33498 0 6.59397 0 8.99996C0 11.403 0.938996 13.6649 2.63699 15.3629C4.33498 17.0639 6.59397 17.9999 8.99996 17.9999C11.01 17.9999 12.9179 17.3459 14.4809 16.1399L22.2719 23.9279C22.2947 23.9508 22.3219 23.9689 22.3517 23.9813C22.3816 23.9936 22.4136 24 22.4459 24C22.4782 24 22.5102 23.9936 22.5401 23.9813C22.5699 23.9689 22.5971 23.9508 22.6199 23.9279L23.9279 22.6229C23.9508 22.6001 23.9689 22.5729 23.9813 22.5431C23.9936 22.5132 24 22.4812 24 22.4489C24 22.4166 23.9936 22.3846 23.9813 22.3547C23.9689 22.3249 23.9508 22.2977 23.9279 22.2749ZM13.7519 13.7519C12.4799 15.0209 10.794 15.7199 8.99996 15.7199C7.20597 15.7199 5.51998 15.0209 4.24798 13.7519C2.97899 12.4799 2.27999 10.794 2.27999 8.99996C2.27999 7.20597 2.97899 5.51698 4.24798 4.24798C5.51998 2.97899 7.20597 2.27999 8.99996 2.27999C10.794 2.27999 12.4829 2.97599 13.7519 4.24798C15.0209 5.51998 15.7199 7.20597 15.7199 8.99996C15.7199 10.794 15.0209 12.4829 13.7519 13.7519Z"
                fill="#292929"
              />
            </svg>
          </div> */}
          <div className={s.icon_profile}>
            <div onClick={handleOpenModalAuth}>
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M28.2816 26.2055C29.9227 24.231 31.0641 21.8902 31.6092 19.3813C32.1544 16.8724 32.0872 14.269 31.4134 11.7915C30.7397 9.31402 29.4791 7.03523 27.7384 5.14795C25.9976 3.26066 23.828 1.82039 21.4129 0.948975C18.9978 0.077559 16.4084 -0.199366 13.8636 0.141626C11.3189 0.482618 8.89371 1.4315 6.79324 2.90799C4.69278 4.38448 2.97882 6.34516 1.79637 8.62414C0.613921 10.9031 -0.00224272 13.4334 6.1338e-06 16.0009C0.000869098 19.7333 1.31615 23.3461 3.71504 26.2055L3.69219 26.2249C3.77218 26.3209 3.8636 26.4032 3.94587 26.4981C4.04872 26.6158 4.15956 26.7266 4.26584 26.8409C4.5858 27.1883 4.91491 27.5219 5.26002 27.8351C5.36515 27.931 5.47371 28.0202 5.57998 28.1116C5.94566 28.427 6.32162 28.7264 6.71129 29.0052C6.76157 29.0395 6.80728 29.0841 6.85756 29.1195V29.1058C9.53397 30.9892 12.7268 32 15.9994 32C19.2721 32 22.4649 30.9892 25.1413 29.1058V29.1195C25.1916 29.0841 25.2362 29.0395 25.2876 29.0052C25.6761 28.7252 26.0532 28.427 26.4189 28.1116C26.5252 28.0202 26.6337 27.9299 26.7389 27.8351C27.084 27.5208 27.4131 27.1883 27.7331 26.8409C27.8393 26.7266 27.949 26.6158 28.053 26.4981C28.1342 26.4032 28.2267 26.3209 28.3067 26.2238L28.2816 26.2055ZM15.9983 6.85899C17.0154 6.85899 18.0096 7.16058 18.8552 7.72563C19.7009 8.29067 20.36 9.09379 20.7492 10.0334C21.1384 10.9731 21.2402 12.007 21.0418 13.0045C20.8434 14.002 20.3536 14.9183 19.6345 15.6375C18.9153 16.3566 17.999 16.8464 17.0015 17.0448C16.004 17.2432 14.9701 17.1414 14.0304 16.7522C13.0908 16.363 12.2877 15.7039 11.7226 14.8582C11.1576 14.0126 10.856 13.0184 10.856 12.0013C10.856 10.6375 11.3978 9.32951 12.3621 8.36514C13.3265 7.40077 14.6345 6.85899 15.9983 6.85899ZM6.86442 26.2055C6.88424 24.7051 7.49401 23.2727 8.56179 22.2184C9.62958 21.1641 11.0695 20.5726 12.5701 20.5718H19.4265C20.9271 20.5726 22.367 21.1641 23.4348 22.2184C24.5026 23.2727 25.1124 24.7051 25.1322 26.2055C22.626 28.4639 19.372 29.7138 15.9983 29.7138C12.6247 29.7138 9.37062 28.4639 6.86442 26.2055Z'
                  fill='#292929'
                />
              </svg>
            </div>
            <Modal
              open={modalAuth}
              onClose={handleCloseModalAuth}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={!user ? styleModal : styleModalLogin}>
                <div className={s.close_auth_modal} onClick={handleCloseModalAuth}>
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                      stroke='#292929'
                      strokeWidth='3'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <div className={s.title_auth_modal}>
                  {!user ? (
                    <Trans i18nKey='header.login.item1' />
                  ) : user?.status_user == 1 ? (
                    <div>
                      <Trans i18nKey='dopSection.item23' />
                    </div>
                  ) : user?.status_user == 2 ? (
                    <div>
                      <Trans i18nKey='dopSection.item24' />
                    </div>
                  ) : (
                    <div>
                      <Trans i18nKey='header.login.item9' />
                    </div>
                  )}
                </div>
                {!user ? (
                  <>
                    <form action='' onSubmit={handleSubmit(onLoginInnPass)}>
                      <div className={s.inputs_login}>
                        <TextField
                          className={s.input_login}
                          label={<Trans i18nKey='header.login.item2' />}
                          variant='outlined'
                          {...register('IIN', {
                            required: 'Введите ИИН',
                            pattern: {
                              value: /^\d+$/,
                              message: 'Вводить можно только цифры',
                            },
                            maxLength: {
                              value: 12,
                              message: 'Максимум 12 цифр',
                            },
                            minLength: {
                              value: 12,
                              message: 'Минимум 12 цифр',
                            },
                          })}
                        />
                        <div className={s.input_password_wrapper}>
                          <TextField
                            className={s.input_password}
                            id='input_password'
                            label={<Trans i18nKey='header.login.item3' />}
                            type='password'
                            {...register('password', {
                              required: 'Введите пароль',
                            })}
                          />
                          {iconEye ? (
                            <VisibilityIcon className={s.icon_view_eye} onClick={viewOnPassword} />
                          ) : (
                            <VisibilityOffIcon
                              className={s.icon_view_eye}
                              onClick={viewOfPassword}
                            />
                          )}
                        </div>
                      </div>
                      <div className={s.btn_forgot_password} onClick={handleOpenResetPassword}>
                        <Trans i18nKey='header.login.item4' />
                      </div>
                      <div className={s.btn_login_ecp} onClick={startLoginEcp}>
                        <Trans i18nKey='header.login.item7' />
                      </div>
                      {isLoadingAuth && (
                        <div className={s.status_auth}>
                          <div>
                            <Trans i18nKey='header.login.item10' />
                          </div>
                          <LoadingDots />
                        </div>
                      )}
                      <div className={s.validate_form}>
                        {error && <span>{error}</span>}
                        {errors?.IIN && <span>{errors?.IIN?.message || 'Error!'}</span>}
                        {errors?.password && <span>{errors?.password?.message || 'Error!'}</span>}
                      </div>
                      <button className={s.btn_login}>
                        <Trans i18nKey='header.login.item5' />
                      </button>
                    </form>
                    <button className={s.btn_register} onClick={handleOpenModalReg}>
                      <Trans i18nKey='header.login.item6' />
                    </button>
                    {ncaLayerStatus && <div className={s.ncaLayerLoad_modal}>{ncaLayerStatus}</div>}
                    {ncaLayerErr && <div className={s.ncaLayerError_modal}>{ncaLayerErr}</div>}
                  </>
                ) : user?.status_user == 0 ? (
                  <ModalAfterAuth
                    countNotifLS={countNotifLS}
                    setCountNotifLS={setCountNotifLS}
                    dispatch={dispatch}
                    setUserLogin={setUserLogin}
                    handleCloseModalAuth={handleCloseModalAuth}
                    toggleDrawerServFirst={toggleDrawerServFirst}
                    toggleDrawerUslugiFirst={toggleDrawerUslugiFirst}
                    Trans={Trans}
                  />
                ) : user?.status_user == 1 ? (
                  <ModalAfterAuthForAdmin
                    countNotifLS={countNotifLS}
                    setCountNotifLS={setCountNotifLS}
                    dispatch={dispatch}
                    setUserLogin={setUserLogin}
                    Trans={Trans}
                    handleCloseModalAuth={handleCloseModalAuth}
                    toggleDrawerServFirst={toggleDrawerServFirst}
                    toggleDrawerUslugiFirst={toggleDrawerUslugiFirst}
                  />
                ) : user?.status_user == 2 ? (
                  <ModalAfterAuthForAdmin
                    countNotifLS={countNotifLS}
                    setCountNotifLS={setCountNotifLS}
                    dispatch={dispatch}
                    setUserLogin={setUserLogin}
                    handleCloseModalAuth={handleCloseModalAuth}
                    toggleDrawerServFirst={toggleDrawerServFirst}
                    toggleDrawerUslugiFirst={toggleDrawerUslugiFirst}
                    Trans={Trans}
                  />
                ) : (
                  console.info()
                )}
              </Box>
            </Modal>

            <ModalRegistration
              modalRegistation={modalRegistation}
              handleCloseModalReg={handleCloseModalReg}
              handleOpenModalReg={handleOpenModalReg}
              dispatch={dispatch}
              Trans={Trans}
            />

            <ModalActivateRecovery
              dispatch={dispatch}
              openAcivateRecovery={openAcivateRecovery}
              handleCloseAcivateRecovery={handleCloseAcivateRecovery}
              activateRecoveryIin={activateRecoveryIin}
            />

            <ModalResetPassword
              openResetPassword={openResetPassword}
              handleCloseResetPassword={handleCloseResetPassword}
            />

            <Modal
              open={openAcivateRecoveryErr}
              onClose={handleCloseAcivateRecoveryErr}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={styleActiveArr}>
                <div className={s.close_auth_modal} onClick={handleCloseAcivateRecoveryErr}>
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                      stroke='#292929'
                      strokeWidth='3'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <div className={s.err_active_recovery}>{activateRecoveryErrText}</div>
                <button className={s.btn_login} onClick={handleOpenModalReg}>
                  <Trans i18nKey='header.login.item6' />
                </button>
                <button className={s.btn_register} onClick={handleCloseAcivateRecoveryErr}>
                  <Trans i18nKey='header.login.item11' />
                </button>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
