import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainPageOptionsStates } from '../../redux/slices/mainSlice';
import { useNavigate } from 'react-router-dom';

import s from './DefaultSideBar.module.css';
import { errorData } from '../../common/helpers/sliceToasts';

import UslugiDrawer from './UslugiDrawer/UslugiDrawer';
import AnalitikaDrawer from './AnalitikaDrawer/AnalitikaDrawer';
import ServiceDrawer from './ServiceDrawer/ServiceDrawer';
import ERMDrawer from './ERMDrawer/ERMDrawer';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { styled, useTheme } from '@mui/material/styles';

import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SummarizeIcon from '@mui/icons-material/Summarize';

const DefaultSideBarCrup = ({ Trans, toggleDriwer1, toggleDriwer2 }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const width = useSelector((state) => state.store.mainSlice.width);
  const tabs = useSelector((state) => state.store.mainSlice.mainPageOptionsState);

  var warrObjects2 = [];

  const [state, setState] = React.useState({ left: false });

  const user = useSelector((state) => state.store.mainSlice.user);
  const [userStatus, setUserStatus] = React.useState(false);
  const [SotrudnikStatus, setSotrudnikStatus] = React.useState(false);
  const [AdminStatus, setAdminStatus] = React.useState(false);

  React.useEffect(() => {
    user
      ? user.status_user == '0'
        ? setUserStatus(user.status_user)
        : user.status_user == '1'
        ? setSotrudnikStatus(user.status_user)
        : user.status_user == '2'
        ? setAdminStatus(user.status_user)
        : errorData('Ошибка аутентификации')
      : console.info();
  }, [user]);

  React.useEffect(() => {
    toggleDriwer1 && toggleDrawer('left', true, 'Сервисы');
  }, [toggleDriwer1]);

  React.useEffect(() => {
    toggleDriwer2 && toggleDrawer('left', true, 'Услуги');
  }, [toggleDriwer2]);

  const toggleDrawer = (anchor, open, cat) => {
    setState({ ...state, [anchor]: open });
    makeMenu(cat);
    console.log(123123);
  };

  const list = (anchor) =>
    anchor == 'Услуги' ? (
      <UslugiDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : anchor == 'Сервисы' ? (
      <ServiceDrawer
        dispatch={dispatch}
        Trans={Trans}
        width={width}
        handleDrawerClose={handleDrawerClose}
      />
    ) : anchor == 'Единое рабочее место' ? (
      <ERMDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    ) : (
      <AnalitikaDrawer dispatch={dispatch} Trans={Trans} handleDrawerClose={handleDrawerClose} />
    );

  const makeMenu = (cat) => {
    for (let i = 0; i < 3; i++) {
      let user = makeCat(cat);
      warrObjects2[i] = user;
    }
    dispatch(mainPageOptionsStates(warrObjects2));
  };

  const makeCat = (cat) => {
    return {
      cat: cat,
      side: 'left',
    };
  };

  const handleDrawerClose = () => {
    setState({ ...state, ['left']: false });
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'left',
  }));

  return (
    <>
      <div>
        {tabs.map((anchor, index) => (
          <React.Fragment key={index}>
            <Drawer
              BackdropProps={{
                sx: { backgroundColor: 'rgb(0 0 0 / 25%) ', left: width },
              }}
              PaperProps={{ sx: { left: width, boxShadow: 'none' } }}
              anchor={anchor.side}
              open={state[anchor.side]}
              onClose={() => {
                toggleDrawer(anchor.side, false);
              }}
              variant={'temporary'}
            >
              <div
                style={{ width: width, height: 'inherit', left: 0, position: 'fixed' }}
                onClick={() => {
                  toggleDrawer(anchor.side, false);
                }}
              ></div>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </DrawerHeader>

              {list(anchor.cat)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default DefaultSideBarCrup;
