import { useEffect, useState } from 'react';

import { personalAreaAPI } from '../../../api/api';
import { useSelector } from 'react-redux';
import { errorData, successData, infoData } from '../../../common/helpers/sliceToasts';
import { appealsAPI } from '../../../api/api';

import pdfMake from 'pdfmake';
import vfs from './fonts/vfs_fonts';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { NCALayerClient } from 'ncalayer-js-client';

import './../../../pages/AppealsPage/AppealsPage.module.css';

const PDFEdit = ({
  setOpenModal,
  setSendAppealsReload,
  SendAppealsReload,
  handleCloseModal,
  IDforPDFAppeal,
  selectedValue,
  fileID,
  dataB64,
  ws,
  TEXTforPDFAppeal,
  title,
  DopCatselectedValue,
  CatselectedValue,
  appealNumber,
  appealDate,
  setModalStep3,
  DeleteID,
  IDforAppeal,
  selectedLangForSend,
  PDFEdit,
  Trans,
}) => {
  const [docDefinition, setDocDefinition] = useState({});
  const [personalInfo, setPersonalInfo] = useState([]);
  const [iframSrc, setIframeUrl] = useState('');
  const [modalWidth, setmodalWidth] = useState('0');
  const [AppealDataB64, setAppealDataB64] = useState(null);
  const [AppealDataB64Title, setAppealDataB64Title] = useState('-');
  const [error, seterror] = useState(null);

  const user = useSelector((state) => state.store.mainSlice.user);

  let ncaLayer = new NCALayerClient();
  const axios = require('axios').default;

  let predefinedEmails = [], // Сюда можно добавить сервисные адреса электронной почты
    awaitingSignature = false,
    signed = false,
    sigexId = null,
    connecting = true,
    description = 'Подписано в информационной системе Business Ertis',
    sigexURL = 'https://sigex.kz';

  useEffect(() => {
    setTableBodyData();
  }, [personalInfo]);

  useEffect(() => {
    const fd = new FormData();
    fd.append('id', user.id);
    const res = personalAreaAPI.fetchPersonalInfo(fd);
    res.then((res) => setPersonalInfo(res)).catch((err) => console.log(err));
  }, []);

  const setTableBodyData = () => {
    const template = { ...docDefinitionDefault };
    setDocDefinition(template);
  };
  const create = () => {
    setmodalWidth('520');
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      setAppealDataB64(data);
      console.log(data);
    });
    setAppealDataB64Title('BusinessErtisAppeal_' + appealNumber + '.pdf');
    console.log('BusinessErtisAppeal_' + appealNumber);
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setIframeUrl(dataUrl);

      // pdfDocGenerator.download();
      // handleCloseModal();
      console.log(personalInfo);
      console.log(personalInfo[0].city);
    });
  };

  const handleItemSend = () => {
    selectStorageAndContinue();
  };

  async function selectStorageAndContinue() {
    let storageTypeIn = '';

    try {
      await ncaLayer.connect();
      connecting = false;
    } catch (err) {
      console.log('Ошибка: Включите программу NCALayer' + err);
      errorData(<Trans i18nKey='MyNotFinishAppeals.item7' /> + err);
    }
    awaitingSignature = true;

    try {
      const storageTypes = await ncaLayer.getActiveTokens();

      if (storageTypes.length > 1) {
        console.log(
          'Обнаружено несколько разных типов защищенных хранилищ подключенных к компьютеру',
        );
        errorData(<Trans i18nKey='MyNotFinishAppeals.item8' />);
        return;
      }

      if (storageTypes.length == 0) {
        storageTypeIn = 'PKCS12';
      } else {
        storageTypeIn = storageTypes[0];
      }
      console.log(storageTypeIn);
    } catch (err) {
      console.log('NCALayer вернул неожиданную ошибку', err);
      errorData(<Trans i18nKey='MyNotFinishAppeals.item9' />, err);
      return;
    }

    signAndSend(storageTypeIn);
  }

  async function signAndSend(storageType) {
    let signature;
    try {
      console.log(AppealDataB64);
      signature = await ncaLayer.createCMSSignatureFromBase64(storageType, AppealDataB64);
    } catch (err) {
      seterror({
        message: 'NCALayer вернул неожиданную ошибку',
        description: err,
      });
      errorData(<Trans i18nKey='MyNotFinishAppeals.item9' />, err);
      return;
    }

    try {
      // const emailNotifications = {
      //   to: predefinedEmails,
      // }
      let response = await axios.post(`${sigexURL}/api`, {
        title: AppealDataB64Title,
        description: description,
        signature,
        // emailNotifications,
      });
      if (response.data.message) {
        this.error = {
          message: 'Сервер не принял подпись',
          description: response.data.message,
        };
        errorData(<Trans i18nKey='MyNotFinishAppeals.item11' />, response.data.message);
        return;
      }

      sigexId = response.data.documentId;

      const dataToSend = Uint8Array.from(atob(AppealDataB64), (c) => c.charCodeAt(0)).buffer;
      response = await axios.post(`${sigexURL}/api/${sigexId}/data`, dataToSend, {
        headers: { 'Content-Type': 'application/octet-stream' },
      });

      if (response.data.message) {
        seterror({
          message: 'Сервер не принял подпись (проблема с проверкой подписанных данных)',
          description: response.data.message,
        });
        errorData(<Trans i18nKey='MyNotFinishAppeals.item12' />, response.data.message);
        return;
      }

      awaitingSignature = false;
      signed = true;

      // const titleParts = title.split('.');
      // if (titleParts.length === 1) {
      //   settitle(`${title}-SigexId${sigexId}`);
      // } else {
      //   const extension = titleParts.pop();
      //   settitle(`${titleParts.join('.')}-SigexId${sigexId}.${extension}`);
      // }
    } catch (err) {
      seterror({
        message: 'Сервер вернул неожиданную ошибку',
        description: err,
      });
      errorData(<Trans i18nKey='MyNotFinishAppeals.item13' />, err);
    }
    successData(<Trans i18nKey='MyNotFinishAppeals.item14' />);

    GeneralSendAppeal(sigexId, AppealDataB64Title, AppealDataB64);
  }

  const GeneralSendAppeal = (sigexId, AppealDataB64Title, AppealDataB64) => {
    const fd = new FormData();
    fd.append('id', user.id);
    fd.append('text', TEXTforPDFAppeal);
    fd.append('title', selectedValue);
    fd.append('CatTitle', CatselectedValue);
    fd.append('DopCatTitle', DopCatselectedValue);
    fd.append('metod', 'SendAppeals');
    fd.append('attachment', title);
    fd.append('attachmentID', fileID);
    dataB64 === null
      ? console.log(1)
      : fd.append('dataB64', `data:application/pdf;base64,${dataB64}`);
    fd.append('id_appeal', appealNumber);
    fd.append('date', appealDate);
    fd.append('MainDataB64', AppealDataB64Title);
    fd.append('MainAppealDataB64', `data:application/pdf;base64,${AppealDataB64}`);
    fd.append('sigexId', sigexId);
    fd.append('lang', selectedLangForSend);

    const ItemSend = appealsAPI.SendAppeals(fd);
    ItemSend.then((res) =>
      res.message == 'Такой файл уже есть!'
        ? errorData(<Trans i18nKey='SendAppeals.item15' />)
        : infoData(res.message),
    );
    ItemSend.then((res) => (res.message == 'Такой файл уже есть!' ? null : SuccesSend()));
  };

  const SuccesSend = () => {
    const fet = new FormData();
    fet.append('IIN', user.IIN);
    fet.append('id', DeleteID);

    const ItemDelete = appealsAPI.DeleteAppels(fet);
    ItemDelete.then((res) => console.log(res));

    ws.send('SendAppeals');
    handleCloseModal();
    setOpenModal(false);
    setModalStep3(false);
    setSendAppealsReload(!SendAppealsReload);
  };

  const SendAppealDraft = () => {
    const fdd = new FormData();
    fdd.append('id', appealNumber);
    fdd.append('metod', 'DeleteAppeals');
    const ItemSendDelete = appealsAPI.fetchAppealNumber(fdd);

    const fd = new FormData();
    fd.append('id', user.id);
    fd.append('text', TEXTforPDFAppeal);
    fd.append('title', selectedValue);
    fd.append('CatTitle', CatselectedValue);
    fd.append('DopCatTitle', DopCatselectedValue);
    fd.append('metod', 'SendAppeals');
    fd.append('attachment', title);
    fd.append('attachmentID', fileID);
    dataB64 === null
      ? console.log(1)
      : fd.append('dataB64', `data:application/pdf;base64,${dataB64}`);
    fd.append('id_appeal', IDforAppeal);
    fd.append('date', appealDate);
    fd.append('MainDataB64', AppealDataB64Title);
    fd.append('MainAppealDataB64', `data:application/pdf;base64,${AppealDataB64}`);
    fd.append('lang', selectedLangForSend);

    const ItemSend = appealsAPI.editAppealDraft(fd);
    ItemSend.then((res) =>
      res.message == 'Такой файл уже есть!'
        ? errorData(<Trans i18nKey='SendAppeals.item15' />)
        : infoData(res.message),
    );

    // ItemSend.then((res) =>
    // 	res.message == 'Такой файл уже есть!' ? null : ws.send('SendAppeals')
    // );
    handleCloseModal();
    setOpenModal(false);
    setModalStep3(false);
    setSendAppealsReload(!SendAppealsReload);
  };

  pdfMake.vfs = vfs;
  pdfMake.fonts = {
    NimbusSans: {
      normal: 'NimbusSanL-Reg.otf',
      bold: 'NimbusSanL-Bol.otf',
      italics: 'NimbusSanL-RegIta.otf',
      bolditalics: 'NimbusSanL-BolIta.otf',
    },
  };

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
    },
  });

  const docDefinitionDefault = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 60, 40, 60],
    content: [
      {
        text: 'Общие сведения ',
        style: 'header',
        alignment: 'left',
      },
      {
        style: 'table1',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Тип заявителя', 'Характер обращения', 'Номер обращения '],
            ['Физическое лицо', 'Индвидуальное', appealNumber],
          ],
        },
      },
      {
        text: 'Обращение',
        style: 'header2',
        alignment: 'left',
      },
      {
        style: 'table1',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Вид обращения ', 'Форма обращения', 'Язык обращения'],
            [
              selectedValue,
              'Электронная',
              selectedLangForSend == 'ru'
                ? 'Русский'
                : selectedLangForSend == 'kz'
                ? 'Қазақ'
                : selectedLangForSend == 'en'
                ? 'English'
                : '-',
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Категория', 'Подкатегория', 'Город/Село/Аул'],
            [
              CatselectedValue,
              DopCatselectedValue,
              personalInfo[0]?.city ? personalInfo[0].city : 'J',
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Регистрационный номер', 'Срок рассмотрения', 'Срок предоставление ответа'],
            ['34714128947', 'Неизвестно', 'Неизвестно'],
          ],
        },
      },
      {
        style: 'table3',
        alignment: 'center',
        table: {
          widths: ['*', '68%'],
          heights: [100, 100],
          body: [['Описание', TEXTforPDFAppeal ? TEXTforPDFAppeal : 'j']],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['*', '68%'],
          body: [['Вложенные документы', title]],
        },
      },
      {
        text: 'Заявитель',
        style: 'header2',
        alignment: 'left',
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['БИН/ИИН', 'Наименование юр.лица', 'Дата регистрации обращения'],
            [
              personalInfo[0]?.IIN ? personalInfo[0].IIN : 'j',
              personalInfo[0]?.organization ? personalInfo[0].organization : 'j',
              appealDate,
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['ФИО', 'Категория юр.лица', 'Адрес регистрации'],
            [
              (personalInfo[0]?.last_name ? personalInfo[0].last_name : 'j') +
                ' ' +
                (personalInfo[0]?.first_name ? personalInfo[0].first_name : 'j') +
                ' ' +
                (personalInfo[0]?.middle_name ? personalInfo[0].middle_name : 'j'),
              'Неизвестно',
              'Неизвестно',
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Фактический адрес', 'Номер телефона', 'Эл.почта'],
            [
              'Неизвестно',
              personalInfo[0]?.number ? personalInfo[0].number : 'J',
              personalInfo[0]?.email ? personalInfo[0].email : 'j',
            ],
          ],
        },
      },
      {
        text: 'Жалпы мәліметтер',
        style: 'header3',
        alignment: 'left',
      },
      {
        style: 'table1',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Өтініш берушінің түрі', 'Өтініш сипаты', 'Өтініш нөмірі'],
            ['Жеке тұлға', 'Жеке', appealNumber],
          ],
        },
      },
      {
        text: 'Үндеу',
        style: 'header2',
        alignment: 'left',
      },
      {
        style: 'table1',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Өтініш түрі', 'Жүгіну нысаны', 'Жүгіну тілі'],
            [
              selectedValue,
              'Электрондық',
              selectedLangForSend == 'ru'
                ? 'Русский'
                : selectedLangForSend == 'kz'
                ? 'Қазақ'
                : selectedLangForSend == 'en'
                ? 'English'
                : '-',
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Санаты', 'Ішкі санат', 'Қала / Ауыл / Ауыл'],
            [
              CatselectedValue,
              DopCatselectedValue,
              personalInfo[0]?.city ? personalInfo[0].city : '-',
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Тіркеу нөмірі', 'Қарау мерзімі', 'Жауап беру мерзімі'],
            ['34714128947', 'Белгісіз', 'Белгісіз'],
          ],
        },
      },
      {
        style: 'table3',
        alignment: 'center',
        table: {
          widths: ['*', '68%'],
          heights: [100, 100],
          body: [['Сипаттамасы', TEXTforPDFAppeal ? TEXTforPDFAppeal : '-']],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['*', '68%'],
          body: [['Салынған құжаттар', title]],
        },
      },
      {
        text: 'Өтініш беруші',
        style: 'header2',
        alignment: 'left',
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['БСН / ЖСН', 'Заңды тұлғаның атауы', 'Өтінішті тіркеу күні'],
            [
              personalInfo[0]?.IIN ? personalInfo[0].IIN : '-',
              personalInfo[0]?.organization ? personalInfo[0].organization : '-',
              appealDate,
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Аты-жөні', 'Заңды тұлғаның санаты', 'Тіркеу мекенжайы'],
            [
              (personalInfo[0]?.last_name ? personalInfo[0].last_name : '-') +
                ' ' +
                (personalInfo[0]?.first_name ? personalInfo[0].first_name : '') +
                ' ' +
                (personalInfo[0]?.middle_name ? personalInfo[0].middle_name : ''),
              'Белгісіз',
              'Белгісіз',
            ],
          ],
        },
      },
      {
        style: 'table2',
        alignment: 'center',
        table: {
          widths: ['33%', '33%', '33%'],
          body: [
            ['Нақты мекен-жайы', 'Телефон нөмірі', 'Эл.пошта'],
            [
              'Белгісіз',
              personalInfo[0]?.number ? personalInfo[0].number : '-',
              personalInfo[0]?.email ? personalInfo[0].email : '-',
            ],
          ],
        },
      },
    ],
    defaultStyle: {
      font: 'NimbusSans',
    },
    styles: {
      withMargin: {
        margin: [20, 20, 20, 20],
      },
      alignCenter: {
        alignment: 'center',
      },
      header: {
        fontSize: 18,
        bold: true,
      },
      header2: {
        marginTop: 20,
        fontSize: 18,
        bold: true,
      },
      header3: {
        marginTop: 150,
        fontSize: 18,
        bold: true,
      },
      textBody: {
        fontSize: 12,
      },
      subheader: {
        fontSize: 15,
        bold: true,
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 8,
      },
      table1: {
        marginTop: 20,
      },
      table2: {
        marginTop: 10,
      },
      table3: {
        marginTop: 5,
      },
    },
  };

  return (
    <div className='PDFcreate'>
      <iframe
        title='myBook'
        src={iframSrc}
        height='310'
        width='800'
        frameBorder='0'
        scrolling='no'
        allowFullScreen={false}
        // ref={refIframe}
      ></iframe>
      <ThemeProvider theme={theme}>
        <Button
          color='neutral'
          sx={{ height: '48px', width: '100%' }}
          variant='outlined'
          onClick={create}
        >
          <Trans i18nKey='SendAppeals.item12' />
        </Button>
        {/* Документ{' '}
        <a
          href={`data:application/pdf;base64,${AppealDataB64}`}
          target='_blank'
          download={AppealDataB64Title}
        >
          {AppealDataB64Title}
        </a>{' '}
        успешно подписан. */}
        <Button
          color='neutral'
          disabled={AppealDataB64Title == '-' ? true : false}
          sx={{
            mt: '24px',
            height: '48px',
            width: '100%',
            boxShadow: 'none',
            paddingTop: '15px',
          }}
          variant='contained'
          onClick={handleItemSend}
        >
          <Trans i18nKey='SendAppeals.item13' />
        </Button>
        <Button
          color='neutral'
          disabled={AppealDataB64Title == '-' ? true : false}
          sx={{ mt: '24px', height: '48px', width: '100%' }}
          variant='outlined'
          onClick={SendAppealDraft}
        >
          <Trans i18nKey='SendAppeals.item14' />
        </Button>
      </ThemeProvider>
    </div>
  );
};
export default PDFEdit;
