import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { appealsAPI } from '../../../api/api';
import { infoData } from '../../../common/helpers/sliceToasts';
import { useSelector } from 'react-redux';

import s from '../../../pages/AppealsPage/AppealsPage.module.css';

import { setSelectedItemDispatch } from '../../../redux/slices/appealsSlice';

const AppealViewForAdminPDF = ({
  CloseModal,
  IDforPDFAppeal,
  IDforAppeal,
  handleOpenModal,
  SelectedItem,
  IDforAcceptAppeal,
  GetDataAfterChange,
  SelectedItemsAcceptables,
  status,
  functionFor,
  dispatch,
  SIGEXIDforPDFAppeal,
  Trans,
  ws,
}) => {
  const [pdfName, setpdfName] = React.useState('none');
  const user = useSelector((state) => state.store.mainSlice.user);

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#FF7F00',
        contrastText: '#fff',
      },
      refused: {
        main: '#FF0000',
        contrastText: '#fff',
      },
    },
  });

  const styleModal = {
    position: 'absolute',
    top: 380,
    right: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: '33px 48px',
    p: 4,
    outline: 0,
  };

  const handleCloseModal = () => {
    CloseModal();
  };

  const handleStatus = (onChangeStatus) => {
    const fd = new FormData();
    var arr2 = [];
    let obj = {
      id: IDforAcceptAppeal,
      status: onChangeStatus,
      user: user.id,
    };

    arr2.push(obj);
    arr2 = JSON.stringify(arr2);
    console.log(arr2);
    fd.append('array', arr2);
    const appealsResponse = appealsAPI.fetchchangeStatus(fd);
    appealsResponse.then((res) => GetDataAfterChange());

    let strAp = '';
    strAp = 'ActionAppeals,' + IDforAcceptAppeal;
    setTimeout(() => ws.send(strAp), 1000);
  };

  React.useEffect(() => {
    dispatch(setSelectedItemDispatch(SelectedItem));
    const fd = new FormData();
    fd.append('id', IDforAppeal == 'none' ? IDforPDFAppeal : IDforAppeal);
    const appealsResponse = appealsAPI.fetchAppealPDF(fd);
    const appealsResponseDraft = appealsAPI.fetchgetPDFNameDraft(fd);

    IDforAppeal == 'none'
      ? appealsResponse.then((res) => setpdfName(res[0].MainDataB64))
      : appealsResponseDraft.then((res) => setpdfName(res[0].MainDataB64));
  }, [IDforPDFAppeal]);

  return (
    <>
      <Modal
        open={true}
        // onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal}>
          <div className={s.close_auth_modal} onClick={handleCloseModal}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div>
              <Link
                target='_'
                href={`https://sigex.kz/show/?id=${SIGEXIDforPDFAppeal}#documentMenu`}
                underline='hover'
                color='inherit'
              >
                <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item23' /> #
                {SIGEXIDforPDFAppeal}
              </Link>
            </div>
          </div>
          <Box sx={{ minWidth: 120, mb: '25px' }}>
            {pdfName == 'none' ? null : (
              <iframe
                title='myBook'
                src={'https://biznes.15000pvl.kz/file/appeal/' + pdfName}
                height='500'
                width='800'
                frameBorder='0'
                scrolling='no'
                allowFullScreen={false}
              ></iframe>
            )}
          </Box>
          {functionFor != 'Fulfilled' ? (
            functionFor != 'ReturnWithoutPerformer' ? (
              <ThemeProvider theme={theme}>
                {functionFor == 'OnExecution' ? (
                  <Button
                    color='neutral'
                    sx={{ mb: '30px', height: '48px', width: '240px' }}
                    variant='contained'
                    disabled={
                      SelectedItem
                        ? SelectedItem?.length == '0'
                          ? true
                          : SelectedItemsAcceptables > 0
                          ? true
                          : false
                        : true
                    }
                    onClick={() => {
                      handleOpenModal('end');
                      CloseModal();
                    }}
                  >
                    <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item21' />
                  </Button>
                ) : (
                  <Button
                    color='neutral'
                    sx={{ mb: '30px', height: '48px', width: '240px' }}
                    variant='contained'
                    disabled={
                      SelectedItem
                        ? SelectedItem?.length == '0'
                          ? true
                          : SelectedItemsAcceptables > 0
                          ? true
                          : false
                        : true
                    }
                    onClick={() => {
                      handleStatus(2);
                      CloseModal();
                    }}
                  >
                    <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item8' />
                  </Button>
                )}
                {status == 'Admin' ? (
                  <Button
                    color='neutral'
                    sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                    variant='outlined'
                    disabled={
                      SelectedItem
                        ? SelectedItem?.length == '0'
                          ? true
                          : SelectedItemsAcceptables > 0
                          ? true
                          : false
                        : true
                    }
                    onClick={() => {
                      handleOpenModal('transfer');
                      dispatch(setSelectedItemDispatch(SelectedItem));
                    }}
                  >
                    <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item9' />
                  </Button>
                ) : null}
                <Button
                  color='refused'
                  sx={{ ml: '30px', mb: '30px', height: '48px', width: '240px' }}
                  variant='outlined'
                  disabled={
                    SelectedItem
                      ? SelectedItem?.length == '0'
                        ? true
                        : SelectedItemsAcceptables > 0
                        ? true
                        : false
                      : true
                  }
                  onClick={() => {
                    handleOpenModal('refuse');
                    CloseModal();
                    dispatch(setSelectedItemDispatch(SelectedItem));
                  }}
                >
                  <Trans i18nKey='AdminChooseAppealsType.AdminAppeals.AllAppeals.item10' />
                </Button>
              </ThemeProvider>
            ) : null
          ) : null}
        </Box>
      </Modal>
    </>
  );
};

export default AppealViewForAdminPDF;
