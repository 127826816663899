/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { NCALayerClient } from 'ncalayer-js-client';

import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import s from './header.module.css';

import { setNcaLayerErr, setNcaLayerStatus } from '../../redux/slices/mainSlice';

import objToDf from '../../common/helpers/objToFd';
import LoadingDots from '../LoadingDots/LoadingDots';
import { mainPage } from '../../api/api';

const styleModalReg = {
  position: 'absolute',
  top: '25%',
  left: '93%',
  transform: 'translate(-93%, -25%)',
  maxWidth: 768,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleFullReg = {
  position: 'absolute',
  top: '25%',
  left: '81%',
  transform: 'translate(-81%, -25%)',
  maxWidth: 736,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingTop: '33px',
  padding: '33px 48px',
};
const styleModalSuccess = {
  position: 'absolute',
  top: '25%',
  left: '81%',
  transform: 'translate(-81%, -25%)',
  maxWidth: 424,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingTop: '33px',
  padding: '33px 48px',
};
export default function ModalRegistration({
  modalRegistation,
  handleCloseModalReg,
  handleOpenModalReg,
  dispatch,
  Trans,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  let ncaLayer = new NCALayerClient();

  const [viewCheck, setViewCheck] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [checkedErr, setCheckedErr] = React.useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = React.useState(false);
  const [openStageFullReg, setopenStageFullReg] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [oblast, setOblast] = React.useState('Павлодарская область');
  const [city, setCity] = React.useState('Павлодар');
  const [area, setArea] = React.useState('Павлодарская р-он');
  const [isErrorReg, setIsErrorReg] = React.useState(false);
  const [isErrorRegStatus, setIsErrorRegStatus] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [userEcp, setUserEcp] = React.useState(false);
  const [userForm, setUserForm] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);

  const ncaLayerErr = useSelector((state) => state.store.mainSlice.ncaLayerErr);
  const ncaLayerStatus = useSelector((state) => state.store.mainSlice.ncaLayerStatus);

  let undataB64, storageType;

  React.useEffect(() => {
    userEcp && checkEcpNFormData();
  }, [userEcp]);

  //START------------------------------Модальное окно с текстом соглашения
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setCheckedErr(false);
  };
  const handleNextBtn = () => {
    if (checked) {
      handleCloseModalReg();
      handleopenStageFullReg();
    } else {
      setCheckedErr(true);
    }
    setChecked(false);
    setViewCheck(false);
  };
  const trackerScrolling = (e) => {
    let st = e.target.scrollTop;
    st >= 300 ? setViewCheck(true) : setViewCheck(false);
  };

  //END------------------------------Модальное окно с текстом соглашения

  //START------------------------------Модальное окно полной регистрации
  const handleopenStageFullReg = () => setopenStageFullReg(true);
  const handlCloseStageFullReg = () => {
    setopenStageFullReg(false);
    dispatch(setNcaLayerStatus(null));
    dispatch(setNcaLayerErr(null));
    reset();
  };
  const handlBackStageFullReg = () => {
    setopenStageFullReg(false);
    handleOpenModalReg();
  };

  const handleChangeOblast = (event) => {
    setOblast(event.target.value);
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangeArea = (event) => {
    setArea(event.target.value);
  };

  const handleRegistrationForm = (data) => {
    setPhone(true);
    const isPhone = data.number
      .split('')
      .filter(
        (item) => item !== '_' && item !== '-' && item !== '(' && item !== ')' && item !== '+',
      );
    if (isPhone.length === 11) {
      setUserForm(data);
      startLoginEcp();
    } else {
      setPhone(false);
    }
  };

  const startLoginEcp = async () => {
    //  ЭЦП
    setIsErrorReg(false);

    setIsErrorRegStatus(false);
    dispatch(setNcaLayerErr(null));
    dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item1' />));

    fetch('https://sigex.kz/api/auth', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
      },

      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(function (data) {
        undataB64 = data.nonce;

        selectStorageAndContinue();
      })
      .catch(function (error) {
        dispatch(setNcaLayerStatus(null));
        dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item2' /> + ' ' + error));

        console.log('Request failed', error);
      });
  };

  const selectStorageAndContinue = async () => {
    //работает при нажатии на кнопку Подписать документ
    try {
      await ncaLayer.connect();
    } catch (err) {
      console.log(err);
      dispatch(setNcaLayerStatus(null));
      dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item3' /> + ' ' + err));
    }

    try {
      dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item4' />));

      const storageTypes = await ncaLayer.getActiveTokens();
      if (storageTypes.length > 1) {
        dispatch(setNcaLayerStatus(null));
        dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item5' />));

        return false;
      }

      if (storageTypes.length === 0) {
        storageType = 'PKCS12';
      } else {
        storageType = storageTypes[0];
      }
    } catch (err) {
      dispatch(setNcaLayerStatus(null));
      dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item3' />));

      console.log(err);
      return false;
    }
    signAndSend();
  };

  const signAndSend = async () => {
    // eslint-disable-next-line no-unused-vars
    let userId, email, fullname, dadname, serialnumber, nationality, signature;

    //открытие NCALAyer и подписка данных с sigex
    try {
      signature = await ncaLayer.createCAdESFromBase64(storageType, undataB64);
    } catch (err) {
      dispatch(setNcaLayerStatus(null));
      dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item6' />));

      console.log('NCALayer вернул неожиданную ошибку signAndSend', err);
      return; //Если произошла ошибка, то он выходит. Код дальше не обрабтывается
    }

    dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item7' />));

    //Отправка на API Sigex
    await fetch('https://sigex.kz/api/auth', {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        nonce: undataB64,
        signature: signature,
        external: true,
      }),
    })
      .then((response) => response.json())
      .then(function (data) {
        const res = data;
        let separatedArray = [];
        separatedArray = res.subject.split(',');

        userId = res.userId;
        email = res.email; //res.email.toLowerCase();
        fullname = separatedArray[0].replace('CN=', '');

        dadname = separatedArray[4].replace('GIVENNAME=', '');
        serialnumber = res.userId.replace('IIN', '');
        nationality = separatedArray[3].replace('C=', '');
        const last_name = data.subject.split(',')[1].replace('SURNAME=', '');
        const last_name_ver_2 = data.subject.split(',')[0].replace('SURNAME=', '');
        const last_name_ver_3 = separatedArray[0].replace('SURNAME=', '');

        const first_name = data.subject.split(',')[0].replace('CN=', '').split(' ')[1];
        const first_name_ver_2 = data.subject.split(',')[1].replace('CN=', '').split(' ')[1];
        const first_name_ver_3 = separatedArray[1].replace('CN=', '').split(' ')[1];

        const middle_name = data.subject.split(',')[4].replace('GIVENNAME=', '');
        let arr = {}; //ассоциативный массив

        arr['userId'] = userId;
        arr['email'] = email;
        arr['firstname'] = first_name;
        arr['lastname'] = last_name;
        arr['firstname_ver_2'] = first_name_ver_2;
        arr['lastname_ver_2'] = last_name_ver_2;
        arr['firstname_ver_3'] = first_name_ver_3;
        arr['lastname_ver_3'] = last_name_ver_3;
        arr['middlename'] = middle_name;
        arr['dadname'] = dadname;
        arr['serialnumber'] = serialnumber;
        arr['nationality'] = nationality;
        setUserEcp(arr);

        // console.log(arr);
      })
      .catch(function (error) {
        dispatch(setNcaLayerStatus(null));
        dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item8' /> + '  ' + error));

        console.log('Сервер не принял подпись (проблема с проверкой подписанных данных) ', error);
      });
  };

  const checkEcpNFormData = () => {
    dispatch(setNcaLayerStatus(null));
    dispatch(setNcaLayerErr(null));
    if (
      userForm.first_name.toUpperCase().trim() === userEcp.firstname &&
      userForm.last_name.toUpperCase().trim() === userEcp.lastname &&
      userForm.IIN.trim() === userEcp.serialnumber
    ) {
      setConfirmed(true);
      dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item10' />));
    } else if (
      userForm.first_name.toUpperCase().trim() === userEcp.firstname_ver_2 &&
      userForm.last_name.toUpperCase().trim() === userEcp.lastname_ver_2 &&
      userForm.IIN.trim() === userEcp.serialnumber
    ) {
      setConfirmed(true);
      dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item10' />));
    } else if (
      userForm.first_name.toUpperCase().trim() === userEcp.firstname_ver_3 &&
      userForm.last_name.toUpperCase().trim() === userEcp.lastname_ver_3 &&
      userForm.IIN.trim() === userEcp.serialnumber
    ) {
      setConfirmed(true);
      dispatch(setNcaLayerStatus(<Trans i18nKey='header.login.NCALayer.item10' />));
    } else {
      // console.log(userEcp);
      // console.log(
      //   userForm.first_name,
      //   userEcp.firstname,
      //   userEcp.firstname_ver_2,
      //   userEcp.firstname_ver_3,
      // );
      // console.log(
      //   userForm.last_name,
      //   userEcp.lastname,
      //   userEcp.lastname_ver_2,
      //   userEcp.lastname_ver_3,
      // );
      // console.log(userForm.IIN, userEcp.serialnumber, userEcp.serialnumber, userEcp.serialnumber);
      dispatch(setNcaLayerErr(<Trans i18nKey='header.login.NCALayer.item11' />));
    }
  };

  const confirmFormRegistation = () => {
    setIsLoadingAuth(true);
    setIsErrorReg(false);
    setIsErrorRegStatus(null);
    dispatch(setNcaLayerStatus(null));

    const fd = new FormData();
    objToDf(fd, userForm);
    fd.append('country', oblast);
    fd.append('city', city);
    fd.append('area', area);
    const registation = mainPage.userRegistation(fd);
    registation.then((res) => {
      if (res === true) {
        handlCloseStageFullReg();
        handleOpenSuccessModal();
        setIsLoadingAuth(false);
        setIsErrorReg(false);
        setIsErrorRegStatus(null);
      } else {
        setIsLoadingAuth(false);
        setIsErrorReg(true);
        setIsErrorRegStatus(res);
        setConfirmed(false);
      }
    });
  };

  //END------------------------------Модальное окно полной регистрации

  //START------------------------------Модальное окно успешной регистрации
  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    dispatch(setNcaLayerStatus(null));
    dispatch(setNcaLayerErr(null));
  };
  //END------------------------------Модальное окно успешной регистрации

  return (
    <>
      <Modal
        open={modalRegistation}
        onClose={handleCloseModalReg}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModalReg}>
          <div className={s.close_auth_modal} onClick={handleCloseModalReg}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div className={s.title_auth_modal}>
              <Trans i18nKey='header.login.item6' />
            </div>
          </div>
          <div className={s.content_reg_modal} onScroll={trackerScrolling}>
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета Текст о назначении личного кабинета Текст о назначении личного кабинета
            Текст о назначении личного кабинета Текст о назначении личного кабинета Текст о
            назначении личного кабинета Текст о назначении личного кабинета Текст о назначении
            личного кабинета
          </div>
          {viewCheck && (
            <div className={s.license_check}>
              <Checkbox onChange={handleChange} id='agree' />
              <label htmlFor='agree'>
                <Trans i18nKey='header.modalregistration.item1' />
              </label>
            </div>
          )}
          {checkedErr && (
            <div className={s.validate_form} style={{ marginTop: 15 }}>
              <Trans i18nKey='header.modalregistration.item2' />
            </div>
          )}
          <div className={s.buttons_group_reg}>
            <button className={s.btn_cancel_modal_reg} onClick={handleCloseModalReg}>
              <Trans i18nKey='header.login.item11' />
            </button>

            <button className={s.btn_login} onClick={handleNextBtn}>
              <Trans i18nKey='header.modalregistration.item3' />
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openStageFullReg}
        onClose={handlCloseStageFullReg}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleFullReg}>
          <div className={s.close_auth_modal} onClick={handlCloseStageFullReg}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div className={s.title_auth_modal}>
              <Trans i18nKey='header.login.item6' />
            </div>
          </div>
          <form className={s.form_registration} onSubmit={handleSubmit(handleRegistrationForm)}>
            <div className={s.group_input_registration}>
              <div className={s.labelNinputReg}>
                <label htmlFor=''>
                  <Trans i18nKey='header.modalregistration.item4' />*
                </label>
                <TextField
                  {...register('last_name', {
                    required: 'Введите фамилию',
                  })}
                />
                <div className={s.validate_form_reg}>
                  {errors?.last_name && <span>{errors?.last_name?.message || 'Error!'}</span>}
                </div>
              </div>
              <div className={s.labelNinputReg}>
                <label htmlFor=''>
                  <Trans i18nKey='header.modalregistration.item5' />*
                </label>
                <TextField
                  {...register('first_name', {
                    required: 'Введите имя',
                  })}
                />
                <div className={s.validate_form_reg}>
                  {errors?.first_name && <span>{errors?.first_name?.message}</span>}
                </div>
              </div>
              <div className={s.labelNinputReg}>
                <label htmlFor=''>
                  <Trans i18nKey='header.modalregistration.item6' />
                </label>
                <TextField id='outlined-required' {...register('middle_name')} />
              </div>
            </div>
            <div className={s.group_input_select}>
              <FormControl fullWidth>
                <InputLabel className={s.label_select} style={{ top: -5, left: -12 }}>
                  <Trans i18nKey='header.modalregistration.item7' />*
                </InputLabel>
                <Select value={oblast} required onChange={handleChangeOblast}>
                  <MenuItem value={'Павлодарская область'}>Павлодарская область</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel className={s.label_select} style={{ top: -5, left: -12 }}>
                  <Trans i18nKey='header.modalregistration.item8' />*
                </InputLabel>
                <Select value={area} onChange={handleChangeArea}>
                  <MenuItem value={'Павлодарская р-он'}>Павлодарская р-он</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel className={s.label_select} style={{ top: -5, left: -12 }}>
                  <Trans i18nKey='header.modalregistration.item9' />*
                </InputLabel>
                <Select value={city} onChange={handleChangeCity}>
                  <MenuItem value={'Павлодар'}>Павлодар</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={s.group_input_registration}>
              <div className={s.labelNinputReg}>
                <label htmlFor=''>
                  <Trans i18nKey='header.modalregistration.item10' />*
                </label>

                <InputMask
                  mask='+7(999)999-99-99'
                  style={{ width: '100%' }}
                  placeholder='+7(___)___-__-__'
                  onChange={(e) => setPhone(e.target.value)}
                  className={s.input_iin_one}
                  {...register('number')}
                />
                <div className={s.validate_form_reg}>
                  {!phone && phone !== null && <span>Введите номер телефона</span>}
                </div>
              </div>
              <div className={s.labelNinputReg}>
                <label htmlFor=''>Email*</label>
                <TextField
                  id='outlined-required'
                  type='email'
                  {...register('email', {
                    required: 'Введите email',
                  })}
                />
                <div className={s.validate_form_reg}>
                  {errors?.email && <span>{errors?.email?.message}</span>}
                </div>
              </div>
              <div className={s.labelNinputReg}>
                <label htmlFor=''>
                  {' '}
                  <Trans i18nKey='header.modalregistration.item11' />
                </label>
                <TextField
                  {...register('IIN', {
                    required: 'Введите ИИН',
                    pattern: {
                      value: /^\d+$/,
                      message: 'Вводить можно только цифры',
                    },
                    maxLength: {
                      value: 12,
                      message: 'Максимум 12 цифр',
                    },
                    minLength: {
                      value: 12,
                      message: 'Минимум 12 цифр',
                    },
                  })}
                />
                <div className={s.validate_form_reg}>
                  {errors?.IIN && <span>{errors?.IIN?.message}</span>}
                </div>
              </div>
            </div>
            <div
              className={s.labelNinputReg}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '42px',
                position: 'relative',
              }}
            >
              <label htmlFor=''>
                <Trans i18nKey='header.modalregistration.item12' />*
              </label>
              <TextField
                id='outlined-required'
                type='text'
                {...register('organization', {
                  required: 'Введите наименование организации',
                })}
              />
              <div className={s.validate_form_reg}>
                {errors?.organization && <span>{errors?.organization?.message}</span>}
              </div>
            </div>
            {isLoadingAuth && (
              <div className={s.status_auth} style={{ position: 'relative', top: '-18px' }}>
                <div>
                  <Trans i18nKey='header.login.item6' />
                </div>
                <LoadingDots />
              </div>
            )}
            {ncaLayerStatus && (
              <div
                className={s.ncaLayerLoad_modal}
                style={{ textAlign: 'center', marginBottom: '33px' }}
              >
                {ncaLayerStatus}
              </div>
            )}
            {ncaLayerErr && (
              <div className={s.validate_form} style={{ textAlign: 'center' }}>
                {ncaLayerErr}
              </div>
            )}
            {isErrorReg && (
              <div className={s.validate_form} style={{ textAlign: 'center' }}>
                {isErrorRegStatus}
              </div>
            )}
            {!confirmed && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className={s.btn_register}
                  style={{ width: 200, marginRight: 24, marginTop: '0' }}
                  onClick={handlBackStageFullReg}
                >
                  <Trans i18nKey='header.modalregistration.item13' />
                </button>

                <button className={s.btn_login} style={{ width: 200 }}>
                  <Trans i18nKey='header.modalregistration.item14' />
                </button>
              </div>
            )}
          </form>

          {confirmed && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                className={s.btn_register}
                style={{ width: 200, marginRight: 24, marginTop: '0' }}
                onClick={handlBackStageFullReg}
              >
                <Trans i18nKey='header.modalregistration.item13' />
              </button>
              <button
                className={s.btn_login}
                style={{ width: 200 }}
                onClick={confirmFormRegistation}
              >
                <Trans i18nKey='QuestionBlock.item7' />
              </button>
            </div>
          )}
        </Box>
      </Modal>
      <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModalSuccess}>
          <div className={s.close_auth_modal} onClick={handleCloseSuccessModal}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_auth_modal}>
            <div className={s.title_auth_modal}>
              <Trans i18nKey='header.modalregistration.item16' />
            </div>
          </div>
          <div className={s.content_success_modal}>
            <Trans i18nKey='header.modalregistration.item15' />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className={s.btn_login}
              style={{ width: 200 }}
              onClick={handleCloseSuccessModal}
            >
              <Trans i18nKey='Notifications.item6' />
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
